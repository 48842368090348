<template>
  <draggable v-model="drapList" :disabled="!canDrag" 
    :delay="1"
    :fallbackTolerance="3"
    chosenClass="chosen" forceFallback="true" ghostClass="ghostClass" animation="1000" 
     @start="onStart" @end="onEnd" @sort="onSort"
    :style="{'--cursor':!canDrag ? 'default' :'move'}"
    handle=".handle"
    >
    <transition-group name="flip-list" tag="div">
      <div class="item input-wrapper pointer" 
        v-for="(litem,lind) in drapList" :key="lind + 'i'" 
        :class="{'active':current==lind}"
      >
        <div class="list-item flex flex_jcbetween flex_acenter">
          <div class="list-item-inner flex1 flex flex_jcbetween flex_acenter">
            <div class="list-item-content flex flex_acenter" >
              <el-input class="modal-sets-inlineinput noborder flex1 fontsize_14 color_222" 
                :value="litem" :placeholder="placeholder" 
                @input="handlerInput($event, lind)"
                @blur="handlerBlur($event, lind)"></el-input>
            </div>
            <div class="handle list-item-tools flex flex_acenter" >
              <div v-for="(bitem,bind) in setProps.action" :key="'rbtn'+bind" class="list-item-one">
                <div v-if="bitem.display_type === 'icon'" 
                  class="list-item-action pointer prel "
                  @click.stop="tapAction(litem,lind,bitem.type)" >
                  <i class="icon-edit-black fontsize_14 color_222 icon_color_222" v-if="bitem.title == '编辑'"></i>
                  <i class="icon-menu-del fontsize_14 color_222 all_del_icon icon_color_222 icon_hover_E74362" v-else-if="bitem.title == '删除'"></i>
                  <i class="icon-page-copy fontsize_14 color_222 icon_color_222" v-else-if="bitem.type == 'copy'"></i>
                  <span class="icon-tips fontsize_12 color_222">{{bitem.title}}</span>
                </div>
                <div v-else-if="bitem.display_type === 'button'" @click.stop="tapAction(litem,lind,bitem.type)" class="margin_left5 pointer bgc_F7F7F7">
                  {{bitem.title}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </draggable>
</template>

<script>
//导入draggable组件
import draggable from 'vuedraggable';
// handle:可拖动元素，无法拖动句柄里过滤input
export default {
  props: {
    setProps: Object,
    list: {
      type: Array,
      default: function() {
        return []
      }
    },
    current: Number,
    type: String, //['hotgraphic']
    canDrag: { //是否可以拖拽
      type: Boolean,
      default: false
    },
    placeholder: String
  },
  data() {
    return {
      drapList:this.list,
      drag:false,
      "activeNames":""
    };
  },
  watch: {
    list: {
      immediate:true,
      deep: true,
      handler(newVal) {
        this.drapList = newVal || [];
      }
    }
  },
  components: {
    draggable,
  },
  methods: {
    //开始拖拽事件
    onStart(){
      this.drag=true;
      this.$emit('closeOptionsModal'); //关闭弹窗
    },
    //拖拽结束事件
    onEnd() {
      this.drag=false;
      
      this.$emit("updateSort", JSON.parse( JSON.stringify( this.drapList ) ) );
    },
    onSort(evt) {
      let { newDraggableIndex, oldDraggableIndex } = evt;
      // console.log('onSort',newDraggableIndex, oldDraggableIndex);
    },
    tapAction(item,ind,type) {
      item = JSON.parse(JSON.stringify(item));
      switch(type) {
        case "edit":
          // 编辑
          this.$emit("edit",{item:item,index:ind});
          break;
        case "copy":
          // 复制
          this.$emit("copy",{item:item,index:ind});
          break;
        case "delete":
          // 删除
          this.$emit("del",{item:item,index:ind});
          break;
      }
    },
    handlerInput(value, index) {
      if(this.drapList[index] === value) return;
      this.drapList[index] = value;
      this.$forceUpdate();
      this.$emit("change",{item:value,index});
    },
    handlerBlur(event, index) {
      let value = event.target.value;
      if(this.drapList[index] === value) return;
      this.drapList[index] = value;
      this.$forceUpdate();
      this.$emit("change",{item:value,index});
    },
  },
}
</script>

<style lang="less" scoped>
  .flip-list-move {
    transition: transform 1s;
  }

   /*被拖拽对象的样式*/
  .item {
    cursor: var(--cursor);
  } 
  /*选中样式*/
  .chosen {
    
  }
  /*停靠位置样式*/
  .ghostClass {
    opacity: 0.5 !important;
    border-width: 1px;
    border-color: #000;
    border-style: dashed !important;
  }

  .input-wrapper {
    box-sizing: border-box;
    padding: 8px 16px;
    transition: background 0.6s;
    &.active,&:hover {
      background: #EDEDED;
    }
  }
  // 头部
  .list-item {
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
    padding: 4px 8px;
    height: 46px;
    width: 100%;
    border-radius: 4px;
  }
  .list-item-content {
    // padding-left: 8px;
    width: calc(100% - 82px);
    /deep/.el-input {
      border: none;
    }
    /deep/.el-input__inner {
      padding-left: 0;
    }
    .list-item-title {
      // width: 144px;
      /deep/p{
        font-size: 14px!important;
        color: #222!important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      /deep/span {
        font-size: 14px!important;
        color: #222!important;
      }
    }
    .list-title-richhtml {
      // max-width: 206px;
      width: 13vw;
      height: 18px;
      line-height: 18px;
    }
  }
  .list-item-tools {
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    
    .list-item-action {
      margin-left: 8px;
      &:hover {
        & > .icon-tips {
          display: block;
        }
      }

      >i {
        display: block;
        position: relative;
        border-radius: 4px;
        width: 33px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: #505050;
        background-color: #FAFAFA;
        &:hover {
          background-color: #e4e4e4;
          .icon-tips {
            display: block;
          }
        }
      }
    }

    .icon-tips {
      display: none;
      position: absolute;
      left: 50%;
      bottom: -2px;
      transform: translateY(100%) translateX(-50%);
      background: #F2F2F1;
      border: 1px solid #DADADA;
      border-radius: 1px;
      padding: 0 6px;
      height: 17px;
      line-height: 17px;
      white-space:nowrap;
    }
  }

  .title_icon {
    box-sizing: border-box;
    flex-shrink: 0;
    border: 1px solid #D4D4D4;
    border-radius: 1px;
    width: 38px;
    height: 38px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .round_icon {
    box-sizing: border-box;
    border: 1px solid #D4D4D4;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    // background-size: 24px 24px;
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: center;
  }
  .round-arrow {
    margin-right: 8px;
    width: 14px;
    transition: all 0.5s;
    &.active {
      transform: rotate(180deg);
    }
  }

  .modal-sets-inlineinput.noborder {
    // border: none;
  }
</style>