  <template>
  <div class="Accordion breakword">
    <!-- Accordion Header -->
    <div class="AccordionHeader" @click="Shrink">
      <slot name="header"></slot>
    </div>
    <!-- Accordion Body -->
    <div class="AccordionBody" ref="AccordionBody">
      <!-- 接受slot的容器 -->
      <div class="ContentA">
        <slot name="content"></slot>
        <p class="isshrink" v-show="isShrink"></p>
      </div>
    </div>
    <div class="hidden-content" ref="heightBase">
      <slot name="content"></slot>
      <p class="isshrink" v-show="isShrink"></p>
    </div>
  </div>
</template>
  <script>
/**
 * Accordionindex   控制展开收缩 -1收缩 
 * 
 */
export default {
  props: ["Accordionindex"],
  data() {
    return {
      RightContent: "收缩",
      isshow: this.Accordionindex, //-1 收缩状态
      isShrink: false,
    };
  },
  watch: {
    Accordionindex: {
      handler(n) {
        if(n == -1) {
          this.$nextTick(() => { //如果为-1，默认不显示，收缩状态
            let AllHiden = this.$refs.AccordionBody;
            AllHiden.style.height = 0 + "px";
          });
        }
      },
      immediate: true
    }
  },
  methods: {
    Shrink() {
      let AllHiden = this.$refs.AccordionBody;
      let heightBase = this.$refs.heightBase; //高度基准
      // let eleMoreHeight = AllHiden.childNodes[0].offsetHeight; //不用
      let eleMoreHeight = heightBase.childNodes[0].offsetHeight; //获取基准高度
      AllHiden.style.height = eleMoreHeight + "px";
      
      setTimeout(() => {
        if (this.isshow == 0) {
          AllHiden.style.height = "0px";
          this.isshow = 1;
          this.RightContent = "展开";
          this.isShrink = false;
        } else {
          AllHiden.style.height = eleMoreHeight + "px";
          this.isshow = 0;
          this.RightContent = "收缩";
          this.isShrink = true;
        }
      }, 1);
    },
  },
};
</script>
<style scoped>
.Accordion {
  width: 100%;
  height: auto;
}
.AccordionHeader {
  width: 100%;
  display: flex;
}
/* 动画效果采用css3来实现 */
.AccordionBody {
  position: relative;
  height: auto;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  -o-transition: height 0.4s;
  transition: height 0.4s;
}
.ClickArea {
  flex: 1;
}
.isshrink {
  width: 100%;
  height: 20px;
}
/* 隐藏内容区域用来测高度 */
.hidden-content {
  position: absolute;
  z-index: -1;
  visibility: hidden;
}
</style>
    