<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true,'reset':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-slider"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @reset="initOptionRelative"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div class="slider-outer margin_top16 prel">
        <div class="slider-label flex flex_jcbetween flex_acenter breakword">
          <span v-if="detail['component-labelStart']" class="fontsize_12 color_6E6E6E">{{detail['component-labelStart']}}</span>
          <span v-if="detail['component-labelEnd']" class="fontsize_12 color_6E6E6E">{{detail['component-labelEnd']}}</span>
        </div>
        <div  v-if="sliderRange.step" class="slider-wrapper">
          <div class="slider-content" :class="{'first-active':sliderData.value==sliderRange.min, 'last-active':sliderData.value==sliderRange.max}">
            <div class="text" 
              :class="{'active':sliderData.value==v, 'noshow':!sliderRange.showScale , 'nonum':!sliderRange.showScaleNumbers }"
              :style="{'left': (v - sliderRange.min) * 100 / (sliderRange.max - sliderRange.min) +'%'}"
              v-for="(v,ind) in sliderArr" :key="v"
            ><span @click="answerState == -1 ? tapNumber(v) :''" style="padding:0 10px;">{{v}}</span></div>
            <span v-if="sliderRange.showScale && sliderRange.showScaleNumbers" @click="tapNumber(sliderRange.max)" class="text-last">{{sliderRange.max}}</span>
            <div class="round-text"
              v-if="sliderRange.showScaleIndicator && detail['component-__rangeType'] === 'number' || !showRightAnswer || !Array.isArray(selected)"
              :style="{'left': (sliderData.value - sliderRange.min) * 100 / (sliderRange.max - sliderRange.min) +'%',
                'width': maxWidth + 'px',
                'height': maxWidth + 'px'}"
              >
              <span v-if="sliderRange.showNumber">{{sliderData.value}}</span>
            </div>
            <div v-else-if="sliderRange.showScaleIndicator && detail['component-__rangeType'] === 'range_number' && showRightAnswer">
              <div class="round-text"
                v-for="(item) in selected"
                :key="item"
                :style="{'left': (item - sliderRange.min) * 100 / (sliderRange.max - sliderRange.min) +'%'}">
                <span v-if="sliderRange.showNumber">{{item}}</span>
              </div>
            </div>
          </div>
          <el-slider v-model="sliderData.value" 
            :disabled="answerState != -1"
            :step="sliderRange.step" :show-tooltip="false" 
            :min="sliderRange.min"
            :max="sliderRange.max"
            :range="sliderData.range"
            @change="sliderChange"
            class="slider-self"></el-slider>
        </div>
        <questionsBtnGroup 
          :answerState="answerState" 
          :singeBtnShowMarking="singeBtnShowMarking"
          :leftBtnName="getBtnName('left') && detail[getBtnName('left')]"
          :feedbackBtnName="getBtnName('left') && detail[getBtnName('showFeedback')]"
          :canShowMarking="detail[singleData.canShowMarking]"
          :canShowFeedback="detail['component-_canShowFeedback']"
          :shouldDisplayAttempts="detail['component-_shouldDisplayAttempts']"
          :remainingAttemptsText="detail['component-_buttons-properties-remainingAttemptsText']"
          :attempts="detail['component-_attempts']"
          @tapBtn="tapBtn"></questionsBtnGroup>
      </div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_slider_sub'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_slider'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
      <fd-dialog
        custom-class="hotgraphic-dialog"
        :visible.sync="feedbackDialogSet.visible"
        width="100%"
        :modal-append-to-body="true"
        :append-to-body="true"
        :before-close="beforeCloseDialogSet"
        :style="containerStyle">
        <div class="feedback-popup-inner" >
          <div class="notify__popup-inner">
            <div class="notify__content">
              <div class="notify__content-inner">
                <div class="notify__title" id="notify-heading">
                  <div class="notify__title-inner" role="heading" aria-level="1">
                    <p class="fontsize_16"><strong v-html="detail['component-_feedback-properties-title']"></strong></p>
                  </div>
                </div>
                <div class="notify__body">
                  <div class="notify__body-inner">
                    <p v-if="answerState == 1" v-html="detail['component-_feedback-properties-correct']"></p>
                    <p v-else-if="answerState == 0" v-html="detail['component-_feedback-properties-_incorrect-properties-final']"></p>
                  </div>
                </div>
              </div>
            </div>
            <button @click="feedbackDialogSet.visible = false" class="btn-icon notify__btn-icon notify__close-btn js-notify-close-btn" aria-label="关闭弹出窗口">
              <div class="el-icon-close fontsize_24 color_fff"></div>
            </button>
          </div>
          <a class="a11y-focusguard a11y-ignore a11y-ignore-focus" role="presentation">&nbsp;</a>
        </div>
      </fd-dialog>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import questionsBtnGroup from "@/renderer/components/FormItem/questions-btn-group.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import questionsCommon from '@/renderer/mixins/questions/index';

/**
 * 滑动题 组件
 * 1、start、end、step、answer、answerRange 
 * start < end ; 
 * step < end; 
 * step > 0;  
 * start <= answer <= end; 
 * 
 * 2、切换-是否显示正确答案按钮 (canShowModelAnswer)
 * 3、切换-是否显示标记- 用户选中项的对错标记 (canShowMarking)
*/
export default {
  mixins: [hideModal, componentCommon, questionsCommon],
  components:{ topTools, rightForm, scaffoldForm, objForm, questionsBtnGroup, contentList },
  props: {
    containerStyle: Object,
  },
  data() {
    return {
      currentForm: {},
      componentName:"adapt-contrib-slider",
      ckeditorPrefix:"componentSlider",
      feedbackDialogSet: {
        visible: false, //是否显示
        title: "",
      },
      userSelected:"", //用户选中
      selected: "", //选中
      firstFeedback: true, //是否显示首次提交显示反馈
      answerState: -1, //答题状态： -1 未答题（未提交）、0 提交并且错误、1 提交并且正确、
      showRightAnswer: false, //是否显示了正确答案
      singleData: {
        canShowMarking:"component-_canShowMarking", //显示标记 - 正确错误标记
        canShowModelAnswer:"component-_canShowModelAnswer", //显示答案 - 按钮
      },
      sliderData: {
        value:0, //当前值
        range: false, //范围选择：2点可拖动点
      },
      maxWidth: 32
    };
  },
  computed: {
    sliderRange() {
      return {
        step: Number(this.detail["component-_scaleStep"]),
        max: Number(this.detail["component-_scaleEnd"]),
        min: Number(this.detail["component-_scaleStart"]),
        showScale: this.detail["component-_showScale"], //显示滑动位置
        showScaleNumbers: this.detail["component-_showScaleNumbers"], //显示滑动位置数字
        showNumber: this.detail["component-_showNumber"], //显示圆球指示器-数字
        showScaleIndicator: this.detail["component-_showScaleIndicator"], //marker 圆球指示器
      }
    },
    sliderArr() {
      let arr = [];
      let start = this.sliderRange.min;
      let end = this.sliderRange.max;
      let step = this.sliderRange.step;
      // 计算小数后几位
      let isDecimals = step && step.toString().indexOf(".") || -1;
      let decimalsNum = isDecimals != -1 && step.toString().split(".")[1].length || 0;
      
      let len = Math.ceil( (end - start)/step);

      for(let i = 0; i < len;i++ ) {
        let currentVal = i*step + start;
        //保留小数后几位
        arr.push( currentVal.toFixed(decimalsNum) );
      }
      return arr;
    },
    rightAnswer() { //正确答案
      let rangeType = this.detail["component-__rangeType"]; // number , range_number
      
      if(rangeType === "number") return this.detail["component-_correctAnswer"] || -1;

      // range_number
      return {
        "top": this.detail["component-_correctRange-properties-_top"],
        "bottom": this.detail["component-_correctRange-properties-_bottom"],
        "scaleStep": this.detail["component-_scaleStep"]
      };
    },
    singeBtnShowMarking() {
      // 单个按钮-显示标记
      return this.detail[this.singleData.canShowMarking] && !this.detail['component-_canShowFeedback'] && this.answerState != -1;
    },
  },
  watch: {
    sliderArr: {
      deep:true,
      immediate:true,
      handler(n) {
        if(n) {
          this.$nextTick( () => {
            this.getMaxWidth();
          } );
        }
      }
    },
    sliderRange: {
      deep: true,
      handler(n) {
        this.initOptionRelative();
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("loaded");
      this.initOptionRelative();
    });
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    getMaxWidth() {
      let roundEles = this.$el.querySelectorAll(".slider-content .text span");
      let maxWidth = 0;
      roundEles.forEach( ele => {
        if(ele.offsetWidth > maxWidth) {
          maxWidth = ele.offsetWidth;
        }
      } );
      this.maxWidth = maxWidth - 10; //左右边距各10，各减去8
    },
    beforeCloseDialogSet(done) {
      this.feedbackDialogSet.visible = false;
      done();
    },
    tapNumber(num) {
      // 点击数字也可移动slider
      this.sliderData.value = Number(num);
      this.userSelected = Number(num);
    },
    sliderChange(val) {
      //选中改变
      this.userSelected = val;
    },
    initOptionRelative() {
      // 初始化选项相关数据
      // 初始状态为选中最小值
      this.selected = this.sliderRange.min;
      this.userSelected = this.sliderRange.min;
      this.sliderData.value = this.sliderRange.min;
      this.answerState = -1;
      this.showRightAnswer = false;
      this.firstFeedback = true;
    },
    tapBtn(btnWhere = 1) {
      // btnWhere: 1 左侧按钮 提交或者查看正确答案; 2 反馈
      let { firstFeedback, userSelected, answerState, showRightAnswer } = this;
      let rangeType = this.detail["component-__rangeType"]; // number , range_number
      //是否开启了显示反馈
      let canShowFeedback = this.detail["component-_canShowFeedback"];
      
      //答案集合
      let answerSet = this.rightAnswer;
      
      // 是否开启答案显示
      let canShowModelAnswer = this.detail[this.singleData.canShowModelAnswer];
      // 是否拖动选择
      let isSelect = userSelected !== "";

      // 点击提交，显示反馈
      if(btnWhere == 1 && !!firstFeedback && !!canShowFeedback && isSelect) {
        this.feedbackDialogSet.visible = true;
        this.firstFeedback = false;
      }

      if(btnWhere == 1  && isSelect && answerState == -1) {
        // 提交
        let isTrue = false;
        if(rangeType === "number") {
          isTrue = userSelected != "" && answerSet == userSelected
        }else {
          // range_number
          isTrue = userSelected != "" && userSelected <= answerSet.top && userSelected >= answerSet.bottom;
        }
        this.answerState = !!isTrue ? 1 : 0;
        return;
      }
      
      if(canShowModelAnswer && btnWhere == 1 && isSelect && answerState == 0) {
        // 查看答案 - 隐藏答案
        //隐藏答案 - 显示用户答案
        if(showRightAnswer) {
          this.selected = "";
          this.sliderData.value = this.userSelected;
          this.showRightAnswer = false;
          return;
        }
        // 显示答案
        if(rangeType === "number") {
          this.selected = Number(answerSet);
        } else if(answerSet.top && answerSet.bottom){ //答案是范围时，全部标记
          // rangeType = "range_number"
          let bottom = +answerSet.bottom || 0;
          let top = +answerSet.top || 0;
          let scaleStep = +answerSet.scaleStep || 0;

          this.selected = [];
          this.sliderData.value = bottom;
          
          for(let i=bottom; i<=top; i= i + Number(scaleStep)) {
            this.selected.push(i);
          }
        }else {
          this.selected = Number(answerSet.bottom || answerSet.top);
        }
        
        if(!Array.isArray(this.selected))  this.sliderData.value = this.selected;
        this.showRightAnswer = true;
        return;
      }

      if( btnWhere == 2 && answerState != -1 && !!canShowFeedback) {
        // 已答题，开启反馈，显示反馈弹窗
        this.feedbackDialogSet.visible = true;
        return;
      }
    },
    openInnerDrawer({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value}) {
      // 显示\隐藏二层弹出窗口
      this.innerDrawer.form = {};
      this.innerDrawer.submitAsObj = submitAsObj || false; //整体提交
      this.innerDrawer.key_name = key_name;
      this.innerDrawer.parentDetail = parentDetail;
      this.innerDrawer.title = title;
      this.innerDrawer.propList = list;
      this.innerDrawer.current = index;
      if(value) this.innerDrawer.form = value;

      if(!visible || visible == 2) {
        this.innerDrawer.visible = false;
        return;
      }else{
        this.innerDrawer.visible = true;
      }
    },
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/

  @sliderItemColor: var(--item-color, #4D98B7);
  @sliderItemInverted: var(--item-color-inverted, #fff);

  @boxMargin: 10px;
  .slider-outer {
    // max-width: 882px;
  }
  .slider-wrapper {
    .slider-content {
      box-sizing: border-box;
      margin: 0 @boxMargin;
      width: calc(100% - @boxMargin*2);
      height: 59px;
      border-bottom: 2px solid #A1A1A1;
      display: flex;
      position: relative;
      .text-last {
        position: absolute;
        right: 0;
        transform: translateX(50%);
        color: var(--font-color, #333);
      }
      .round-text {
        position: absolute;
        top: 2px;
        left: 0;
        transform: translate(-50%, -25%);
        border-radius: 50%;
        height: 32px;
        width: 32px;
        text-align: center;
        color: @sliderItemInverted;
        background-color: @sliderItemColor;
        transition: left 100ms;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          line-height: 1;
          transform: translateX(-50%) translateY(-50%);
        }
      }
      &::before {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 2px;
        height: 20px;
        background-color:#A1A1A1;
        content: "";
      }
      &::after {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 2px;
        height: 20px;
        background-color:#A1A1A1;
        content: "";
      }
      &.first-active {
        &::before {
          background-color:@sliderItemColor;
        }
      }
      &.last-active {
        &::after {
          background-color:@sliderItemColor;
        }
      }
      .text {
        position: absolute;
        bottom: 0;
        left: 0;
        flex: 1;
        height: 20px;
        font-size: 16px;
        color: var(--font-color, #222);
        &>span {
          position: relative;
          top: -100%;
          // vertical-align: text-top;
          display: inline-block;
          line-height: 1;
          transform: translateX(-50%) translateY(-100%);
        }
        &.active {
          &::after {
            background-color:@sliderItemColor;
          }
        }
        // 不显示刻度=不显示刻度线+数字
        &.noshow {
          display: none;
        }
        // 不显示刻度数字
        &.nonum {
          color: transparent;
        }
        &:nth-child(1){
          &::after {
            display: none;
          }
        }
        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 2px;
          height: 20px;
          // transform: translateX(-4px);
          background-color:#A1A1A1;
          content: "";
        }
      }
    }
  }

  // 滑动条
  .slider-self {
    margin: 0 @boxMargin;
    width: calc(100% - @boxMargin*2);
    /deep/.el-slider__runway {
      // height: 2px;
      // background: #A1A1A1;
      height: .375rem;
      background-color: #dddddd;
      .el-slider__bar {
        // height: 2px;
        height: .375rem;
        background: @sliderItemColor;
      }
    }
    /deep/.el-slider__button-wrapper {
      top: 50%;
      transform: translate(-40%, -50%);
      z-index: 0;
    }
    /deep/.el-slider__button {
      width: 14px;
      height: 14px;
      border: 4px solid @sliderItemColor;
    }
  }
  // 滑动首尾标签
  .slider-label {
    padding: 4px 0 20px;
    width: 100%;
    line-height: 1;
  }
</style>