<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-image"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div class="component-image-wrapper prel" :class="{'isFullWidth':isFullWidth}">
        <el-image :src="detail['component-_graphic-properties-large']" @load="loadedImg" @error="errorImg" :lazy="lazyImage"  class="img" ></el-image>
        <div v-if="detail['component-_graphic-properties-attribution']" class="component-image-tips poverOfHtml color_fff flex flex_acenter padding_left15" v-html="detail['component-_graphic-properties-attribution']"></div>
      </div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            ckeditorNameId="component-_id"
            :ckeditorPrefix="ckeditorPrefix"
            @open="openInnerDrawer"
            @close="closeRightInner"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222 poverOfHtmlTile richHtml_c222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <scaffoldForm 
                v-if="innerDrawer.visible"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_image'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import { mapState } from 'vuex';
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import imageCommon from '@/renderer/mixins/image/index';

/**
 * 图片组件
*/
export default {
  mixins: [hideModal, componentCommon, imageCommon],
  components:{ topTools, rightForm, scaffoldForm, contentList },
  props: {
    isFullWidth: Boolean,
  },
  data() {
    return {
      componentName:"adapt-contrib-graphic",
      currentForm: {},
      ckeditorPrefix:"componentGraphic",
      inlineInputObj: { //页面输入框
        displayTitle: "1",
        title: "1",
        body: "1",
        instruction: "1",
      },
    };
  },
  computed: {
    ...mapState({
      lazyImage: state => state.course.lazyImage,
    }),
    atLeastOne() { //至少一个文本显示
      let target = -1;
      target = this.list.findIndex(item => 
        item.display == 1 && item.input_type === 'richtext_mini' && this.inlineInputObj[item.properties_name] && this.detail[item.key_name]
      )
      return target > -1;
    }
  },
  watch: {
  },
  mounted() {
    this.$nextTick(() => {
      if(!this.detail['component-_graphic-properties-large']) this.$emit("loaded");
    });
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    getImgSrc(src) {
      if(src && src.indexOf("x-oss-process=style/") == -1) {
        if(src.indexOf("?") >= 0) return src + `&x-oss-process=style/${'xl'}`;
        else return src + `?x-oss-process=style/${'xl'}`;
      }
      return src;
    },
    closeInnerDrawer(isBtn = 2) {
      this.innerDrawer.visible = false;
      // this.innerForm = {};
    },
    closeRightInner({list, title, visible} = {}) {
      // 显示\隐藏二层弹出窗口
      if(!visible) {
        this.innerDrawer.visible = false;
        return;
      }
    },
    openInnerDrawer({list, title, visible}) {
      // 显示\隐藏二层弹出窗口
      this.innerDrawer.title = title;
      this.innerDrawer.propList = list;

      if(!visible || visible == 2) {
        this.innerDrawer.visible = false;
        return;
      }else{
        this.innerDrawer.visible = true;
      }
    },
  },
}
</script>

<style lang="less" scoped>
    @import '~@/assets/css/component.less'; /*引入公共组件样式*/

    .hasValue {
      margin-bottom: 16px;
    }
    .isFirstAndValue {
      margin-top: 16px;
    }

  // 图片
  @singlePicHei:477px;
  @secondPicHei:232px;
  .component-image-wrapper {
    position: relative;
    margin-top: 16px;
    width: 100%;
    &.isFullWidth {
      // height: @singlePicHei;
      .img {
        width: 100%;
        height: auto;
      }
    }
    .img {
      display: block;
      width: 100%;
      height: auto;
    }
    .component-image-tips {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      padding-right: 15px;
      height: 40px;
      // background-color: rgba(0, 0, 0, 0.3);
      opacity: 0.3;
      background-color: #000000;
      overflow: hidden;
    }
  }

</style>