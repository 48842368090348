<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true,'reset':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-accordion"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @reset="resetData"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <el-collapse v-if="detail['component-_items'] && Array.isArray(detail['component-_items'])" 
        v-model="collapseActiveName" 
        :accordion="detail['component-_shouldCollapseItems']" 
        class="accordion-collapse margin_top16 prel">
        <el-collapse-item v-for="(ditem, dind) in detail['component-_items']" :key="'accordion_'+dind" class="" :name="''+(dind)">
          <template slot="title">
            <span v-html="ditem.title" class="accordion-head-text fontsize_16 color_fff"></span>
          </template>
          <!-- <div v-if="ditem.body" v-html="ditem.body" class="accordion-content fontsize_14 color_222 lineheight_20 margin_bot24"></div> -->
          <ckeditor
            type="simpleInline" 
            class="accordion-content fontsize_14 color_222 lineheight_20 margin_bot24"
            :id="ckeditorPrefix+'-'+ currentForm.id +'-inline' +'-body-'+dind"
            :value="ditem.body"
            :throttle="throttleDelay"
            @input="itemsInput($event,dind,{parentProp:'component-_items',prop:'body'})"
            @blur="itemsBlur($event,dind,{parentProp:'component-_items',prop:'body'})"
            @blurNotUpdate="itemsBlur($event,dind,{parentProp:'component-_items',prop:'body'},false)"
          >
          </ckeditor>
          <el-image v-if="ditem._graphic && ditem._graphic.src" 
            @load="loadedImg" 
            @error="errorImg" 
            :src="ditem._graphic.src + '?x-oss-process=style/xl'" 
            fit="contain">
          </el-image>
        </el-collapse-item>
      </el-collapse>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            :listItemSet="{'canDrag':true}"
            @closeOptionsModal="closeOptionsModal"
            @sortOptionList="handleSort"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur"
            @changeListItem="changeListItem">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_narrative_pic'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_narrative'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import imageCommon from '@/renderer/mixins/image/index';
import listCommon from '@/renderer/mixins/list/index';

/**
 * 手风琴
*/
export default {
  mixins: [hideModal, componentCommon, imageCommon, listCommon],
  components:{ topTools, rightForm, scaffoldForm, objForm, contentList },
  data() {
    return {
      currentForm: {},
      ckeditorPrefix:"componentAccordion",
      componentName:"adapt-contrib-accordion", //组件名
      collapseActiveName: "", //展开项
    };
  },
  computed: {
    imgTotal() {
      // 图片总数
      let items = this.detail['component-_items'];
      let length = 0;
      
      items.forEach(v => {
        if(v._graphic && v._graphic.src && v._graphic.src != undefined && v._graphic.src != null) {
          length++;
        }
      });
      return length;
    },
  },
  created() {
    this.$watch(
      function () { // 第一个函数就是处理你要监听的属性，只要将其return出去就行
        // 展开项
        return this.detail['component-_shouldExpandFirstItem'];
      },
      (val, old) => {
        // 是否展开第一个
        this.collapseActiveName = val ? "0" : "";
      }
    );
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    resetData() {
      let shouldExpandFirstItem = this.detail['component-_shouldExpandFirstItem'];
      
      this.collapseActiveName = shouldExpandFirstItem ? "0" : "";
    },
    openInnerDrawer({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value}) {
      // 显示\隐藏二层弹出窗口
      
      this.innerDrawer.form = {
        "_graphic":{}
      };
      this.innerDrawer.submitAsObj = submitAsObj || false; //整体提交
      this.innerDrawer.key_name = key_name;
      this.innerDrawer.parentDetail = parentDetail;
      this.innerDrawer.title = title;
      this.innerDrawer.propList = list;
      this.innerDrawer.current = index;
      if(value) {
        Object.keys(value).forEach( vitem => {
          if(vitem === "_graphic" && Array.isArray(value[vitem])) {
            value[vitem] = {};
          }
          value[vitem] = value[vitem] ? value[vitem] : ""
        } );
        this.innerDrawer.form = value;
      }

      if(!visible || visible == 2) {
        this.innerDrawer.visible = false;
        this.collapseActiveName = "";
        return;
      }else{
        if(index >= 0) this.collapseActiveName = index + "";

        if(this.innerDrawer.visible == true) {
          this.innerDrawer.visible = false;
          setTimeout(() => {
            this.innerDrawer.visible = true;
          }, 220);
        }else {
          this.innerDrawer.visible = true;
        }
      }
    },
    closeInnerDrawer({list, title, visible} = {}) {
      // 显示\隐藏二层弹出窗口
      if(!visible) {
        this.collapseActiveName = "";
        this.innerDrawer.visible = false;
        return;
      }
    },
    itemsInput(value, index, {parentProp, prop}) {
      let obj = {};
      if(this.detail[parentProp] && this.detail[parentProp][index]) {
        this.detail[parentProp][index][prop] = value;
      }
      obj[parentProp] = this.detail[parentProp];
      
      this.saveDetail(obj, false);
    },
    itemsBlur(value, index, {parentProp, prop}, isUpdate = true) {
      let obj = {};
      if(this.detail[parentProp] && this.detail[parentProp][index]) {
        this.detail[parentProp][index][prop] = value;
      }
      obj[parentProp] = this.detail[parentProp];
      
      !!isUpdate && this.saveDetail(obj, false);
    },
    changeListItem(obj) {
      this.saveDetail(obj, true);
    },
  },
}
</script>

<style lang="less" scoped>
    @import '~@/assets/css/component.less'; /*引入公共组件样式*/
    
    @itemColor:var(--item-color, #4D98B7);
    @itemColorHover: var(--item-color-hover, #4D98B7);
    @ItemColorInverted: var(--item-color-inverted, #fff);
    @ItemColorInvertedHover: var(--item-color-inverted-hover, #fff);
    .accordion-head-text,
    /deep/.el-collapse-item__arrow::before {
      color: @ItemColorInverted !important;
    }

    .accordion-content {
      ul {
        list-style-type: disc;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 40px;
      }
    }
  
  // 折叠面板
  .accordion-collapse {
    border: none;
    /deep/.el-collapse-item__wrap {
      border-bottom: none;
    }
    /deep/.el-collapse-item {
      margin-bottom: 0.25rem;
    }
    /deep/.el-collapse-item__header {
      box-sizing: border-box;
      border-bottom: none;
      height: auto;
      // min-height: 54px;
      background-color: @itemColor;
      padding: 15px 16px;
      line-height: 1.5;
      font-weight: normal;
      &:hover {
        background-color: @itemColorHover;
        .accordion-head-text,
        .el-collapse-item__arrow::before {
          color: @ItemColorInvertedHover !important;
        }
      }
      p,span {
        word-break: break-all;
      }
    }
    /deep/.el-collapse-item__arrow.is-active {
      transform: rotate(0);
    }
    /deep/.el-icon-arrow-right {
      font-family: 'icomoon' !important;
      font-size: 18px;
    }
    /deep/.el-icon-arrow-right.is-active:before {
      content: "\e9cc";
      color: #fff;
    }
    /deep/.el-icon-arrow-right:before {
      content: "\e9cb";
      color: #fff;
    }
    /deep/.el-collapse-item__content {
      padding: 24px 20px;
      line-height: 1;
    }
  }
</style>