<template>
  <div>
    <fdMatch 
      :ref="'match_' + lind"
      v-for="(litem,lind) in list" :key="'match-group'+lind"
      class="margin_bot16"
      :showModal="currentModal == lind"
      :placeholder="placeholder"
      :options="litem._options"
      :value="litem.value" 
      :label="litem.label" 
      :checked="getCheckStatus(lind)"
      :name="name"
      :disabled="disabled"
      :showRes="showRes"
      @showModal="handleModal($event,lind)"
      @change="handleChange($event,lind)"
      ></fdMatch>
  </div>
</template>

<script>
import fdMatch from "@/renderer/components/FormItem/fd-match.vue";
export default {
  props: {
    name:String, //组别
    selected: Object, //选中值
    showRes: Boolean, //是否显示结果
    placeholder: String,
    disabled: Boolean, //是否禁用
    list: {
      type: Array,
      default: function() {
        return [
          {
            value:"",
            label:"",
            id: 0,
          }
        ];
      }
    }
  },
  components: {
    fdMatch
  },
  data() {
    return {
      currentModal:-1,
      selectedSet: {

      },
    };
  },
  methods: {
    getCheckStatus(index) { 
      // 选中第几项
      let current =this.selected[index];

      if(Array.isArray(current)) return current[0];
      return current != undefined ? current : -1;
    },
    handleChange({item, checked}, pind) { //item 选中项, checked 是下标
      // 选中状态改变
      this.selectedSet[pind] = checked;
      
      let len = Object.keys(this.selectedSet).length;
      if(len < this.list.length) return;
      this.$emit("change", this.selectedSet);
    },
    handleModal(value, index) {
      // 控制选项列表显示
      this.currentModal = !!value ? index : -1;
    },
    initStatus() {
      // 清空选中状态-外部调用
      this.list.forEach((v,i) => {
        let currentElement = this.$refs[`match_${i}`];

        this.selectedSet[i] = -1;
        if( currentElement && Array.isArray(currentElement) ) {
          currentElement[0].cancelSelect();
        }else if( currentElement ) {
          currentElement.cancelSelect();
        }
      });
    },
  }
}
</script>

<style>

</style>