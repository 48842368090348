import Vue from 'vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import Config from '@/libs/config';
import { moveComponent } from '@/libs/api/component'

// 组件公有
export default {
  props: {
    detail: {
      type: Object,
      default: function() {
        return {}
      }
    },
    enabledProgress: Boolean,
    moveInline: Boolean,
  },
  data() {
    return {
      isFirst:true,
      canKeyEnter:Config.titleCkeditorCanKeyEnter, //标题能否回车换行
      throttleDelay:Config.realTimeInput,
      prefix:"component",
      drawerSet: {
        direction: "rtl", //从右往左
        visible: false, //是否显示
        title: "",
        activeName: 0,
      },
      innerDrawer: {
        visible: false, //是否显示
        title: "",
        form:{},
        propList:[],
        submitAsObj: false,
        key_name:"",
        current:-1,
      },
    };
  },
  computed: {
    ...mapState({
      popShowId: (state) => state.popShowId,
      schemaJson: state => state.course.schemaJson
    }),
    componentTitle() {
      return this.schemaJson[this.componentName].title && this.schemaJson[this.componentName].title.replace('组件','') || '';
    },
    isHighlight() {
      return this.popShowId && this.popShowId.includes(this.detail[ 'component-id' ] + '_' + this.componentName) || false;
    },
    tabList() {
      return this.schemaJson[this.componentName] && this.schemaJson[this.componentName].tabgroup_list || []
    },
    list() { //行内编辑框
      let arr = this.tabList[0] && this.tabList[0].setting_list || [];
      let filterArr = arr.filter(v => v.display == 1 && ( v.input_type === 'richtext_mini' || v.input_type === 'richtext' ));
      return filterArr;
    },
  },
  watch: {
    "detail": {
      immediate: true,
      deep: true,
      handler(n,o) {
        if(n) {
          let arr = Object.keys(this.detail);
          if(this.isFirst) {
            this.isFirst = false;
            if(this.currentForm) {
              this.currentForm = JSON.parse(  JSON.stringify( this.detail ) ); //首次赋值
            }
          }
        }
      },
    },
  },
  beforeMount() {},
  beforeDestroy() {},
  methods: {
    ...mapMutations(['openPop','closePop','SET_CUSTOM_CSS','SET_COPYING','SET_COPYTIPS']),
    ...mapActions(['SaveCourse']),
    handleCopying() {
      // 组件复制开启
      // 判断是否有可粘贴区域
      let canPaste = document.querySelectorAll(".select-component-btn").length > 0;
      if(!canPaste) {
        this.SET_COPYTIPS(true);
        return;
      }
      
      this.SET_COPYING({
        copying: true,
        data: {
          course_id: this.detail.course_id,
          component_id: this.detail['component-id'],
        }
      });
    },
    handleMoveInline() {
      // 组件左右移动
      moveComponent({
        course_id: this.detail.course_id,
        block_id: this.detail['block_id'],
        _id: this.detail['component-id'],
        _layout: this.detail['component-_layout'] == 'left' ? 'right' : 'left'
      }).then(res => {
        if(res.errorcode == 0) {
          this.$emit("updateComponent");
        }
      });
    },
    getProgressStatus(item) {
      // 进度条状态-是否开启
      return !!this.enabledProgress 
        && this.currentForm['component-_pageLevelProgress-properties-_isCompletionIndicatorEnabled'] 
        && (item.key_name === 'component-title' || item.key_name === 'component-displayTitle');
    },
    showEdit() {
      // 编辑组件
      let ids = [
        this.detail['component-id']+'_'+this.componentName,
        this.detail['block_id']+'_'+'block',
        this.detail['article_id']+'_'+'article'
      ];
      this.openPop({type: this.componentName, id: this.detail['component-id'], ids});

      this.drawerSet.visible = true;
      this.drawerSet.title = this.schemaJson[this.componentName].title;

      this.$emit("scrollToModule", {id: this.detail['component-id']});
    },
    delComponent() {
      // 删除组件
      this.$emit("del",{
        course_id: this.detail.course_id,
        component_id: this.detail['component-id'],
        title: this.currentForm.title,
        componentTitle: this.schemaJson[this.componentName].title,
        'component-_component': this.detail['component-_component']
      });
    },
    switchDisplay(isShow) {
      // 控制组件是否显示
      let obj = {};
      let prop = this.prefix+'-'+'_isHidden';
      obj[prop] = !isShow;
      this.detail["component-_isHidden"] = !isShow;
      this.$forceUpdate();
      this.saveDetail(obj, false);
    },
    handleInput(value, prop) { 
      //当前页面输入
      if( !!this.drawerSet.visible ) return;
      
      let obj = {};
      obj[prop] = value;
      this.detail[prop] = value; //左侧输入 同步到 右侧弹窗

      this.saveDetail(obj, false);
    },
    formItemInput(value, prop) { //右侧弹窗输入
      let obj = {};
      obj[prop] = value;
      this.$set(this.currentForm, prop, value); //同步到中间区域

      this.saveDetail(obj, true);
    },
    formItemBlur(value,propName, isUpdate = true) {
      let obj = {};
      obj[propName] = value;

      this.$set(this.detail, propName, value);
      this.$set(this.currentForm, propName, value);
      
      !!isUpdate && this.saveDetail(obj, true);
    },
    formChangeBlur(value,propName,leftForm) {
      let obj = {};
      obj[propName] = value;

      this.detail[propName] = value;
      this.saveDetail(obj, false);
    },
    selectChange(e,prop) {
      this.formItemBlur(e,prop);
    },
    objFormChange(args) {
      if(this.detail[this.innerDrawer.key_name] == undefined) return;
      
      let items = JSON.parse( JSON.stringify( this.detail[this.innerDrawer.key_name] ) );
      let current = this.innerDrawer.current;
      if(!items || !Array.isArray(items)) items= [];
      if(current >= 0 && current < items.length)  {
        items.splice(current,1,args.form);
      }else {
        items.push(args.form);
      }
      this.$set(this.detail, this.innerDrawer.key_name, items);
      this.$forceUpdate();
      this.saveDetail({
        [this.innerDrawer.key_name]: items
      }, false);
    },
    handleClose(done) {
      //关闭右侧弹窗
      this.drawerSet.visible = false;
      if(this.innerDrawer.visible) this.innerDrawer.visible = false; //关闭内层
      done();
      this.closePop();
    },
    openInnerDrawer({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value}) {
      // 显示\隐藏二层弹出窗口
      this.innerDrawer.form = {};
      this.innerDrawer.submitAsObj = submitAsObj || false; //整体提交
      this.innerDrawer.key_name = key_name;
      this.innerDrawer.parentDetail = parentDetail;
      this.innerDrawer.title = title;
      this.innerDrawer.propList = list;
      this.innerDrawer.current = index;
      if(value) this.innerDrawer.form = value;

      if(!visible || visible == 2) {
        this.innerDrawer.visible = false;
        this.innerDrawer.current = -1;
        return;
      }else{
        if(this.innerDrawer.visible == true) {
          this.innerDrawer.visible = false;
          setTimeout(() => {
            this.innerDrawer.visible = true;
          }, 220);
        }else {
          this.innerDrawer.visible = true;
        }
      }
    },
    closeInnerDrawer({list, title, visible} = {}) {
      // 显示\隐藏二层弹出窗口
      if(!visible) {
        this.innerDrawer.visible = false;
        return;
      }
    },
    closeOuterDrawer() {
      this.drawerSet.visible = false;
      if(this.innerDrawer.visible) this.innerDrawer.visible = false; //关闭内层
      this.closePop();
    },
    closeInnerPop() {
      // 关闭列表项弹窗
      this.innerDrawer.current = -1;
      this.innerDrawer.visible = false;
      this.$refs.rightForm && this.$refs.rightForm.closeListItems();
    },
    saveDetail(updateObj, isUpdateList) { //isUpdateList：是否刷新列表
      //保存组件详情
      this.SaveCourse({
        "page_key":this.componentName,"course_id":this.detail.course_id,
        "properties_data": updateObj,
        "block_id": this.detail['block_id'],
        "component_id": this.detail['component-id']
      }).then(res=>{
        if(res.errorcode == 0) {
          if(isUpdateList) this.$emit("update");
          if(updateObj.hasOwnProperty("course-customStyle")) {
            // 更新了自定义css
            this.SET_CUSTOM_CSS({ data: res.data["course-customStyle"], course_id: this.detail.course_id });
          }
        }
      });
    },
  }
};