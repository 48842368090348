import Vue from 'vue';

export default {
  props: {},
  data() {
    return {};
  },
  watch: {},
  beforeMount() {},
  beforeDestroy() {},
  methods: {
    handleSort({list, propName}) {
      // 选项排序后的list
      this.$set(this.detail, propName, list);
      this.saveDetail({
        [propName]: list
      }, false);
    },
    handleCopy({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value, component_target}) {
      if(component_target !== 'component-_items') return;
      
      let items = this.detail[key_name];
      let copyItem = JSON.parse(JSON.stringify(items[index]));
      if(copyItem) {
        // 复制一项
        items.push(copyItem);
        this.saveDetail({
          [key_name]: items
        }, false);
      }
    },
    handleDel({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value, component_target}) {
      if(component_target !== 'component-_items') return;
      
      let items = this.detail[key_name];
      let itemsLen = items.length;
      if(index < itemsLen && index >= 0) {
        // 是否当前打开
        if(visible == 2) {
          this.closeInnerDrawer();
        }
        // 删除
        items.splice(index,1);
        this.saveDetail({
          [key_name]: items
        }, false);
      }
    },
    closeOptionsModal() {
      // 拖拽排序时，关闭左侧弹窗
      this.innerDrawer.current = -1;
      this.closeInnerDrawer();
    },
  }
};