<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true,'reset':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-media"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @reset="resetData"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div class="prel">
        <div v-if="mediaType === 'audio'" class="audio-wrapper">
          <el-image  v-if="detail['component-_media-properties-poster']" @load="loadedImg" @error="errorImg" :src="detail['component-_media-properties-poster']"  fit="contain" :lazy="lazyImage" class="img"></el-image>
          <audio ref="audioPlayer"
            controls controlslist="nodownload" 
            :src="detail['component-_media-properties-source']" 
            style="width: 100%;object-fit: fill"
            class="audio-self">
            您的浏览器不支持 audio 标签。
          </audio>
        </div>
        <video v-else-if="mediaType === 'video'" ref="videoPlayer" controls 
          preload="none"
          :src="detail['component-_media-properties-source']" 
          :poster="detail['component-_media-properties-poster']" 
          controlslist="nodownload"
          style="width: 100%; height: auto; max-height: 495px; object-fit: fill"
          class="margin_top16">
        </video>
        <el-row 
          v-if="detail['component-_transcript-properties-_inlineTranscript'] || detail['component-_transcript-properties-_externalTranscript']" 
          class="flex flexwrap" >
          <customButton 
            v-if="detail['component-_transcript-properties-_inlineTranscript']"
            @click="tapInlineTranscript"
            width="auto"
            color="#4D98B7" :roundPX="4"
            padding="15px 24px"
            class="margin_right16 margin_top16 pointer">
            <span class="fontsize_14">{{!showInlineContent ? detail['component-_transcript-properties-inlineTranscriptButton']: detail['component-_transcript-properties-inlineTranscriptCloseButton']}}</span>
          </customButton>
          <customButton 
            v-if="detail['component-_transcript-properties-_externalTranscript']"
            @click="openLink(detail['component-_transcript-properties-transcriptLink'])"
            width="auto"
            color="#4D98B7" :roundPX="4"
            padding="15px 24px" 
            class="margin_top16 pointer">
            <span class="fontsize_14">{{detail['component-_transcript-properties-transcriptLinkButton']}}</span>
          </customButton>
        </el-row>
        <accordion ref="inlineTranscriptAccordion" :Accordionindex="-1" class="inline-content margin_top16">
          <p slot="content" v-html="detail['component-_transcript-properties-inlineTranscriptBody']" class="fontsize_14 color_222"></p>
        </accordion>
      </div>
      <fd-drawer
        destroy-on-close
        :title="drawerSet.title"
        :visible.sync="drawerSet.visible"
        :direction="drawerSet.direction"
        :before-close="handleClose"
        :size="479"
        custom-class="drawer-right not-header"
        :with-header="false"
        append-to-body
        @open="openDrawer"
        @closed="closeDrawer"
        :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur"
            @changeListItem="changeListItem">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_media_pic'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_media'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import { mapState } from 'vuex';
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import customButton from "@/renderer/components/common/buttonTheme.vue";
import accordion from "@/renderer/components/common/accordion.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';

/**
 * 媒体组件
*/
export default {
  mixins: [hideModal, componentCommon],
  components:{ topTools, rightForm, scaffoldForm, objForm, customButton, accordion, contentList },
  data() {
    return {
      currentForm: {},
      componentName:"adapt-contrib-media",
      ckeditorPrefix:"componentMedia",
      showInlineContent: false,//显示内联文本
      loadDetail: {
        count:0,
      }
    };
  },
  computed: {
    ...mapState({
      lazyImage: state => state.course.lazyImage,
      is_web: state => state.app.isWebSide,
    }),
    mediaType() {
      // 资源类型
      let audioExts = ['mp3','wav','mpga'];
      let src = this.detail['component-_media-properties-source'];
      let ext = src.substr(src.lastIndexOf('.')+1);

      return src ? audioExts.includes(ext) ? 'audio' : 'video' : "";
    },
    loadTotal() {
      let length = 0;
      if(this.mediaType === 'audio') {
        if(this.detail['component-_media-properties-poster']) length++;
      }
      // if(this.detail['component-_media-properties-source']) length++;//视频默认不加载，会导致没触发load
      return length;
    },
  },
  watch: {
  },
  mounted() {
    this.loadDetail.count = 0;

    this.$nextTick(() => {
      if(this.loadTotal == 0) this.$emit("loaded");
    });
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    resetData() {
      this.showInlineContent = false;
      if(this.mediaType === 'audio') {
        this.$refs.audioPlayer.pause();
        this.$refs.audioPlayer.currentTime = 0;
        return;
      }
      // 视频
      this.$refs.videoPlayer.pause();
      this.$refs.videoPlayer.currentTime = 0;
    },
    errorImg() {
      // 图片加载失败
      this.loadDetail.count++;

      if(this.loadDetail.count == this.loadTotal) {
        this.$emit("loaded");
      }
    },
    loadedImg() {
      // 图片加载完成
      this.loadDetail.count++;

      if(this.loadDetail.count == this.loadTotal) {
        this.$emit("loaded");
      }
    },
    tapInlineTranscript() {
      // 切换内联文本显示隐藏
      this.showInlineContent = !this.showInlineContent;
      this.$refs['inlineTranscriptAccordion'].Shrink();
    },
    closeInnerDrawer({list, title, visible} = {}) {
      // 显示\隐藏二层弹出窗口
      if(!visible) {
        this.innerDrawer.current = -1;
        this.innerDrawer.visible = false;
        return;
      }
    },
    handleCopy({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value, component_target}) {
      if(component_target !== 'component-_items') return;
      
      let items = this.detail[key_name];
      let itemsLen = items.length;
      let copyItem = JSON.parse(JSON.stringify(items[index]));
      if(copyItem) {
        // 复制一项
        items.push(copyItem);
        this.saveDetail({
          [key_name]: items
        }, false);
      }
      
    },
    handleDel({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value, component_target}) {
      if(component_target !== 'component-_items') return;
      
      let items = this.detail[key_name];
      let itemsLen = items.length;
      if(index < itemsLen && index >= 0) {
        // 是否当前打开
        if(visible == 2) {
          this.closeInnerDrawer();
        }
        // 删除
        items.splice(index,1);
        this.saveDetail({
          [key_name]: items
        }, false);
      }
    },
    changeListItem(obj) {
      Object.assign(this.detail, obj);
      this.saveDetail(obj, true);
    },
    saveDetail(updateObj, isUpdateList) { //isUpdateList：是否刷新列表
      if(updateObj["component-_media-properties-poster"]) {
        // 更换封面后，强制刷新视频
        this.$refs.videoPlayer.src = "";
        this.$refs.videoPlayer.src = this.detail['component-_media-properties-source'];
      }

      //保存组件详情
      this.SaveCourse({
        "page_key":this.componentName,"course_id":this.detail.course_id,
        "properties_data": updateObj,
        "block_id": this.detail['block_id'],
        "component_id": this.detail['component-id']
      }).then(res=>{
        if(res.errorcode == 0) {
          if(isUpdateList) this.$emit("update");
          if(updateObj.hasOwnProperty("course-customStyle")) {
            // 更新了自定义css
            this.SET_CUSTOM_CSS({ data: res.data["course-customStyle"], course_id: this.detail.course_id });
          }
        }
      })
    },
    openLink(link) {
      // 打开文档链接
       window.open(link,"_blank");
    //   if(this.is_web) {
    //     window.open(link,"_blank");
    //     return;
    //   }

    //   // 桌面端
    //   let { href } = this.$router.resolve({
    //     path: "/webview",
    //     query:{
    //       websrc: link
    //     }
    //   });
    //   window.open(href, "_blank");
    }
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/
  
  // 音频
  .audio-wrapper {
    position: relative;
    min-height: 60px;
    .img {
      display: block;
    }
    .audio-self {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      transition: opacity 0.6s;
    }
  }
</style>