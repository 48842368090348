<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true,'reset':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-textinput"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @reset="initOptionRelative"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div class="margin_top16 prel">
        <div class="">
          <div v-for="(oitem, oind) in optionsItem" :key="'textInput_'+oind" class="flexwrap textinput-options-item">
            <span v-html="oitem.prefix" class="flexshrink inline"></span>
            <div class="options-blank inline">{{oind+1}}</div>
            <span v-html="oitem.suffix" class="flexshrink inline"></span>
            <!-- <span  v-if="oind != optionsItem.length-1" class="padding_right4 flexshrink inline">,</span>
            <span v-else class="flexshrink inline">。</span> -->
          </div>
        </div>
        <div>
          <div v-for="(aitem, aind) in answers" :key="'answer_'+aind" class="flex flex_acenter margin_bot16">
            <span class="answer-num">{{aind+1}}</span>
            <el-input :value="aitem" @input="answerInput($event,aind)" :placeholder="optionsItem[aind].placeholder"  class="modal-sets-inlineinput noborder bgc_fff" style="border-radius:0;"></el-input>
          </div>
        </div>
        <questionsBtnGroup 
          :answerState="answerState" 
          :singeBtnShowMarking="singeBtnShowMarking"
          :leftBtnName="getBtnName('left') && detail[getBtnName('left')]"
          :feedbackBtnName="getBtnName('left') && detail[getBtnName('showFeedback')]"
          :canShowMarking="detail[singleData.canShowMarking]"
          :canShowFeedback="detail['component-_canShowFeedback']"
          :shouldDisplayAttempts="detail['component-_shouldDisplayAttempts']"
          :remainingAttemptsText="detail['component-_buttons-properties-remainingAttemptsText']"
          :attempts="detail['component-_attempts']"
          @tapBtn="tapBtn"></questionsBtnGroup>
      </div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_textinput_sub'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_textinput'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
      <fd-dialog
        custom-class="hotgraphic-dialog"
        :visible.sync="feedbackDialogSet.visible"
        width="100%"
        :modal-append-to-body="true"
        :append-to-body="true"
        :before-close="beforeCloseDialogSet"
        :style="containerStyle">
        <div class="feedback-popup-inner" >
          <div class="notify__popup-inner">
            <div class="notify__content">
              <div class="notify__content-inner">
                <div class="notify__title" id="notify-heading">
                  <div class="notify__title-inner" role="heading" aria-level="1">
                    <p class="fontsize_16"><strong v-html="detail['component-_feedback-properties-title']"></strong></p>
                  </div>
                </div>
                <div class="notify__body">
                  <div class="notify__body-inner">
                    <p v-if="answerState == 1" v-html="detail['component-_feedback-properties-correct']"></p>
                    <p v-else-if="answerState == 0" v-html="detail['component-_feedback-properties-_incorrect-properties-final']"></p>
                  </div>
                </div>
              </div>
            </div>
            <button @click="feedbackDialogSet.visible = false" class="btn-icon notify__btn-icon notify__close-btn js-notify-close-btn" aria-label="关闭弹出窗口">
              <div class="el-icon-close fontsize_24 color_fff"></div>
            </button>
          </div>
          <a class="a11y-focusguard a11y-ignore a11y-ignore-focus" role="presentation">&nbsp;</a>
        </div>
      </fd-dialog>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import questionsBtnGroup from "@/renderer/components/FormItem/questions-btn-group.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import questionsCommon from '@/renderer/mixins/questions/index';

/**
 * 填空题 组件
 * 1、
 * 2、切换-是否显示正确答案按钮 (canShowModelAnswer)
 * 3、切换-是否显示标记- 用户选中项的对错标记 (canShowMarking)
 * 4、如果选项修改、新增、删除 - 初始化相关数据 (optionsItem)
*/
export default {
  mixins: [hideModal, componentCommon, questionsCommon],
  components:{ topTools, rightForm, scaffoldForm, objForm, contentList, questionsBtnGroup },
  props: {
    containerStyle: Object,
  },
  data() {
    return {
      currentForm: {},
      componentName:"adapt-contrib-textInput",
      ckeditorPrefix:"componentTextInput",
      feedbackDialogSet: {
        visible: false, //是否显示
        title: "",
      },
      userAnswer:[], //用户输入值
      answers: [], //输入框值
      firstFeedback: true, //是否显示首次提交显示反馈
      answerState: -1, //答题状态： -1 未答题（未提交）、0 提交并且错误、1 提交并且正确、
      showRightAnswer: false, //是否显示了正确答案
      singleData: {
        canShowMarking:"component-_canShowMarking", //显示标记 - 正确错误标记
        canShowModelAnswer:"component-_canShowModelAnswer", //显示答案 - 按钮
      },
    };
  },
  computed: {
    optionsItem() {
      // 选项
      return this.detail["component-_items"];
    },
    rightAnswer() { //正确答案
      let optionList = this.optionsItem;
      let arr = [];
      optionList.forEach(v => {
        arr.push(v['_answers']);
      });
      return arr;
    },
    singeBtnShowMarking() {
      // 单个按钮-显示标记
      return this.detail[this.singleData.canShowMarking] && !this.detail['component-_canShowFeedback'] && this.answerState != -1;
    },
  },
  watch: {
    optionsItem: {
      immediate: true,
      deep: true,
      handler(n) {
        if(!n) return;
        // 选项新增或删除，初始化选项相关数据
        this.initOptionRelative();
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("loaded");
    });
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    beforeCloseDialogSet(done) {
      this.feedbackDialogSet.visible = false;
      done();
    },
    answerInput(value, index) {
      this.$set(this.answers, index, value);
      // 记录用户答案
      this.$set(this.userAnswer, index, value);
    },
    initOptionRelative() {
      // 初始化选项相关数据
      this.answers = [];
      let len = this.detail['component-_items'] && this.detail['component-_items'].length || 0;
      if(len) this.answers = new Array(len).fill("");
      this.answerState = -1;
      this.firstFeedback = true;
      this.showRightAnswer = false;
    },
    tapBtn(btnWhere = 1) {
      // btnWhere: 1 左侧按钮 提交或者查看正确答案; 2 反馈
      let { firstFeedback, answers, answerState, showRightAnswer } = this;
      let list = this.optionsItem;
      let listLen = this.optionsItem.length;
      //答案集合
      let answerSet = this.rightAnswer;
      let userAnswerLen = answers.filter( v => v != "" ).length;
      // 是否开启答案显示
      let canShowModelAnswer = this.detail[this.singleData.canShowModelAnswer];
      // 全部填空
      let isAnswerAll = userAnswerLen == listLen;
      //是否开启了显示反馈
      let canShowFeedback = this.detail["component-_canShowFeedback"];

      // 点击提交，显示反馈
      if(btnWhere == 1 && !!firstFeedback && !!canShowFeedback && isAnswerAll) {
        this.feedbackDialogSet.visible = true;
        this.firstFeedback = false;
      }

      if(btnWhere == 1  && isAnswerAll && answerState == -1) {
        // 提交
        let allowsAnyCase = this.detail['component-_allowsAnyCase']; //区分大小写
        let isTrue = list.findIndex((val,ind)=> {
          if( !allowsAnyCase ) {
            let hasVal = val['_answers'].findIndex(subVal => {
              return subVal && subVal.toLocaleLowerCase() === answers[ind].toLocaleLowerCase()
            });
            return hasVal != -1;
          }
          return val['_answers'].includes( answers[ind] )
        })
        this.answerState = isTrue >= 0 ? 1 : 0;
        return;
      }
      if(canShowModelAnswer && btnWhere == 1 && isAnswerAll && answerState == 0) {
        // 查看答案 - 隐藏答案
        //隐藏答案 - 查看用户答案
        if(showRightAnswer) {
          // this.answers = new Array(this.answers.length).fill("");
          this.answers = JSON.parse( JSON.stringify( this.userAnswer ) );
          this.showRightAnswer = false;
          return;
        }
        // 显示答案
        this.answers = [];
        answerSet.forEach(v => {
          this.answers.push(v[0]);
        });
        this.showRightAnswer = true;
        return;
      }

      if( btnWhere == 2 && answerState != -1 && !!canShowFeedback) {
        // 已答题，开启反馈，显示反馈弹窗
        this.feedbackDialogSet.visible = true;
        return;
      }
    },
    openInnerDrawer({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value}) {
      // 显示\隐藏二层弹出窗口
      this.innerDrawer.form = {};
      list.forEach(v => {
        if(v.input_type == "textinput_answer") {
          this.innerDrawer.form[v.input_name] = []
        }
      });
      this.innerDrawer.submitAsObj = submitAsObj || false; //整体提交
      this.innerDrawer.key_name = key_name;
      this.innerDrawer.parentDetail = parentDetail;
      this.innerDrawer.title = title;
      this.innerDrawer.propList = list;
      this.innerDrawer.current = index;
      if(value) this.innerDrawer.form = value;

      if(!visible || visible == 2) {
        this.innerDrawer.visible = false;
        return;
      }else{
        if(this.innerDrawer.visible == true) {
          this.innerDrawer.visible = false;
          setTimeout(() => {
            this.innerDrawer.visible = true;
          }, 220);
        }else {
          this.innerDrawer.visible = true;
        }
      }
    },
    handleCopy({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value, component_target}) {
      if(component_target !== 'component-_items') return;

      let items = this.detail[key_name];
      let copyItem = JSON.parse(JSON.stringify(items[index]));
      if(copyItem) {
        // 复制一项
        items.push(copyItem);
        this.saveDetail({
          [key_name]: items
        }, false);
      }

    },
    handleDel({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value, component_target}) {
      if(component_target !== 'component-_items') return;

      let items = this.detail[key_name];
      let itemsLen = items.length;
      if(index < itemsLen && index >= 0) {
        // 是否当前打开
        if(visible == 2) {
          this.closeInnerDrawer();
        }
        // 删除
        items.splice(index,1);
        this.saveDetail({
          [key_name]: items
        }, false);
      }
    },
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/

  // 选项空白
  .options-blank {
    flex-shrink: 0;
    box-sizing: border-box;
    margin: 0 8px;
    padding: 0 25px;
    width: 58px;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
    border-bottom: 1px solid var(--item-color, #70ADC6);
    color: var(--item-color, #70ADC6);
  }
  .textinput-options-item {
    margin-bottom: 20px;
    word-break: break-all;
    line-height: 1.5;
    .inline {
      /deep/p {
        display: inherit;
      }
    }
  }
  .answer-num {
    flex-shrink: 0;
    border-radius: 50%;
    margin-right: 16px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    color: var(--item-color-inverted, #FFFFFF);
    text-align: center;
    background: var(--item-color, #70ADC6);
  }
</style>