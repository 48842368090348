<template>
  <div>
    <div v-for="(item, ind) in list" :key="item.id" class="marginCenter prel">
      <div class="flex prel" 
        :class="{'margin_top16': item.display == 1 && currentForm[item.key_name] }">
        <div v-if="getProgressStatus(item)"
          class="component-progress"
          :class="{'hasValue': currentForm[item.key_name]}">
          <progressExample :progress="46" :detail="currentForm"></progressExample>
          <p v-html="currentForm[item.key_name]"
              class="progress-text"
              :class="{ 'fontsize_20': item.properties_name == 'title' || item.properties_name == 'displayTitle' }"></p>
        </div>
        <ckeditor 
          v-if="currentForm[item.key_name]"
          type="simpleInline" 
          :class="{'ckeditor_inline_title is_title fontsize_16':(item.properties_name == 'title' || item.properties_name == 'displayTitle'),
          'ckeditor_inline_body fontsize_14 color_59':item.properties_name === 'body',
          'ckeditor_inline_instruction ckeditor_inline_instruction fontsize_14 color_8C':item.properties_name === 'instruction',
          'ck-has-progress': getProgressStatus(item),}"
          :id="ckeditorPrefix+'-'+ currentForm.id +'-inline' +'-'+item.id"
          :canKeyEnter="canKeyEnter || item.properties_name !== 'displayTitle'"
          :value="currentForm[item.key_name]" 
          :throttle="throttleDelay"
          @input="$emit('input', $event,item.key_name)"
          @blur="$emit('blur', $event,item.key_name)"
          @blurNotUpdate="$emit('blurNotUpdate', $event,item.key_name,false)"
        >
        </ckeditor>
      </div>
    </div>
  </div>
</template>

<script>
import progressExample from "@/renderer/components/common/progressExample.vue";
export default {
  components: {progressExample},
  props: {
    list: Array,
    currentForm: Object,
    getProgressStatus: Function,
    ckeditorPrefix: String,
    canKeyEnter: Boolean,
    throttleDelay: [Number, String]
  },
}
</script>

<style lang="less">
// 标题左侧-进度条
.component-progress {
  padding-top: 16px;
  left: 0;
  z-index: 0;
  line-height: 1.3;
  display: inline-block;
  vertical-align: middle;
  &.hasValue {
    position: absolute;
    padding-top: 2px;
  }
  .progress-text {
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline;
    vertical-align: middle;
    /deep/p {
      width: 0;
      overflow: hidden;
      display: inline;
      &:not(:first-child) {
        display: none;
      }
    }
  }
}
</style>