<template>
  <draggable v-model="list" :disabled="disabled" 
      :delay="1"
      :fallbackTolerance="3"
      chosenClass="chosen" forceFallback="true" ghostClass="ghostClass" group="people" animation="1000" 
      filter=".panel-header-tools" @start="onStart" @end="onEnd" @sort="onSort">
    <transition-group name="flip-list"  class="collapse-panel-container" accordion >
      <div v-for="(litem,lind) in drapList" :key="litem.id" :name="litem.id" @click="tapItem(litem,lind)" 
        :class="{'is-active': current == lind}" class="item collapse-panel-item" ref="liConWrap">
        <div class="collapse-panel-item-inner">
          <div class="panel-header flex flex_jcbetween flex_acenter">
            <div class="panel-header-content flex flex_acenter">
              <i class="el-icon-caret-right panel-header-arrow" :class="{'is-active':true}"></i>
              <i :class="[ litem._type === 'menu' ? 'icon-menu-theme': 'icon-menu-content']" class="fontsize_18">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
              </i>
              <div class="panel-header-title pover custom-tree-label-html fontsize_14 color_222" v-html="litem.title"></div>
            </div>
            <div class="panel-header-tools flex flex_acenter" @click.stop="">
              <!-- v-if="litem._type !== 'menu'" -->
              <i class="icon-page-copy pointer fontsize_14 icon_color_222" @click="copy(litem,lind)">
                <span class="icon-tips fontsize_12 color_222">{{$t('lang.copying')}}</span>
              </i>
              <i class="icon-menu-del pointer fontsize_14 margin_left8 all_del_icon icon_color_222 icon_hover_E74362" @click="remove(litem,lind)">
                <span class="icon-tips fontsize_12 color_222">{{$t('lang.delete')}}</span>
              </i>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </draggable> 
</template>

<script>
//导入draggable组件
import draggable from 'vuedraggable'
import { mapActions } from 'vuex';
export default {
  props: {
    accordion: { //唯一展开
      type:[Boolean,String],
      default: false,
    },
    list: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      current:-1,
      isChanging: false, //是否在变动中
      liConHeight: 0,
      newId: 99,
      drag:false,
      //定义要被拖拽对象的数组
      // drapList:[] ,
      disabled: false, //禁用拖动
      tapTimer: null,
    };
  },
  computed: {
    drapList() {
      return this.list;
    }
  },
  components: {
    draggable
  },
  created() {
    // this.drapList = [].concat(this.list)
  },
  methods: {
    ...mapActions(['DelContentObjectOne','SortContent','GetContentObjectSonList','MoveSortContent','CopyContentObjectOne','GetTree']),
    //开始拖拽事件
    onStart(){
      this.drag=true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag=false;
      this.clearAllStatus(); //关闭弹窗
      this.$emit('closeLeft'); //关闭弹窗
    },
    onSort(evt) {
      // console.log('onSort',evt);
      let { newDraggableIndex, oldDraggableIndex } = evt;
      // 上移/下移
      let moveDirection = newDraggableIndex < oldDraggableIndex ? 'up' : 'down';
      // 当前移动
      let currentMoveItem = this.drapList[newDraggableIndex];
      let below_contentobject_id = null; //移动后上下方id
      // 非 置顶或置底
      if(newDraggableIndex != 0 && newDraggableIndex != this.drapList.length - 1) {
        below_contentobject_id = moveDirection === 'up' ? this.drapList[newDraggableIndex+1].contentobject_id : this.drapList[newDraggableIndex-1].contentobject_id;
      }
      this.MoveSortContent({
        course_id: currentMoveItem.course_id,
        _parentId: currentMoveItem._parentId,
        contentobject_id: currentMoveItem.contentobject_id, 
        below_contentobject_id: below_contentobject_id,
        move_type: moveDirection
      });
    },
    moveUp(item,index) {
      // 向上移动

      this.clearAllStatus(); //关闭弹窗
      this.$emit('closeLeft'); //关闭弹窗

      if(this.isChanging || index === 0) {
        return;
      }
      this.list.splice(
        index,
        1,
        ...this.list.splice(index - 1, 1, this.list[index])
      );
      this.SortContent({
        course_id: item.course_id,
        _parentId: item._parentId,
        contentobject_id: item.contentobject_id,
        sort_type: "up",
      });
    },
    moveDown(item,index) {

      this.clearAllStatus(); //关闭弹窗
      this.$emit('closeLeft'); //关闭弹窗

      if(this.isChanging || index === this.list.length - 1) {
        return;
      }
      this.list.splice(
        index + 1,
        1,
        ...this.list.splice(index, 1, this.list[index + 1])
      );
      this.SortContent({
        course_id: item.course_id,
        _parentId: item._parentId,
        contentobject_id: item.contentobject_id,
        sort_type: "down",
      });
    },
    copy(item,index) {
      // 复制

      this.clearAllStatus(); //关闭弹窗
      this.$emit('closeLeft'); //关闭弹窗

      // item = JSON.parse(JSON.stringify(item))
      // item.title = item.title + "-副本"
      // item.id = this.newId++;
      // this.list.push(item)
      this.CopyContentObjectOne({course_id:this.$route.params.id, contentobject_id:item.contentobject_id}).then(res => {
        if(res.errorcode == 0) {
          // 刷新目录树
          this.GetTree(this.$route.params.id);
          // 刷新中间主题列表
          let contParams = {
            course_id: item.course_id,
          };
          if(this.$route.query.pageType != 'index') {
            contParams.contentObjectId = this.$route.params.contentId;
          }
          this.GetContentObjectSonList(contParams);
        }
      }).catch(err => {

      });
    },
    remove(item,index) {
      // 删除
      this.$emit("del", { item: { course_id: item.course_id, contentobject_id: item.contentobject_id, title: item.title }, index: index });
    },
    onlyOneOpen(targetIndex) { //关闭其他展开状态
      this.list.forEach((item,i)=>{
        if(i == targetIndex) return;
        
        this.$set(this.list[i], 'openFlag', false);
      });
    },
    tapItem(item, i) {
      if(this.tapTimer) return;

      this.open(item, i);
      this.tapTimer = setTimeout(() => {
        this.tapTimer = null;
      }, 500);
    },
    open(item, i) {
      let copyItem = JSON.parse( JSON.stringify(item) );
      
      this.onlyOneOpen(i);
      if (this.current != i) {
        this.current = i;
        this.$emit('openLeft',copyItem,i);
        this.$set(item, 'openFlag', true);
      } else {
        this.current = -1;
        this.$emit('closeLeft',copyItem,i);
        this.$set(item, 'openFlag', false);
      }
    },
    openWithStatus(item, i) {
      //改变状态，不触发emit
      let copyItem = JSON.parse( JSON.stringify(item) );
      
      this.onlyOneOpen(i);
      if (this.current != i) {
        this.current = i;
        this.$set(item, 'openFlag', true);
      } else {
        this.current = -1;
        this.$set(item, 'openFlag', false);
      }
    },
    clearAllStatus() {
      // 全部关闭
      this.current = -1;
      this.list.forEach((item,i)=>{
        this.$set(item, 'openFlag', false);
      });
    },
  },
}
</script>

<style lang="less" scoped>
  .custom-tree-label-html {
    font-size: 14px;
    color: #222;
    /deep/span {
      font-size: 14px!important;
      color: #222!important;
    }
  }
  // 过渡
  .flip-list-move {
    transition: all 1s;
  }

  /*被拖拽对象的样式*/
  .item {
    cursor: move;
  } 
  /*选中样式*/
  .chosen {
    
  }
  /*停靠位置样式*/
  .ghostClass {
    opacity: 0.5 !important;
    border-style: dashed !important;
  }

  .collapse-panel-item {
    padding: 12px 16px;
    transition: all 0.3s;
    &:hover {
      background: #EDEDED;
      .panel-header {
        background-color: #EDEDED;
      }
    }
    &.is-active{
      cursor: initial;
      background: #EDEDED;
      .panel-header {
        background-color: #EDEDED;
      }
      .panel-header-arrow {
        transform: rotate(90deg);
      }
    }
  }
  .collapse-panel-item-inner {
    box-sizing: border-box;
    border: 1px solid #D4D4D4;
    border-radius: 4px;
  }
  // 头部
  .panel-header {
    box-sizing: border-box;
    border-radius: 4px;
    padding: 8px;
    transition: all 0.3s;
    width: 100%;
    background-color: #fff;
  }

  .panel-header-content {
    flex: 1;
    width: calc(100% - 100px);
    .panel-header-title {
      padding-left: 14px;
      // max-width: 235px;
      width: 70%;
    }
    .panel-header-arrow {
      transition: transform 0.3s;
      margin: 0 10px 0 4px;
    }
  }
  .panel-header-tools {
    flex-shrink: 0;
    border-radius: 4px;
    height: 30px;
    
    >i {
      position: relative;
      border-radius: 4px;
      width: 33px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: #505050;
      background-color: #FAFAFA;
      &:hover {
        background-color: #e4e4e4;
        .icon-tips {
          display: block;
        }
      }
      .icon-tips {
        display: none;
        position: absolute;
        left: 50%;
        bottom: -2px;
        transform: translateY(100%) translateX(-50%);
        background: #F2F2F1;
        border: 1px solid #DADADA;
        border-radius: 1px;
        padding: 0 6px;
        height: 17px;
        line-height: 17px;
        white-space:nowrap;
      }
    }
  }

  // 箭头指向
  .collapse-panel-item.is-active .panel-header-arrow {
    transform: rotate(180deg);
  }
</style>