<template>
  <div>
    <div class="radio-group flex flex_acenter">
      <label v-for="item in ways" :key="item.type"  @click.stop="changeWay(item.type)" class="flex flex_acenter margin_right32 pointer" >
        <span class="radio_box" :class="{'active': currentType === item.type}">
          <i class="icon-check-symbol fontsize_12 icon_color_fff"></i>
        </span>
        <span class="fontsize_12 color_222">{{item.title}}</span>
      </label>
    </div>
    <div v-if="currentType === 'local' && currentSrc" class="img-item flex flex_acenter">
      <img v-if="cover" :src="coverStr" alt="" class="img" loading="lazy">
      <p v-if="currentName" class="fontsize_14 color_222 pover" style="width:120px;">{{currentName}}</p>
    </div>
    <div v-if="currentType === 'outlink'" class="img-link">
      <el-input  @input="inputChange" @blur="inputBlur" :value="currentSrc" :placeholder="'请输入链接'" class="modal-sets-inlineinput noborder bgc_fff" ></el-input>
    </div>
    <div v-if="currentType === 'local'" @click.stop="tapBtn" class="action-btn fontsize_12 color_E74362 pointer">{{currentSrc ? '移除' : '添加'}}</div>
    <mediaSelectDialog 
      :exts="exts"
      :visible="coverDialogSet.visible" 
      :title="coverDialogSet.title" 
      :fromDetail="coverDialogSet.item" 
      :mediaType="coverDialogSet.mediaType"
      :appendToBody="true"
      @select="selectCover"  @close="closeMediaDialog"></mediaSelectDialog>
  </div>
</template>

<script>
// 通过媒体列表选择
import mediaSelectDialog from '@/renderer/components/common/mediaSelectDialog';
export default {
  components: {
    mediaSelectDialog
  },
  props: {
    src:String,
    exts:Array, //限定后缀名
    checkType: {
      type: String,
      default: "local", // outlink
    },
    name: String,
    cover: String,
  },
  data() {
    return {
      currentSrc:this.src,
      currentName: this.name,
      currentType: this.checkType || "local",
      ways: [{title:"本地上传",type:"local"},{title:"外部链接",type:"outlink"}],
      coverDialogSet: { //修改封面弹窗
        title:"媒体库",
        visible: false,
        item:{},
        mediaType:'all'
      },
    };
  },
  computed: {
    coverStr() {
      let isLightImg = this.cover && this.cover.indexOf("?x-oss-process=style/") > -1;
      // return isLightImg ? this.cover : this.cover + "?x-oss-process=style/s";
      return this.cover;
    }
  },
  watch: {
    src: {
      immediate:true,
      handler(newVal) {
        if(newVal) {
          this.currentSrc = newVal
        }else {
          this.currentSrc = "";
        }
      }
    },
    name: {
      immediate:true,
      handler(newVal) {
        if(newVal) {
          this.currentName = newVal
        }else {
          this.currentName = "";
        }
      }
    },
  },
  methods: {
    changeWay(type) {
      if(type === this.currentType) return;
      this.currentSrc = "";
      this.currentType = type;
      // this.$emit("change", this.currentSrc);
      this.$emit('change', {url:this.currentSrc, name: "", cover: "", type: this.currentType});
    },
    inputChange(val) {
      this.currentSrc = val;
    },
    inputBlur() {
      if(this.currentSrc === "") return;
      if(this.currentType === "outlink" && !this.checkUrl()) {
        this.$message.error("外部链接不正确，允许类型有："+ JSON.stringify(this.exts));
        return
      };
      // this.$emit("change", this.currentSrc);
      this.$emit('change', {url:this.currentSrc, name: "", cover: "", type: "outlink"});
    },
    checkUrl() {
      // 校验链接是否合法
      let isEfficacious = false;
      let internalUrl = /^http[s]?:\/\/([\w-]+\.)+[\w-]+(\/[\w-./?%&=]*)?$/;
      let lastIndex = this.currentSrc.lastIndexOf(".");
      
      if(!internalUrl.test(this.currentSrc)) return false;
      if(lastIndex > -1 && this.exts) {
        let endStr = this.currentSrc.substring(this.currentSrc.lastIndexOf(".")+1);
        if(Array.isArray(this.exts) && this.exts.length > 0 && this.exts.includes(endStr)) {
          isEfficacious = true;
        }else if(!this.exts) {
          isEfficacious = true;
        }
      }
      return isEfficacious;
    },
    tapBtn() {
      let operate = this.currentSrc ? "remove" : "add";
      
      if(operate === "remove") {
        // 移除
        this.currentSrc = "";
        // this.$emit("change", this.currentSrc);
        this.$emit('change', {url:this.currentSrc, name: "", cover: "", type: "local"});
        return;
      }
      // 添加
      this.coverDialogSet.visible = true;
    },
    selectCover({item, fromDetail}) {
      //选择图片作为背景图
      this.currentSrc = item.url;
      this.$emit('change', {url:item.url, name: item.name, cover: item.cover, type: "local"});
      this.closeMediaDialog();
    },
    closeMediaDialog() {
      // 关闭弹窗/后
      this.coverDialogSet.visible = false;
      this.coverDialogSet.item = {};
    },
  },
}
</script>

<style lang="less" scoped>
  .radio-group {
    margin-bottom: 13px;
  }
  .radio_box {
    display: inline-block;
    box-sizing: border-box;
    margin-right: 5px;
    border-radius: 50%;
    border: 1px solid #D4D4D4;
    width: 20px;
    height: 20px;
    line-height: 18px;
    text-align: center;
    &.active {
      border-color: #E74362;
      background-color: #E74362;
    }
  }
  .img-item {
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #D4D4D4;
    padding-left: 8px;
    width: 100%;
    height: 46px;
    .img {
      margin-right: 12px;
      border-radius: 2px;
      width: 38px;
      height: 38px;
      object-fit: cover;
    }
  }
  .action-btn {
    box-sizing: border-box;
    border-radius: 14.5px;
    border: 1px solid #E74362;
    margin-top: 13px;
    width: 52px;
    height: 30px;
    text-align: center;
    line-height: 28px;
  }
  .img-link {
    margin-top: 13px;
  }
</style>