<template>
  <div>
    <fdCheckbox  v-for="(litem,lind) in list" :key="'radio_'+lind" 
      class="margin_bot16"
      :value="litem.value" 
      :label="litem.label" 
      :checked="getCheckStatus(litem.value)"
      :name="name"
      :disabled="disabled"
      :showRes="showRes"
      :isRightAnswer="litem._shouldBeSelected"
      @change="handleChange">
      </fdCheckbox>
  </div>
</template>

<script>
import fdCheckbox from "@/renderer/components/FormItem/fd-checkbox.vue";
export default {
  props: {
    name:String, //组别
    selected: Array, //选中值
    disabled: Boolean, //是否禁用
    showRes: Boolean, //是否显示结果
    list: {
      type: Array,
      default: function() {
        return [
          {
            value:"",
            label:"",
            id: 0,
          }
        ];
      }
    }
  },
  components: {
    fdCheckbox
  },
  data() {
    return {
      selectedArr: this.selected, //选中值 []
    };
  },
  watch: {
    selected: {
      handler(n) {
        if(n != undefined) {
          //保证值是 String
          let newArr = JSON.parse(JSON.stringify(n));
          newArr.map((element,index) => {
            newArr[index] = String(element);
          });
          this.selectedArr = newArr;
        }
      },
      immediate: true
    },
  },
  methods: {
    getCheckStatus(value) { 
      //是否勾选状态
      return this.selectedArr.indexOf(String(value)) > -1;
    },
    handleChange({value, checked}) { //value 接收到的是字符串格式
      // 选中状态改变
      let targetIndex = this.selectedArr.indexOf(value);
      
      if(checked && targetIndex == -1) {
        // 存储选中
        this.selectedArr.push(value);
      }else if(!checked && targetIndex != -1){
        // 取消选中
        this.selectedArr.splice(targetIndex,1);
      }
      this.$emit("change",this.selectedArr);
    },
  },
}
</script>

<style>

</style>