<template>
  <div>
    <div v-for="(litem,lind) in copyList" :key="'matching_'+lind" class="margin_bot20 matching-item-wrapper">
      <ckeditor v-if="properties[0].display == 1 && properties[0]['input_type'] == 'richtext_mini' "
        :type="'simple'" :id="'matchingItem-' + ckeditorNameId + '-'+lind" 
        :value="litem[properties[0].input_name]" 
        :throttle="throttleDelay"
        :placeholder="placeholder"
        @input="richTextInput($event,properties[0].input_name,lind)" 
        @blur="formItemBlur($event,properties[0].input_name,lind)"
        class="matching-item-ckeditor" >
      </ckeditor>
      <div class="flex flex_acenter matching-item-bottom">
        <div class="flex1">
          <el-switch
            v-if="properties[1].display == 1 && properties[1]['input_type'] == 'truefalse'"
            @change="switchChange($event,properties[1].input_name,lind)"
            v-model="litem[properties[1].input_name]"
            :width="52"
            active-color="#E74362"
            inactive-color="#D4D4D4"
            class="">
          </el-switch>
          <span class="fontsize_12 color_222 padding_left8">{{properties[1].title}}</span>
        </div>
        <div class="flex flex_end flexshrink">
          <div class="list-item-tools flex flex_acenter" >
            <div v-for="(bitem,bind) in setting.action" :key="'rbtn'+bind" class="list-item-one">
              <div v-if="bitem.display_type === 'icon'" 
                class="list-item-action pointer prel "
                @click.stop="tapAction(litem,lind,bitem.type)" >
                <i class="icon-edit-black fontsize_14 color_222 icon_color_222" v-if="bitem.title == '编辑'"></i>
                <i class="icon-menu-del fontsize_14 color_222 all_del_icon icon_color_222 icon_hover_E74362" v-else-if="bitem.title == '删除'"></i>
                <i class="icon-page-copy fontsize_14 color_222 icon_color_222" v-else-if="bitem.type == 'copy'"></i>
                <span class="icon-tips fontsize_12 color_222">{{bitem.title}}</span>
              </div>
              <div v-else-if="bitem.display_type === 'button'" @click.stop="tapAction(litem,lind,bitem.type)" class="margin_left5 pointer bgc_F7F7F7">
                {{bitem.title}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <customButton  
      v-if="setting.button_title" 
      plain color="#E74362" :width="52" :height="30"
      @click="addItems"
      class="pointer">
      <span class="fontsize_12">{{setting.button_title}}</span>
    </customButton>
  </div>
</template>

<script>
import Config from '@/libs/config';
import customButton from "@/renderer/components/common/button.vue";
export default {
  props: {
    list: Array,
    setting: Object,
    placeholder:String,
  },
  components: {
    customButton
  },
  data() {
    return {
      ckeditorNameId:"matching-item",
      throttleDelay:Config.realTimeInput,
      copyList: [],
      idBase:new Date().getTime(),
      ruleForm: {},
    };
  },
  computed: {
    properties() {
      return this.setting && this.setting.properties || [];
    }
  },
  watch: {
    list: {
      handler(n) {
        if(n) {
          this.copyList = this.list;
        }
      },
      immediate: true
    }
  },
  created() {
    this.copyList = this.list || [];
  },
  methods: {
    tapAction(item,index,actionType) {
      if(!actionType) return;
      let actionsSet = {
        'copy': Symbol(),
        'delete': Symbol(),
      };
      switch(actionsSet[actionType]) {
        case actionsSet.copy:
          let newItem = JSON.parse( JSON.stringify(item) );
          newItem.id = new Date().getTime();
          this.copyList.push( newItem );
          break;
        case actionsSet.delete:
          this.copyList.splice(index,1)
          break;
      }
      this.$emit("change",{
        list:this.copyList
      });
    },
    switchChange(value,prop,index) {
      this.copyList[index][prop] = value;
      this.$emit("change",{
        list:this.copyList
      });
    },
    richTextInput(value, prop,index) {
      // this.$set(this.ruleForm, prop, value);
      this.copyList[index][prop] = value;
      this.$emit("change",{
        list:this.copyList
      });
      // this.$emit("formItemBlur", {
      //   value: value, 
      //   propName: prop,
      //   form: this.ruleForm
      // })
    },
    formItemBlur(value, prop,index) {
      this.copyList[index][prop] = value;
      this.$emit("change",{
        list:this.copyList
      });
    },
    addItems() {
      // 添加空的一项
      let itemProps = this.setting.properties;
      let itemObj = {
        id: this.idBase++
      };
      itemProps.forEach(v => {
        itemObj[v.input_name] = v.input_type === "truefalse" ? false : ""
      });
      
      this.copyList.push(itemObj);
      this.$emit("change",{
        list:this.copyList
      });
    }
  },
}
</script>

<style lang="less" scoped>
  .list-item-tools {
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    
    .list-item-action {
      margin-left: 8px;
      &:hover {
        & > .icon-tips {
          display: block;
        }
      }

      >i {
        display: block;
        position: relative;
        border-radius: 4px;
        width: 33px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: #505050;
        background-color: #FAFAFA;
        &:hover {
          background-color: #e4e4e4;
          .icon-tips {
            display: block;
          }
        }
      }
    }

    .icon-tips {
      display: none;
      position: absolute;
      left: 50%;
      bottom: -2px;
      transform: translateY(100%) translateX(-50%);
      background: #F2F2F1;
      border: 1px solid #DADADA;
      border-radius: 1px;
      padding: 0 6px;
      height: 17px;
      line-height: 17px;
      white-space:nowrap;
    }
  }
  .matching-item-bottom {
    border-radius: 0 0 4px 4px;
    height: 46px;
    padding: 0 8px 0 16px;
    background-color: #fff;
  }
  .matching-item-ckeditor {
    /deep/.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
      border: none;
    }
  }
  .richEditor-container /deep/.ck.ck-editor {
    border-radius: 4px 4px 0 0;
    border: none;
    border-bottom: 1px solid #D4D4D4;
  }
  .matching-item-wrapper {
    // overflow: hidden;
    border-radius: 4px;
    border: 1px solid #D4D4D4;
  }
  /deep/.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border: none;
  }
  /deep/.ck.ck-toolbar {
    border-radius: 4px 4px 0 0!important;
    border: none;
  }
  /deep/.ck.ck-sticky-panel__content {
    border-bottom: 1px solid #D4D4D4;
  }
</style>