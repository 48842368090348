<template>
  <div class="course-main">
    <div class="course-main-inner">
      <!-- <ToArticle
        v-for="item in articleList"
        :key="item.id"
        @addBlock="addBlock"
        :detail="getDetail(item)"
      ></ToArticle> -->
      <transition-group name="list-complete" tag="p">
          <div v-for="(item, index) in articleList"
            :key="item.id" class="list-complete-item">
            <ToArticle
              @scrollToModule="scrollToModule"
              @scrollToNew="scrollToNew"
              @loaded="()=>$emit('loaded')"
              @addBlock="addBlock"
              :moveUp="index !== 0"
              :moveDown="index !== (articleListLen - 1)"
              :detail="getDetail(item)"
              :enabledProgress="enabledProgress"
              :containerStyle="containerStyle"
            ></ToArticle>
          </div>
      </transition-group>
      <div  class="add-article-box flex flexcolumn flex_center flex_acenter pointer" @click="addArticle">
        <i class="icon-add-article-default fontsize_24">
          <span class="path1"></span>
          <span class="path2"></span>
        </i>
      <span class="fontsize_14 margin_top10 color_A1A1A1" :id="'add-article_'+$route.params.contentId">添加章节</span>
    </div>
    </div>
  </div>
</template>

<script>
import ToArticle from "./toarticle.vue";
import { mapState, mapActions } from 'vuex';
export default {
  props: {
    list: Array,
    enabledProgress: Boolean,
    containerStyle: Object,
  },
  components: { ToArticle },
  data() {
    return {
      blockList: [],
      demoArticle: {
        id:901,
        title: "<p><span style=\"color: #222222;font-size:16px;\">章节标题</span></p>",
        body: "",
        instruction: "",
      },
    };
  },
  computed: {
    ...mapState({
      articleList: state => state.article.articleList,
      loading: state => state.article.loading
    }),
    articleListLen() {
      return this.articleList.length
    }
  },
  mounted() {},
  methods: {
    ...mapActions(['CreateArticle']),
    randomIndex: function () {
      return Math.floor(Math.random() * this.articleList.length)
    },
    addArticle() {
      // 新增章节
      // this.$emit("addArticle", this.demoArticle);
      this.CreateArticle({
        course_id: this.$route.params.id,
        _parentId: this.$route.params.contentId,
        title:"<p><span style=\"color: #222222;font-size:16px;\">章节标题</span></p>"
      }).then(res => {
        if(res.errorcode == 0) {
          // 拿到章节id，并让当前章节滑动到视野区域
          // res.data.article['article-_id']
          this.$emit("scrollToNew",{id: res.data.article['article-_id']});
        }
      });
    },
    getDetail(item) {
      let copyItem = JSON.parse(JSON.stringify(item));
      let result = Object.assign(item, item.properties_list);
      delete result.properties_list;
      return result;
    },
    addBlock(obj) {
      this.blockList.push(obj);
    },
    scrollToNew({ id }) {
     this.$emit("scrollToNew",{ id });
    },
    scrollToModule({ id }) {
     this.$emit("scrollToModule",{ id });
    },
  },
};
</script>

<style lang="less" scoped>
  .add-article-box {
    margin-left: auto;
    margin-right: auto;
    box-sizing: border-box;
    border-radius: 0.75px;
    border: 1px dashed #51CFAE;
    // width: 1057px;
    // height: 116px;
    width: 105.8rem;
    height: 11.6rem;
  }
  .list-complete-item {
    position: relative;
    transition: transform 0.4s, opacity 0.2s;
    // display: inline-block;
    width: 100%;
    // padding-bottom: 145px; //原本article的margin-bottom
    // background-color: #fff; //防止在过渡时，由于透明且重叠，看到其他移动的内容 --挡住了背景图
    // 当前点击添加组件时，以防弹窗被 下层挡住
    &:hover {
      z-index: 1;
    }
  }
  .list-complete-enter, .list-complete-leave-to
  /* .list-complete-leave-active for below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(100px);
  }
  .list-complete-leave-active {
    position: absolute;
  }
  .course-main {
    padding-bottom: 20px;
  }
</style>
