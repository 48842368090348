<template>
  <div>
    <label class="checkbox_items flex flex_acenter breakword" 
      :class="{'active':isCheck,'pointer': !disabled, 'not_selected_disabled': disabled && !isCheck  }" :style="{'padding':padding+'px'}">
      <div class="answer-status flexshrink flex flex_acenter flex_center" >
        <input v-if="!showRes && disabled || !disabled" type="checkbox" :disabled="disabled"
          :name="name" :value="value"
          v-model="isCheck"  
          @change="changeCheck" 
          class="checkbox-self">
        <span v-else-if="showRes && isCheck" :class="{'icon-radio_error_answer icon_color_E74362': answerStatus == 2,'icon-radio_right_answer': answerStatus == 1}" class="fontsize_20">
          <span class="path1"></span>
          <span class="path2"></span>
        </span>
      </div>
      <p v-html="label" class="label-text fontsize_14 color_fff padding_left16 lineheight_1d5 breakword"></p>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    checked: Boolean, //是否选中
    value: [String, Number],
    label: String,
    name: String,
    disabled:Boolean, //禁用
    showRes: Boolean, //是否显示结果
    isRightAnswer:Boolean, //是否正确答案
    padding: {
      type:Number,
      default: 16
    }
  },
  data() {
    return {
      isCheck: this.checked, //选中值
    };
  },
  computed: {
    answerStatus() {
      // 禁用 // 选中 // 正确
      let { disabled, isCheck, value, isRightAnswer } = this;
      //未回答
      if(!disabled) return -1;
      // 选中正确
      if(isCheck && isRightAnswer) return 1;
      // 选中错误
      if(isCheck && !isRightAnswer) return 2;
      // 未选中
      return 0;
    },
  },
  watch: {
    checked: {
      handler(n) {
        this.isCheck = n;
      },
      immediate: true
    }
  },
  methods: {
    changeCheck(event) {
      // 选中状态改变 event.target.checked,event.target.value
      this.$emit("change",{
        value: event.target.value,
        checked: event.target.checked
      });
    },
    getStyle() {
      let { padding } = this;
      let styleSet = {};

      if(padding && !/\D/.test(padding)) { //数字 - px
        styleSet['padding'] = padding + 'px';
      }else if( padding && padding.indexOf('px') > -1 ) { 
        styleSet['padding'] = padding;
      }

      return styleSet;
    },
  },
}
</script>

<style lang="less" scoped>
  // 文本颜色
  @textColor: var(--item-color-inverted , #fff);
  // 文本悬浮
  @textHover: var(--item-color-inverted-hover , #fff);
  // 文本选中
  @textSelect: var(--item-color-inverted-selected, #fff);
  
  .checkbox_items {
    transition: background 0.4s;
    background-color: var(--item-color , #70ADC6);
    &:hover {
      background-color: var(--item-color-hover ,#4D98B7);
      .label-text {
        color: @textHover;
      }
      .checkbox-self {
        &:before {
          color: @textHover;
        }
        &:checked:before {
          color: @textHover;
        }
      }
    }
    // 选中
    &.active {
      background-color: var(--item-color-selected, #4D98B7);
      .label-text {
        color: @textSelect;
      }
      .checkbox-self {
        &:before {
          color: @textSelect;
        }
        &:checked:before {
          color: @textSelect;
        }
      }
    }
    // 提交-禁用且未选中
    &.not_selected_disabled {
      background-color: #A1A1A1;
    }
  }
  .label-text {
    color: @textColor;
    vertical-align: middle;
  }

  @radioWidth:18px;
  @radioHeight:18px;
  @radioColor:@textColor;

  .answer-status {
    width: @radioWidth;
    height: @radioHeight;
  }
  .checkbox-self {
    position: relative;
    width: @radioWidth;
    height:@radioHeight;
    appearance: none;
    font-family: 'icomoon';
    font-size: 18px;
    top: -1px;
    vertical-align: middle;
    &:before{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: @radioWidth;
      height: @radioHeight;
      display: inline-block;
      content: "\e9d4";
      color: @textColor;
    }
    &:checked:before{
      content: '\e9d3';
      color: @textColor;
    }
  }
</style>