<template>
  <topTools
    type="right"
    rightTips="组件"
    :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true,'reset':true, 'moveInline':moveInline}"
    :isHighlight="isHighlight"
    class="component-container component-timedsequence"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit"
    @del="delComponent"
    @reset="resetAll"
    @copy="handleCopying"
    @moveInline="handleMoveInline"
  >
    <contentList
      :list="list"
      :currentForm="currentForm"
      :getProgressStatus="getProgressStatus"
      :ckeditorPrefix="ckeditorPrefix"
      :canKeyEnter="canKeyEnter"
      :throttleDelay="throttleDelay"
      @input="handleInput"
      @blur="formItemBlur"
      @blurNotUpdate="formItemBlur"
    ></contentList>
    <div class="timed-sequence marginCenter">
      <div class="timed-sequence-inner component-inner">
        <div class="timed-sequence-widget component-widget">
          <div class="sequence-container">
            <div class="sequence-container-inner clearfix">
              <img
                v-for="(gitem, gind) in optionsItem"
                :key="'sequenceImg_'+gind"
                class="sequence-image"
                :src="gitem._graphic.src"
                :alt="gitem._graphic.alt"
                :title="gitem._graphic.title"
                @load="imgLoad"
                @error="imgError"
              />
            </div>
            <div class="sequence-feedback-container">
              <div class="sequence-feedback-icon icon icon-tick"></div>
              <div class="sequence-feedback-icon icon icon-cross"></div>
            </div>
            <div class="sequence-state-container" :class="{'complete':isComplete}">
              <img
                class="sequence-start-image"
                :src="detail[propSet._startGraphic.src]"
                :alt="detail[propSet._startGraphic.alt]"
                :title="detail[propSet._startGraphic.title]"
                @load="imgLoad"
                @error="imgError"
              />
              <img
                class="sequence-end-image"
                :src="detail[propSet._endGraphic.src]"
                :alt="detail[propSet._endGraphic.alt]"
                :title="detail[propSet._endGraphic.title]"
                @load="imgLoad"
                @error="imgError"
              />
            </div>
          </div>
          <div
            class="sequence-indicators"
            :style="{'--duration':  detail['component-_timerInterval'] + 'ms' }"
          >
            <div class="sequence-indicators-inner clearfix">
              <div
                v-for="(sitem, sind) in optionsItem"
                :key="'sequenceIndicator_'+sind"
                class="sequence-indicator"
                :class="{
                    'correct':showMarking && userAnswers[sind] && userAnswers[sind]._isCorrect,
                    'error':showMarking && userAnswers[sind] && !userAnswers[sind]._isCorrect,}"
              >
                <div class="sequence-indicator-inner"></div>
                <div
                  class="sequence-indicator-icon icon icon-tick el-icon-check"
                  :class="{'show': showMarking && userAnswers[sind] && userAnswers[sind]._isCorrect }"
                ></div>
                <div
                  class="sequence-indicator-icon icon icon-cross el-icon-close"
                  :class="{'show': showMarking && userAnswers[sind] && !userAnswers[sind]._isCorrect }"
                ></div>
              </div>
            </div>
          </div>
          <div class="sequence-controls">
            <div class="sequence-controls-inner clearfix breakword">
              <a
                href="#"
                @click.stop="onStartClicked"
                class="sequence-start-button button"
                :class="{'show': isStart}"
              >{{detail[propSet._controls.start]}}</a>
              <a
                href="#"
                @click.stop="onAnswerClicked"
                class="sequence-answer-button button"
                :class="{'show': isAnswer}"
              >{{detail[propSet._controls.answer]}}</a>
              <div
                class="sequence-complete-button button disabled"
                :class="{'show': isComplete}"
              >{{detail[propSet._controls.complete]}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-row class="margin_top20">
      <el-col v-if="detail['component-_canShowFeedback']" class="flex1">
        <customButton
          @click="tapBtn(2)"
          :disabled="answerState == -1"
          :color="answerState==-1?'#A1A1A1':'#70ADC6'"
          padding="15px 52px"
          width="100%"
          :roundPX="0"
        >
          <span
            class="fontsize_14"
          >{{detail["component-_buttons-properties-_showFeedback-properties-buttonText"] || '显示反馈'}}</span>
        </customButton>
      </el-col>
    </el-row>
    <fd-drawer
      destroy-on-close
      :title="drawerSet.title"
      :visible.sync="drawerSet.visible"
      :direction="drawerSet.direction"
      :before-close="handleClose"
      :size="479"
      custom-class="drawer-right"
      :with-header="false"
      append-to-body
      @open="openDrawer"
      @closed="closeDrawer"
      :modal="false"
    >
      <div class="drawer-right-inner prel bgc_fff">
        <div
          class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header"
          style="z-index:1;"
        >
          <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
          <i
            class="icon-close-popup header-close-btn pointer fontsize_14 color_333"
            @click="closeOuterDrawer"
          ></i>
        </div>
        <rightForm
          ref="rightForm"
          class="drawer-right-inner-form"
          :ruleForm="detail"
          :tabList="tabList"
          :currentTab="drawerSet.activeName"
          :ckeditorNameId="'component-id'"
          :ckeditorPrefix="ckeditorPrefix"
          :listItemSet="{'canDrag':true}"
          @closeOptionsModal="closeOptionsModal"
          @sortOptionList="handleSort"
          @open="openInnerDrawer"
          @close="closeInnerDrawer"
          @copy="handleCopy"
          @del="handleDel"
          @selectChange="selectChange"
          @formItemInput="formItemInput"
          @formItemBlur="formItemBlur"
        ></rightForm>
        <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
          <div class="right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14">
            <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
            <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop"></i>
          </div>
          <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
            <objForm
              v-if="innerDrawer.visible && innerDrawer.submitAsObj"
              :titleFontSize="14"
              :scrollBoxPaddingLR="16"
              :ruleForm="innerDrawer.form"
              :propList="innerDrawer.propList"
              :ckeditorNameId="'component-_timedsequence_sub'+innerDrawer.current"
              :current="innerDrawer.current"
              @selectChange="objFormChange"
              @formItemBlur="objFormChange"
            ></objForm>
            <scaffoldForm
              v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
              :titleFontSize="14"
              :scrollBoxPaddingLR="16"
              :ruleForm="detail"
              :propList="innerDrawer.propList"
              :ckeditorNameId="'component-_timedsequence'"
              @selectChange="formChangeBlur"
              @formItemBlur="formChangeBlur"
            ></scaffoldForm>
          </div>
        </div>
      </div>
    </fd-drawer>
    <fd-dialog
      custom-class="hotgraphic-dialog"
      :visible.sync="feedbackDialogSet.visible"
      width="100%"
      :modal-append-to-body="true"
      :append-to-body="true"
      :before-close="beforeCloseDialogSet"
      :style="containerStyle"
    >
      <div class="feedback-popup-inner">
        <div class="notify__popup-inner">
          <div class="notify__content">
            <div class="notify__content-inner">
              <div class="notify__title" id="notify-heading">
                <div class="notify__title-inner" role="heading" aria-level="1">
                  <p class="fontsize_16">
                    <strong v-html="detail['component-_feedback-properties-title']"></strong>
                  </p>
                </div>
              </div>
              <div class="notify__body">
                <div class="notify__body-inner">
                  <p
                    v-if="answerState == 1"
                    v-html="detail['component-_feedback-properties-correct']"
                  ></p>
                  <p
                    v-else-if="answerState == 0"
                    v-html="detail['component-_feedback-properties-_incorrect-properties-final']"
                  ></p>
                </div>
              </div>
            </div>
          </div>
          <button
            @click="feedbackDialogSet.visible = false"
            class="btn-icon notify__btn-icon notify__close-btn js-notify-close-btn"
            aria-label="关闭弹出窗口"
          >
            <div class="el-icon-close fontsize_24 color_fff"></div>
          </button>
        </div>
        <a class="a11y-focusguard a11y-ignore a11y-ignore-focus" role="presentation">&nbsp;</a>
      </div>
    </fd-dialog>
  </topTools>
</template>

<script>
import topTools from "@/renderer/components/common/topTools.vue";
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import customButton from "@/renderer/components/common/buttonTheme.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import hideModal from "@/renderer/mixins/popup/hideModal";
import componentCommon from "@/renderer/mixins/component/index";
import listCommon from "@/renderer/mixins/list/index";

/**
 * 定时序列
 */
export default {
  mixins: [hideModal, componentCommon, listCommon],
  components: {
    topTools,
    rightForm,
    scaffoldForm,
    contentList,
    objForm,
    customButton,
  },
  props: {
    containerStyle: Object,
  },
  data() {
    return {
      currentForm: {},
      componentName: "adapt-lianda-timedSequence",
      ckeditorPrefix: "componentTimedSequence",
      propSet: {
        _controls: {
          answer: "component-_controls-properties-answer",
          complete: "component-_controls-properties-complete",
          start: "component-_controls-properties-start",
        },
        _endGraphic: {
          alt: "component-_endGraphic-properties-alt",
          src: "component-_endGraphic-properties-src",
          title: "component-_endGraphic-properties-title",
        },
        _startGraphic: {
          alt: "component-_startGraphic-properties-alt",
          src: "component-_startGraphic-properties-src",
          title: "component-_startGraphic-properties-title",
        },
      },
      loadTotal: 0,
      width: 0,
      isStart: true,
      isAnswer: false,
      isComplete: false,
      currentStageIndex: 0,
      lastStageAnswered: -1,
      userAnswers: [],
      correctAnswers: 0,
      incorrectAnswers: 0,
      timer: null,
      showMarking: false,
      answerState: -1, //答题状态： -1 未答题（未提交）、0 提交并且错误、1 提交并且正确
      feedbackDialogSet: {
        visible: false, //是否显示
        title: "",
      },
    };
  },
  watch: {
    optionsItem: {
      deep: true,
      handler(n) {
        if (n) {
          this.$nextTick(() => {
            //及时刷新
            this.resetData();
            this.setupLayout();
            this.setupSequenceIndicators();
          });
        }
      },
    },
  },
  computed: {
    optionsItem() {
      return this.detail["component-_items"];
    },
    imgLen() {
      let items = this.optionsItem;
      let length =
        (Array.isArray(items) &&
          items.filter((v) => v._graphic && v._graphic.src).length) ||
        0;
      if (this.detail[this.propSet._startGraphic.src]) {
        length++;
      }
      if (this.detail[this.propSet._endGraphic.src]) {
        length++;
      }
      return length;
    },
    atLastStage: function () {
      return this.currentStageIndex == this.optionsItem.length - 1;
    },
    userDidInteract: function () {
      return this.lastStageAnswered === this.currentStageIndex;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("loaded");
      this.loadTotal = 0;
      this.isStart = true;

      // 监听窗口大小变化
      this.$el.addEventListener("resize", this.resizeEvent, true);
    });
  },
  destroyed() {
    this.$el.removeEventListener("resize", this.resizeEvent, true);
    this.closePop();
  },
  methods: {
    resetAll() {
      this.timer && clearInterval(this.timer);
      this.resetData();
      this.setupLayout();
      this.setupSequenceIndicators();
    },
    resizeEvent() {
      this.$nextTick(() => {
        this.setupLayout();
      });
    },
    beforeCloseDialogSet(done) {
      this.feedbackDialogSet.visible = false;
      done();
    },
    tapBtn() {
      let { answerState } = this;
      //是否开启了显示反馈
      let canShowFeedback = this.detail["component-_canShowFeedback"];

      if (answerState != -1 && !!canShowFeedback) {
        // 已答题，开启反馈，显示反馈弹窗
        this.feedbackDialogSet.visible = true;
        return;
      }
    },
    imgLoad() {
      this.loadTotal++;
      if (this.imgLen === this.loadTotal) {
        // 图片加载完成
        this.onWidgetImageReady();
      }
    },
    imgError() {
      this.loadTotal++;
      if (this.imgLen === this.loadTotal) {
        // 图片加载完成
        this.onWidgetImageReady();
      }
    },
    onWidgetImageReady: function () {
      // 图片加载完成
      this.resetData();
      this.setupLayout();
      this.setupSequenceIndicators();
    },
    setupLayout: function () {
      this.width = this.getElement(".sequence-container", 1).offsetWidth;
      this.getElement(".sequence-container-inner", 1).style.width =
        this.width * this.optionsItem.length + "px";
      this.getElement(".sequence-image").forEach(
        (element) => (element.style.width = this.width + "px")
      );
    },
    resetData: function () {
      this.timer = -1;
      this.currentStageIndex = 0;
      this.lastStageAnswered = -1;
      this.userAnswers = [];
      this.correctAnswers = 0;
      this.incorrectAnswers = 0;
      this.answerState = -1;
      this.isComplete = false;
      this.isStart = true;
      this.isAnswer = false;

      let indicatorInner = this.$el.querySelectorAll(
        ".sequence-indicator-inner"
      );
      indicatorInner.forEach((ele) => {
        ele.classList.remove("widen");
        ele.removeEventListener("animationend", this.updateSequence);
      });
    },
    setupSequenceIndicators: function () {
      var itemsLength = this.optionsItem.length;
      this.getElement(".sequence-indicator").forEach(
        (element) => (element.style.width = 100 / itemsLength + "%")
      );
    },
    onStartClicked: function (event) {
      if (event) event.preventDefault();

      this.getElement(".sequence-state-container", 1).velocity(
        { top: "-100%" },
        { duration: 800, easing: "swing" }
      );
      this.isStart = false;
      this.isAnswer = true;

      this.startTimer();
      this.updateIndicator();
    },
    onAnswerClicked: function (event) {
      if (event) event.preventDefault();

      if (this.lastStageAnswered == this.currentStageIndex) return;
      this.lastStageAnswered = this.currentStageIndex;
      this.stopTimer();
      this.endCurrentStage();
    },
    startTimer: function () {
      let timerInterval = this.detail["component-_timerInterval"];
      this.timer = setInterval(this.onTimerInterval, timerInterval);
    },
    stopTimer: function () {
      clearInterval(this.timer);
      this.timer = -1;
    },
    onTimerInterval: function () {
      this.updateSequence();
    },
    updateSequence: function () {
      this.markAnswer(this.currentStageIndex);

      if (this.atLastStage) this.endSequence();
      else this.showNextImage();
    },
    markAnswer: function (index) {
      let userDidInteract = this.userDidInteract;
      let shouldBeSelected = this.optionsItem[index]._shouldBeSelected;
      let correctInteraction =
        (userDidInteract && shouldBeSelected) ||
        (!userDidInteract && !shouldBeSelected);

      this.userAnswers.push({
        _stageID: index,
        _isCorrect: correctInteraction,
      });

      if (correctInteraction) this.correctAnswers++;
      else this.incorrectAnswers++;

      this.showIndicatorMarking();
      this.showSequenceFeedback(this.userAnswers[index]);
    },
    endSequence: function () {
      this.stopTimer();
      this.isAnswer = false;
      this.isComplete = true;
      this.getElement(".sequence-state-container", 1).velocity(
        { top: 0 },
        { duration: 800, easing: "swing" },
        this.onQuestionComplete
      );

      let hasFalse = Object.keys(this.userAnswers).findIndex(
        (key) => this.userAnswers[key]._isCorrect == false
      );
      this.answerState = hasFalse >= 0 ? 0 : 1;

      // 显示反馈弹窗
      if (this.detail["component-_canShowFeedback"]) {
        this.tapBtn(2);
      }
    },
    showNextImage: function () {
      this.currentStageIndex++;
      var leftMarg = -(this.currentStageIndex * this.width);
      this.getElement(".sequence-container-inner", 1).velocity({
        marginLeft: leftMarg + "px",
      });
      this.updateIndicator();

      if (this.timer === -1) this.startTimer();
    },
    updateIndicator: function () {
      let timerInterval = this.detail["component-_timerInterval"];
      let indicator = this.getElement(".sequence-indicator")[
        this.currentStageIndex
      ];
      let indicatorInner = indicator.querySelector(".sequence-indicator-inner");

      indicatorInner.className = indicatorInner.className += " widen";
      // widen
    },
    onQuestionComplete: function () {
      // this.updateAttempts();
      // this.setQuestionAsSubmitted();
      // this.markQuestion();
      // this.setScore();
      // this.setupFeedback();
      // this.showFeedback();
    },
    showIndicatorMarking: function () {
      this.showMarking = true;
    },
    showSequenceFeedback: function (userAnswer) {
      let feedbackContainer = this.getElement(
        ".sequence-feedback-container",
        1
      );
      let iconClass = userAnswer._isCorrect ? ".icon-tick" : ".icon-cross";
      this.animateFeedbackIcon(feedbackContainer.querySelector(iconClass));
    },
    animateFeedbackIcon: function (element) {
      // quickly fade in, then fade out immediately
      element.velocity({ opacity: 1 }, 50, function () {
        element.velocity({ opacity: 0 }, 500);
      });
    },
    endCurrentStage: function () {
      let indicator = this.getElement(".sequence-indicator")[
        this.currentStageIndex
      ];
      let indicatorInner = indicator.querySelector(".sequence-indicator-inner");
      // indicatorInner.animate({ width:"100%" }, 500, this.updateSequence);
      indicatorInner.style.animationPlayState = "paused";
      indicatorInner.style.animationDuration = "1000ms";
      setTimeout(() => {
        indicatorInner.style.animationPlayState = "running";
      }, 100);
      // 只对 CSS animation 动画有效
      indicatorInner.addEventListener("animationend", this.updateSequence);
    },
    getElement(selector, type = 2) {
      //type: 1 (querySelector) 2 (querySelectorAll)
      // 当前组件内
      let $el = this.$el;
      if (!selector) return null;
      if (type === 1) return $el.querySelector(selector);
      return $el.querySelectorAll(selector);
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/component.less"; /*引入公共组件样式*/

.sequence-indicator-icon {
  font-family: element-icons !important;
}

// 定时序列
.timed-sequence {
  margin-top: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  width: 460px;
  max-width: 100%;

  .timed-sequence-widget {
    position: relative;
  }

  .sequence-container {
    border-radius: 4px 4px 0 0;
    overflow: hidden;
    position: relative;
  }

  .sequence-image {
    display: block;
    float: left;
    min-width: auto;
    max-width: 100%;
  }

  .sequence-controls-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    text-align: center;
  }
  .sequence-start-button,
  .sequence-answer-button,
  .sequence-complete-button {
    border-radius: 4px;
    padding: 13px 58px;
  }

  .sequence-start-button {
    display: none;
    border: none;
    color: var(--btn-color-inverted);
    text-decoration: none;
    background-color: var(--btn-color);
    transition: all 300ms ease;
    &.show {
      display: block;
    }
    &:hover {
      color: var(--btn-color-inverted-hover);
      background-color: var(--btn-color-hover);
    }
  }

  .sequence-answer-button {
    display: none;
    border: none;
    color: var(--btn-color-inverted);
    text-decoration: none;
    background-color: var(--btn-color);
    transition: all 300ms ease;
    &.show {
      display: block;
    }
    &:hover {
      color: var(--btn-color-inverted-hover);
      background-color: var(--btn-color-hover);
    }
  }

  .sequence-complete-button {
    display: none;
    border: none;
    text-decoration: none;
    &.show {
      display: block;
    }
    &.disabled {
      background-color: var(--disabled, #727272) !important;
      color: var(--disabled-inverted, #ccc) !important;
    }
  }

  .sequence-feedback-container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    .sequence-feedback-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff;
      opacity: 0;
      font-size: 100px;
      width: 100px;
      height: 100px;
      margin-left: -50px;
      margin-top: -50px;
      border-radius: 50%;
      &.icon-tick {
        background-color: #00ff00;
      }
      &.icon-cross {
        background-color: #ff0000;
      }
      &.icon-tick::before,
      &.icon-cross::before {
        font-size: 58px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .sequence-state-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .sequence-start-image {
      max-width: 100%;
      display: block;
    }
    .sequence-end-image {
      max-width: 100%;
      display: none;
    }

    &.complete {
      .sequence-start-image {
        display: none;
      }
      .sequence-end-image {
        display: block;
      }
    }
  }

  .sequence-indicators {
    background-color: var(--item-color);
  }
  .sequence-indicator {
    &.correct .sequence-indicator-icon.icon-tick {
      color: var(--validation-success-inverted);
    }
    &.error .sequence-indicator-icon.icon-cross {
      color: var(--validation-error-inverted);
    }
  }

  .sequence-indicator {
    background-color: #fff;
    float: left;
    box-sizing: border-box;
    border: 2px solid var(--item-color);
    position: relative;
  }

  .sequence-indicator-inner {
    height: 28px;
    background-color: var(--item-color-hover);
    width: 0;
    transition-duration: 500ms;
  }

  .sequence-indicator-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
    &.icon-tick {
      color: #fff;
      display: none;
      &.show {
        display: block;
      }
    }
    &.icon-cross {
      color: #fff;
      display: none;
      &.show {
        display: block;
      }
    }
  }
}
.sequence-indicator.correct {
  .sequence-indicator-inner {
    background-color: var(--validation-success);
  }
}
.sequence-indicator.error {
  .sequence-indicator-inner {
    background-color: var(--validation-error);
  }
}

// 动画
@keyframes move-roll {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.widen {
  animation: move-roll var(--duration) linear 1;
  animation-fill-mode: forwards;
}
</style>