<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-assessmentResults"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div class="margin_top16 prel">
        <customButton @click="tapBtn(2)"
          :color="'#4d4d4d'" padding="4px 20px" width="auto" :roundPX="5">
          <span class="fontsize_14">{{detail[singleData.retryButton]}}</span>
        </customButton>
      </div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_assessmentresults_sub'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_assessmentresults'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import customButton from "@/renderer/components/common/button.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import questionsCommon from '@/renderer/mixins/questions/index';

/**
 * 评估 组件
*/
export default {
  mixins: [hideModal, componentCommon, questionsCommon],
  components:{ topTools, rightForm, scaffoldForm, objForm, customButton, contentList},
  data() {
    return {
      currentForm: {},
      componentName:"adapt-contrib-assessmentResults",
      ckeditorPrefix:"componentAssessmentResults",
      selected: {}, //选中
      rightAnswer: {},
      answerState: -1, //答题状态： -1 未答题（未提交）、0 提交并且错误、1 提交并且正确、
      showRightAnswer: false, //是否显示了正确答案
      singleData: {
       retryButton :"component-_retry-properties-button", //重新评估按钮
      },
      sliderData: {
        value:0, //当前值
        step:1, //步长
        range: false, //范围选择：2点可拖动点
        max:10,
        min:0
      },
    };
  },
  computed: {
    optionsItem() {
      // 选项
      return this.detail["component-_items"];
    },
  },
  watch: {
    optionsItem: {
      immediate: true,
      deep: true,
      handler(n) {
        if(!n) return;
        // 选项新增或删除，初始化选项相关数据
        this.initOptionRelative();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("loaded");
    });
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    initOptionRelative() {
      // 初始化选项相关数据
      this.selected = {};
      this.rightAnswer = {};
      this.answerState = -1;
      this.showRightAnswer = false;
    },
    tapBtn(btnWhere = 1) {
      // btnWhere: 1 左侧按钮 提交或者查看正确答案; 2 反馈
      let { selected, answerState, showRightAnswer } = this;
      let list = this.optionsItem;
      let listLen = this.optionsItem.length;
      //答案集合
      let answerSet = this.rightAnswer;
      let userAnswerLen = Object.keys(selected).length;
      // 是否开启答案显示
      let canShowModelAnswer = this.detail[this.singleData.canShowModelAnswer];
      // 全部选择
      let isSelectAll = userAnswerLen == listLen;

      if(btnWhere == 1  && isSelectAll && answerState == -1) {
        // 提交
        return;
      }
      if(canShowModelAnswer && btnWhere == 1 && isSelectAll && answerState == 0) {
        // 查看答案 - 隐藏答案
        //隐藏答案
        if(showRightAnswer) {
          this.selected = {};
          this.showRightAnswer = false;
          return;
        }
        // 显示答案
        this.selected = answerSet;
        this.showRightAnswer = true;
        return;
      }
    },
    handleCopy({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value, component_target}) {
      if(component_target !== 'component-_items') return;

      let items = this.detail[key_name];
      let copyItem = JSON.parse(JSON.stringify(items[index]));
      if(copyItem) {
        // 复制一项
        items.push(copyItem);
        this.saveDetail({
          [key_name]: items
        }, false);
      }
    },
    handleDel({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value, component_target}) {
      if(component_target !== 'component-_items') return;

      let items = this.detail[key_name];
      let itemsLen = items.length;
      if(index < itemsLen && index >= 0) {
        // 是否当前打开
        if(visible == 2) {
          this.closeInnerDrawer();
        }
        // 删除
        items.splice(index,1);
        this.saveDetail({
          [key_name]: items
        }, false);
      }
    },
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/
</style>