<template>
  <div class="coursepanel-wrapper" :style="containerStyle">
    <navbar ref="headNavBar" @toShare="shareSet"></navbar>
    <div class="coursepanel-inner flex" :style="{ 'height': !!is_web ? 'calc(100% - 40px)' : 'calc(100% - 48px)' }">
      <sidebar ref="siderBar" @showGlobalModal="showGlobalModal"></sidebar>
      <section class="coursepanel-main flex1 bgc_fff flex flex_acenter flexcolumn "
        :class="[pageType==='index'?courseForm['course-_htmlClasses']:contentForm['contentobject-_htmlClasses'],]" >
        <!-- <transition name="fade" mode="out-in"> -->
          <div class="course-create width_100p">
            <div 
              id="courseScroll"
              class="scroll-box width_100p" 
              :class="{
                'theme-box':($route.path.indexOf('course/theme')>-1 || $route.path.indexOf('course/template')>-1)
              }">
              <div 
                class="course-edit-inner width_100p height_100p"
                :class="{
                  'padding_bot42': $route.path.indexOf('course/edit')>-1,
                }"
                :style="pageType != 'index'? contentStyle : courseStyle"
                >
                <FullPage v-if="$route.path.indexOf('course/edit')>-1"
                  :containerStyle="containerStyle"
                  :detail="courseSchema"
                  :children="articleList"
                  :enabledProgress="courseProgress_isEnabled"
                  @loaded="loaded" @scrollToNew="scrollToNew" @scrollToModule="scrollToModule">
                </FullPage>
                <ThemePage v-else-if="$route.path.indexOf('course/theme')>-1 || $route.path.indexOf('course/course_theme')>-1 || $route.path.indexOf('course/template')>-1"
                  @updateList="updateThemeList"
                  >
                </ThemePage>
              </div>
            </div>
          </div>
        <!-- </transition> -->
      </section>
    </div>
    <fd-dialog
      append-to-body
      :title="''"
      :visible.sync="copyTips"
      width="700px"
      :before-close="closeCopyTips"
      custom-class="course-dialog full tips-dialog">
      <div class="tips-header">
        <p class="title">
          <img src="~@/assets/other/warning.svg" alt="">
          <span class="">请先添加粘贴区域。</span>
        </p>
        <p class="desc">先添加组块，再复制组件并粘贴到组块里面。</p>
      </div>
      <div class="tips-main">
        <img src="~@/assets/other/tips_component.png" alt="" class="tips_component_img">
      </div>
    </fd-dialog>
    <shareDialog :visible.sync="shareVisible" :shareData="shareData" :entryType="entryType" :update="updateLink"></shareDialog>
    <shareLinkDialog :visible.sync="shareLinkVisible" :shareData="shareData" ></shareLinkDialog>
  </div>
</template>

<script>
import FullPage from "@/renderer/components/scaffold/fullpage.vue";
import ThemePage from "@/renderer/components/Theme/main.vue";
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { Navbar, Sidebar, AppMain } from '@/renderer/components/Course/components';
import { getContentTotal } from "@/libs/api/content";
import { appendStyle } from "@/libs/utils/tools";
import { defaultColors, defaultColorList, LightenDarkenColor } from "@/libs/utils/data";
import shareDialog from '@/renderer/components/common/shareDialog.vue';
import shareLinkDialog from '@/renderer/components/common/shareLinkDialog.vue';
export default {
  components: {
    FullPage,
    ThemePage,
    Navbar,
    Sidebar,
    AppMain,
    shareDialog,
    shareLinkDialog
  },
  data() {
    return {
      shareVisible:false,
      shareLinkVisible:false,
      shareData:{},
      course_id:null,
      activeName: "first",
      filterText: "",
      defaultProps: {
        children: "children",
        label: "label",
      },
      currentPage: {
        detail: {}, //渲染schema
      },
      loadDone: {
        total:-1,
        count:0,
      },
      toRedirect: -1, //无-1，跳转1，0待跳转
      entryType:'new'
    };
  },
  computed: {
    ...mapState({
      listJson: state => state.course.schemaJson,
      contentDetail: state => state.content.contentDetail,
      articleList: state => state.article.articleList,
      contentForm: state => state.content.contentForm,
      copyTips: state => state.component.copyTips,
    }),
    ...mapGetters([
      'getCourseFormById',
      'getCustomStyleById'
    ]),
    containerStyle() {
      // 最外层样式 - 当前获取了主题的颜色表
      let styleSet = {};
      let propSet = Object.keys(this.courseForm).filter(v => v.indexOf("course__themeVariables___")>-1);
      propSet.forEach(v => {
        let prop = this.getShortPropOfTheme(v);
        styleSet[prop] = this.courseForm[v];
      });
      styleSet = this.getColorDefault(styleSet);
      return styleSet;
    },
    courseSchema() {
      //首页（特殊的主题页）index-index，主题页 contentobject-title ，内容页 contentobject-content
      let pageType = this.$route.query.pageType;
      let obj = {
        index: "index-index",
        menu: "contentobject-title",
        content: "contentobject-content",
      };
      return this.listJson[ obj[pageType] ];
    },
    courseForm() {
      return this.getCourseFormById(this.$route.params.id) || {};
    },
    is_web() {
      return !process.env.IS_ELECTRON;
    },
    pageType() {
      return this.$route.query.pageType;
    },
    pageForm(){
      return this.$route.query && (this.pageType == 'index') ? this.courseForm : this.contentForm;
    },
    tabList() {
      let schemaName = this.pageType == 'index' ? 'course-style' : 'contentobject-style';
      return this.listJson[schemaName] && this.listJson[schemaName].tabgroup_list || []
    },
    styleProps() { //获取样式各项属性名
      let styleProps = {};
      this.tabList[0] &&  this.tabList[0].setting_list && this.tabList[0].setting_list.forEach(v=>{
        if(v.properties_name == "_backgroundStyles") {
          v.properties.forEach(sval=>{
            styleProps[sval.properties_name] = sval.key_name
          })
        }else {
          styleProps[v.properties_name] = v.properties && v.properties[0].key_name || v.key_name
        }
      });
      return styleProps;
    },
    courseStyle() {
      return {
        'background-image': this.pageForm[this.styleProps['_backgroundImage']] ? `url(${this.pageForm[this.styleProps['_backgroundImage']]})` : 'none',
        'background-repeat': this.pageForm[this.styleProps['_backgroundRepeat']] || 'repeat',
        'background-position': this.pageForm[this.styleProps['_backgroundPosition']] || '0% 0%',
        'background-size': this.pageForm[this.styleProps['_backgroundSize']] || 'auto',
      }
    },
    contentStyle() {
      return {
        'background-image': this.pageForm[this.styleProps['_backgroundImage']] ? `url(${this.pageForm[this.styleProps['_backgroundImage']]})` : 'none',
        'background-repeat': this.pageForm[this.styleProps['_backgroundRepeat']] || 'repeat',
        'background-position': this.pageForm[this.styleProps['_backgroundPosition']] || '0% 0%',
        'background-size': this.pageForm[this.styleProps['_backgroundSize']] || 'auto',
      }
    },
    courseProgress_isEnabled() {
      // 课程进度条是否启用
      return this.courseForm['course-_pageLevelProgress-properties-_isEnabled'] || false;
    },
    pageProgress_isEnabled() {
      // 页面进度条是否启用
      return this.pageType !== 'index' && this.pageForm['contentobject-_pageLevelProgress-properties-_isEnabled'] 
      || (this.pageType === 'index' && true) 
      || false;
    },
    customStyle() {
      return this.getCustomStyleById(this.$route.params.id) || "";
    },
  },
  watch: {
    "$route": {
      handler(to, from) {
        // console.log('to',to, 'from', from)
        this.toRedirect = -1;
        this.SET_COPYTIPS(false);
        this.SET_COPYING({
          copying: false,
          data: {}
        });
        if(to.params.is_redirect == 1) { //如果是报错跳转过来，图片不做懒加载
          this.toRedirect = 0;
          this.SET_LAZY_IMAGE(false);
        }else {
          this.SET_LAZY_IMAGE(true);
        }
        this.loadDone.count = 0;
        //清掉高亮id
        this.closePop();
        if(this.$route.query.pageType === 'content') {
          this.getContentTotal();
        }
        if(from && (from.query.pageType ==="content" || this.$route.query.pageType==="menu") ) {
          this.CLEAR_CONTENT();
          this.CLEAR_ARTICLE();
        }
        if(to.params.id && (to.query.pageType==="content" || this.$route.query.pageType==="menu")) {
          //获取 内容页/主题页 详情
          this.GetContentObjectOne({
            course_id:this.$route.params.id,
            contentobject_id:this.$route.params.contentId,
            isMenu: this.$route.query.pageType==="menu"
          });
        }else if(this.$route.params.id && (this.$route.query.pageType==="index") ) {
          //获取首页详情 - 课程详情
          this.GetCourseDetail({course_id:this.$route.params.id}).then(res => {
            if(res.errorcode == 0 ) {
              appendStyle({style:res.data.course['course-customStyle'], document});
            }
          })
          // 获取首页排序列表
          this.GetHomeSetting(this.$route.params.id);
        }
        if (this.listJson && typeof this.listJson == "object") {
          // this.modifyJson(this.$route.query.pageType);
        }
      },
      immediate:true,
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    customStyle: {
      immediate:true,
      handler(val) {
        if(val !== undefined) {
          appendStyle({style:val, document});
        }
      }
    },
  },
  mounted() {
    //当前页面多路由，路由变化看 watch里"$route"
    // this.$nextTick(() => {
    //   console.log('courseForm-customStyle', this.courseForm['course-customStyle'])
    //   appendStyle({style:this.courseForm['course-customStyle'], document});
    // });
    this.GetSchemaCourse({course_id:this.$route.params.id}); //获取通用设置 -- 在layout获取了
  },
 
  created() {
    // 获取组件列表
    this.GetComponentListByGroup();
    this.GetComponentList();
  },
  destroyed() {
    // 页面即将销毁
    this.CLEAR_COURSE_DATA();
    this.CLEAR_TREE();
    this.CLEAR_CONTENT();
    this.CLEAR_ARTICLE();
  },
  methods: {
    ...mapMutations(['CLEAR_COURSE_DATA','CLEAR_CONTENT','CLEAR_ARTICLE','CLEAR_TREE','closePop','SET_LAZY_IMAGE','SET_COPYTIPS','SET_COPYING']),
    ...mapActions(['GetCourseDetail','GetContentObjectOne','GetHomeSetting','GetSchemaCourse','GetComponentListByGroup','GetComponentList','ShareCourseDetail']),
    toShare(){
      this.entryType='new';
      this.shareVisible=true;
      this.shareData={
        code: '', 
        title: '',
        open_way: '',
        code_type: '',
        valid_type: '',
        teloremail: {
          email: [],
          tel: [],
        },
        valid_time: '',
        valid_days: '',
        if_reset_url: 0
      }
    },
    shareLink(item,t) {
      if (item.is_valid == 2) {
        return false
      }
      this.shareData={}
      this.ShareCourseDetail(this.$route.params.id)
        .then((res) => {
          if (res.errorcode==0) {
            this.shareData = res.data;
            if(t){
              this.shareData.is_update=t;
            }
            if(!res.data.code_type){
              this.shareData.code_type='';
            }
            this.shareLinkVisible = true;
          }
        }).catch((err) => {

        });
    },
    shareSet() {
      let that =this;
      this.entryType='other';
      this.shareData={}
      this.ShareCourseDetail(this.$route.params.id)
        .then((res) => {
          if (res.errorcode==0) {
            if (!res.data.teloremail) {
              res.data.teloremail={tel:[],email:[]}
            }
            this.shareData = {
              title:'',
              if_reset_url:0,
              ...res.data,
            };
            that.shareVisible = true;
          }
        }).catch((err) => {

        });
    },
    updateLink(id,t){
      this.shareLink({id:id,is_valid:1},t)
    },
    closeCopyTips(done) {
      done();
      this.SET_COPYTIPS(false);
    },
    updateThemeList({type}) {
      // 更新主题列表

      let siderBarElement = this.$refs.siderBar;
      if(siderBarElement && siderBarElement.updateListOfExtra) {
        siderBarElement.updateListOfExtra(type);
      }
    },
    getColorDefault(obj) {
      // 颜色默认值
      defaultColorList.forEach(v => {
        let currentVal = obj['--'+v.propName];
        if(!currentVal) {
          if(v.from) {
            currentVal = v.withMethod ? LightenDarkenColor(obj['--'+v.from], Math.abs(v.params.value), 'darken' )  : obj['--'+v.from];
            // currentVal = obj['--'+v.from];
          }else {
            currentVal = defaultColors[v.default];
          }
          obj['--'+v.propName] = currentVal;
        }
      });
      return obj;
    },
    scrollToId(id, type) { //type：center
      // 滚动到指定id
      let parent = document.getElementById("courseScroll");
      let target = document.getElementById(id);
      if(!target) return;
      // 获取需要滚动高度
      let offsetTop = this.getOffsetTop(target);
      // this.$router.push(`/course/edit/${this.$route.params.id}/${this.$route.params.contentId}?pageType=${this.$route.query.pageType}`);
      this.$nextTick(()=>{
        setTimeout(() => {
          if(type === "center") {
            //滚动至视野中
            let centerH = (parent.offsetHeight - target.offsetHeight)/3;
            parent.scrollTo({top:offsetTop - 50 - 20 - (centerH > 0 ? centerH : 0), behavior: 'smooth'});
          }else {
            parent.scrollTo({top:offsetTop - 50 - 20, behavior: 'smooth'});
          }
          
        }, 0);
      });
    },
    getOffsetTop(obj) {
      return obj.offsetTop + (obj.offsetParent ? this.getOffsetTop(obj.offsetParent) : 0);
    },
    loaded() {
      // 加载计数
      this.loadDone.count++;
      // console.log('加载计数', this.loadDone.count, '总数',this.loadDone.total);
      if(this.loadDone.total >= 0 && this.loadDone.total <= this.loadDone.count) {
        // 章节、组件、组块已全部加载完成
        let n = this.$route;
        // console.log('n',n);
        if(n && n.path.indexOf('/course/edit') > -1 && n.params.is_redirect == 1 && (n.params.article_id || n.params.block_id)) {
          // console.log('需要重定向',n.params.id,n.params.contentId, n.params.article_id || n.params.block_id);
          this.$nextTick(() => {
            //需要监听图片真实加载完成状态，定时时间不准确
            setTimeout(() => {
              //待跳转的 == 0
              if(this.toRedirect != 0) return;
              this.toRedirect = 1;
              let target = document.getElementById(n.params.block_id || n.params.article_id);
              target.classList.add("is-error")
              this.scrollToId(n.params.block_id || n.params.article_id);
            }, 10);
          });
        }
      }
    },
    scrollToNew({id}) {
      // 滚动至
      let target_id = id;
      this.$watch(function() {
        return this.loadDone.count;
      },(newVal, oldVal)=>{
        if(this.loadDone.total >= 0 && newVal >= this.loadDone.total) {
          this.$nextTick(() => {
            target_id && this.scrollToId(target_id);
            target_id = null;
          });
        }
      });
    },
    scrollToModule({id}) {
      // 立即滚动至视野中
      this.scrollToId(id, "center");
    },
    getContentTotal() {
      // 获取article、block、component数量
      getContentTotal(this.$route.params.id, this.$route.params.contentId).then(res => {
        if(res.errorcode == 0) {
          this.loadDone.total = res.data.article + res.data.block + res.data.component;
        }
      }).catch();
    },
    getShortPropOfTheme(propName) {
      // 获取简短属性
      // course__themeVariables___global__
      if(!propName || !propName.trim()) return;
      let shortName = propName.replace(/course__themeVariables___\w+__/, "");
      return '--'+shortName;
    },
    showGlobalModal(type) {//打开全局设置弹窗
      this.$refs.headNavBar && this.$refs.headNavBar.showTypeModal(type);
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    modifyJson(pageType) {
      //首页（特殊的主题页）index-index，主题页 contentobject-title ，内容页 contentobject-content
      let obj = {
        index: "index-index",
        menu: "contentobject-title",
        content: "contentobject-content",
      };
      this.currentPage.detail = this.listJson[ obj[pageType] ];
    },
  },
};
</script>

<style lang="less" scoped>
// 主题菜单 - 按钮
/deep/.theme-duration span{
  color:  var(--menu-item-inverted, --font-color)!important;
}


.coursepanel-wrapper {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden; //导致宽屏时，不显示最外层的滚动条
}
// 主体区域
.coursepanel-inner {
  // transition: margin-left .28s;
  // margin-left: 220px;
}
.course-create {
  box-sizing: border-box;
  height: calc(100%);
}
.course-edit-inner {
  box-sizing: border-box;
  // background-color: #ffffff;
  min-height: 100%;
  height: auto;
}
.theme-box {
  padding-bottom: 0;
}

// tips
.tips-header {
  padding: 16px 16px 10px;
  .title {
    margin-bottom: 8px;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
    line-height: 24px;
    img {
      vertical-align: middle;
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
    span {
      margin-left: 8px;
      font-weight: bolder;
    }
  }
  .desc {
    text-indent: 32px;
    font-size: 14px;
    color: #8C8C8C;
    line-height: 24px;
  }
}
.tips-main {
  position: relative;
  height: 469px;
  background-color: #F8EFF1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 601px 393px;
  background-image: url(~@/assets/other/copytips_bg.svg);
  .tips_component_img {
    position: absolute;
    right: 65px;
    bottom: 33px;
    width: 237px;
    height: 354px;
    object-fit: contain;
  }
}
</style>
