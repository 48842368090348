<template>
  <topTools
    class="body-theme"
    type="centerOne"
    rightTips="菜单栏"
    @edit="showEidt"
    :isHighlight="isHighlight"
  >
    <div class="body-theme-inner flex flex_atop flexwrap aloneCenter prel" :style="coloursVariable">
      <div class="course-menu-inner">
        <div>
          <!-- 菜单编辑 -->
          <div class="course-header-inner aloneCenter prel margin_top8 margin_bot16">
            <div v-for="item in contentDetail" :key="item.id" class="prel" :title="item.key_name">
              <div v-if="item.display == 1" class="flex prel" :title="dynamicForm[item.key_name]">
                <ckeditor
                  v-if="dynamicForm[item.key_name]"
                  :value="dynamicForm[item.key_name]"
                  type="simpleInline"
                  :id="'totheme-menuBody'+'-inline'+'-'+item.id"
                  :throttle="1000"
                  :canKeyEnter=" item.properties_name !== 'displayTitle' && item.properties_name !== 'title'"
                  @input="handleInput($event,item.key_name,item,true,false)"
                  @blur="handleBlur($event,item.key_name,item,true,false)"
                  @blurNotUpdate="handleBlur($event,item.key_name,item,false,false)"
                  :class="{ 'is_title fontsize_20': item.properties_name == 'body'}"
                ></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 class="menuNode">目录</h2>
      <draggable
        v-model="contentObjectList"
        filter=".add-page-quickly"
        chosenClass="chosen"
        forceFallback="true"
        ghostClass="ghostClass"
        group="people"
        animation="400"
        @start="onStart"
        @end="onEnd"
        @sort="onSort"
      >
        <transition-group tag="div" class="flex flex_atop flexwrap aloneCenter greentreeMenu">
          <div v-for="(item, index) in contentObjectList" :key="item.contentobject_id">
            <div
              ref="themeCard"
              class="theme-card pointer"
              :class="{ 'always-show-hide': item['contentobject-_isHidden'] }"
            >
              <div class="theme-img-wrapper">
                <div class="img-mask" :class="{'is-show':item['contentobject-_isHidden']}"></div>
                <div class="tools-wrapper">
                  <div
                    @click.stop="tapShowEdit(item,index)"
                    class="theme-edit-icon flex flex_acenter flex_center prel"
                  >
                    <i class="icon-menu-edit fontsize_12"></i>
                    <div class="hover-tips">{{$t('lang.edit')}}</div>
                  </div>
                  <div
                    @click.stop="tapCopy(item,index)"
                    class="theme-copy-icon flex flex_acenter flex_center prel"
                  >
                    <i class="icon-page-copy fontsize_12 color_fff"></i>
                    <div class="hover-tips">{{$t('lang.copying')}}</div>
                  </div>
                  <div
                    @click.stop="changeVisible(item)"
                    class="theme-show-icon flex flex_acenter flex_center prel"
                    :class="{'hide-status':item['contentobject-_isHidden']}"
                  >
                    <i
                      class="fontsize_14"
                      :class="{'icon-eye-left-show':!item['contentobject-_isHidden'], 'icon-eye-left-hide':item['contentobject-_isHidden']}"
                    ></i>
                    <div
                      class="hover-tips"
                    >{{item['contentobject-_isHidden'] ? $t('lang.display') : $t('lang.hide')}}</div>
                  </div>
                  <div
                    @click.stop="tapDel(item,index)"
                    class="theme-del-icon theme-edit-icon flex flex_acenter flex_center prel"
                  >
                    <i class="icon-new-del fontsize_12 color_fff"></i>
                    <div class="hover-tips">{{$t('lang.delete')}}</div>
                  </div>
                </div>
              </div>

              <div
                class="theme-content flex flexcolumn flex_jcbetween"
                :class="{'is-hide':item['contentobject-_isHidden']}"
              >
                <div class="theme-icon-tree pageIcon" v-if="item['_type'] == 'page'"></div>
                <div class="theme-icon-tree menuIcon" @click="HideMore(item)" v-if="item['_type'] == 'menu'" :class="!item.showMenu?'':'hideMenuIcon'"></div>
                <div
                  class="flex flex_acenter flex_jcbetween flexwrap flexAuto"
                  @click="tapStudyBtn(item,index,$event)"
                >
                  <div>
                    <div class="flex">
                      <p
                        class="theme-title flex1 pover2OfHtmlTile fontsize_14 color_222"
                        v-html="item['contentobject-title']"
                      ></p>
                      <!-- <ckeditor
                        type="simpleInline" 
                        :id="'totheme-title'+'-inline'+'-'+item['contentobject-id']" 
                        :value="item['contentobject-title']"
                        :canKeyEnter="false"
                        :throttle="1000"
                        @input="handleInput($event,'contentobject-title',item)"
                        @blur="handleBlur($event,'contentobject-title',item)"
                        class="theme-title fontsize_14 color_222">
                      </ckeditor>-->
                    </div>
                    <!-- <p class="theme-body fontsize_12 color_6E6E6E margin_bot8" v-html="item['contentobject-body']"></p> -->
                    <!-- <ckeditor
                        type="simpleInline" 
                        :id="'totheme-body'+'-inline'+'-'+item['contentobject-id']" 
                        :value="item['contentobject-body']"
                        :canKeyEnter="false"
                        :throttle="1000"
                        @input="handleInput($event,'contentobject-body',item)"
                        @blur="handleBlur($event,'contentobject-body',item)"
                        class="theme-body fontsize_12 color_6E6E6E margin_bot8">
                    </ckeditor>-->
                    <!-- <p class="theme-duration fontsize_12 color_A1A1A1" v-if="item['contentobject-duration']"><span class="padding_right4">用时:</span>{{item['contentobject-duration']}}分钟</p> -->
                    <!-- <el-progress
                      v-if="item.progress"
                      :percentage="item.progress"
                      :color="'#666666'"
                      class="theme-progress"
                    ></el-progress>-->
                  </div>
                  <div class="flex flex_acenter flex_jcbetween flexwrap">
                    <!-- <el-button class="theme-stydy-btn fontsize_12 color_222 margin_top16" @click.stop="tapStudyBtn(item)">{{item['contentobject-linkText'] || ''}}</el-button> -->
                    <!-- <progressExample  v-if="!!enabledProgress && item['contentobject-_pageLevelProgress-properties-_isEnabled']" :isMenuProgress="true" :progress="30" :detail="item" class="margin_top16 flexshrink"></progressExample> -->
                    <el-progress
                      v-if="!!enabledProgress && item['contentobject-_pageLevelProgress-properties-_isEnabled']"
                      type="circle"
                      :percentage="30"
                      :width="16"
                      :show-text="false"
                      :stroke-width="2"
                    ></el-progress>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="item['_type'] == 'menu' && (item['contentobject-child'] && item['contentobject-child'].length>0)" v-show="!item.showMenu"
            >
              <div v-for="(v,i) in item['contentobject-child']" :key="v.id" 
              class="childList theme-card pointer" :class="{ 'always-show-hide': v.properties_list['contentobject-_isHidden'] }">
                <div
                  class="theme-img-wrapper"
                >
                  <div class="img-mask" :class="{'is-show':v.properties_list['contentobject-_isHidden']}"></div>
                  <div class="tools-wrapper">
                    <div
                      @click.stop="tapShowEdit(v,i)"
                      class="theme-edit-icon flex flex_acenter flex_center prel"
                    >
                      <i class="icon-menu-edit fontsize_12"></i>
                      <div class="hover-tips">{{$t('lang.edit')}}</div>
                    </div>
                    <div
                      @click.stop="tapCopy(v,i)"
                      class="theme-copy-icon flex flex_acenter flex_center prel"
                    >
                      <i class="icon-page-copy fontsize_12 color_fff"></i>
                      <div class="hover-tips">{{$t('lang.copying')}}</div>
                    </div>
                    <div
                      @click.stop="changeVisible(v,'child')"
                      class="theme-show-icon flex flex_acenter flex_center prel"
                      :class="{'hide-status':v.properties_list['contentobject-_isHidden']}"
                    >
                      <i
                        class="fontsize_14"
                        :class="{'icon-eye-left-show':!v.properties_list['contentobject-_isHidden'], 'icon-eye-left-hide':v.properties_list['contentobject-_isHidden']}"
                      ></i>
                      <div
                        class="hover-tips"
                      >{{v.properties_list['contentobject-_isHidden'] ? $t('lang.display') : $t('lang.hide')}}</div>
                    </div>
                    <div
                      @click.stop="tapDel(v,i)"
                      class="theme-del-icon theme-edit-icon flex flex_acenter flex_center prel"
                    >
                      <i class="icon-new-del fontsize_12 color_fff"></i>
                      <div class="hover-tips">{{$t('lang.delete')}}</div>
                    </div>
                  </div>
                </div>

                <div class="theme-content flex flexcolumn flex_jcbetween">
                  <div class="theme-icon-tree pageIcon"></div>
                  <div
                    class="flex flex_acenter flex_jcbetween flexwrap flexAuto"
                    @click="tapStudyBtn(v,i,$event)"
                  >
                    <div>
                      <div class="flex">
                        <p
                          class="theme-title flex1 pover2OfHtmlTile fontsize_14 color_222"
                          v-html="v['title']"
                        ></p>
                      </div>
                    </div>
                    <div class="flex flex_acenter flex_jcbetween flexwrap">
                      <el-progress
                        v-if="v.properties_list['contentobject-_pageLevelProgress-properties-_isEnabled']"
                        type="circle"
                        :percentage="30"
                        :width="16"
                        :show-text="false"
                        :stroke-width="2"
                      ></el-progress>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="add-page-quickly pointer"
            v-if="contentLayer < 5"
            @click="addPage('page')"
            key="99012"
          >
            <i class="icon-quick-add-content icon_color_51CFAE fontsize_24"></i>
          </div>
        </transition-group>
      </draggable>
    </div>
    <fd-drawer
      destroy-on-close
      :with-header="false"
      :title="drawerSet.title"
      :visible.sync="drawerSet.visible"
      :direction="drawerSet.direction"
      :before-close="handleClose"
      :size="479"
      custom-class="drawer-right"
      :modal="false"
    >
      <div class="drawer-right-inner prel">
        <div
          class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header"
          style="z-index:1;"
        >
          <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
          <i
            class="icon-close-popup header-close-btn pointer fontsize_14 color_333"
            @click="closeOuterDrawer"
          ></i>
        </div>
        <rightForm
          v-if="drawerSet.visible"
          class="drawer-right-inner-form"
          ref="rightForm"
          :ruleForm="ruleForm"
          :tabList="tabList"
          :ckeditorNameId="getCkId()"
          :ckeditorPrefix="ckeditorPrefix"
          :addPageControl="{
                  menu: !!(contentLayer < 4),
                  page: !!(contentLayer < 5)
                }"
          @handleDelPage="handleDelPage"
          @open="openInnerDrawer"
          @close="closeInnerDrawer(2,$event)"
          @handleClick="handleClick"
          @changeVal="changeVal"
          @selectChange="selectChange"
          @uploadImg="uploadImg"
          @formItemInput="formItemInput"
          @formItemBlur="formItemBlur"
        ></rightForm>
<!-- 
        <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
                  <div class="right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
                    <span class="padding_16 fontsize_16 color_222 poverOfHtmlTile richHtml_c222" style="width:350px;">编辑菜单</span>
                    <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerDrawer(1)" ></i>
                  </div>
                  <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
                    <scaffoldForm 
                      v-if="innerDrawer.visible"
                      :titleFontSize="14"
                      :scrollBoxPaddingLR="16"
                      :ruleForm="innerForm"
                      :propList="innerPropList.setting_list"
                      :ckeditorNameId="getCkId()"
                      @handleClick="handleClick"
                      @selectChange="formChangeBlur" 
                      @formItemBlur="formChangeBlur"
                    ></scaffoldForm>
                  </div>
        </div> -->
      </div>
    </fd-drawer>
    <fd-drawer
      destroy-on-close
      :with-header="false"
      :title="'编辑菜单'"
      :visible.sync="innerDrawer.visible"
      :direction="innerDrawer.direction"
      :before-close="closeInnerDrawer"
      :size="479"
      custom-class="drawer-right"
      :modal="false"
    >
      <div class="drawer-right-inner prel">
        <div
          class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header"
          style="z-index:1;"
        >
          <span class="fontsize_16 color_222">编辑菜单</span>
          <i
            class="icon-close-popup header-close-btn pointer fontsize_14 color_333"
            @click="closeInnerDrawer(2)"
          ></i>
        </div>
        <div style="height: calc(100% - 55px)">
          <scaffoldForm
            :titleFontSize="14"
            :scrollBoxPaddingLR="16"
            :ruleForm="innerForm"
            :propList="innerPropList.setting_list"
            :ckeditorNameId="getCkId()"
            @handleClick="handleClick"
            @selectChange="formChangeBlur"
            @formItemBlur="formChangeBlur"
          ></scaffoldForm>
        </div>
      </div>
    </fd-drawer>
    <fd-dialog
      append-to-body
      :title="delDialogDetail.title"
      :visible.sync="delDialogDetail.visible"
      width="600px"
      :before-close="handleCloseDel"
      custom-class="course-dialog not-full"
    >
      <p
        class="fontsize_16 color_222 textcenter margin_bot16"
        style="padding-top:9px;"
      >请确认是否删除{{delDialogDetail.item._type === 'page' ? '内容页' : '主题页'}}：{{delDialogDetail.item.title}}</p>
      <p class="fontsize_14 color_E74362 textcenter" style="padding-bottom:9px;">您确认要删除吗？删除之后将无法找回</p>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="sureDel" class="course-save pointer">确定删除</button>
        <button @click="closeDelDialog" class="course-close pointer">不删了</button>
      </span>
    </fd-dialog>
  </topTools>
</template>
  
  <script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import topTools from "@/renderer/components/common/topTools.vue";
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import progressExample from "@/renderer/components/common/progressExample.vue";
var time = null; //  在这里定义time 为null
export default {
  components: {
    topTools,
    rightForm,
    scaffoldForm,
    progressExample,
    draggable,
  },
  props: {
    ruleForm: Object,
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 主题颜色值
    themeVariables: {
      type: Object,
      default: function () {
        return {};
      },
    },
    enabledProgress: Boolean,
  },
  data() {
    return {
      ckeditorPrefix: "themecard",
      drawerSet: {
        direction: "rtl", //从右往左
        visible: false, //是否显示
        title: "",
        activeName: 0,
      },
      innerDrawer: {
        direction: "rtl", //从右往左
        visible: false, //是否显示
        title: "",
        activeName: 0,
      },
      innerForm: {},
      maxHeight: "auto",
      delDialogDetail: {
        title: "删除",
        visible: false,
        item: {},
        index: -1,
      },
      dynamicForm: this.ruleForm, //ruleForm是跟随请求响应而实时刷新，会导致跟输入不同步；所以不直接用ruleForm
      isFirst: true,
      // throttleDelay:Config.realTimeInput,
      // 编辑框-聚集状态
      ckFocusStatus: {},
      drag: false,
    };
  },
  computed: {
    ...mapState({
      popShowId: (state) => state.popShowId,
      // contentObjectList: state => state.content.currentContentList,
      schemaJson: (state) => state.course.schemaJson,
    }),
    ...mapGetters(["getLayerById"]),
    contentObjectList: {
      get() {
        return this.$store.state.content.currentContentList;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_CONTENT", value);
      },
    },
    componentName() {
      return this.pageType == "index"
        ? "course-style-right"
        : "contentobject-style-right";
    },
    isHighlight() {
      return (
        (this.popShowId &&
          this.popShowId.includes(
            this.ruleForm[this.getCkId()] + "_" + this.componentName
          )) ||
        false
      );
    },
    tabList() {
      let schemaName = this.componentName;
      return (
        (this.schemaJson[schemaName] &&
          this.schemaJson[schemaName].tabgroup_list) ||
        []
      );
    },
    contentDetail() {
      let schemaName = this.componentName;
      let bodyList =
        (this.schemaJson[schemaName] &&
          this.schemaJson[schemaName].tabgroup_list &&
          this.schemaJson[schemaName].tabgroup_list[0]) ||
        {};
      let objArr =
        bodyList.setting_list.filter(
          (v) => v.input_type === "richtext_mini" || v.input_type === "richtext"
        ) || [];
      return objArr;
    },
    pageType() {
      //区分首页 index、主题页 menu、内容页 content
      return this.$route.query.pageType;
    },
    innerPropList() {
      // 页面属性列表
      let schemaName = "course-style-right-2";
      return (
        (this.schemaJson[schemaName] &&
          this.schemaJson[schemaName].tabgroup_list &&
          this.schemaJson[schemaName].tabgroup_list[0]) ||
        {}
      );
    },
    coloursVariable() {
      // 主题颜色变量
      let themeVariables = this.themeVariables;
      let obj = {
        "--menu-item-border-color":
          themeVariables["--menu-item-border-color"] ||
          themeVariables["--item-color"],
      };
      return obj;
    },
    contentListLen() {
      return this.contentObjectList.length;
    },
    courseLayer() {
      // 当前课程层级数据
      return this.getLayerById(this.$route.params.id);
    },
    contentLayer() {
      // 当前页面层级
      return this.$route.params.contentId
        ? this.courseLayer[this.$route.params.contentId]
        : 0;
    },
  },
  watch: {
    contentObjectList: {
      immediate: true,
      deep: true,
      handler(newVal) {
        // 数据改变，获取theme-card最大 高度
        this.$nextTick(() => {
          this.getCardHeight();
        });
      },
    },
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    ...mapMutations(["openPop", "closePop"]),
    ...mapActions([
      "SaveCourse",
      "GetContentObjectSonList",
      "DelContentObjectOne",
      "CreateContentObject",
      "GetTree",
      "GetContentObjectSonList",
      "CopyContentObjectOne",
      "MoveNode",
      "getSonContentList",
    ]),
    //子菜单展示/收起
    HideMore(item){
      if(item.showMenu){
        this.$set(item,'showMenu',false);
      }else{
        this.$set(item,'showMenu',true);
      }
    },
    //开始拖拽事件
    onStart() {
      this.drag = true;
    },
    //拖拽结束事件
    onEnd() {
      this.drag = false;
      // console.log(this.list);
    },
    onSort(evt) {
      let { newIndex, oldIndex } = evt;
      if (!this.contentObjectList[newIndex]) return;

      let parentId = this.$route.params.contentId || this.$route.params.id;
      let params = {
        course_id: this.$route.params.id,
        _parentId: parentId,
        contentobject_id: this.contentObjectList[newIndex].contentobject_id,
        move_type: newIndex < oldIndex ? "up" : "down",
      };
      if (newIndex !== 0 && newIndex !== this.contentObjectList.length - 1) {
        params.below_contentobject_id =
          this.contentObjectList[oldIndex].contentobject_id;
      }

      this.MoveNode(params)
        .then((res) => {
          if (res.errorcode == 0) {
            // 当前移动节点，是当前内 子主题
            this.getSonContentList(this.$route.params.id, parentId);
          }
        })
        .catch();
    },
    tapCopy(item, index) {
      // 复制某个页面/主题
      this.CopyContentObjectOne({
        course_id: this.$route.params.id,
        contentobject_id: item.contentobject_id,
      })
        .then((res) => {
          if (res.errorcode == 0) {
            // 刷新目录树
            this.GetTree(this.$route.params.id);
            // 刷新中间主题列表
            let contParams = {
              course_id: item.course_id,
            };
            if (this.$route.query.pageType != "index") {
              contParams.contentObjectId = this.$route.params.contentId;
            }
            this.GetContentObjectSonList(contParams);
          }
        })
        .catch((err) => {});
    },
    tapDel(item, index) {
      // 删除某个页面/主题
      this.handleDelPage({ item, index });
    },
    addPage(pageType) {
      if (!["page", "menu"].includes(pageType)) return;
      let course_id = this.$route.params.id;
      let contentObject_id = this.$route.params.contentId;
      let params = {
        course_id: course_id,
        _parentId: contentObject_id,
        _type: pageType,
      };
      if (this.$route.query.pageType == "index") {
        //首页
        params._parentId = course_id;
      }
      this.CreateContentObject(params)
        .then((res) => {
          if (res.errorcode == 0) {
            this.$message("创建成功！");

            // 刷新中间主题列表
            let contParams = {
              course_id: course_id,
            };
            if (this.$route.query.pageType != "index") {
              contParams.contentObjectId = contentObject_id;
            }
            this.GetContentObjectSonList(contParams);
          }
        })
        .catch();
    },
    handleInput(value, propName, item, isContent, isUpdateSon) {
      //当前页面输入 - 不要回写数据-不然会有输入重复bug

      //如果右侧弹窗输入，刷新当前触发的change事件，不更新
      if (!!this.drawerSet.visible) return;
      let obj = {};
      obj[propName] = value;
      this.dynamicForm[propName] = value;
      this.formChangeBlur(value, propName, item, isContent, isUpdateSon);
    },
    handleBlur(value, propName, item, isContent, isUpdateSon) {
      //当前页面输入 - 不要回写数据-不然会有输入重复bug

      //如果右侧弹窗输入，刷新当前触发的change事件，不更新
      if (!!this.drawerSet.visible) return;

      let obj = {};
      obj[propName] = value;
      this.dynamicForm[propName] = value;
      this.formChangeBlur(value, propName, item, isContent, isUpdateSon);
    },
    handleDelPage({ item, index }) {
      // 删除-显示确认弹窗
      this.delDialogDetail.visible = true;
      this.delDialogDetail.item = item;
      this.delDialogDetail.index = index;
    },
    sureDel() {
      // 确认删除
      let { item, index } = this.delDialogDetail;

      this.$refs.rightForm && this.$refs.rightForm.closeTellChild();

      this.DelContentObjectOne({
        course_id: item.course_id,
        contentobject_id: item.contentobject_id,
      })
        .then((res) => {
          this.closeDelDialog();
          if (res.errorcode == 0) {
            this.closeInnerDrawer(2, {});
            if (item._type == "menu") {
              this.$message("主题页删除成功！");
            } else {
              this.$message("内容页删除成功！");
            }
            // 刷新中间主题列表
            let contParams = {
              course_id: item.course_id,
            };
            if (this.$route.query.pageType != "index") {
              contParams.contentObjectId = this.$route.params.contentId;
            }
            this.GetContentObjectSonList(contParams);
          }
        })
        .catch((_) => {
          this.closeDelDialog();
        });
    },
    handleCloseDel(done) {
      done();
      this.delDialogDetail.visible = false;
      this.delDialogDetail.index = -1;
      this.delDialogDetail.item = {};
    },
    closeDelDialog() {
      this.delDialogDetail.visible = false;
      this.delDialogDetail.index = -1;
      this.delDialogDetail.item = {};
    },
    getCardHeight() {
      this.maxHeight = "auto";
      this.$nextTick(() => {
        // console.log('nextTick');
        // console.log(this.$refs.themeCard);
        let targets = this.$refs.themeCard;
        let height = 0;
        targets &&
          targets.forEach((v) => {
            if (v.offsetHeight > height) {
              height = v.offsetHeight;
            }
          });
        if (height > 0) {
          this.maxHeight = height + "px";
          // console.log('最终高度',height);
        }
      });
    },
    // 单击事件函数
    clickMenuItem(item, index, event) {
      clearTimeout(time); //首先清除计时器
      time = setTimeout(() => {
        // 单击
        this.tapShowEdit(item, index);
      }, 210); //大概时间200ms
    }, // 双击事件函数，清除计时器，直接处理逻辑
    dblclickMenuItem(item, event) {
      // clearTimeout(time);  //清除
      // // 双击
      // this.tapStudyBtn(item);
    },
    tapShowEdit(item, index) {
      // 显示对应页面编辑二级弹窗
      // this.showEidt();
      this.openInnerDrawer(item);
      // this.$nextTick( () => {
      //   this.$refs.rightForm && this.$refs.rightForm.toOpenWithStatus(item, index);
      // } );
    },
    tapStudyBtn(item) {
      // 跳转对应页面
      this.$router.push({
        name: "EditContent",
        params: {
          id: this.$route.params.id,
          contentId: item.contentobject_id,
        },
        query: {
          pageType: item._type === "page" ? "content" : "menu",
        },
      });
    },
    changeVisible(item,type) {
      this.saveDetail(
        { "contentobject-_isHidden": type && type=="child"?!item.properties_list["contentobject-_isHidden"]:!item["contentobject-_isHidden"] },
        item
      );
    },
    formChangeBlur(value, propName, leftForm, isContent, isUpdateSon) {
      let obj = {};
      obj[propName] = value;
      this.dynamicForm[propName] = value;
      leftForm? this.innerForm[propName] = value:'';
      isContent = isContent ? true : false;
      isUpdateSon = isUpdateSon ? true : false;
      this.saveDetail(obj, leftForm, isContent, isUpdateSon);
    },
    saveDetail(updateObj, leftForm, isContent, isUpdateSon) {
      let course_id = this.$route.params.id;
      // let courseId = isContent ? course_id : leftForm.courseId;
      let ContentIdName = "contentobject-id"
      let params = {
        page_key: isContent
          ? "course-style-right"
          : this.innerPropList.page_key,
        course_id: course_id,
        properties_data: updateObj,
        contentobject_id: leftForm["contentobject-id"] || leftForm.contentobject_id,
        isLeftUpdate: true,
        updateContentObject: true,
        refreshOther: false,
        theme_type: "greentree-theme",
      };
      isUpdateSon = params.page_key == "course-style-right-2"?true:isUpdateSon;
      //保存详情

      this.SaveCourse(params).then((res) => {
        if (res.errorcode == 0) {
          // 更新当前 currentObjectList
          if (isUpdateSon) {
            let nextParams = {
              course_id: leftForm.course_id,
            };
            if (
              this.$route.query.pageType !== "index" ||
              this.$route.params.contentId != undefined
            ) {
              nextParams["contentObjectId"] = this.$route.params.contentId;
            }
            this.GetContentObjectSonList(nextParams).then((res) => {
              if (res.errorcode == 0) {
              }
            });
          }
        }
      });
    },
    openInnerDrawer(item) {
      let properties_list = item.properties_list;
      // delete item.properties_list;
      this.innerForm = Object.assign(item, properties_list);
      if (!this.innerDrawer.visible) {
        this.innerDrawer.visible = true;
      } else {
        this.innerDrawer.visible = false;
        setTimeout(() => {
          this.innerDrawer.visible = true;
        }, 105);
      }
    },
    closeInnerDrawer(isBtn = 2) {
      this.innerDrawer.visible = false;
      this.innerForm = {};
      // if(isBtn == 1) { //点击按钮 - 告诉子组件，恢复关闭
      //   this.$refs.rightForm && this.$refs.rightForm.closeTellChild();
      // }
    },
    showEidt() {
      this.openPop({
        type: this.componentName,
        id: this.ruleForm[this.getCkId()],
        ids: [this.ruleForm[this.getCkId()] + "_" + this.componentName],
      });
      this.drawerSet.title = this.schemaJson[this.componentName].title;
      this.drawerSet.visible = true;
    },
    closeOuterDrawer() {
      this.drawerSet.visible = false;
      if (this.innerDrawer.visible) this.innerDrawer.visible = false; //关闭内层
      this.closePop();
    },
    getCkId() {
      let pageType = this.pageType;
      let ids = {
        index: "course_id",
        menu: "contentobject_id",
      };
      return ids[pageType];
    },
    uploadImg(val, propName) {
      // console.log('uploadImg',val, propName)
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    changeVal(value, prop) {
      // console.log('changeVal',value,prop)
    },
    formItemInput(value, propName) {
      //右侧弹窗 输入
      let obj = {};
      obj[propName] = value;
      this.dynamicForm[propName] = value;
      // console.log(obj);
      this.savePageDetail(obj);
    },
    formItemBlur(value, propName) {
      let obj = {};
      obj[propName] = value;
      this.dynamicForm[propName] = value;
      this.saveThemeDetail(obj);
    },
    changeFormProp(prop, data) {
      //更新courseForm 某个属性
      let ruleForm = JSON.parse(JSON.stringify(this.detail));
      ruleForm[prop] = data;
      return ruleForm;
    },
    selectChange(e, prop) {
      this.formItemBlur(e, prop);
    },
    handleClose(done) {
      //关闭右侧弹窗
      this.drawerSet.visible = false;
      // 关闭内部弹窗
      if (this.innerDrawer.visible) {
        this.closeInnerDrawer(1);
      }
      done();
      this.closePop();
    },
    savePageDetail(updateObj) {
      //保存章节详情
      let pageType = this.$route.query.pageType;
      let course_id = this.$route.params.id;
      let contentId = this.$route.params.contentId;
      //首页（特殊的主题页）index-index，主题页 contentobject-title ，内容页 content
      let params = {
        page_key: "course-style-right",
        course_id: course_id,
        properties_data: updateObj,
        theme_type: "greentree-theme",
      };
      // 主题页
      if (pageType == "menu" && contentId) {
        params["page_key"] = "contentobject-style-right";
        params["contentobject_id"] = this.$route.params.contentId;
      }

      this.SaveCourse(params).then((res) => {});
    },
    saveThemeDetail(updateObj) {
      //保存主题详情
      let pageType = this.pageType;
      let params = {
        page_key: "",
        course_id: this.$route.params.id,
        properties_data: updateObj,
        themm_type: "greentree-theme",
      };
      // 首页
      if (pageType == "index") {
        params["page_key"] = "course-style-right";
      }
      // 主题页
      if (pageType == "menu") {
        params["page_key"] = "contentobject-style-right";
        params["contentobject_id"] = this.$route.params.contentId;
      }
      this.SaveCourse(params).then((res) => {
        if (res.errorcode == 0) {
          // this.$emit("update")
        }
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.flexAuto {
  flex: 1;
}
.greentreeMenu{
  flex-direction: column;
  &>div:not(:last-child){
    flex: 1;
    width: calc(100% - 35px);
  }
}
.theme-icon-tree {
  padding: 0 12px 0 8px;
  width: 16px;
  height: 16px;
  transition: all .3s ease-in-out;
  &.pageIcon::before {
    content: "\e924";
    color: #999;
    font-family: "icomoon";
    font-size: 12px;
  }
  &.menuIcon::before {
    content: "\ea08";
    color: #222222;
    font-family: "icomoon";
    font-size: 12px;
  }
  &.hideMenuIcon{
    transform: rotate(-90deg);
  }
}
.childList {
  padding-left: 32px;
  // width: calc(100% - 32px);
}
/*被拖拽对象的样式*/
.item {
  cursor: move;
}
/*选中样式*/
.chosen {
}
/*停靠位置样式*/
.ghostClass {
  opacity: 0.5 !important;
  // border-style: dashed !important;
}

.body-theme {
  box-sizing: border-box;
}

.body-theme-inner {
  box-sizing: border-box;
  padding-top: 19px;
  padding-bottom: 24px;
  width: 850px;
  flex-direction: row;
  & > .flex-atop {
    flex-direction: column;
  }
  & > .menuNode {
    font-size: 20px;
    color: #262626;
    font-weight: 500;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;
    width: 100%;
    margin: 16px 0;
  }
}

@themeCardWidth: 100%;
.theme-card {
  transition: height 0.6s;
  display: flex;
  flex-direction: column;
  margin-right: 35px;
  // margin-bottom: 30px;
  box-sizing: border-box;
  // border: 1px solid #D4D4D4;
  // border-radius: 4px;
  width: @themeCardWidth;
  background-color: var(--menu-item-btn-color);
  color: var(--menu-item-btn-color-inverted);
  // border-color: var(--menu-item-border-color, #D4D4D4);
  // &:nth-child(3n) {
  //   margin-right: 0;
  // }
  // 最后一行
  &:nth-child(3n + 1):nth-last-child(-n + 3),
  &:nth-child(3n + 1):nth-last-child(-n + 3) ~ .theme-card {
    margin-bottom: 0;
  }
  &:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.12);
    background-color: var(--menu-item-btn-color-hover);
    color: var(--menu-item-btn-color-inverted-hover);
    .theme-show-icon,
    .theme-edit-icon,
    .theme-copy-icon,
    .theme-del-icon {
      opacity: 1;
    }
    .theme-show-icon {
    }
  }
  // 隐藏状态时需要一直显示隐藏
  &.always-show-hide {
    .theme-show-icon {
      opacity: 1;
      background-color: #a1a1a1;
    }
  }
}
.theme-img-wrapper {
  flex-shrink: 0;
  position: relative;
  border-radius: 4px 4px 0 0;
  width: calc(@themeCardWidth - 2px);
  .img-mask {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    transition: all 0.2s;
    &.is-show {
      opacity: 1;
    }
  }
  .tools-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 50px;
    top: 60%;
    transform: translate(0, 60%);
    z-index: 0; //超过1将会高于 左侧弹出窗口层级
  }
  .theme-edit-icon {
    position: relative;
    //   border-radius:  0 0 0 4px;
    width: 24px;
    height: 24px;
    background-color: #2a6958;
    opacity: 0;
    transition: opacity 0.6s;
    &:hover {
      background-color: #203630;
      .hover-tips {
        display: block;
      }
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      display: block;
      content: "";
      width: 1px;
      height: 10px;
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
  .theme-copy-icon {
    opacity: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background-color: #2a6958;
    transition: opacity 0.6s;
    &:hover {
      background-color: #203630;
      .hover-tips {
        display: block;
      }
    }
  }
  .theme-del-icon {
    //   border-radius: 0 4px 0 0;
    &::after {
      left: 0;
      right: auto;
    }
  }
  .theme-show-icon {
    opacity: 0;
    transition: opacity 0.6s;
    // position: absolute;
    // right: 0px;
    // top: 0px;
    // z-index: 0; //超过1将会高于 左侧弹出窗口层级
    // border-radius: 0 4px 0 4px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    background-color: #2a6958;
    &:hover {
      background-color: #203630;
      .hover-tips {
        display: block;
      }
    }
  }
}
.theme-img {
  display: block; //无基线对齐 - 底部无空隙
  border-radius: 4px 4px 0 0;
  width: calc(@themeCardWidth - 2px);
  height: 136px;
}

.theme-content {
  flex: 1;
  box-sizing: border-box;
  padding: 16px;
  flex-direction: row;
  // min-height: 166px;
  p {
    color: var(--menu-item-inverted, --font-color);
  }
  &.is-hide {
    // 标题
    .theme-title /deep/p,
    .theme-title /deep/span {
      color: #aaaaaa !important;
    }
    // 介绍
    .theme-body /deep/p,
    .theme-body /deep/span {
      color: #aaaaaa;
    }
    // 用时
    .theme-duration,
    .theme-duration span {
      color: #aaaaaa !important;
    }
    // 按钮
    .theme-stydy-btn {
      border-color: #d8d8d8;
      background-color: #d8d8d8;
    }
    // 进度条
    /deep/.progress-inner {
      background-color: #aaaaaa !important;
    }
    /deep/.progress-example {
      background-color: #ededed;
      box-shadow: inset 0 0 0 1px #ededed;
    }
  }
  & /deep/.el-progress-circle {
    svg > path:first-child {
      stroke: var(--menu-item-progress-inverted) !important;
    }
    svg > path:last-child {
      stroke: var(--menu-item-progress) !important;
    }
  }
}
.theme-title {
  display: inline-block;
  padding: 0;
  line-height: 1.2;
}
.theme-body {
  // /deep/span {
  //   font-size: 12px!important;
  //   color: #6E6E6E!important;
  // }
}
.theme-duration {
  // /deep/span {
  //   font-size: 12px!important;
  //   color: #A1A1A1!important;
  // }
}
.theme-progress {
  width: 135px;
}
.theme-stydy-btn {
  box-sizing: border-box;
  border: 1px solid #222222;
  border-radius: 16px;
  padding: 4px 20px;
  overflow: visible;
  max-width: 100%;
  min-width: 64px;
  min-height: 28px;
  // line-height: 26px;
  line-height: 1.5;
  text-align: center;
  //主题色
  border-color: var(--menu-item-btn-color);
  color: var(--menu-item-btn-color-inverted);
  background-color: var(--menu-item-btn-color);
  opacity: 0.9;
  white-space: pre-wrap;
  &:hover {
    // color: #ffffff;
    opacity: 1;
    border-color: var(--menu-item-btn-color-hover);
    color: var(--menu-item-btn-color-inverted-hover);
    background-color: var(--menu-item-btn-color-hover);
  }
}

//由于内部弹出窗口
.drawer-right {
  /deep/ &.el-drawer {
    overflow: visible;
    .el-drawer__body {
      overflow: visible;
    }
  }
}
/deep/.el-drawer {
  // overflow: visible;
}
/deep/.el-drawer__body {
  // overflow: visible!important;
}
// 右侧弹窗
// 右侧弹窗头部高度
@drawHeaderHeight: 54px;
.drawer-right-inner {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .header {
    box-sizing: border-box;
    height: @drawHeaderHeight;
  }
  .header-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 19px 16px;
  }
  .drawer-right-inner-form {
    box-sizing: border-box;
    padding-top: 16px;
    height: calc(100% - @drawHeaderHeight);
  }
}
// 自定义 左边侧边栏弹窗
.right-sub-modal {
  position: absolute;
  z-index: 0;
  left: 0;
  transform: translateX(0);
  transition: all 0.05s;
  bottom: 0;
  width: 28vw;
  height: 100%;
  background: #fafafa;
  opacity: 0;

  /deep/.el-tabs__content {
    height: calc(100% - 40px);
  }

  &.visible {
    opacity: 1;
    box-shadow: -10px 2px 20px 0 rgba(0, 0, 0, 0.15);
    border-right: 1px solid #eee;
    transform: translateX(-100%);
    transition-duration: 0.2s; //展开
    & + .right-sub-modal-mask {
      display: block;
    }
  }

  .right-sub-modal-header {
    line-height: 22px;
    border-bottom: 1px solid #ededed;
  }
}
// 自定义 左侧弹窗 遮罩层
.right-sub-modal-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.15);
}

// 主题盒子，页面标题进度条
.theme-title-progress {
  flex-shrink: 0;
  margin-top: 6px;
  margin-right: 5px;
}

.add-page-quickly {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // width: 260px;
  // height: 302px;
  // height: 166px;
  width: calc(100% - 35px);
  height: 56px;
  margin-top: 23px;
  border-radius: 4px;
  border: 1px dashed #51cfae;
  &:hover {
    border-color: #1e9c7b;
    border-style: solid;

    .icon-quick-add-content::before {
      color: #1e9c7b;
    }
  }
  .content-page,
  .menu-page {
    border-radius: 4px;
    border: 1px solid #51cfae;
    width: 106px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #51cfae;
    transition: background 0.2s;
    cursor: pointer;
    &:hover {
      color: #ffffff;
      background-color: #51cfae;
      .icon-add::before {
        color: #ffffff;
      }
    }
    .icon-add::before {
      color: #51cfae;
    }
  }
}

//  提示
.hover-tips {
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  display: none;
  border-radius: 1px;
  border: 1px solid #dadada;
  padding: 1px 6px;
  font-size: 12px;
  line-height: 17px;
  color: #222;
  text-indent: 0;
  background: #f2f2f1;
  white-space: nowrap;
}
</style>
  