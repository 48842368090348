<template>
  <div>
    <fdRadio  v-for="(litem,lind) in list" :key="'radio_'+lind" 
      class="margin_bot16"
      :value="litem.value" 
      :label="litem.label" 
      :checked="selectedValue"
      :name="name"
      :disabled="disabled"
      :showRes="showRes"
      :isRightAnswer="litem._shouldBeSelected"
      @change="handleChange">
      </fdRadio>
  </div>
</template>

<script>
import fdRadio from "@/renderer/components/FormItem/fd-radio.vue";
export default {
  props: {
    name:String, //组别
    selected: [Number, String], //唯一选中值
    disabled: Boolean, //是否禁用
    showRes: Boolean, //是否显示结果
    list: {
      type: Array,
      default: function() {
        return [
          {
            value:"",
            label:"",
            id: 0,
          }
        ];
      }
    }
  },
  components: {
    fdRadio
  },
  data() {
    return {
      selectedValue: this.selected, //选中值
    };
  },
  watch: {
    selected: {
      handler(n) {
        if(n != undefined) {
          this.selectedValue = n;
        }
      },
      immediate: true
    },
  },
  methods: {
    handleChange(val) {
      // 选中value值
      this.selectedValue = val;
      this.$emit("change",val);
    },
  },
}
</script>

<style>

</style>