import Vue from 'vue';

export default {
  data() {
    return {
      loadImageDetail: {
        count:0,
      }
    };
  },
  mounted() {
    this.loadImageDetail.count = 0;

    this.$nextTick(() => {
      //有图片时，需要等待图片加载完成
      if(this.imgTotal === 0) this.$emit("loaded");
    });
  },
  methods: {
    errorImg(evt) {
      // 图片加载失败
      this.loadImageDetail.count++;

      if(this.loadImageDetail.count == this.imgTotal) {
        this.$emit("loaded");
      }
    },
    loadedImg() {
      // 图片加载完成
      this.loadImageDetail.count++;

      if(this.loadImageDetail.count == this.imgTotal) {
        this.$emit("loaded");
      }
    },
  }
};