<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true, 'reset':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-expose"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @reset="resetData"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div class="marginCenter">
        <div class="expose-widget component-widget breakword">
          <div class="expose-items clearfix" :class="{'expose-columns flex': withColumns}">
            <div v-for="(item, eind) in optionsItem" :key="'expose_'+eind" 
              :class="['expose-item', 'nth-child-'+eind, withColumns?'expose-column':'' ]"
              :style="{
                'width':columnsWidth
              }">
              <div class="expose-item-inner" :class="[animationType, !!item.open?'is_open':'']">
                <!-- <button class="expose-item-button"></button> -->
                <div @click.stop="toggleItem($event, eind)" class="expose-item-cover" :class="{ [animationType]: !!item.open}">
                  <div class="back" :style="{'background-color':getColorValue('--item-color')}"></div>
                  <div class="front"></div>
                  <span v-if="item.front" class="text" v-html="item.front"></span>
                </div>
                <div @click.stop="toggleItem($event, eind)" class="expose-item-content" ref="expose-item-content" :style="{
                  'height': exposeContentHeight
                }">
                  <div v-if="item.back && item.back.title" class="expose-item-title" v-html="item.back.title"></div>
                  <div v-if="item.back && item.back.body" class="expose-item-body" v-html="item.back.body"></div>
                  <div class="expose-item-img-wrap">
                    <img v-if="item.back && item.back._graphic && item.back._graphic.src" 
                      @load="onImageReady" 
                      :src="getImgSrc(item.back._graphic.src)" 
                      :alt="item.back._graphic.alt" class="expose-item-img">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            :listItemSet="{'canDrag':true}"
            @closeOptionsModal="closeOptionsModal"
            @sortOptionList="handleSort"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_expose_sub'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_expose'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import { LightenDarkenColor } from "@/libs/utils/data";
import objForm from "@/renderer/components/common/objForm.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import listCommon from '@/renderer/mixins/list/index';

/**
 * 揭示组件
*/
export default {
  mixins: [hideModal, componentCommon, listCommon],
  components:{ topTools, rightForm, scaffoldForm, contentList, objForm },
  props: {
    containerStyle: Object,
  },
  data() {
    return {
      currentForm: {},
      componentName:"adapt-lianda-expose",
      ckeditorPrefix:"componentExpose",
      withColumns: false, //是否通过几列显示
      exposeContentHeight: "auto",
    };
  },
  watch: {
    optionsItem: {
      deep: true,
      handler(n, o) {
        if(n) {
          this.exposeContentHeight = "auto";
          this.$nextTick(() => {
            this.setEqualHeights();
          });
        }
      }
    },
  },
  computed: {
    columnsWidth() {
      // 每一列宽度 - expose-item
      if(this.withColumns) {
        return 100 / this.detail["component-_columns"] + "%";
      }
      return "auto";
    },
    optionsItem() {
      return this.detail["component-_items"];
    },
    animationType() {
      return this.toHyphenCase(this.detail['component-_animationType']) || "slide-up";
    },
  },
  created() {
    // this.animationType = this.toHyphenCase(this.detail['component-_animationType']) || "slide-up";
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("loaded");
      
			this.setupColumns();
			this.setupEventListeners();
    });
  },
  destroyed() {
    this.$el.removeEventListener("resize", this.resizeEvent, true);
    this.$el.removeEventListener("transitionend", this.hiddenFilp, true);
    this.closePop();
  },
  methods: {
    resetData() {
      let items = this.optionsItem;
      
      items.forEach(item => {
        item.open = false;
      });
    },
    getImgSrc(src) {
      let columns = this.detail["component-_columns"];
      let size = columns == 1 ? 'xl' : columns == 2 ? 'm' : 's'
      if(src && src.indexOf("x-oss-process=style/") == -1) {
        if(src.indexOf("?") >= 0) return src + `&x-oss-process=style/${size}`;
        else return src + `?x-oss-process=style/${size}`;
      }
      return src;
    },
    resizeEvent() {
      this.$nextTick(() => {
        this.onDeviceResize();
      });
    },
    onImageReady: function() {
			this.setEqualHeights();
		},
    toHyphenCase: function(str) {
      if (!str) return false;
      return str.replace(/([A-Z])/g, "-$1").toLowerCase();
    },
    hiddenFilp(event) {
      let parentElement = event.target.parentElement;
      let isFilp = parentElement.classList.contains("flip-left") || parentElement.classList.contains("flip-up");
      let isOpen = parentElement.classList.contains("is_open");
      if(isFilp && isOpen) {
        parentElement.classList.add('hidden');
      }else {
        parentElement.classList.remove('hidden');
      }
    },
    setupEventListeners: function() {
      this.$el.addEventListener("transitionend", this.hiddenFilp, true);
      this.$el.addEventListener("resize", this.resizeEvent, true);
		},
    onDeviceResize: function() {
			this.setupColumns();
			this.setEqualHeights();
		},
    setupColumns: function() {
      let _columns = this.detail["component-_columns"];
      
			if (_columns && window.innerWidth > 760) {
        this.withColumns = true;
			} else {
        this.withColumns = false;
			}
		},

		setEqualHeights: function () {
			if (this.detail["_equalHeights"] === false) return;
			var contentElements = this.$refs["expose-item-content"];
      
      this.exposeContentHeight = "auto";
			var hMax = 0;
      contentElements.forEach(el => {
        var h = el.offsetHeight;
				if (h > hMax) hMax = h;
      });
      
      this.exposeContentHeight = hMax + "px";
		},
    toggleItem: function(e,index) {
			if (e.target.tagName === "A") return;
      console.log(this.optionsItem)
      this.$set(this.optionsItem[index], 'open', !this.optionsItem[index].open);
		},
    getColorValue(propValue = "--item-color") {
      return LightenDarkenColor(this.containerStyle[propValue], 10, 'darken')
    },
    saveDetail(updateObj, isUpdateList) { //isUpdateList：是否刷新列表
      if(updateObj.hasOwnProperty("component-_columns")) {
        // 列数变动
        this.exposeContentHeight = "auto";
        this.$nextTick(() => {
          this.setEqualHeights();
        });
      }
      //保存组件详情
      this.SaveCourse({
        "page_key":this.componentName,"course_id":this.detail.course_id,
        "properties_data": updateObj,
        "block_id": this.detail['block_id'],
        "component_id": this.detail['component-id']
      }).then(res=>{
        if(res.errorcode == 0) {
          if(isUpdateList) this.$emit("update");
          if(updateObj.hasOwnProperty("course-customStyle")) {
            // 更新了自定义css
            this.SET_CUSTOM_CSS({ data: res.data["course-customStyle"], course_id: this.detail.course_id });
          }
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/

  // 揭示组件
.expose-columns {
  flex-wrap: wrap;
	margin: 8px -4px 0;

  .expose-item {
    margin-top: 8px;
  }

	.expose-item-inner {
    border-radius: 4px;
		margin: 0 4px;
    // padding: 0 10px;
    &.is_open {
      box-shadow: 0 1px 8px rgba(0,0,0,0.12);
    }
	}
}

.expose-column {
	float: left;
}

.expose-item-inner {
  position: relative;
  perspective: 1000px;
  margin-bottom: 20px;
  cursor: pointer;

  &.slide-down,
  &.slide-left,
  &.slide-right,
  &.slide-up {
    overflow: hidden;
  }

  &.flip-left,
  &.flip-up {
    .expose-item-cover {
      .origin(0 ,0);
    }
  }

  &.shrink {
    .expose-item-cover {
      .zoom(1);
    }
  }

	.expose-item-cover {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 1;
		.transition-time(0.25s);
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		-ms-transform-style: preserve-3d;
		-o-transform-style: preserve-3d;
		transform-style: preserve-3d;
		.rotateX(0deg);
		.rotateY(0deg);
		padding: 0;
		text-align: center;
		.text {
			position: relative;
			display: inline-block;
			position: relative;
			top: 50%;
      box-sizing: border-box;
      padding: 16px;
			width: 100%;
			-webkit-transform: translateY(-50%);
			-ms-transform: translateY(-50%);
			transform: translateY(-50%);
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
			font-size: 20px;
      color: var(--item-color-inverted);
		}

		.back,
		.front {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}

		.front {
			background: var(--item-color);
			-webkit-backface-visibility: hidden;
			backface-visibility: hidden;
      
		}
    & .front:hover {
        background: var(--item-color-hover);
      }
		.back {
			background: var(--item-color);
			// background: darken(var(--item-color), 10%);
		}

		&.fade {
			opacity: 0;
		}

		&.slide-up {
			top: -100%;
		}

		&.slide-left {
			left: -100%;
		}

		&.slide-right {
			left: 100%;
		}

		&.slide-down {
			top: 100%;
		}

		&.flip-up {
			.rotateX(100deg);
		}

		&.flip-left {
			.rotateY(-100deg);
		}

		&.shrink {
			.zoom(0);
		}
	}
}

.expose-item-button {
	z-index: 10;
	position: absolute;
	top: 0;
	left: 0;
	height: 0;
	width: 0;
	padding: 0;
	background: none !important;
}

.expose-item-content {
  // 不跟主题色
	// background: var(--item-color-inverted);
  box-sizing: border-box;
  padding-bottom: 12px;
  background-color: #ffffff;
  padding:0 10px;

	.expose-item-title {
		padding: 10px 0;
    // 不跟主题色
		// background: var(--font-color);
    line-height: 22px;
		font-size: 16px;
    color: #222222;
	}

	.expose-item-body {
		padding: 5px 0;
    font-size: 14px;
    color: #6E6E6E;
    line-height: 1.5;
    /deep/p:not(:last-child) {
      margin-bottom: 8px;
    }
	}

  .expose-item-img-wrap {
    padding: 0 2px;
  }
  .expose-item-img {
    display: block;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    object-fit: contain;
  }
}

.transition-time(@time) {
	-webkit-transition: @time;
	-moz-transition: @time;
	-ms-transition: @time;
	-o-transition: @time;
	transition: @time;
}

.origin(@x, @y) {
	-webkit-transform-origin: @x @y;
	-moz-transform-origin: @x @y;
	-ms-transform-origin: @x @y;
	-o-transform-origin: @x @y;
	transform-origin: @x @y;
}

.rotateX(@angle) {
	-webkit-transform: rotateX(@angle);
	-moz-transform: rotateX(@angle);
	-ms-transform: rotateX(@angle);
	-o-transform: rotateX(@angle);
	transform: rotateX(@angle);
}

.rotateY(@angle) {
	-webkit-transform: rotateY(@angle);
	-moz-transform: rotateY(@angle);
	-ms-transform: rotateY(@angle);
	-o-transform: rotateY(@angle);
	transform: rotateY(@angle);
}

.zoom(@zoom) {
	-webkit-transform: scale(@zoom, @zoom);
	-moz-transform: scale(@zoom, @zoom);
	-ms-transform: scale(@zoom, @zoom);
	-o-transform: scale(@zoom, @zoom);
	transform: scale(@zoom, @zoom);
}
</style>