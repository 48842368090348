var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drag-caption-container",style:({
    'background-image': _vm.img && 'url('+_vm.img+')' || 'none',
    'width': _vm.width,
    'height': _vm.height,
    'background-repeat': 'no-repeat',
    'background-position': 'center',
    'background-size': 'cover',
  }),on:{"mousedown":function($event){$event.stopPropagation();return _vm.hang.apply(null, arguments)},"mouseup":function($event){$event.stopPropagation();return _vm.drop.apply(null, arguments)},"mouseleave":function($event){$event.stopPropagation();return _vm.mouseleave.apply(null, arguments)}}},[_c('div',{staticClass:"blinds-popup-captions"},_vm._l((_vm.list),function(citem,cind){return _c('div',{key:'caption_'+cind,staticClass:"blinds-popup-caption prel canDrag",class:{'focus':_vm.focusIndex === cind},style:({
        left: _vm.getUnitResult(citem.left),
        top: _vm.getUnitResult(citem.top),
        width: _vm.changeIndex == cind ? 'auto' : _vm.getUnitResult(citem.width),
      }),attrs:{"data-drag":"1","data-index":cind}},[_c('div',{staticClass:"remove_btn flex flex_center flex_acenter pointer",on:{"click":function($event){$event.stopPropagation();return _vm.tapRemove(citem, cind)},"mousedown":function($event){$event.stopPropagation();},"mouseup":function($event){$event.stopPropagation();}}},[_c('i',{staticClass:"el-icon-close fontsize_14 color_222"})]),_c('el-input',{ref:"caption_input",refInFor:true,staticClass:"resize_input",attrs:{"type":"textarea","resize":"horizontal","autosize":"","placeholder":_vm.placeholder},on:{"focus":function($event){return _vm.focusCaption($event, citem, cind)},"input":function($event){return _vm.inputCaption($event, citem, cind)},"blur":function($event){return _vm.blurCaption($event, citem, cind)}},nativeOn:{"mouseup":function($event){$event.stopPropagation();return _vm.inputMouseUp($event, citem, cind)},"mousedown":function($event){$event.stopPropagation();return _vm.inputMouseDown($event, citem, cind)}},model:{value:(citem.text),callback:function ($$v) {_vm.$set(citem, "text", $$v)},expression:"citem.text"}})],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }