<template>
  <div>
    <label class="radio_items flex flexcolumn flex_acenter" 
      :class="{'active':isCheck==value,'pointer': !disabled, 'not_selected_disabled': disabled && isCheck!=value  }" :style="{'padding':padding+'px'}">
      <div v-if="graphic.large" class="img-wrapper" :style="{'height': imgHeight, 'opacity': imgHeight == 'auto' ? 1 : 0.1}">
        <el-image ref="imageElement" @load="loadedImg" @error="errorImg" :src="getImgSrc(graphic.large)" fit="fill" :lazy="lazyImage" class="img"></el-image>
        <div  v-if="graphic.attribution" class="img-attribution poverOfHtml">{{graphic.attribution}}</div>
      </div>
      <div class="radio_inner flex flexcolumn flex_acenter padding_16">
        <div class="answer-status flexshrink flex flex_acenter flex_center" >
          <input v-if="!showRes && disabled || !disabled" type="radio" :disabled="disabled"
            :name="name" :value="value"
            v-model="isCheck"  
            @change="changeRadio" 
            class="radio-self">
          <span v-else-if="showRes" :class="{'icon-radio_error_answer icon_color_E74362': answerStatus == 2,'icon-radio_right_answer': answerStatus == 1}" class="fontsize_20">
            <span class="path1"></span>
            <span class="path2"></span>
          </span>
        </div>
        <p v-html="label" class="label-text fontsize_14 color_fff margin_top8 breakword"></p>
      </div>
    </label>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: {
    checked: [String, Number],
    value: [String, Number],
    label: String,
    name: String,
    graphic: {
      type: Object,
      default: function() {
        return {
          attribution:"", //图片说明
          large:"", //图片src
          _score:"",
        };
      }
    },
    disabled:Boolean, //禁用
    showRes: Boolean, //是否显示结果
    isRightAnswer:Boolean, //是否正确答案
    padding: {
      type:Number,
      default: 0
    },
    imgHeight:String,
    imgSize: {
      type: String,
      default: "m", // xl , m , s
    },
  },
  data() {
    return {
      isCheck: this.checked, //选中值
    };
  },
  computed: {
    ...mapState({
      lazyImage: state => state.course.lazyImage,
    }),
    answerStatus() {
      // 禁用 // 选中 // 正确
      let { disabled, isCheck, value, isRightAnswer } = this;
      //未回答
      if(!disabled) return -1;
      // 选中正确
      if(isCheck == value && isRightAnswer) return 1;
      // 选中错误
      if(isCheck == value && !isRightAnswer) return 2;
      // 未选中
      return 0;
    },
  },
  watch: {
    checked: {
      handler(n) {
        this.isCheck = n;
      },
      immediate: true
    }
  },
  methods: {
    getImgSrc(src) {
      if(src && src.indexOf("x-oss-process=style/") == -1) {
        if(src.indexOf("?") >= 0) return src + `&x-oss-process=style/${this.imgSize || 'xl'}`;
        else return src + `?x-oss-process=style/${this.imgSize || 'xl'}`;
      }
      return src;
    },
    loadedImg(evt) {
      this.$emit("loadedImg", {height:this.$refs.imageElement.$el.clientHeight});
    },
    errorImg() {
      this.$emit("errorImg");
    },
    changeRadio(event) {
      // 选中状态改变 event.target.checked,event.target.value
      this.$emit("change",event.target.value);
    },
    getStyle() {
      let { padding } = this;
      let styleSet = {};

      if(padding && !/\D/.test(padding)) { //数字 - px
        styleSet['padding'] = padding + 'px';
      }else if( padding && padding.indexOf('px') > -1 ) { 
        styleSet['padding'] = padding;
      }

      return styleSet;
    },
  },
}
</script>

<style lang="less" scoped>
  // 文本颜色
  @textColor: var(--item-color-inverted , #fff);
  // 文本悬浮
  @textHover: var(--item-color-inverted-hover , #fff);
  // 文本选中
  @textSelect: var(--item-color-inverted-selected, #fff);
  .radio_items {
    transition: background 0.4s;
    
    .radio_inner {
      box-sizing: border-box;
      width: 100%;
      background-color: var(--item-color , #70ADC6);
    }
    &:hover {
      .radio_inner {
        background-color: var(--item-color-hover ,#4D98B7);
      }
      .label-text {
        color: @textHover;
      }
      .radio-self {
        &:before {
          border-color: @textHover;
        }
        &:checked:after {
          background-color: @textHover;
        }
      }
    }
    // 选中
    &.active {
      .radio_inner {
        background-color: var(--item-color-selected, #4D98B7);
      }
      .label-text {
        color: @textSelect;
      }
      .radio-self {
        &:before {
          border-color: @textSelect;
        }
        &:checked:after {
          background-color: @textSelect;
        }
      }
    }
    // 提交-禁用且未选中
    &.not_selected_disabled {
      .radio_inner {
        background-color: #A1A1A1;
      }
    }
  }
  .radio_items /deep/span,
  .radio_items /deep/p {
    word-break: break-all;
  }
  .label-text {
    color: @textColor;
  }

  @radioWidth:18px;
  @radioHeight:18px;
  @radioColor:@textColor;
  .radio-self {
    position: relative;
    width: @radioWidth;
    height:@radioHeight;
    appearance: none;
    &:before{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      width: calc(@radioWidth - 4px);
      height: calc(@radioHeight - 4px);
      border: 2px solid @radioColor;
      display: inline-block;
      border-radius: 50%;
    }
    &:checked:before{
      content: '';
    }
    &:checked:after{
      content: '';
      width: 8px;
      height: 8px;
      text-align: center;
      background: @radioColor;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .answer-status {
    width: 18px;
    height: 18px;
  }
  // 图片部分
  .img-wrapper {
    position: relative;
    width: 100%;
    transition: all 0.6s;
    .img {
      display: block;
      height: 100%;
      min-height: 80px;
    }
    .img-attribution {
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 12px 16px;
      line-height: 16px;
      font-size: 12px;
      color: #FFFFFF;
      opacity: 0.3;
      background-color: #000;
    }
  }
</style>