<template>
  <topTools class="course-block" type="left"
    :id="detail['block-_id']"
    :eyeShow="!detail['block-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'add':true,'hideStatus':true, 'moveUp': moveUp, 'moveDown': moveDown}" 
    rightTips="组块"
    :isHighlight="isHighlight"
    @switchDisplay="switchDisplay"
    @del="del"
    @edit="showEdit"
    @add="unshiftBlock"
    @copy="handleCopy"
    @moveUp="handleMove('up')"
    @moveDown="handleMove('down')"
    :style="courseBlockStyle"
    :class="{'not-highlight':notHighlight}" >
    <div class="select-component-position-wrapper" :class="{'isShow': componentPositionShow}" @mouseleave="componentPositionShow = false">
      <div class="select-component-tips">添加组块于上方</div>
      <div  class="select-component-position">
        <div class="popper-arrow"></div>
        <div class="select-component-position-inner">
            <p class="fontsize_12 color_222 textcenter margin_bot3">选择结构</p>
            <div class="flex flex_acenter">
                <div class="structure_box" @click.stop="addBlock(1)">
                    <div class="structure_inner flex flex_acenter">
                        <div class="structure_item"></div>
                    </div>
                </div>
                <div class="structure_box" @click.stop="addBlock(2)">
                    <div class="structure_inner flex flex_acenter">
                        <div class="structure_item"></div>
                        <div class="structure_item"></div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
    <div class="course-block-inner aloneCenter">
      <div v-for="(item, ind) in list" :key="item.id" 
        :class="{'margin_bot16': item.display == 1 && currentForm[item.key_name] }" class="aloneCenter block-content">
        <div v-if="item.display == 1" class="flex prel">
          <div v-if="getProgressStatus(item)"
            class="progress-position"
            :class="{'hasValue': currentForm[item.key_name]}">
            <progressExample :progress="75" :detail="currentForm"></progressExample>
            <p v-html="currentForm[item.key_name]"
                class="progress-text"
                :class="{ 'fontsize_20': item.properties_name == 'title' || item.properties_name == 'displayTitle' }"></p>
          </div>
          <ckeditor 
            v-if="currentForm[item.key_name]"
            type="simpleInline" 
            class="marginCenter"
            :class="{ 'is_title fontsize_16': item.properties_name == 'title' || item.properties_name == 'displayTitle',
            'fontsize_14 color_59':item.properties_name === 'body',
            'ckeditor_inline_instruction fontsize_14 color_8C':item.properties_name === 'instruction',
            'ck-has-progress': getProgressStatus(item),}"
            :id="ckeditorPrefix+'-'+ currentForm.id +'-inline'+'-'+item.id" 
            v-model="currentForm[item.key_name]" 
            :throttle="throttleDelay"
            @input="handleInput($event,item.key_name)"
            @blur="formItemBlur($event,item.key_name)"
            @blurNotUpdate="formItemBlur($event,item.key_name,false)"
          >
          </ckeditor>
        </div>
      </div>
      <div class="flex aloneCenter" >
          <div v-for="(nitem, nind) in nums" :key="detail.id+(nitem._id || nind)" 
            class="select-component-btn-wrapper" 
            :class="{'width_50p': nums.length == 2, 'width_100p': nums.length == 1 }" >
            <div v-if="isNaN(nitem) && nitem['properties_list']" class="flex1 width_100p lineheight_1d5">
              <eText v-if="nitem['component-_component'] === 'text'" @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')" @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eText>
              <eImage v-else-if="nitem['component-_component'] === 'graphic'" @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :containerStyle="containerStyle" :isFullWidth="nums.length == 1" :detail="nitem" :enabledProgress="enabledProgress" :moveInline="moveInline"></eImage>
              <eBlank v-else-if="nitem['component-_component'] === 'blank'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eBlank>
              <eNarrative v-else-if="nitem['component-_component'] === 'narrative'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eNarrative>
              <eHotgraphic v-else-if="nitem['component-_component'] === 'hotgraphic'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eHotgraphic>
              <eAccordion v-else-if="nitem['component-_component'] === 'accordion'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"> </eAccordion>
              <eMedia v-else-if="nitem['component-_component'] === 'media'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eMedia>
              <eMcq v-else-if="nitem['component-_component'] === 'mcq'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eMcq>
              <eGmcq v-else-if="nitem['component-_component'] === 'gmcq'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eGmcq>
              <eMatching v-else-if="nitem['component-_component'] === 'matching'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eMatching>
              <eSlider v-else-if="nitem['component-_component'] === 'slider'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eSlider>
              <eTextinput v-else-if="nitem['component-_component'] === 'textinput'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eTextinput>
              <eAssessmentResults v-else-if="nitem['component-_component'] === 'assessmentResults'"  @updateComponent="updateComponentList" @del="delComponent"  @scrollToModule="$emit('scrollToModule',$event)" @loaded="$emit('loaded')" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eAssessmentResults>
              <eBlinds  v-else-if="nitem['component-_component'] === 'blinds'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eBlinds>
              <eExpose  v-else-if="nitem['component-_component'] === 'expose'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eExpose>
              <eFlipper  v-else-if="nitem['component-_component'] === 'flipper'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eFlipper>
              <eStacklist v-else-if="nitem['component-_component'] === 'stacklist'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eStacklist>
              <eFlipcard v-else-if="nitem['component-_component'] === 'flipcard'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eFlipcard>
              <eHotgrid v-else-if="nitem['component-_component'] === 'hotgrid'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eHotgrid>
              <eDragndrop v-else-if="nitem['component-_component'] === 'dragndrop'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eDragndrop>
              <eTabs v-else-if="nitem['component-_component'] === 'tabs'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')" :detail="nitem"  @scrollToModule="$emit('scrollToModule',$event)" 
                :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eTabs>
              <eTimedSequence v-else-if="nitem['component-_component'] === 'timed-sequence'"  @updateComponent="updateComponentList" @del="delComponent" @loaded="$emit('loaded')"  @scrollToModule="$emit('scrollToModule',$event)" 
                :detail="nitem" :containerStyle="containerStyle" :enabledProgress="enabledProgress" :moveInline="moveInline"></eTimedSequence>
              <!-- <div v-else  @updateComponent="updateComponentList" @del="delComponent" :detail="nitem">{{nitem['component-_component']}}</div> -->
            </div>
            <div 
              v-else
              class="select-component-btn flex flex_acenter flex_center prel width_100p flexshrink"
              :class="{ 
                'hover':isHover && current == nind,
                'active': active_id === detail.id + '_' + nind,
                'is_copying': copying,
                'pointer': !copying}"
              @mouseenter="enterComponet(true,nind)" 
              @mouseleave="enterComponet(false,nind)"
              @click.stop="moreTools(nitem,nind)">
                <i v-if="!copying" class="add-course-icon icon-course-add fontsize_14 color_A4AFB7"></i>
                <span v-if="!copying" class="padding_left6 fontsize_14 btn-text">添加内容</span>
                <button v-if="copying" @click="cancelCopyComponent" class="copy_reset_btn">取消</button>
                <button v-if="copying" @click="toCopyComponent(nitem,nind)" class="copy_doing_btn">粘贴</button>
                <div class="component-lists ">
                    <div class="popper-arrow"></div>
                    <div class="component-lists-inner flex flex_acenter width_100p height_100p">
                        <div class="component-item-wrapper prel flex flex_acenter flex_center" 
                          :class="{'bright':cind !== 0}" 
                          v-for="(citem,cind) in componentsByGroup" :key="citem.id">
                          <div v-for="(ditem,dind) in citem.component_list" :key="ditem.id" 
                            @click.stop="clickTools(ditem,nind)" 
                            class="component-item-one prel flex flexcolumn flex_acenter flex_center textcenter" >
                            <el-image :src="ditem.icon" fit="contain" class="component-item-img"></el-image>
                            <span class="margin_top8">{{ditem.title}}</span>
                          </div>
                          <div v-if="cind !== componentsByGroup.length - 1" class="split-line"></div>
                        </div>
                        <div class="component-item-one prel bright flex flexcolumn flex_acenter flex_center textcenter"
                        @click.stop="moreTools(nitem,nind)">
                            <i class="icon-more-black margin_bot8 fontsize_20">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                            </i>
                            <span class="">更多</span>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      </div>
    </div>
    <continueBtn v-if="detail['block-_trickle-properties-_isEnabled']" 
        :title="detail['block-_trickle-properties-_button-properties-text']"
      ></continueBtn>
    <fd-drawer
      destroy-on-close
      :title="drawerSet.title"
      :visible.sync="drawerSet.visible"
      :direction="drawerSet.direction"
      :before-close="handleClose"
      :size="479"
      custom-class="drawer-right not-header"
      append-to-body
      @open="openDrawer"
      @closed="closeDrawer"
      :modal="false"
      :with-header="false"
    >
      <div class="drawer-right-inner prel bgc_fff">
        <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
          <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
          <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="handleClose"></i>
        </div>
        <rightForm :ruleForm="detail" :tabList="tabList" :currentTab="drawerSet.activeName"
          :ckeditorNameId="'block-id'"
          :ckeditorPrefix="ckeditorPrefix"
          @handleClick="handleClick"
          @changeVal="changeVal"
          @selectChange="selectChange"
          @uploadImg="uploadImg"
          @formItemInput="formItemInput"
          @formItemBlur="formItemBlur"
          @open="openInnerDrawer"
          class="drawer-right-inner-form">
        </rightForm>
        <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
          <div class="right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
            <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
            <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerDrawer(1)" ></i>
          </div>
          <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
            <scaffoldForm 
              v-if="innerDrawer.visible"
              :titleFontSize="14"
              :scrollBoxPaddingLR="16"
              :ruleForm="detail"
              :propList="innerDrawer.propList"
              :ckeditorNameId="'block_ck'"
              @selectChange="formChangeBlur" 
              @formItemBlur="formChangeBlur"
            ></scaffoldForm>
          </div>
        </div>
      </div>
    </fd-drawer>
    <fd-drawer
      :with-header="false"
      :title="drawerComponent.title"
      :visible.sync="drawerComponent.visible"
      :direction="drawerComponent.direction"
      :before-close="closeComponents"
      :size="479"
      custom-class="drawer-component-right drawer-right not-header"
      destroy-on-close
      append-to-body
      @open="openDrawer"
      @closed="closeDrawer"
      :modal="false"
      >
      <div class="components-select-modal prel">
        <div class="width_100p height_100p prel bgc_fff components-select-inner" style="z-index:1;">
          <div class="header flex flex_acenter width_100p prel lineheight_22 modal-header">
            <span class="fontsize_16 color_222">选择组件</span>
            <i class="icon-close-popup header-close-btn fontsize_14 color_333 pointer" @click.stop="closeComponentModal"></i>
          </div>
          <div class="filter-head flex flex_acenter">
            <el-select @change="changeGroup" v-model="componentsValue" popper-class="filter-head-select" :popper-append-to-body="false" class="noborder" >
              <el-option
                v-for="item in componentsOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-input
              class="filter-head-input noborder"
              placeholder="请输入组件名称"
              suffix-icon="icon-search"
              @input="handleComponentInput"
              v-model="searchComponentVal">
            </el-input>
          </div>
          <div class="filter-list scroll-box" id="filter-list" @scroll="scrollList">
            <compListItem 
              v-for="(item, cpind) in componentsList" :key="item.id" :detail="item" 
              :current="componentsDisplayData.current === cpind"
              @add="({defalut_mode_id,component}) => selectMode({id:defalut_mode_id, component:component, 'select_ways':'direct'})"
              @showMode="({mode,visible,component, top}) => changeComponent({visible,component, item:mode, index: cpind , top})"
              ></compListItem>
          </div>
        </div>
        <div class="components-display" 
          :class="{'components-display--open': componentsDisplayData.showMode  }" 
          :style="{'--top': (componentsDisplayData.top + 'px' || 'auto'), '--bottom': (componentsDisplayData.bottom + 'px' || 'auto')}">
          <div v-for="(citem, cind) in componentsDisplayData.modeArr" :key="citem.id"
            @click.stop="selectMode(citem)"
            class="components-display-item components-display-text pointer">
            <p class="margin_bot12 fontsize_14 color_222">{{citem.title}}</p>
            <div class="components-display-content bgc_fff">
              <el-image :src="citem.cover" fit="contain" lazy class="components-display-img"></el-image>
            </div>
          </div>
        </div>
      </div>
    </fd-drawer>
    <fd-dialog
      append-to-body
      :title="delDialogDetail.title"
      :visible.sync="delDialogDetail.visible"
      width="600px"
      :before-close="handleCloseDel"
      custom-class="course-dialog not-full">
      <p class="fontsize_16 color_222 textcenter margin_bot16" style="padding-top:9px;">请确认是否删除{{delDialogDetail.item.componentTitle || '组块'}}</p>
      <p class="fontsize_14 color_E74362 textcenter" style="padding-bottom:9px;">您确认要删除吗？删除之后将无法找回</p>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="delShunt" class="course-save pointer">确定删除</button>
        <button @click="closeDelDialog" class="course-close pointer">不删了</button>
      </span>
    </fd-dialog>
  </topTools>
</template>

<script>
import Config from '@/libs/config';
import hideModal from '@/renderer/mixins/popup/hideModal';
import topTools from '@/renderer/components/common/topTools.vue';
import eText from '@/renderer/components/Element/fd-text.vue';
import eImage from '@/renderer/components/Element/fd-image.vue';
import eBlank from '@/renderer/components/Element/fd-blank.vue';
import eNarrative from '@/renderer/components/Element/fd-narrative.vue';
import eHotgraphic from '@/renderer/components/Element/fd-hotgraphic.vue';
import eAccordion from '@/renderer/components/Element/fd-accordion.vue';
import eMedia from '@/renderer/components/Element/fd-media.vue';
import eMcq from '@/renderer/components/Element/fd-mcq.vue';
import eGmcq from '@/renderer/components/Element/fd-gmcq.vue';
import eMatching from '@/renderer/components/Element/fd-matching.vue';
import eSlider from '@/renderer/components/Element/fd-slider.vue';
import eTextinput from '@/renderer/components/Element/fd-textinput.vue';
import eAssessmentResults from '@/renderer/components/Element/fd-assessmentResults.vue';
import eBlinds from '@/renderer/components/Element/fd-blinds.vue';
import eExpose from '@/renderer/components/Element/fd-expose.vue';
import eFlipper from '@/renderer/components/Element/fd-flipper.vue';
import eStacklist from '@/renderer/components/Element/fd-stacklist.vue';
import eFlipcard from '@/renderer/components/Element/fd-flipcard.vue';
import eDragndrop from '@/renderer/components/Element/fd-dragndrop.vue';
import eHotgrid from '@/renderer/components/Element/fd-hotgrid.vue';
import eTimedSequence from '@/renderer/components/Element/fd-timedSequence.vue';
import eTabs from '@/renderer/components/Element/fd-tabs.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import compListItem from "@/renderer/components/scaffold/compListItem.vue";
import progressExample from "@/renderer/components/common/progressExample.vue";
import { mapActions, mapMutations, mapState } from 'vuex';
import { getComponentListByGroup, getComponentList, createComponent, getComponentOfBlock, delComponent, copyComponent } from '@/libs/api/component';
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import continueBtn from "@/renderer/components/common/continueBtn.vue";
export default {
  mixins: [hideModal],
  components: {
    topTools,
    scaffoldForm,
    continueBtn,
    eText,
    eImage,
    eBlank,
    rightForm,
    compListItem,
    eNarrative,
    eHotgraphic,
    eAccordion,
    eMedia,
    eMcq,
    eGmcq,
    eMatching,
    eSlider,
    eTextinput,
    eAssessmentResults,
    progressExample,
    eBlinds,
    eExpose,
    eFlipper,
    eStacklist,
    eFlipcard,
    eDragndrop,
    eHotgrid,
    eTimedSequence,
    eTabs
  },
  props: {
    detail: {
      type: Object,
      default: function() {
        return {
          
        }
      }
    },
    enabledProgress: Boolean,
    containerStyle: Object,
    moveUp: Boolean,
    moveDown: Boolean,
  },
  data() {
    return {
      componentName:"block",
      showProgress:false,
      currentForm:this.detail,
      ckeditorPrefix:"block",
      prefix:"block",
      isHover:false,
      current: -1,
      drawerSet: { //block 编辑弹窗
        direction: "rtl", //从右往左
        visible: false, //是否显示
        title: "",
        activeName: 0,
      },
      drawerComponent: { //组件列表的显示
        direction: "rtl", //从右往左
        visible: false, //是否显示
        title: "",
        activeName: 0,
      },
      numArr:[],
      componentsValue: "", //组件类型筛选
      searchComponentVal: "", //组件名称筛选
      componentsOptions: [],
      componentsList: [],
      throttleDelay:Config.realTimeInput,
      componentsDisplayData: {
        modeArr: [], //显示模式
        showMode:false, //组件第二层是否展示
        current: -1,
        detail: {}, //当前从哪个block打开
      },
      delDialogDetail: {
        title:"删除",
        visible: false,
        item:{}
      },
      active_id: null,
      componentPositionShow: false,
      innerDrawer: {
        visible: false, //是否显示
        title: "",
        form:{},
        propList:[],
        submitAsObj: false,
        key_name:"",
        current:-1,
      },
    };
  },
  computed: {
    ...mapState({
      popShowId: (state) => state.popShowId,
      schemaJson: state => state.course.schemaJson,
      componentsByGroup: state => state.component.groupList, //快捷选择组件列表
      totalGroupDetail: state => state.component.totalGroupDetail, //
      copying: state => state.component.copying, //
      copyData: state => state.component.copyData, //
    }),
    isHighlight() {
      // 高亮
      // ↓：只要编辑包含即显示
      // return this.popShowId && this.popShowId.includes(this.detail['block-id'] + '_' + this.componentName) || false;
      // ↓：只显示当前
      return this.popShowId && this.popShowId[0] == this.detail['block-id'] + '_' + this.componentName || false;
    },
    notHighlight() {
      // 非当前高亮
      return this.popShowId 
        && this.popShowId.includes(this.detail['block-id'] + '_' + this.componentName)
        && this.popShowId[0] != (this.detail['block-id'] + '_' + this.componentName) || false;
    },
    nums: {
      get(){
        return this.numArr;
      },
      set(v) {
        this.numArr = v
      }
    },
    tabList() {
      return this.schemaJson[ this.componentName ] && this.schemaJson[ this.componentName ].tabgroup_list || []
    },
    list() {
      return (
        (this.tabList[0] &&
          this.tabList[0].setting_list.filter(v => (v.input_type === "richtext_mini" || v.input_type === "richtext") ) ) ||
        []
      );
    },
    styleProps() { //获取样式各项属性名
      let styleProps = {};
      this.tabList[1] &&  this.tabList[1].setting_list && this.tabList[1].setting_list.forEach(v=>{
        if(v.properties_name == "_backgroundStyles") {
          v.properties.forEach(sval=>{
            styleProps[sval.properties_name] = sval.key_name
          })
        }else {
          styleProps[v.properties_name] = v.properties[0].key_name
        }
      });
      return styleProps;
    },
    courseBlockStyle() {
      return {
        'background-image': this.detail[this.styleProps['_backgroundImage']] ? `url(${this.detail[this.styleProps['_backgroundImage']]})` : 'none',
        'background-repeat': this.detail[this.styleProps['_backgroundRepeat']] || 'repeat',
        'background-position': this.detail[this.styleProps['_backgroundPosition']] || '0% 0%',
        'background-size': this.detail[this.styleProps['_backgroundSize']] || 'auto',
      }
    },
    moveInline() {
      return this.nums.length === 2;
    }
  },
  created() {
    this.$watch(
      function () { // 第一个函数就是处理你要监听的属性，只要将其return出去就行
        this.showProgress = this.detail['block-_pageLevelProgress-properties-_isCompletionIndicatorEnabled'];
        
        return this.detail['block-_pageLevelProgress-properties-_isCompletionIndicatorEnabled'];
      },
      (val, old) => {
        this.showProgress = val;
      }
    );
    this.numArr = this.detail['block-_layout'] == 'leftright' ?  [1,2] : [1];
    this.getListOfBlock();
    
    this.componentsOptions =  this.totalGroupDetail.group || [];
    this.componentsList =  this.totalGroupDetail.list || [];
    this.componentsValue =  this.componentsOptions[0] ? this.componentsOptions[0].value : "";; //组件类型筛选
  },
  mounted() {
    this.$nextTick(() => {
      // console.log('block加载', new Date().getTime() );
      
      if(this.nums.length == 2) {
        this.$emit("loaded");
        this.$emit("loaded");
      }else {
        this.$emit("loaded");
      }
    });
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    ...mapMutations(['openPop','closePop','SET_COPYING']),
    ...mapActions(['SaveCourse','AddComponent','CreateBlock', 'MoveBlock','CopyBlock']),
    scrollList() {
      // 滚动即隐藏左侧
      this.componentsDisplayData.showMode = false;
      this.componentsDisplayData.top = "auto";
      this.componentsDisplayData.bottom = "auto";
      this.componentsDisplayData.modeArr = [];
      this.componentsDisplayData.current = -1;
      this.componentsDisplayData.component = {};
    },
    formChangeBlur(value,propName,leftForm) {
      let obj = {};
      obj[propName] = value;

      this.detail[propName] = value;
      this.$set(this.detail, propName, value);
      this.$forceUpdate();
      this.saveBlockDetail(obj, true);
    },
    openInnerDrawer({list, title, visible}) {
      // 显示\隐藏二层弹出窗口
      this.innerDrawer.title = title;
      this.innerDrawer.propList = list;

      if(!visible || visible == 2) {
        this.innerDrawer.visible = false;
        return;
      }else{
        this.innerDrawer.visible = true;
      }
    },
    closeInnerDrawer({list, title, visible} = {}) {
      // 显示\隐藏二层弹出窗口
      if(!visible) {
        this.innerDrawer.visible = false;
        return;
      }
    },
    toCopyComponent(item, index) {
      // 粘贴组件
      let _layout = index === 1 ? 'right' : this.nums.length === 1 ? 'full' : 'left';

      setTimeout(() => {
        this.cancelCopyComponent();
      }, 50);

      copyComponent({
        course_id: this.detail.course_id,
        block_id: this.detail['block-_id'],
        component_id: this.copyData['component_id'],
        _layout: _layout
      }).then(res => {
        if(res.errorcode == 0) {
          this.getListOfBlock();
        }
      });
    },
    cancelCopyComponent() {
      // 取消复制组件
      setTimeout(() => {
        this.SET_COPYING({
          copying: false,
          data: {}
        });
      }, 20);
    },
    updateComponentList() {
      this.getListOfBlock();
    },
    handleCopy() {
      // 组块复制
      this.CopyBlock({
        course_id: this.detail.course_id,
        block_id: this.detail['block-_id'],
      }).then(res => {
        if(res.errorcode == 0) {
          this.$emit("update");
        }
      });
    },
    handleMove(moveDirection) {
      // 组块上移/下移
      this.MoveBlock({
        course_id: this.detail.course_id,
        block_id: this.detail['block-_id'],
        move_way: moveDirection
      }).then(res => {
        if(res.errorcode == 0) {
          this.$emit("update");
        }
      });
    },
    getProgressStatus(item) {
      // 标题左侧 - 是否显示进度条
      return !!this.enabledProgress && this.currentForm['block-_pageLevelProgress-properties-_isCompletionIndicatorEnabled']
        && (item.key_name === 'block-title' || item.key_name === 'block-displayTitle');
    },
    getList(isFirst) {
      let keyword = this.searchComponentVal;
      let group_id = this.componentsValue;
      getComponentList({keyword, group_id}).then(res => {
        if(res.errorcode == 0) {
          this.componentsList = res.data.component_data;
          this.componentsOptions = res.data.group_list;
          //默认选中第一个
          if(isFirst) this.componentsValue = this.componentsOptions[0] ? this.componentsOptions[0].value : "";
        }
      });
    },
    getListByGroup() {
      // 用于显示快捷选择的组件列表
      getComponentListByGroup().then(res => {
        if(res.errorcode == 0) {
          this.componentsByGroup = res.data.group_data || [];
        }
      })
    },
    changeGroup(val) {
      //改变组件分类
      this.componentsDisplayData.current = -1;
      this.getList();
    },
    handleComponentInput() {
      // 搜索框输入-组件列表
      this.componentsDisplayData.showMode = false;
      this.componentsDisplayData.current = -1;
      this.getList();
    },
    getListOfBlock() {
      // 获取当前block的组件
      let {course_id, id} = this.detail;
      getComponentOfBlock({course_id, block_id: id}).then(res => {
        if(res.errorcode == 0) {
          if(res.data.component.length > 0) {
            let arr = this.detail['block-_layout'] === 'full' ? [1] : [1,2];
            res.data.component.forEach(v => {
              let newOne = Object.assign(v, v.properties_list)
              if(newOne['component-_layout'] == "left") {
                arr[0] = newOne;
              }else if(newOne['component-_layout'] == "right") {
                arr[1] = newOne;
              }else {
                arr[0] = newOne;
              }
            })
            this.numArr = arr;
          }else {
            this.numArr = this.detail['block-_layout'] === 'full' ? [1] : [1,2]
          }
        }
      })
    },
    unshiftBlock() {
      this.componentPositionShow = true;
    },
    addBlock(num) {
      // 添加组件
      this.componentPositionShow = false;

      this.CreateBlock({
        course_id: this.detail.course_id,
        _parentId: this.detail['article_id'],
        title: "",
        _layout: num==1?"full":"leftright",
        below_block_id: this.detail['block-_id']
      }).then(res=>{
        if(res.errorcode == 0) {
          this.$emit("scrollToNew",{id: res.data.block['block-_id']});
          this.$emit("update");
        }
      }).catch();
    },
    switchDisplay(isShow) {
      // 控制组块是否显示
      let obj = {};
      let prop = 'block-_isHidden';
      obj[prop] = !isShow;
      this.detail[prop] = !isShow;
      this.$forceUpdate(); //强制刷新
      this.saveBlockDetail(obj, false);
    },
    selectMode(item) {
      // 选择组件模式
      let {course_id,id,_layout} = this.componentsDisplayData.detail;
      // course_id 
      // _parentId - block的ID
      // _layout  - full全宽,left左边,right右边
      // _component component_data里的component字段
      // component_mode_id 组件使用的模式ID
      let params = {
        course_id,
        _layout,
        _parentId: id,
        _component: item && item.select_ways === 'direct' ? item.component : this.componentsDisplayData.component,
      };
      if(item) {
        params['component_mode_id'] = item.id;
      }
      createComponent(params).then(res => {
        // 拿到组件id，并让当前组件滑动到视野区域
        if(res.errorcode == 0) {
          this.$emit("scrollToNew",{id: res.data.component_id});
          this.drawerComponent.visible = false;
          this.componentsDisplayData.showMode = false;
          this.active_id = null;
          this.$emit("blockSelectComponent",{addComponentVisible:true});
          this.getListOfBlock();
          this.scrollToBottom();
        }
      })
    },
    handleCloseDel(done) {
      typeof done === 'function' && done();
      this.delDialogDetail.visible = false;
      this.delDialogDetail.type = "";
      this.delDialogDetail.item = {};
    },
    closeDelDialog() {
      this.delDialogDetail.visible = false;
      this.delDialogDetail.type = "";
      this.delDialogDetail.item = {};
    },
    delComponent({course_id, component_id, title, componentTitle}) {//title 富文本
      // 删除组件-弹窗
      this.delDialogDetail.item = {course_id, component_id, title, componentTitle};
      this.delDialogDetail.visible = true;
      this.delDialogDetail.type = "component";
    },
    sureDelComponent() {
      // 确认删除
      let {course_id, component_id} = this.delDialogDetail.item;
      delComponent({course_id, component_id}).then(res => {
        if(res.errorcode == 0) {
          this.delDialogDetail.visible = false;
          this.delDialogDetail.type = "";
          this.delDialogDetail.item = {};
          this.getListOfBlock();
        }
      })
    },
    closeComponentModal() {
      //组件弹窗关闭
      this.active_id = null;
      this.componentsDisplayData.showMode = false;
      this.drawerComponent.visible = false;
      this.componentsDisplayData.current = -1;
      this.$emit("blockSelectComponent",{addComponentVisible:true});
    },
    changeComponent({visible, item, index, component, top}) {
      this.componentsDisplayData.top = "auto";
      this.componentsDisplayData.bottom = "auto";
      this.componentsDisplayData.showMode = visible;
      this.componentsDisplayData.modeArr = item;
      this.componentsDisplayData.current = index;
      this.componentsDisplayData.component = component;
      if(top >= 0) {
        this.componentsDisplayData.top = top;
      }else {
        this.componentsDisplayData.bottom = 0;
      }
    },
    closeComponents(done) {
      // 组件列表关闭
      this.active_id = null;
      this.componentsDisplayData.showMode = false;
      this.componentsDisplayData.current = -1;
      this.$emit("blockSelectComponent",{addComponentVisible:true});
      done(); //关闭弹窗
    },
    getIconClass(type) {
      let classList = {
        '_text': 'icon-component-text',
        '_graphic': 'icon-component-image',
        '_media': 'icon-component-media',
        '_mcq': 'icon-component-choice',
        '_matching': 'icon-component-matching-ques',
        '_textinput': 'icon-component-cloze',
        '_assessmentResults': 'icon-component-evaluate',
        '_blank': 'icon-component-blank',
      };
      return classList[type]
    },
    enterComponet(showBool, nind) {
      this.isHover = showBool;
      this.current = nind;
    },
    clickTools(item, bind) { //bind 按钮第几个
      // 选择组件
      let detail = this.componentsDisplayData.detail;
      let numLen = this.nums.length;
      let { id, course_id, article_id } = this.detail;
      let _layout = bind === 0 && numLen == 2 ? 'left' :  bind === 0 ? 'full' : 'right';

      // 清除按钮状态
      this.isHover = false;
      this.current = -1;

      this.componentsDisplayData.detail = {
        id,
        course_id,
        article_id,
        _layout
      };
      this.componentsDisplayData.component = item.component;
      this.selectMode(null);
    },
    moreTools(item, ind) {
      if(this.copying) return;

      let numLen = this.nums.length;
      let { id, course_id, article_id } = this.detail;
      
      let _layout = ind === 0 && numLen == 2 ? 'left' :  ind === 0 ? 'full' : 'right';
      this.active_id = id + '_' + ind;

      // 清除按钮状态
      this.isHover = false;
      this.current = -1;

      this.drawerComponent.title ="选择组件";
      this.drawerComponent.visible = true;
      this.componentsDisplayData.detail = {
        id,
        course_id,
        article_id,
        _layout
      };
      //组块高亮时，添加组件按钮不高亮
      this.$emit("blockSelectComponent", { addComponentVisible:false });
      
      this.$emit("scrollToModule", {id: this.detail['block-id']});
    },
    showEdit() {
      let ids = [ this.detail['block-id']+'_'+this.componentName, this.detail['article_id']+'_'+'article' ];
      this.openPop({type: this.componentName, id: this.detail['block-id'], ids:ids});
      Object.assign(this.detail, this.currentForm); //获取最新值
      this.drawerSet.title = this.schemaJson['block'].title;
      this.drawerSet.visible = true;

      this.$emit("scrollToModule", {id: this.detail['block-id']});
    },
    delShunt() {
       if(this.delDialogDetail.type === 'component') {
         this.sureDelComponent();
         return;
       }
       if(this.delDialogDetail.type === 'block') {
         this.delBlock();
         return;
       }
    },
    del() {
      this.delDialogDetail.type = "block";
      this.delDialogDetail.visible = true;
    },
    delBlock() {
      this.$emit("del",{course_id: this.detail.course_id, _id: this.detail['block-id']})
    },
    uploadImg(val,propName) {
      // console.log('uploadImg',val, propName)
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    changeVal(value, prop) {
      // console.log('changeVal',value,prop)
    },
    handleInput(value, prop) { //当前页面输入
      if( !!this.drawerSet.visible ) return;
      
      let obj = {};
      obj[prop] = value;
      this.currentForm[prop] = value;
      this.detail[prop] = value; //左侧输入 同步到 右侧弹窗
      this.saveBlockDetail(obj, false);
    },
    formItemInput(value, prop) { //右侧弹窗输入
      let obj = {};
      obj[prop] = value;
      this.currentForm[prop] = value;
      this.detail[prop] = value;
      this.$forceUpdate();
      this.saveBlockDetail(obj, false);
    },
    formItemBlur(value,propName, isUpdate = true) {
      let obj = {};
      obj[propName] = value;

      this.currentForm[propName] = value;
      this.detail[propName] = value;
      this.$forceUpdate();
      !!isUpdate && this.saveBlockDetail(obj, true);
    },
    changeFormProp(prop,data) { //更新courseForm 某个属性
      let ruleForm  = JSON.parse(JSON.stringify(this.detail));
      ruleForm[prop] = data;
      return ruleForm;
    },
    selectChange(e,prop) {
      this.formItemBlur(e,prop);
    },
    handleClose(done) {
      //关闭右侧弹窗
      this.drawerSet.visible = false;
      typeof done === 'function' && done();
      this.closePop();
    },
    saveBlockDetail(updateObj, isUpdateList = false) { //isUpdateList：是否刷新列表
      //保存章节详情
      this.SaveCourse({
        "page_key":"block","course_id":this.detail.course_id,
        "properties_data": updateObj,
        "block_id": this.detail['block-id']
      }).then(res=>{
        if(res.errorcode == 0) {
          if(isUpdateList) this.$emit("update");
        }
      })
    },
    scrollToBottom() {

      // // 滚动至新增的组件
      // this.$nextTick(() => {
      //   setTimeout(()=>{
      //     // console.log(document.getElementById("scroll-sight_"+this.detail.id));
      //     // console.log(document.getElementById("addComp_"+article_id));
      //     document.getElementById('add-article_'+this.$route.params.contentId).scrollIntoView({behavior: "smooth", block: "end", inline: "center"});
      //   },600);
      // });
    },
  },
}
</script>

<style lang="less" scoped>
  //由于内部弹出窗口
    .drawer-right {
      /deep/ &.el-drawer {
        overflow: visible;
        .el-drawer__body {
          overflow: visible;
        }
      }
  }
  .course-block {
      padding: 24px 0;
      // margin-bottom: 24px;
      &:hover {
          // z-index: 1; //导致图文叙事上一个编辑弹窗口，下一个图文叙事的图片层级过高
      }
  }
  .block-content {
    padding: 0 16px 0;
  }
  .select-component-btn-wrapper {
    display: flex;
    justify-content: center;
    // padding: 0 20px;
    overflow: visible;
    &.width_50p {
      &:nth-child(1) {
        padding-right: 12px;
      }
      &:nth-child(2) {
        padding-left: 12px;
      }
    }
  }
  .select-component-btn {
      box-sizing: border-box;
      width: calc(100% - 20px);
      // height: 46px;
      height: 193px;
      border: 1px solid #F2F2F2;
      background-color: #F2F2F2;
      .btn-text {
        color: rgba(119, 119, 119, 0.7);
      }
      &.hover {
        background-color: #F2F2F2;
        border-color: #D5D5D4;
        .btn-text {
          color: rgba(51, 51, 51, 0.77);
        }
        .add-course-icon {
          color: #203630;
        }
        // 隐藏快捷选择组件的悬浮弹框
          // > .component-lists {
          //     display: block;
          // }
      }
      &.active {
        border-color: #51CFAE;
        background-color: #EBFFFA;
        .add-course-icon {
          color: #51CFAE;
        }
        .btn-text {
          color: #51CFAE;
        }
      }
      &.is_copying {
        border: 1px dashed #51CFAE;
        background-color: #EBFFFA;
      }
  }
  .component-lists {
      display: none;
      position: absolute;
      z-index: 99;
      bottom: 8px;
      transform: translate(0, 100%);
      // width: 630px; //不给宽度，导致2个按钮时，超出部分无法显示
      height: 89px;
      padding-top: 7px;
      &:hover {
          display: block;
      }
  }
  .component-lists-inner {
      //start以下两行可以让当前宽度不受限于父元素，并且由子元素撑开
      display: inline-flex;
      white-space: nowrap;
      //end
      box-sizing: border-box;
      padding: 8px;
      height: 82px;
      background-color: #fff;
      border: 1px solid #E8EAE9;
      box-shadow: 0 4px 12px 0 rgba(0,0,0,0.12);
      border-radius: 8px;
  }
  .component-item-wrapper {
      flex-shrink: 0;
      height: 100%;
      .split-line {
        margin: 0 4px 0;
        width: 1px;
        height: calc(100% + 16px);
        background-color: #D4D4D4;
      }
  }
  .component-item-one {
    flex-shrink: 0;
    border-radius: 4px;
    padding: 0 16px;
    height: 100%;
    font-size: 13px;
    color: #222;
    &:hover {
      background-color: #F4F4F4;
    }
  }
  .component-item-img {
    width: 22px;
    height: 22px;
    overflow: hidden;
  }
  .popper-arrow {
      top: -17px;
  }


  // 组件右侧弹窗列表
.components-select-modal {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .header {
    padding-left: 16px;
    height: 54px;
    border-bottom: 1px solid #E4E4E4;
  }
  .header-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 19px 16px;
  }
}

.filter-head {
  box-sizing: border-box;
  height: 52px;
  border-bottom: 1px solid #E4E4E4;
  // ::placeholder
  /deep/.el-select .el-input input::-webkit-input-placeholder{
    font-size: 12px;
    color: #222222;
  }
  // 组件类型选择
  /deep/.el-select {
    position: relative;
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%);
      display: block;
      content: "";
      width: 1px;
      height: 21px;
      background-color:  #D8D8D8;
    }
  }
  /deep/.filter-head-select {
    width: 125px;
    .el-select-dropdown__item {
      height: 44px;
      line-height: 44px;
      transition: background 0.6s;
      font-size: 14px;
      color: #222222;
    }
    .el-select-dropdown__item.hover, .el-select-dropdown__item:hover,
    .el-select-dropdown__item.selected{
      background: #EDEDED;
    }
  }
  /deep/.el-select .el-input__inner {
    width: 100px;
  }
  // 组件搜索框
  .filter-head-input {
    margin: 4px 16px;
    height: 36px;
    line-height: 36px;
    background-color:#EDEDED;
    /deep/.el-input__suffix {
      right: 14px;
      color:#666666;
    }
  }
}
.filter-list {
  box-sizing: border-box;
  height: calc(100% - 93px);
}
.components-display {
  position: absolute;
  left: 0;
  top: var(--top, 'auto');
  bottom: var(--bottom, 'auto');
  z-index: 0;
  transform: translate(0,0);
  box-shadow: -4px 0 8px 0 rgba(0,0,0,0.15);
  // width: 448px;
  // width: 44.8rem;
  // max-width: 448px;
  width: 28vw;
  max-width: 336px;
  min-width: 300px;
  // height: 100%;
  background: #F5F5F5;
  box-shadow: -4px 0 8px 0 rgba(0,0,0,0.15);

  transition:transform .66s cubic-bezier(.77,0,.175,1);
  // transition: width .3s ease-out;;
  &.components-display--open {
    transform: translate(-100%,0);
  }
  .components-display-item {
    box-sizing: border-box;
    padding: 16px;
    width: 100%;
    // height: 270px;
    &:hover {
      background: #EDEDED;
    }
  }
  @displayImgHeight: 202px;
  .components-display-content {
    box-sizing: border-box;
    width: 100%;
    height: auto;
  }
  .components-display-img {
    width: 100%;
    height: auto;
  }
}

// 文本
.components-display-text {
  .components-display-content {
    // padding: 40px 24px;
  }
}

.drawer-component-right {
  /deep/ &.el-drawer {
    overflow: visible;
    width: 28%!important;
    max-width: 479px!important;
    .el-drawer__body {
      overflow: visible;
    }
  }
}
.progress-position {
  left: 0;
  z-index: 0;
  padding-top: 2px;
  line-height: 1.3;
  display: inline-block;
  vertical-align: middle;
  &.hasValue {
    position: absolute;
  }
  .progress-text {
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline;
    vertical-align: middle;
    /deep/p {
      width: 0;
      overflow: hidden;
      display: inline;
      &:not(:first-child) {
        display: none;
      }
    }
  }
}

.copy_reset_btn,
.copy_doing_btn {
  box-sizing: border-box;
  border: 1px solid #51CFAE;
  border-radius: 4px;
  width: 72px;
  height: 32px;
  font-size: 14px;
  cursor: pointer;
}
.copy_reset_btn {
  color: #51CFAE;
  background-color: transparent;
  &:hover {
    border-color: #1E9C7B;
    color: #1E9C7B;
  }
}
.copy_doing_btn {
  margin-left: 24px;
  box-shadow: 0 4px 10px 0 rgba(0,176,130,0.20);
  color: #FFFFFF;
  background-color: #51CFAE;
  &:hover {
    background-color: #1E9C7B;
  }
}

// 添加组块 结构选择弹窗
  .select-component-position-wrapper {
    display: none;
    position: absolute;
    z-index: 1;
    top: 24px;
    left: 0;
    transform: translate(0, 0);
    width: auto;
    height: 134px;
    padding-top: 26px;

    &.isShow {
        display: block;
    }
    .popper-arrow {
      left: 50px;
    }
    .select-component-tips {
      position: absolute;
      top: 3px;
      left: 32px;
      border-radius: 1px;
      border: 1px solid #DADADA;
      padding: 1px 6px;
      font-size: 12px;
      line-height: 17px;
      color: #222;
      text-indent: 0;
      background: #F2F2F1;
      white-space: nowrap;
    }
  }
    .select-component-position {
      // display: none;
      // position: absolute;
      // z-index: 1;
      // bottom: 0;
      // transform: translate(0, 100%);
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 330px;
      height: 134px;
      padding-top: 4px;

      // &.isShow {
      //     display: block;
      // }
  }
  .select-component-position-inner {
      box-sizing: border-box;
      padding: 13px 0 0;
      width: 100%;
      // height: 134px;
      box-shadow: 0 4px 12px 0 rgba(0,0,0,0.12);
      border-radius: 4px;
      border: 1px solid #E8EAE9;
      background: #FFFFFF;
  }
  .structure_box {
      box-sizing: border-box;
      flex: 1;
      padding: 19px 27px;
      height: 98px;
      &:hover {
          background-color: #F0F0F0;
      }
      .structure_inner {
          position: relative;
          box-sizing: border-box;
          border: 1px solid #222222;
          border-radius: 1px;
          padding: 0 9px;
          height: 60px;
      }
      .structure_item {
          width: 100%;
          box-sizing: border-box;
          border: 1px solid #5ED8B8;
          border-radius: 1px;
          height: 34px;
          background: #C3EFE3;
          &:nth-child(2) {
              margin-left: 10px;
          }
      }
  }

  // 右侧弹窗头部高度
  @drawHeaderHeight: 54px;
  .drawer-right-inner {
    width: 100%;
    height: 100%;
    background-color: #fff;
    .header {
      box-sizing: border-box;
      height: @drawHeaderHeight;
    }
    .header-close-btn {
      position: absolute;
      top: 0;
      right: 0;
      padding: 19px 16px;
    }
    .drawer-right-inner-form {
      box-sizing: border-box;
      padding-top: 16px;
      height: calc(100% - @drawHeaderHeight);
    }
  }
</style>