<template>
  <button type="button" class="custom-btn " :class="{'pointer': !disabled, 'is-disabled':disabled}" :style="getStyle()" @click.stop="tapButton">
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    // 是否朴素按钮 -朴素按钮背景色为白色
    plain: {
      type: Boolean,
      default: false
    },
    // 按钮圆角
    roundPX: {
      type: [Number,String],
      default: -1
    },
    // 背景色、边框色、字体颜色
    color: {
      type: String,
      default: '#1E9C7B'
    },
    width: {
      type:[Number,String],
      default: "64"
    },
    height: [Number,String],
    // hover时 背景色
    hoverBgColor: {
      type: String,
      default: ''
    },
    padding: String,
    disabled: Boolean, //禁用状态
    fontSize:{
      type:Number,
      default: 12
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    tapButton() {
      if(this.disabled) return;
      this.$emit("click");
    },
    getStyle() {
      let styleSet = {};
      let {fontSize, plain, roundPX, color, width, height, padding, hoverBgColor} = this;
      if(fontSize) {
        styleSet['font-size'] = fontSize + 'px';
      }
      if(color && !plain) {
        // 有背景色按钮
        styleSet['background-color'] = color;
        styleSet['border-color'] = color;
      }
      if(color && plain) {
        // 朴素按钮
        styleSet['background-color'] = '#fff';
        styleSet['border-color'] = color;
        styleSet['color'] = color;
      }
      if(roundPX >= 0 && !/\D/.test(roundPX)) {
        styleSet['border-radius'] = roundPX + 'px';
      }
      if(padding) {
        styleSet['padding'] = padding;
      }
      if(width && !/\D/.test(width)) { //数字 - px
        styleSet['width'] = width + 'px';
      }else if( width && /^\d+[.]?\d+%$/.test(width) ) { // 百分比 
        styleSet['width'] = width;
      }
      if(height && !/\D/.test(height)) {
        styleSet['height'] = height + 'px';
        styleSet['line-height'] = (Number(height)-2) + 'px';
      }
      if(hoverBgColor) {
        color && !plain ? styleSet['--hover-border-color'] = hoverBgColor : void(0);
        color && plain ? styleSet['--hover-border-color'] = color : void(0);
        styleSet['--hover-bg-color'] = hoverBgColor;
      }else {
        color && !plain ? styleSet['--hover-border-color'] = styleSet['background-color'] : void(0);
        color && plain ? styleSet['--hover-border-color'] = color : void(0);
        styleSet['--hover-bg-color'] = styleSet['background-color'];
      }
      return styleSet;
    },
  },
}
</script>

<style lang="less" scoped>
  @btnColor: var(--btn-color, #70adc6);
  @btnColorHover: var(--btn-color-hover, #70adc6);
  @btnColorInverted: var(--btn-color-inverted, #fff);
  @btnColorInvertedHover: var(--btn-color-inverted-hover, #fff);
  @disabled: var(--disabled, #ddd);
  @disabledInverted: var(--disabled-inverted, #ddd);
  .custom-btn {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    word-break: break-all;
    box-sizing: border-box;
    border-radius: 16px;
    border: 1px solid @btnColor!important;
    // width: 64px;
    // height: 32px;
    line-height: 20px;
    text-align: center;
    font-size: 12px;
    color: @btnColorInverted;
    padding: 0;
    background: @btnColor!important;
    transition: background 0.3s;
    &:hover {
      border-color: @btnColorHover!important;
      color: @btnColorInvertedHover!important;
      background-color: @btnColorHover!important;
    }
    &.is-disabled {
      border-color: @disabled!important;
      color: @disabledInverted!important;
      background-color: @disabled!important;
    }
  }
</style>