import Vue from 'vue';

export default {
  props: {},
  data() {
    return {};
  },
  watch: {},
  beforeMount() {},
  beforeDestroy() {
    this.closeDrawer();
  },
  methods: {
    openDrawer() {
      let modalEle = document.querySelector("body");
      // modalEle.classList.add("hide-modal");
    },
    closeDrawer() {
      let modalEle = document.querySelector("body");
      modalEle.classList.remove("hide-modal");
    },
  }
};