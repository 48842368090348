<template>
  <div class="progress-example" :class="{'common':!isMenuProgress, 'is_menu':isMenuProgress }" :style="{'--border':colorSet.border, '--inner':colorSet.inner, '--outer':colorSet.outer}">
    <div class="progress-inner" :style="{'width':progress+'%'}"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    progress:{
      type:[Number,String], //进度百分比数字
      default: 40
    },
    detail: {
      type: Object,
      default: function() {
        return {};
      }
    },
    isMenuProgress: false, //是否菜单进度条
  },
  computed: {
    ...mapGetters(["getCourseDetailById"]),
    courseDetail() {
      let courseAll = this.getCourseDetailById({course_id:this.$route.params.id});
      return courseAll && courseAll.course || {};
    },
    colorSet() {
      let obj = {
        inner:"course__themeVariables___progress__progress",
        outer:"course__themeVariables___progress__progress-inverted",
        border:"course__themeVariables___progress__progress-border",
        menu_inner:"course__themeVariables___menu__menu-item-progress",
        menu_outer:"course__themeVariables___menu__menu-item-progress-inverted",
        menu_border:"course__themeVariables___menu__menu-item-progress-border",
      };
      if(this.isMenuProgress) {
        return {
        inner: this.courseDetail[obj.menu_inner] || this.courseDetail[obj.inner] || "#6E6E6E",
        outer: this.courseDetail[obj.menu_outer] || this.courseDetail[obj.outer] || "#EDEDED",
        border: this.courseDetail[obj.menu_border] || this.courseDetail[obj.border] || "#EDEDED",
      }
      }
      return {
        inner: this.courseDetail[obj.inner] || "#6E6E6E",
        outer: this.courseDetail[obj.outer] || "#EDEDED",
        border: this.courseDetail[obj.border] || "#EDEDED",
      }
    },
  },
}
</script>

<style lang="less" scoped>
  .progress-example {
    position: relative;
    overflow: hidden;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 6px;
    width: 54px;
    height: 10px;
    background: #EDEDED;
    
    &.common {
      // border: 1px solid var(--progress-border, --border);
      // 边框与子盒子之间会有白色的缝隙,改成盒子阴影
      box-shadow: inset 0 0 0 1px var(--progress-border, --border);
      background-color: var(--progress-inverted, --outer);
      .progress-inner {
        background-color: var(--progress, --inner);
      }
    }
    &.is_menu {
      box-shadow: inset 0 0 0 1px var(--menu-item-progress-border, --border);
      background-color: var(--menu-item-progress-inverted, --outer);
      .progress-inner {
        background-color: var(--menu-item-progress, --inner);
      }
    }
  }
  .progress-inner {
    position: absolute;
    left: 1px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
    width: 40%;
    height: calc(100% - 2px);
    background: #6E6E6E;
  }
</style>