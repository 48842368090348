<template>
  <buttonTheme width="100%" :height="48"  :roundPX="0" :fontSize="18"  class="margin_top24 pointer">{{title}}</buttonTheme>
</template>

<script>
import buttonTheme from "@/renderer/components/common/buttonTheme.vue";
export default {
  components: {
    buttonTheme
  },
  props: {
    title:{
      type: String,
      default: ""
    }
  }
}
</script>

<style>

</style>