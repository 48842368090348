<template>
  <div class="flex flexwrap flex_center" :style="{'--column-width':(100 / column)+'%'}">
    <fdRadio  v-for="(litem,lind) in list" :key="'radio_'+lind" 
      class="margin_bot16 radio_one"
      :value="litem.value" 
      :label="litem.label" 
      :checked="selectedValue"
      :name="name"
      :graphic="litem._graphic"
      :disabled="disabled"
      :showRes="showRes"
      :isRightAnswer="litem._shouldBeSelected"
      :imgHeight="imgHeight"
      :imgSize="imgSize"
      @loadedImg="loadedImg"
      @errorImg="errorImg"
      @change="handleChange">
      </fdRadio>
  </div>
</template>

<script>
import fdRadio from "@/renderer/components/FormItem/fd-radio-graphic.vue";
export default {
  props: {
    name:String, //组别
    selected: [Number, String], //唯一选中值
    disabled: Boolean, //是否禁用
    showRes: Boolean, //是否显示结果
    //几列显示
    column:{
      type:Number,
      default: 1,
    },
    list: {
      type: Array,
      default: function() {
        return [
          {
            value:"",
            label:"",
            id: 0,
          }
        ];
      }
    },
    imgHeight:String
  },
  components: {
    fdRadio
  },
  data() {
    return {
      selectedValue: this.selected, //选中值
      loadImageDetail: {
        count:0,
      }
    };
  },
  computed: {
    imgTotal() {
      // 图片总数
      let length = 0;
      if(Array.isArray(this.list)) {
        this.list.forEach(v => {
          if(v._graphic && v._graphic.large) {
            length++;
          }
        });
      }
      return length;
    },
    imgSize() {
      let column = this.column;
      if(column == 1) return "xl";
      if(column == 2) return "m";
      return "s";
    },
  },
  watch: {
    selected: {
      handler(n) {
        if(n != undefined) {
          this.selectedValue = n;
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.loadImageDetail.count = 0;

    this.$nextTick(() => {
      //有图片时，需要等待图片加载完成
      if(this.imgTotal === 0) this.$emit("loaded");
    });
  },
  methods: {
    loadedImg(evt) {
      // 图片加载完成
      this.loadImageDetail.count++;

      if(this.loadImageDetail.count == this.imgTotal) {
        this.$emit("loaded");
      }

      this.$emit("loadimg", evt);
    },
    errorImg() {
      // 图片加载失败
      this.loadImageDetail.count++;

      if(this.loadImageDetail.count == this.imgTotal) {
        this.$emit("loaded");
      }
    },
    handleChange(val) {
      // 选中value值
      this.selectedValue = val;
      this.$emit("change",val);
    },
  },
}
</script>

<style lang="less" scoped>
  .radio_one {
    box-sizing: border-box;
    padding: 0.5rem;
    width: calc(var(--column-width));
  }
</style>