<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true,'reset':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-dragndrop"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @reset="resetData"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div v-if="showDragndrop" class="dragndrop-overlay" :class="{'dragging':isDragging}"></div>
      <div v-if="showDragndrop" class="dragndrop__inner component__inner marginCenter breakword" 
        :style="{'--item-width': maxWidth || 'auto', '--item-height': maxHeight || 'auto'}">
        <div class="dragndrop__widget component__widget "
          :class="{'dragging':isDragging}">
          <div class="dragndrop-container">
            <!-- <div class="dragndrop-overlay"></div> -->
            <div class="dragndrop-questions">
              <div v-for="(qitem, qind) in optionsItem" :key="'drag_'+qind" class="dragndrop-question">
                <div class="dragndrop-question-text breakword">{{qitem.text}}</div>
                <!-- :style="{'background-color':isDragging ? getColorValue('--item-color') :'transparent'}" -->
                <div v-for="(aitem, aind) in qitem.accepted" :key="'drag_'+aind+'_'+qind" 
                  class="dragndrop-item dragndrop-droppable ui-state-enabled"
                  :class="{'placed-active':  placeActive[qind+'_'+aind] }"
                  :id="'target_'+qind+'_'+aind"
                  @drop="onDrop($event, aind, qind)"
                  @dragover="onDragOver($event, aind, qind)"
                  @dragleave="onDragLeave($event, aind, qind)"
                  >
                </div>
                <div class="dragndrop-question-state">
                  <div class="dragndrop-correct-icon icon icon-tick"></div>
                  <div class="dragndrop-incorrect-icon icon icon-cross"></div>
                </div>
              </div>
            </div>
            <div class="dragndrop-answers clearfix prel" >
              <div v-for="(pitem, pind) in possibleAnswers" :key="'possibleAnswers_'+pind" 
                class="dragndrop-item dragndrop-answer breakword"
                :class="{'active': answersDefaultPosition[pind] && answersDefaultPosition[pind].active,
                  'canmove':answerState == -1}"
                :id="'answers_'+pind"
                :draggable="answerState == -1"
                @dragstart="onDragStart($event,pind)"
                @drag="onDrag($event,pind)"
                @dragend="onDragEnd($event,pind)">{{pitem}}</div>
            </div>
          </div>
        </div>
      </div>
      <questionsBtnGroup 
        :answerState="answerState" 
        :singeBtnShowMarking="singeBtnShowMarking"
        :leftBtnName="getBtnName('left') && detail[getBtnName('left')]"
        :feedbackBtnName="getBtnName('left') && detail[getBtnName('showFeedback')]"
        :canShowMarking="detail[singleData.canShowMarking]"
        :canShowFeedback="detail['component-_canShowFeedback']"
        :shouldDisplayAttempts="detail['component-_shouldDisplayAttempts']"
        :remainingAttemptsText="detail['component-_buttons-properties-remainingAttemptsText']"
        :attempts="detail['component-_attempts']"
        @tapBtn="tapBtn"></questionsBtnGroup>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            :listItemSet="{'canDrag':true}"
            @closeOptionsModal="closeOptionsModal"
            @sortOptionList="handleSort"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_blinds_sub'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_blinds'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
      <fd-dialog
        custom-class="hotgraphic-dialog"
        :visible.sync="feedbackDialogSet.visible"
        width="100%"
        :modal-append-to-body="true"
        :append-to-body="true"
        :before-close="beforeCloseDialogSet"
        :style="containerStyle">
        <div class="feedback-popup-inner">
          <div class="notify__popup-inner">
            <div class="notify__content">
              <div class="notify__content-inner">
                <div class="notify__title" id="notify-heading">
                  <div class="notify__title-inner" role="heading" aria-level="1">
                    <p class="fontsize_16"><strong v-html="detail['component-_feedback-properties-title']"></strong></p>
                  </div>
                </div>
                <div class="notify__body">
                  <div class="notify__body-inner">
                    <p v-if="answerState == 1" v-html="detail['component-_feedback-properties-correct']"></p>
                    <p v-else-if="answerState == 0" v-html="detail['component-_feedback-properties-_incorrect-properties-final']"></p>
                  </div>
                </div>
              </div>
            </div>
            <button @click="feedbackDialogSet.visible = false" class="btn-icon notify__btn-icon notify__close-btn js-notify-close-btn" aria-label="关闭弹出窗口">
              <div class="el-icon-close fontsize_24 color_fff"></div>
            </button>
          </div>
          <a class="a11y-focusguard a11y-ignore a11y-ignore-focus" role="presentation">&nbsp;</a>
        </div>
      </fd-dialog>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import { LightenDarkenColor } from "@/libs/utils/data";
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import questionsBtnGroup from "@/renderer/components/FormItem/questions-btn-group.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import { shuffle } from 'lodash-es';
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import listCommon from '@/renderer/mixins/list/index';
import questionsCommon from '@/renderer/mixins/questions/index';

/**
 * 拖放问题组件
*/
export default {
  mixins: [hideModal, componentCommon, listCommon, questionsCommon],
  components:{ topTools, rightForm, scaffoldForm, objForm, questionsBtnGroup, contentList },
  props: {
    containerStyle: Object,
  },
  data() {
    return {
      containerElement: null,
      showDragndrop:true,
      currentForm: {},
      componentName:"adapt-lianda-dragndrop",
      ckeditorPrefix:"componentDragndrop",
      feedbackDialogSet: {
        visible: false, //是否显示
        title: "",
      },
      answersDefaultPosition: {
        // 0: {
        //   left:0,
        //   top: 0,
        //   active:false, //是否被放置
        // }
      },
      userAnswer: null, //{}
      placeActive:{},
      parent: { //.dragndrop-container
        width:"",
        height:""
      },
      isDragging:false, //是否拖拽中
      maxWidth: "auto",
      maxHeight: "auto",
      possibleAnswers:[], //可选答案
      firstFeedback: true, //是否显示首次提交显示反馈
      answerState: -1, //答题状态： -1 未答题（未提交）、0 提交并且错误、1 提交并且正确、
      showRightAnswer: false, //是否显示了正确答案
      canShowModelAnswer:"component-_canShowModelAnswer", //显示答案 - 按钮
      answersMoveTimer: [], //显示答案时的定时器id集合
      shiftX:null,
      shiftY:null,
      isIos:false,
      singleData: {
        canShowMarking:"component-_canShowMarking", //显示标记 - 正确错误标记
        canShowModelAnswer:"component-_canShowModelAnswer", //显示答案 - 按钮
        column:"component-_columns", //显示列数
      },
    };
  },
  watch: {
    "optionsItem": {
      deep:true,
      handler() {
        this.resetData();
        this.setupQuestion();
      },
    },
    dummyAnswers: {
      deep:true,
      handler() {
        this.resetData();
        this.setupQuestion();
      },
    },
  },
  computed: {
    optionsItem() {
      return this.detail["component-_items"];
    },
    dummyAnswers() {
      return this.detail["component-dummyAnswers"];
    },
    singeBtnShowMarking() {
      // 单个按钮-显示标记
      return this.detail[this.singleData.canShowMarking] && !this.detail['component-_canShowFeedback'] && this.answerState != -1;
    },
    rightAnswerLen() {
      // 正确答案总数
      let length = 0;
      this.optionsItem.forEach(v => {
        if(v.accepted && v.accepted.length) length += v.accepted.length;
      });
      return length;
    },
  },
  mounted() {
    this.isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    this.containerElement = this.$el;

    this.setupQuestion();

    this.$nextTick(() => {
      this.$emit("loaded");
      
      this.getMaxWidth();
    });
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    beforeCloseDialogSet(done) {
      this.feedbackDialogSet.visible = false;
      done();
    },
    tapBtn(btnWhere = 1) {
      // btnWhere: 1 左侧按钮 提交或者查看正确答案; 2 反馈
      let { firstFeedback, answerState, showRightAnswer } = this;
      // 是否开启答案显示
      let canShowModelAnswer = this.detail[this.canShowModelAnswer];
      //是否开启了显示反馈
      let canShowFeedback = this.detail["component-_canShowFeedback"];
      
      // 选中提交
      if(btnWhere == 1 && answerState == -1) {
        // 判断正误
        let isRightAll = true;
        let placeActiveLen = Object.keys(this.placeActive).length;

        //未答完
        if( placeActiveLen < this.rightAnswerLen) return;

        for(let key in this.placeActive) {
          if(key.indexOf("_") >= 0) {
            let { pIndex, curIndex, answerIndex } = this.placeActive[key];
            if(this.optionsItem[pIndex].accepted.indexOf(this.possibleAnswers[answerIndex]) == -1) {
              // 错误选中
              isRightAll = false;
            }
          }
        }
        this.answerState = isRightAll ? 1 : 0;

        // 点击提交，显示反馈
        if(!!firstFeedback && !!canShowFeedback) {
          this.feedbackDialogSet.visible = true;
          this.firstFeedback = false;
        }
        return;
      }
      // 查看答案
      // this.answersMoveTimer.length!=0时，正处于答案显示移动过程
      if(canShowModelAnswer && btnWhere == 1 && answerState == 0 && this.answersMoveTimer.length == 0) {
        // 查看正确答案
        if(!this.showRightAnswer) {
          this.showRightAnswer = true;
          this.showCorrectAnswer();
          return;
        }
        // 隐藏答案-显示用户答案
        this.showRightAnswer = false;
        this.hideCorrectAnswer();
        return;
      }

      if( btnWhere == 2 && answerState != -1 && !!canShowFeedback) {
        // 已答题，开启反馈，显示反馈弹窗
        this.feedbackDialogSet.visible = true;
        return;
      }
    },
    showAnswer(showUserAnswer) {
      if (!Object.keys(this.placeActive).length) return;

      let backPlaceActive = JSON.parse( JSON.stringify( this.placeActive ) );
      
      if(!this.userAnswer) this.userAnswer = backPlaceActive;

      let items = this.optionsItem;
      let possibleAnswers = this.possibleAnswers;
      
      let time = 0;
      Object.keys(backPlaceActive).forEach((pitem,pind) => {
        // 重置已选
        time++;
        // 清空
        delete this.placeActive[pitem];
        this.$set(this.answersDefaultPosition[backPlaceActive[pitem].answerIndex], 'active', false);

        const defaultPosition_timer = setTimeout(() => {
          // 移除
          let timerIndex = this.answersMoveTimer.indexOf(defaultPosition_timer);
          if(timerIndex >= 0) this.answersMoveTimer.splice(timerIndex,1);

          let {answerIndex} = backPlaceActive[pitem];
          let userElement = this.getElement(`#answers_${answerIndex}`,1);
          
          userElement.style.left = 0;
          userElement.style.top = 0;
        }, time * 200);
        this.answersMoveTimer.push(defaultPosition_timer);
      });

      items.forEach( (vitem, vind) => {
        vitem.accepted && vitem.accepted.forEach( (aitem, aind) => {
          time++;
          let index = !showUserAnswer 
            ? possibleAnswers.findIndex(v => v === aitem)
            : this.userAnswer[`${vind}_${aind}`].answerIndex;
          
          const accepted_timer = setTimeout(() => {
            let acceptedTimerIndex = this.answersMoveTimer.indexOf(accepted_timer);
            if(acceptedTimerIndex >= 0) this.answersMoveTimer.splice(acceptedTimerIndex,1);

            // 放置位置
            let placeEle = this.getElement(`#target_${vind}_${aind}`, 1);
            // 拖拽元素
            let dragElement = this.getElement(`#answers_${index}`,1);

            // 记录
            this.$set(this.placeActive, `${vind}_${aind}`, {
              answerIndex: index,
              pIndex: vind,
              curIndex: aind,
              targetElement: placeEle
            });
            this.$set(this.answersDefaultPosition[index], 'active', true);

            let { leftToParent, topToParent, left } = this.answersDefaultPosition[index];
            dragElement.style.left = (placeEle.parentElement.offsetLeft+placeEle.offsetLeft-leftToParent) + "px";
            dragElement.style.top = (placeEle.parentElement.offsetTop+placeEle.offsetTop-topToParent) + "px";
          }, time * 300);
        this.answersMoveTimer.push(accepted_timer); 
        });
      } );
    },
    hideCorrectAnswer: function () {
      this.showAnswer(true);
    },
    showCorrectAnswer: function () {
      this.showAnswer();
    },
    getAnswersPosition() {
      // 记录初始位置
      let elements = this.getElement(".dragndrop-answer");
      
      elements && elements.forEach((element, index) => {
        this.$set(this.answersDefaultPosition, index, {
          left:element.offsetLeft,
          top:element.offsetTop,
          leftToParent:element.offsetLeft + element.parentElement.offsetLeft,
          topToParent:element.offsetTop + element.parentElement.offsetTop,
          active:false,
        });
      });
    },
    getMaxWidth() {
      let items = this.getElement(".dragndrop-item");
      let maxW = items[0].offsetWidth;
      let maxH = items[0].offsetHeight;

      items.forEach( v => {
        if(v.offsetWidth > maxW) {
          maxW = v.offsetWidth;
        }
        if(v.offsetHeight > maxH) {
          maxH = v.offsetHeight;
        }
      } );

      this.maxWidth = maxW + "px";
      this.maxHeight = maxH + "px";
      this.$nextTick(() => {
        // 获取.dragndrop-container的宽高
        let container = this.getElement(".dragndrop-container", 1);
        this.parent.width = container.offsetWidth;
        this.parent.height = container.offsetHeight;

        this.getAnswersPosition();
      });
    },
    getColorValue(propValue = "--item-color") {
      return LightenDarkenColor(this.containerStyle[propValue], 10, 'lighten')
    },
    onDragStart: function (e, index) {
      this.isDragging = true;
      // 屏蔽原始拖拽图片
      let img = new Image();
      e.dataTransfer.setDragImage(img, 0, 0);

      e.target.classList.add("hover");

      // 定义拖拽数据
      e.dataTransfer.setData("application/drag-id", e.target.id);
      e.dataTransfer.setData("application/drag-index", index);
      e.dataTransfer.setData("application/drag-componentId", this.detail['component-id']);
      e.dataTransfer.dropEffect = "move";
      e.target.style.zIndex = 12;

      if(e.target.style.left && e.target.style.left != "0px") { 
        //不在初始位置时
        let { leftToParent, topToParent, left } = this.answersDefaultPosition[index];
        let placeProps = Object.keys( this.placeActive );
        let targetIndex = placeProps.findIndex(v => this.placeActive[v].answerIndex == index);
        let placeElement = this.getElement("#target_"+ placeProps[targetIndex], 1 );

        this.shiftX =  e.pageX  - e.offsetX - (placeElement.parentElement.offsetLeft+placeElement.offsetLeft-leftToParent); //e.target.offsetLeft
        this.shiftY = e.pageY  - e.offsetY - (placeElement.parentElement.offsetTop+placeElement.offsetTop-topToParent); //e.target.offsetTop
        // 从放置位置拖动出来时，该位置即可释放出来，成为可放置位置
        delete this.placeActive[ placeProps[targetIndex] ];
      }else {
        this.shiftX = e.pageX  - e.offsetX + e.pageX - this.getOffsetLeft(e.target); //e.target.offsetLeft
        this.shiftY = e.pageY  - e.offsetY ; //e.target.offsetTop
      }
    },
    onDrag: function (e, index) {
      let { leftToParent, topToParent } = this.answersDefaultPosition[index];
      let { width:parentWidth, height:parentHeight } = this.parent;
      let newLeft = e.pageX - this.shiftX;
      let newTop = e.pageY - this.shiftY;

      if( newLeft < 0 && -newLeft > leftToParent ) { //超出左边距
        newLeft = -leftToParent;
      }else if( newLeft > (parentWidth - leftToParent - e.target.offsetWidth) ) { //超出右边距
        newLeft = parentWidth - leftToParent - e.target.offsetWidth;
      }

      if( newTop < 0 && -newTop > topToParent ) { //超出上边距
        newTop = -topToParent;
      }else if( newTop > (parentHeight - topToParent - e.target.offsetHeight) ) { //超出下边距
        newTop = parentHeight - topToParent- e.target.offsetHeight;
      }

      e.target.style.left = newLeft + "px";
      e.target.style.top = newTop + "px";
      
      e.dataTransfer.dropEffect = "move";
      // 拖拽时，不作为附着点
      e.target.style.pointerEvents = "none";
    },
    onDragEnd: function(e, index) {
      // 拖拽-源 end
      e.preventDefault();

      e.target.classList.remove("hover");
      e.target.style.zIndex = ""; //不需要给具体值，空即可重置

      // 恢复正常点击事件
      e.target.style.pointerEvents = "auto";
      // 非放置区时
      if(e.dataTransfer.dropEffect === 'none') {
        let { left, top } = this.answersDefaultPosition[index];
        e.target.style.left = 0 + "px";
        e.target.style.top = 0 + "px";

        //非放置态
        this.$set(this.answersDefaultPosition[index], 'active', false);
        for(let key in this.placeActive) {
          if(this.placeActive[key].answerIndex == index) {
            // 移出激活态
            if(this.placeActive[key].targetElement) {
              this.placeActive[key].targetElement.style.backgroundColor = "transparent";
            }
            delete this.placeActive[key];
          }
        }
      }else if(e.dataTransfer.dropEffect === 'move') {
        this.$set(this.answersDefaultPosition[index], 'active', true);
      }
      this.isDragging = false;
    },
    onDrop: function (e, curIndex, pIndex) {
      //放置
      e.preventDefault();
      // Get the id of the target and add the moved element to the target's DOM
      let data = e.dataTransfer.getData("application/drag-id");
      let fromIndex = e.dataTransfer.getData("application/drag-index"); //可选答案索引
      let fromComponent = e.dataTransfer.getData("application/drag-componentId"); //答案来源组件
      
      let { leftToParent, topToParent, left } = this.answersDefaultPosition[fromIndex];

      // 被放置答案是否从另一个放置区拖动过来
      let pArr = Object.keys(this.placeActive);
      let fromPlacedIndex = pArr.findIndex( v => this.placeActive[v].answerIndex ==  fromIndex);
      if(fromPlacedIndex != -1) {
        let fromElement = pArr[fromPlacedIndex] && this.placeActive[pArr[fromPlacedIndex]] && this.placeActive[pArr[fromPlacedIndex]].targetElement || null;
        if(fromElement) fromElement.style.backgroundColor = "transparent";
        delete this.placeActive[pArr[fromPlacedIndex]];
      }

      // 存储用户答案
      this.$set(this.placeActive, pIndex+'_'+curIndex, {
        pIndex:pIndex,
        curIndex: curIndex,
        answerIndex:fromIndex,
        targetElement: e.target
      });
      this.$forceUpdate();

      let dragElement = this.getElement('#'+data, 1);
      dragElement.style.left = (e.target.parentElement.offsetLeft+e.target.offsetLeft-leftToParent) + "px";
      //  + (pIndex%2==0 ? 0 : 1)
      dragElement.style.top = (e.target.parentElement.offsetTop+e.target.offsetTop-topToParent) + "px";
      
    },
    onDragOver: function (e, curIndex, pIndex) {
      // 拖动到有效放置目标（每几百毫秒）上时
      if(!!this.isDragging) {
        e.preventDefault(); //阻止默认的处理, 允许放置
        e.target.style.backgroundColor = this.getColorValue('--item-color');
        e.dataTransfer.dropEffect = "move";
      }
      
    },
    onDragLeave: function (e, curIndex, pIndex) {
      // 放置离开
      e.preventDefault();
      e.target.style.backgroundColor = "transparent";
    },
    getOffsetLeft(obj) {
      return obj.offsetLeft + (obj.offsetParent ? this.getOffsetLeft(obj.offsetParent) : 0);
    },
    getAnswers: function (includeDummyAnswers) {
      let answers = [];
      let items = this.optionsItem;;
      items.forEach((item) => {
        if(item.accepted && item.accepted.length > 0) answers = answers.concat(item.accepted);
      });

      if (includeDummyAnswers) {
          let dummyAnswers = this.dummyAnswers;
          if (dummyAnswers) answers = answers.concat(dummyAnswers);
      }

      return answers
    },
    setupQuestion: function () {
      // available answers
      let possibleAnswers = shuffle(this.getAnswers(true));
      this.possibleAnswers = possibleAnswers;
      
      // 确保accepted是array
      let items = this.optionsItem;;
      items.forEach((item) => {
        if (typeof item.accepted === "string") item.accepted = [item.accepted];
      });
    },
    resetData() {
      // 重置数据
      this.showDragndrop = false;
      this.$forceUpdate();
      
      let elements = this.getElement(".dragndrop-answer");
      elements && elements.forEach((element, index) => {
        element.style.left = 0;
        element.style.top = 0;
      });
      let dropEle = this.getElement(".dragndrop-droppable");
      dropEle && dropEle.forEach((element, index) => {
        element.style.backgroundColor = "transparent";
      });

      this.answersDefaultPosition = {};
      this.userAnswer = null;
      this.placeActive = {};
      this.isDragging = false; //是否拖拽中
      this.maxWidth = "auto";
      this.maxHeight = "auto";
      this.answerState = -1;
      this.firstFeedback = true;
      this.showRightAnswer = false;
      setTimeout(() => {
        this.showDragndrop = true;
        this.$nextTick(() => {
          this.getMaxWidth();
        });
      });
    },
    getElement(selector, type = 2) { //type: 1 (querySelector) 2 (querySelectorAll)
      // 当前组件内
      let $el = this.containerElement;
      if(!selector) return null;
      if(type === 1) return $el.querySelector(selector);
      return $el.querySelectorAll(selector);
    },
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/

  // 拖拽问题
  .dragndrop-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0;
    transition: opacity 200ms;
    display: none;
  }

  .dragging.dragndrop-overlay {
    z-index: 10; //不要改这个 - 层级高于item激活，低于item非激活
    opacity: 0.4;
    display: block;
  }

  .dragndrop__inner {
    margin-top: 16px;
    position: relative; //不要改这个

    .dragndrop-container {
      display: flex;
      flex-direction: column;
    }

    .btn__container,
    .dragndrop-item {
    }
    .dragndrop-item {
      position: relative;
      z-index: 11;
      width: var(--item-width);
      height: var(--item-height);
    }

    .dragndrop__widget.dragging .dragndrop-item {
      &.active,
      &.placed-active {
        z-index: 9; //不要改这个
      }
    }

    .btn__container.bounceback {
      display: none !important;
    }

    .dragndrop-question {
      position: relative; //不要改这个
      margin-bottom: 16px;
      border-radius: 4px;
      padding: 16px;
      background: var(--item-color-selected);
      color: var(--item-color-inverted);

      .dragndrop-question-text {
        font-size: 16px;
        line-height: 22px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .ui-droppable {
        display: inline-block;
        position: relative;
        border: 1px dotted;
        border-color: var(--item-color-inverted);
        vertical-align: middle;
      }

      .ui-droppable.ui-state-active {
        background-color: var(--item-color);
      }

      .ui-droppable.ui-state-enabled.ui-state-hover {
        // background-color: lighten(var(--item-color), 10%);
      }

      .ui-droppable.ui-state-disabled {
        border-style: solid;
        z-index: 8;
      }

      .dragndrop-question-state {
        float: right;

        .icon {
          display: none;
          color: #ffffff;
        }
      }
    }

    .dragndrop-droppable {
      margin-top: 12px;
      border: 1px dashed #fff;
      &::before {
        position: absolute;
        display: block;
        content: "+";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -55%);
        font-size: 20px;
        color: #fff;
      }
    }

    .dragndrop-answers {
      max-width: 100%;
      .dragndrop-answer {
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative; //不要改这个
        float: left;
        margin-top: 16px;
        border: 1px solid var(--item-color-selected);
        text-align: center;
        font-size: 14px;
        color: var(--item-color-selected);
        background-color: var(--item-color-inverted);
        // -webkit-transition: 0.2s;
        // -moz-transition: 0.2s;
        // -ms-transition: 0.2s;
        // -o-transition: 0.2s;
        // transition: 0.2s;
        &:hover,
        &.hover {
          color: var(--item-color-inverted);
          background-color: var(--item-color-selected);
        }
        
        &.canmove {
          cursor: move;
        }
        &.active {
          box-sizing: border-box;
          box-shadow:none;
          border: 1px solid #fff;
          color: var(--item-color-inverted);
          background-color: var(--item-color);
        }

        &.ui-draggable-disabled {
            cursor: default;
        }

        &.ui-draggable-dragging {
            box-shadow: 2px 6px 12px rgba(0, 0, 0, 0.5);
            z-index: 12;
        }

        &.ui-state-placed {
            z-index: 9;
            background: none;
            color: var(--item-color-inverted);
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
        }

        &.ui-state-placing {
            -webkit-transition: 0.2s;
            -moz-transition: 0.2s;
            -ms-transition: 0.2s;
            -o-transition: 0.2s;
            transition: 0.2s;
        }
      }
    }
    .dragndrop__widget:not(.dragging) {
      // 非拖拽时
      .dragndrop-answer {
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
        -ms-transition: 0.2s;
        -o-transition: 0.2s;
        transition: 0.2s;
      }
    }

    .dragndrop-item {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      display: inline-block;
      margin-right: 25px;
      border-radius: 4px;
      padding: 10px 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .dragndrop__widget.show-user-answer {
    .dragndrop-question {
      &.correct .icon-tick {
        display: block;
      }

      &.incorrect .icon-cross {
        display: block;
      }
    }
  }

  .dragndrop-transition-0 {
    -webkit-transition: 0s;
    -moz-transition: 0s;
    -ms-transition: 0s;
    -o-transition: 0s;
    transition: 0s;
  }

  .dragndrop-transition-200 {
    -webkit-transition: top 0.2s, left 0.2s;
    -moz-transition: top 0.2s, left 0.2s;
    -ms-transition: top 0.2s, left 0.2s;
    -o-transition: top 0.2s, left 0.2s;
    transition: top 0.2s, left 0.2s;
  }

  .dragndrop-transition-600 {
    -webkit-transition: top 0.6s, left 0.6s;
    -moz-transition: top 0.6s, left 0.6s;
    -ms-transition: top 0.6s, left 0.6s;
    -o-transition: top 0.6s, left 0.6s;
    transition: top 0.6s, left 0.6s;
  }

</style>