<template>
  <div class="height_100p">
    <div class="scroll-parent hidden">
      <div class="scroll-box  width0" :style="{'padding-bottom':'20px'}">
        <coreForm 
          :topLayer="true"
          :ruleForm="ruleForm" 
          :propList="propList" 
          :ckeditorNameId="ckeditorNameId" 
          :componentName="componentName" 
          :titleFontSize="titleFontSize" 
          :scrollBoxPaddingLR="scrollBoxPaddingLR"
          @formItemBlur="handleItemBlur"
          @changeVal="handleVal"
          @selectChange="handleSelect"
          @showLocationDialog="showLocationDialog"
          @addCaption="handleAddCaption"
          @editCaption="handleEditCaption"
          ></coreForm>
      </div>
    </div>
    <fd-dialog
      custom-class="location-dialog"
      fullscreen
      :visible.sync="locationDialogSet.visible"
      width="100%"
      :modal-append-to-body="true"
      :append-to-body="true"
      :before-close="arg => handleCloseDialog(arg,'location')">
      <div class="flex flexcolumn flex_jcenter width_100p" @mouseup="dialogMouseup">
        <div class="location-close" @mouseup.stop="">
          <customButton color="#E74362" :width="64" :height="32" @click="sureLocation">
            <span class="fontsize_12">确定</span>
          </customButton>
        </div>
        <div class="darg-tips textcenter flex flexcolumn flex_center">
          <p class="fontsize_16 color_222 margin_bot16">热点定位</p>
          <p class="fontsize_14 color_999">将热点指针定位到所需位置</p>
        </div>
        <drag-ball
          ref="dragBall"
          @dropped="getPosition"
          :initLeft="initLeft"
          :initTop="initTop" 
          :img="locationDialogSet.item.imgSrc"
        >
        </drag-ball>
      </div>
    </fd-dialog>
  </div>
</template>

<script>
import Config from '@/libs/config';
import { mapGetters, mapMutations, mapState } from "vuex";
import coreForm from "@/renderer/components/common/coreForm.vue";
import NumberInput from "@/renderer/components/common/numberInput.vue";
import checkBox from "@/renderer/components/common/checkBox.vue";
import AltInput from "@/renderer/components/common/altInput.vue";
import iconSelect from "@/renderer/components/common/iconListSelect.vue";
import customButton from "@/renderer/components/common/button.vue";
import dragPosition from "@/renderer/components/common/dragPosition.vue";
import fileOption from "@/renderer/components/common/fileOption.vue";
import matchingItem from "@/renderer/components/FormItem/matching-item.vue";
import answerItem from "@/renderer/components/FormItem/answer-item.vue";
import captionItem from "@/renderer/components/FormItem/caption-item.vue";
import dragBall from "@/renderer/components/common/dragBall.vue";
export default {
  name:"leftForm",
  props: {
    ruleForm: {
      type: Object,
      default: function() {
        return {};
      }
    },
    parentForm: {
      type: Object,
      default: function() {
        return {};
      }
    },
    componentName: String,
    ckeditorNameId: {
      type: [String, Number],
      required: true,
      default:""
    },
    propList:{
      type: Array,
      default: function() {
        return [];
      }
    },
    titleFontSize: { //每一项标题 字体大小
      type: [Number, String],
      validator(val) { 
        return ! /\D/.test(val)
      },
      default: 16
    },
    scrollBoxPaddingLR: { //scrollBox左右padding值
      type: [Number, String],
      validator(val) { 
        return ! /\D/.test(val)
      },
      default: 13
    }
  },
  components: {
    coreForm,
    NumberInput,
    checkBox,
    AltInput,
    customButton,
    dragPosition,
    iconSelect,
    fileOption,
    matchingItem,
    answerItem,
    dragBall,
    captionItem
  },
  data() {
    return {
      activeTab: 0,
      throttleDelay:Config.realTimeInput,
      subForm:{},
      locationDialogSet: {
        visible: false,
        hasChange:false,
        item: {
          src:"",
          input_name: "",
          left: "",
          top: ""
        }
      },
    };
  },
  watch: {
    propList: {
      handler(n) {
        if(n) {
          Array.isArray(n) && n.forEach(v => {
            if(v.properties && Array.isArray(v.properties)) {
              if(this.ruleForm[v.input_name] === undefined) {
                this.$set(this.ruleForm, v.input_name, {})
              }
            }
          })
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['getCustomStyleById']),
    initLeft() {
      let input_name = this.locationDialogSet.item.input_name;
      let leftPropName = this.locationDialogSet.item.left;
      return this.ruleForm && this.ruleForm[leftPropName]
      // return this.ruleForm[input_name] && this.ruleForm[input_name][leftPropName]
    },
    initTop() {
      let input_name = this.locationDialogSet.item.input_name;
      let topPropName = this.locationDialogSet.item.top;
      return this.ruleForm && this.ruleForm[topPropName]
    },
    customStyle() {
      return this.getCustomStyleById(this.$route.params.id) || "";
    },
  },
  methods: {
    ...mapMutations(["SET_COURSE_FORM"]),
    dialogMouseup(e) {
      this.$refs.dragBall && this.$refs.dragBall.drop();
    },
    addCaption(list, propName) {
      // 添加字幕弹窗
      this.$set(this.ruleForm, propName, list);
      this.$emit('formItemBlur',{
        value: list,
        propName: propName,
        form: this.ruleForm
      });
      this.$emit("addCaption",{
        value: list,
        propName: propName,
        form: this.ruleForm
      });
    },
    editCaption({list, index}, propName) {
      this.$emit("editCaption",{
        value: list,
        propName: propName,
        form: this.ruleForm,
        index
      });
    },
    sureLocation() {
      // 确认位置
      if(!this.locationDialogSet.item) return;
      if(!this.locationDialogSet.hasChange) {
        this.locationDialogSet.visible = false;
        return;
      }

      let item = JSON.parse(JSON.stringify(this.locationDialogSet.item));
      // 确认- 保存位置信息
      this.locationDialogSet.visible = false;
      
      if(item.location) {
        Object.assign(this.ruleForm, item.location);
      
        Object.keys(item.location).forEach( (k,i) => {
          if(i!=0) return;
          this.$emit("formItemBlur", {
            value: item.location[k], 
            propName: k,
            form: this.ruleForm
          });
        } );
        this.$forceUpdate();
      }
    },
    getPosition({left, top, parentWidth, parentHeight}) {
      // 获取位置
      let leftPropName = this.locationDialogSet.item.left;
      let topPropName = this.locationDialogSet.item.top;
      let input_name = this.locationDialogSet.item.input_name;
      // 百分比,2位小数
      let obj = {
        [leftPropName]: ( (left/parentWidth) * 100 ).toFixed(2),
        [topPropName]: ( (top/parentHeight) * 100 ).toFixed(2),
      };
      this.locationDialogSet.hasChange = true;
      this.$set(this.locationDialogSet.item,input_name,obj);
    },
    handleCloseDialog(done, type) {
      done();
      this.locationDialogSet.visible = false;
    },
    showLocationDialog(item) {
      // 显示选择位置弹窗
      if(!this.parentForm[item.imgPropName]) {
        this.$message("请选择图片")
        return;
      }
      item.imgSrc = this.parentForm[item.imgPropName];

      // this.locationDialogSet.item = item;
      Object.assign(this.locationDialogSet.item, item);
      this.locationDialogSet.visible = true;
      this.locationDialogSet.hasChange = false;
    },
    // cansShowSubTitle(input_type) {
    //   //子标题是否显示
    //   let showListTitle = ['number','_graphic','hotgraphic_icon','richtext_mini','richtext'];
    //   // ritem['input_type'] == 'number' || sitem['input_name'] == '_graphic' || sitem['input_type'] === 'hotgraphic_icon'
    //   return showListTitle.includes(input_type)
    // },
    // 新增
    handleItemBlur({value, propName, form, subForm}) {
      let args = {
        value,
        propName,
        form
      };
      if(subForm) {
        args.subForm = subForm;
      }
      this.$emit("formItemBlur", args);
    },
    handleVal({value, propName, form}) {
      this.$emit("changeVal",{
        value,
        propName,
        form
      });
    },
    handleSelect({value, propName, form}) {
      this.$emit("selectChange",{
        value,
        propName,
        form
      });
    },
    handleAddCaption({value, propName, form}) {
      this.$emit('formItemBlur',{
        value,
        propName,
        form
      });
      this.$emit("addCaption",{
        value,
        propName,
        form
      });
    },
    handleEditCaption({value, propName, form, index}) {
      this.$emit("editCaption",{
        value,
        propName,
        form,
        index
      });
    },
  }
}
</script>

<style lang="less" scoped>
  .darg-tips {
    margin-top: 48px;
    margin-bottom: 24px;
    width: 100%;
    height: 92px;
    background: #FAFAFA;
  }
  .location-close {
    position: absolute;
    right: 24px;
    top: 8px;
    z-index: 6;
  }
</style>