import Vue from 'vue';

export default {
  props: {},
  data() {
    return {};
  },
  watch: {},
  beforeMount() {},
  beforeDestroy() {},
  methods: {
    getBtnName(type) {
      let btnsText = {
        submit: "component-_buttons-properties-_submit-properties-buttonText",
        reset: "component-_buttons-properties-_reset-properties-buttonText",
        showCorrectAnswer: "component-_buttons-properties-_showCorrectAnswer-properties-buttonText",
        hideCorrectAnswer: "component-_buttons-properties-_hideCorrectAnswer-properties-buttonText",
        showFeedback: "component-_buttons-properties-_showFeedback-properties-buttonText",
      }
      // 左侧按钮
      if(type === 'left') {
        let { answerState, showRightAnswer } = this;
        let canShowModelAnswer = this.detail[this.singleData.canShowModelAnswer];
        // 显示答案
        if(canShowModelAnswer && answerState == 0 && !showRightAnswer) return btnsText["showCorrectAnswer"];
        // 隐藏答案
        if(canShowModelAnswer && answerState == 0 && showRightAnswer) return btnsText["hideCorrectAnswer"];
        return btnsText["submit"];
      }
      return btnsText[type]
    },
  }
};