<template>
  <topTools type="right" rightTips="组件"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    :componentTitle="componentTitle"
    class="component-blank"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <div class="component-blank-inner"></div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
        <div class="drawer-right-inner prel bgc_fff">
        <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
          <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
          <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
        </div>
        <rightForm
          class="drawer-right-inner-form"
          :ruleForm="detail" 
          :tabList="tabList" 
          :currentTab="drawerSet.activeName"
          ckeditorNameId="component-_id"
          :ckeditorPrefix="ckeditorPrefix"
          @open="openInnerDrawer"
          @selectChange="selectChange"
          @formItemInput="formItemInput"
          @formItemBlur="formItemBlur">
        </rightForm>
        <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
          <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
            <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222 poverOfHtmlTile richHtml_c222" ></span>
            <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerDrawer(1)" ></i>
          </div>
          <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
            <scaffoldForm 
              v-if="innerDrawer.visible"
              :titleFontSize="14"
              :scrollBoxPaddingLR="16"
              :ruleForm="detail"
              :propList="innerDrawer.propList"
              :ckeditorNameId="'component-_blank'"
              @selectChange="formChangeBlur" 
              @formItemBlur="formChangeBlur"
            ></scaffoldForm>
          </div>
        </div>
        </div>
      </fd-drawer>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';

export default {
  mixins: [hideModal, componentCommon],
  components:{ topTools, rightForm, scaffoldForm },
  data() {
    return {
      componentName:'adapt-contrib-blank',
      ckeditorPrefix:"componentBlank",
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("loaded");
    });
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    openInnerDrawer({list, title, visible}) {
      // 显示\隐藏二层弹出窗口
      this.innerDrawer.title = title;
      this.innerDrawer.propList = list;
      
      if(!visible || visible == 2) {
        this.innerDrawer.visible = false;
        return;
      }else{
        this.innerDrawer.visible = true;
      }
    },
    formItemBlur(value,propName) {
      let obj = {};
      obj[propName] = value;
      this.saveDetail(obj, true);
    },
    delComponent() {
      // 删除组件
      this.$emit("del",{
        course_id: this.detail.course_id,
        component_id: this.detail['component-id'],
        title: this.detail.title,
        componentTitle: this.schemaJson[this.componentName].title,
        'component-_component': this.detail['component-_component']
      });
    },
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/
  
  .component-blank {
      box-sizing: border-box;
      padding: 16px;
      .component-blank-inner {
        min-height: calc(44px - 32px);
      }
  }
</style>