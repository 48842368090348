<template>
  <div @mousewheel="handleMousewheel" :class="{'active-container': isShow}">
    <p v-html="label" class="fontsize_16 color_222 match-title breakword" :class="{'show-modal':isShow}"></p>
    <label class="select_items flex flex_acenter prel" 
      :class="{'active':selectedIndex != -1,'pointer': !disabled, 'not_selected_disabled':  disabled && selectedIndex != -1,'show-modal':isShow }" 
      :style="{'padding':padding+'px'}"
      @click.stop="tapMatch">
      <div class="answer-status flexshrink flex flex_acenter fontsize_14 color_fff">
        <div v-html="defaultSelect" class="answer-status-content"></div>
        <div class="answer-status-arrow flex flex_acenter flex_center">
          <!-- !showRes && disabled ||  -->
          <i v-if="!disabled" class="fontsize_12"  :class="[isShow ? 'icon-match-arrow-up': 'icon-match-arrow-down']" ></i>
          <span v-else-if="showRes && answerStatus != 0" :class="{'icon-radio_error_answer icon_color_E74362': answerStatus == 2,'icon-radio_right_answer': answerStatus == 1}" class="fontsize_20 color_222">
            <span class="path1"></span>
            <span class="path2"></span>
          </span>
        </div>
      </div>
      <div class="match-select-wrapper" :class="{'show':isShow, 'is_top': isTop}" :style="{'max-height': selectHeight + 'px'}" 
        @click.stop=""
        @mousewheel.stop="innerMousewheel">
        <div 
          v-for="(oitem,oind) in options" 
          :key="oitem.id" 
          class="fontsize_14 color_222 match-select-item"
          :class="{'selected':selectedIndex === oind}"
          v-html="oitem.text"
          @click="tapItem(oind)"></div>
      </div>
    </label>
    <div v-if="isShow" @click.stop="isShow = false" class="match-select-modal"></div>
  </div>
</template>

<script>
export default {
  props: {
    index:[String, Number], //当前索引
    options:Array,
    showModal:Boolean, //显示遮罩层
    checked: [String, Number], //选中索引
    value: [String, Number],
    label: String,
    name: String,
    disabled:Boolean, //禁用
    showRes: Boolean, //是否显示结果
    placeholder:String,
    padding: {
      type:Number,
      default: 0
    }
  },
  data() {
    return {
      isShow:false, //是否显示
      selectedIndex:this.checked, //选中值
      isTop: false, //朝上
      selectHeight: "auto"
    };
  },
  computed: {
    answerStatus() {
      // 当前选中 // 禁用 // 选中 // 正确
      let { selectedIndex, value } = this;
      let current = this.options[selectedIndex];
      // 选中正确
      if(selectedIndex != -1 && current['_isCorrect']) return 1;
      // 选中错误
      if(selectedIndex != -1 && !current['_isCorrect']) return 2;
      // 未选中
      return 0;
    },
    defaultSelect() {
      return this.selectedIndex >= 0 && this.options[this.selectedIndex].text || this.placeholder
    },
  },
  watch: {
    checked: {
      handler(n) {
        this.selectedIndex = n;
      },
      immediate: true
    },
    showModal: {
      handler(n) {
        this.isShow = n;
      },
      immediate: true
    },
  },
  methods: {
    handleMousewheel(event) { 
      //常规可滚动页面，显示下拉时无法滚动
      if(!this.isShow) return;
      // event.preventDefault();
    },
    innerMousewheel(event) { 
      event.stopPropagation ? event.stopPropagation() : event.cancelBubble = true;
    },
    getClient() {
      return {
        height: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      }
    },
    tapMatch() {
      let element = this.$el.querySelector('.answer-status');
      let clientHeight = this.getClient().height;
      let elementRect = element.getBoundingClientRect();
      // 是否朝下
      let directTop = clientHeight/2 - elementRect.height < elementRect.top;
      this.isTop = directTop;
      this.selectHeight = directTop ? elementRect.top * 2/3 : (clientHeight - elementRect.height - elementRect.top) * 2/3;

      let { disabled } = this;
      if(disabled) return;
      this.isShow = !this.isShow;
      this.$emit("showModal", this.isShow);
    },
    tapItem(index) {
      if(index != this.selectedIndex) {
        this.selectedIndex = index;
        this.$emit("change",{
          item: this.options[index],
          checked: index
        });
      }
      this.isShow = false;
    },
    getStyle() {
      let { padding } = this;
      let styleSet = {};

      if(padding && !/\D/.test(padding)) { //数字 - px
        styleSet['padding'] = padding + 'px';
      }else if( padding && padding.indexOf('px') > -1 ) { 
        styleSet['padding'] = padding;
      }

      return styleSet;
    },
    cancelSelect() {
      // 取消选中-外部调用
      this.selectedIndex = -1;
    },
  },
}
</script>

<style lang="less" scoped>
  @itemColor:var(--item-color, #4D98B7);
  @itemColorHover: var(--item-color-hover, #4D98B7);
  @ItemColorInverted: var(--item-color-inverted, #fff);
  @ItemColorInvertedHover: var(--item-color-inverted-hover, #fff);
  @ItemColorSelected:var(--item-color-selected, #4D98B7);
  @ItemColorSelectedInverted:var(--item-color-inverted-selected, #fff);

  .active-container {
    position: relative;
    z-index: 10;
    .select_items {
      z-index: 9;
    }
  }
  .select_items {
    transition: background 0.4s;
    background-color: @itemColor;
    .answer-status,
    .icon-match-arrow-down::before,
    .icon-match-arrow-up::before {
      color: @ItemColorInverted;
    }

    &:hover {
      background-color: @itemColorHover;
      .answer-status,
      .icon-match-arrow-down::before,
      .icon-match-arrow-up::before {
        color: @ItemColorInvertedHover;
      }
    }
    // 选中
    &.active {
      background-color: @ItemColorSelected;
      .answer-status,
      .icon-match-arrow-down::before,
      .icon-match-arrow-up::before {
        color: @ItemColorSelectedInverted;
      }
    }
    // 禁用
    &.not_selected_disabled {
      background-color: #A1A1A1;
    }
  }

  @answerStatusArrowWidth: 52px;
  .answer-status {
    box-sizing: border-box;
    padding: 16px;
    width: 100%;
    // height: 52px;
    height: auto;
    position: relative;
    // 层级高于遮罩层即可
    z-index: 2;
    word-break: break-all;
    .answer-status-content {
      width: calc(100% - @answerStatusArrowWidth + 16px);
    }
    .answer-status-arrow {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      width: @answerStatusArrowWidth;
      height: 52px;
      line-height: 52px;
      text-align: center;
    }
  }

  .match-title {
    padding: 14px 0;
  }

  // 上拉弹出
  // 遮罩层
  .match-select-modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    // background-color: rgba(0, 0, 0, 0.3);
  }
  .match-select-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    padding-top: 1px;
    background-color: @ItemColorSelected;
    display: none;
    z-index: 1;
    overflow: hidden auto;
    &.show {
      display: block;
    }
    &:not(.is_top) {
      bottom: 0;
      transform: translateY(100%);
    }
    &.is_top {
      top: 0;
      transform: translateY(-100%);
    }
    .match-select-item {
      word-break: break-all;
      margin-bottom: 1px;
      padding: 16px;
      line-height: 20px;
      font-size: 14px;
      color: @ItemColorInverted;
      overflow: hidden;
      background-color: @itemColor;
      &:hover {
        color: @ItemColorInvertedHover;
        background-color: @itemColorHover;
      }
      &.selected {
        background-color: @ItemColorSelected;
        color: @ItemColorSelectedInverted;
      }
    }
  }
</style>