<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true,'reset':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-gmcq"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @reset="initOptionRelative"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div class="margin_top16 prel">
        <fdRadioGroup v-if="!isMultiple" 
          :list="optionsItem" 
          :selected="selected" 
          :name="detail['component-id']"
          :disabled="answerState != -1"
          :showRes="detail[singleData.canShowMarking]"
          :column="detail[singleData.column]"
          :imgHeight="currentHeight"
          @loaded="loaded"
          @loadimg="loadImage"
          @change="radioChange"></fdRadioGroup>
        <fdCheckboxGroup v-else 
          :list="optionsItem" 
          :selected="checkArr" 
          :name="detail['component-id']"
          :disabled="answerState != -1"
          :showRes="detail[singleData.canShowMarking]"
          :column="detail[singleData.column]"
          :imgHeight="currentHeight"
          @loaded="loaded"
          @loadimg="loadImage"
          @change="checkChange"></fdCheckboxGroup>
        <questionsBtnGroup 
          :answerState="answerState" 
          :singeBtnShowMarking="singeBtnShowMarking"
          :leftBtnName="getBtnName('left') && detail[getBtnName('left')]"
          :feedbackBtnName="getBtnName('left') && detail[getBtnName('showFeedback')]"
          :canShowMarking="detail[singleData.canShowMarking]"
          :canShowFeedback="detail['component-_canShowFeedback']"
          :shouldDisplayAttempts="detail['component-_shouldDisplayAttempts']"
          :remainingAttemptsText="detail['component-_buttons-properties-remainingAttemptsText']"
          :attempts="detail['component-_attempts']"
          @tapBtn="tapBtn"></questionsBtnGroup>
      </div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            :listItemSet="{'showArrow':true,'canDrag':true}"
            @closeOptionsModal="closeOptionsModal"
            @sortOptionList="handleSort"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_mcq_pic'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_mcq'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
      <fd-dialog
        custom-class="hotgraphic-dialog"
        :visible.sync="feedbackDialogSet.visible"
        width="100%"
        :modal-append-to-body="true"
        :append-to-body="true"
        :style="containerStyle"
        :before-close="beforeCloseDialogSet">
        <div class="feedback-popup-inner" >
          <div class="notify__popup-inner">
            <div class="notify__content">
              <div class="notify__content-inner">
                <div class="notify__title" id="notify-heading">
                  <div class="notify__title-inner" role="heading" aria-level="1">
                    <p class="fontsize_16"><strong v-html="detail['component-_feedback-properties-title']"></strong></p>
                  </div>
                </div>
                <div class="notify__body">
                  <div class="notify__body-inner">
                    <p v-if="answerState == 1" v-html="detail['component-_feedback-properties-correct']"></p>
                    <p v-else-if="answerState == 0" v-html="detail['component-_feedback-properties-_incorrect-properties-final']"></p>
                  </div>
                </div>
              </div>
            </div>
            <button @click="feedbackDialogSet.visible = false" class="btn-icon notify__btn-icon notify__close-btn js-notify-close-btn" aria-label="关闭弹出窗口">
              <div class="el-icon-close fontsize_24 color_fff"></div>
            </button>
          </div>
          <a class="a11y-focusguard a11y-ignore a11y-ignore-focus" role="presentation">&nbsp;</a>
        </div>
      </fd-dialog>
  </topTools>
</template>

<script>
import { shuffle } from 'lodash-es';
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import fdRadioGroup from "@/renderer/components/FormItem/fd-radio-group-graphic.vue";
import fdCheckboxGroup from "@/renderer/components/FormItem/fd-checkbox-group-graphic.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import questionsBtnGroup from "@/renderer/components/FormItem/questions-btn-group.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import questionsCommon from '@/renderer/mixins/questions/index';

/**
 * 图片选择题 组件 （单选/多选）
 * 1、根据正确答案数-区分单选、多选 (isMultiple)
 * 2、切换-是否显示正确答案按钮 (canShowModelAnswer)
 * 3、切换-是否显示标记- 用户选中项的对错标记 (canShowMarking)
 * 4、如果选项修改、新增、删除 - 初始化相关数据 (optionsItem)
*/
export default {
  mixins: [hideModal, componentCommon, questionsCommon],
  components:{ topTools, rightForm, scaffoldForm, objForm, fdRadioGroup, fdCheckboxGroup, contentList, questionsBtnGroup },
  props: {
    containerStyle: Object,
  },
  data() {
    return {
      currentForm: {},
      componentName:"adapt-contrib-gmcq",
      ckeditorPrefix:"componentGmcq",
      feedbackDialogSet: {
        visible: false, //是否显示
        title: "",
      },
      selected: -1, //选中-单选
      checkArr: [], //选中-多选
      userAnswer: {
        single: -1, //用户答案-单选
        multiple: [], //用户答案-多选
      },
      rightAnswer: {
        single: -1, //正确答案-单选
        multiple: [], //正确答案-多选
      },
      firstFeedback: true, //是否显示首次提交显示反馈
      answerState: -1, //答题状态： -1 未答题（未提交）、0 提交并且错误、1 提交并且正确、
      showRightAnswer: false, //是否显示了正确答案
      singleData: {
        canShowMarking:"component-_canShowMarking", //显示标记 - 正确错误标记
        canShowModelAnswer:"component-_canShowModelAnswer", //显示答案 - 按钮
        column:"component-_columns", //显示列数
      },
      currentHeight:'auto',
      maxImgHeight: 0,
    };
  },
  computed: {
    optionsItem() {
      // 选项
      let optionList = this.detail["component-_items"];
      // 选项新增或删除，初始化选项相关数据
      this.initOptionRelative();

      optionList.forEach((element,index) => {
        element.label = element.text;
        element.value = index;
        if(element._shouldBeSelected) {
          this.rightAnswer.single = index;
          this.rightAnswer.multiple.push(index);
        }
      });
      
      return optionList;
    },
    isMultiple() {
      // 是否多选
      return this.rightAnswer.multiple.length > 1 || this.detail['component-_selectable'] > 1
    },
    singeBtnShowMarking() {
      // 单个按钮-显示标记
      return this.detail[this.singleData.canShowMarking] && !this.detail['component-_canShowFeedback'] && this.answerState != -1;
    },
    isRandom() {
      return this.detail['component-_isRandom'];
    },
  },
  watch: {
    isRandom: {
      immediate: true,
      handler(n) {
        if(!!n) {
          // 随机答案
          let list = shuffle(this.detail['component-_items']);
          this.$set(this.detail, 'component-_items', list);
        }
      }
    }
  },
  mounted() {
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    loadImage(evt) {
      // 图片加载完成
      if(evt.height > this.maxImgHeight) {
        this.maxImgHeight = evt.height; 
      }
    },
    loaded() {
      this.$emit("loaded");
    },
    closeOptionsModal() {
      // 拖拽排序时，关闭左侧弹窗
      this.innerDrawer.current = -1;
      this.closeInnerDrawer();
    },
    handleSort({list, propName}) {
      // 选项排序后的list
      // 控制图片高度一致
      this.currentHeight = this.maxImgHeight + "px";

      this.$set(this.detail, propName, list);
      this.saveDetail({
        [propName]: list
      }, false);

      this.$nextTick(() => {
        setTimeout(() => {
          this.currentHeight = "auto";
        }, 1000);
      });
    },
    beforeCloseDialogSet(done) {
      this.feedbackDialogSet.visible = false;
      done();
    },
    initOptionRelative() {
      // 初始化选项相关数据
      this.selected = -1;
      this.checkArr = [];
      this.rightAnswer.multiple = [];
      this.rightAnswer.single = -1;
      this.answerState = -1;
      this.firstFeedback = true;
      this.showRightAnswer = false;
    },
    tapBtn(btnWhere = 1) {
      // btnWhere: 1 左侧按钮 提交或者查看正确答案; 2 反馈
      let { firstFeedback, isMultiple, selected, answerState, showRightAnswer, checkArr } = this;
      //单选答案
      let singleAnswer = this.rightAnswer.single;
      // 多选答案
      let multipleAnswer = this.rightAnswer.multiple;
      // 是否开启答案显示
      let canShowModelAnswer = this.detail[this.singleData.canShowModelAnswer];
      //是否开启了显示反馈
      let canShowFeedback = this.detail["component-_canShowFeedback"];

      if(btnWhere == 1 && isMultiple && answerState == -1 && checkArr.length > 0) { 
        // 多选提交 - 至少选中一个
        if(checkArr.length != multipleAnswer.length) this.answerState = 0;
        if(checkArr.length == multipleAnswer.length) {
          let targetIndex  = multipleAnswer.findIndex((v) => 
            checkArr.indexOf(v.toString()) == -1
          );
          this.answerState = (targetIndex == -1) ? 1 : 0;
        }

        // 点击提交，显示反馈
        if(!!firstFeedback && !!canShowFeedback) {
          this.feedbackDialogSet.visible = true;
          this.firstFeedback = false;
        }
        return;
      }
      if(btnWhere == 1 && !isMultiple && selected !== -1 && answerState == -1) {
        // 点击提交，显示反馈
        if(!!firstFeedback && !!canShowFeedback) {
          this.feedbackDialogSet.visible = true;
          this.firstFeedback = false;
        }
        // 单选提交
        this.answerState = (selected == singleAnswer) ? 1 : 0;
        return;
      }
      if(canShowModelAnswer && btnWhere == 1 && !isMultiple && selected !== -1 && answerState == 0) {
        // 单选- 查看答案 - 隐藏答案
        //隐藏答案 - 查看用户答案
        if(this.showRightAnswer) {
          // this.selected = -1;
          this.selected = this.userAnswer.single;
          this.showRightAnswer = false;
          return;
        }
        // 显示答案
        this.selected = singleAnswer;
        this.showRightAnswer = true;
        return;
      }
      if(canShowModelAnswer && btnWhere == 1 && isMultiple && checkArr.length > 0 && answerState == 0) {
        // 多选- 查看答案 - 隐藏答案
        //隐藏答案 - 查看用户答案
        if(this.showRightAnswer) {
          // this.checkArr = [];
          this.checkArr = this.userAnswer.multiple;
          this.showRightAnswer = false;
          return;
        }
        // 显示答案
        this.checkArr = multipleAnswer;
        this.showRightAnswer = true;
        return;
      }

      if( btnWhere == 2 && answerState != -1 && !!canShowFeedback) {
        // 已答题，开启反馈，显示反馈弹窗
        this.feedbackDialogSet.visible = true;
        return;
      }
    },
    radioChange(val) {
      // 单选-选中改变
      this.selected = val;
      // 用户答案
      this.userAnswer.single = val;
    },
    checkChange(arr) {
      // 多选-选中改变
      this.checkArr = arr;
       // 用户答案
      this.userAnswer.multiple = arr;
    },
    openInnerDrawer({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value}) {
      // 显示\隐藏二层弹出窗口
      if(!visible || visible == 2) {
        this.innerDrawer.visible = false;
        return;
      }else{
        this.innerDrawer.form = this.initInnerForm(list);

        if(this.innerDrawer.visible == true) {
          this.innerDrawer.visible = false;
          setTimeout(() => {
            this.innerDrawer.visible = true;
          }, 220);
        }else {
          this.innerDrawer.visible = true;
        }
      }
      this.innerDrawer.submitAsObj = submitAsObj || false; //整体提交
      this.innerDrawer.key_name = key_name;
      this.innerDrawer.parentDetail = parentDetail;
      this.innerDrawer.title = title;
      this.innerDrawer.propList = list;
      this.innerDrawer.current = index;
      if(value) this.innerDrawer.form = value;
    },
    handleCopy({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value, component_target}) {
      if(component_target !== 'component-_items') return;

      let items = this.detail[key_name];
      let copyItem = JSON.parse(JSON.stringify(items[index]));
      if(copyItem) {
        // 复制一项
        items.push(copyItem);
        this.saveDetail({
          [key_name]: items
        }, false);
      }
    },
    handleDel({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value, component_target}) {
      if(component_target !== 'component-_items') return;

      let items = this.detail[key_name];
      let itemsLen = items.length;
      if(index < itemsLen && index >= 0) {
        // 删除
        items.splice(index,1);
        // 是否当前打开
        if(visible == 2) {
          this.closeInnerDrawer();
        }
        this.saveDetail({
          [key_name]: items
        }, false);
      }
    },
    initInnerForm(propList) {
      let form = {};
      Array.isArray(propList) && propList.forEach(v => {
        if(v.properties && Array.isArray(v.properties)) {
          if(form[v.input_name] === undefined) {
            form[v.input_name] = {};
            v.properties.forEach(element => {
              form[v.input_name][element.input_name] = "";
            });
          }
        }else if(v.display == 1){
          switch(v.input_type) {
            case "truefalse":
              form[v.input_name] = false;
              break;
            case "text":
              form[v.input_name] = "";
              break;
            default:
              break;
          }
        }
      });
      return form;
    },
    saveDetail(updateObj, isUpdateList) { //isUpdateList：是否刷新列表
      //保存组件详情
      this.SaveCourse({
        "page_key":this.componentName,"course_id":this.detail.course_id,
        "properties_data": updateObj,
        "block_id": this.detail['block_id'],
        "component_id": this.detail['component-id']
      }).then(res=>{
        if(res.errorcode == 0) {
          if(isUpdateList) this.$emit("update");
          if(res.data["component-_selectable"] !== undefined) this.detail["component-_selectable"] = res.data["component-_selectable"];
          if(updateObj.hasOwnProperty("course-customStyle")) {
            // 更新了自定义css
            this.SET_CUSTOM_CSS({ data: res.data["course-customStyle"], course_id: this.detail.course_id });
          }
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/
</style>