<template>
  <div>
    <el-row :gutter="9" class="flex prel margin_top32">
      <el-col class="flex1">
        <customButton @click="$emit('tapBtn',1)" :disabled="answerState == 1" 
          :color="answerState!=0?'#70ADC6':'#A1A1A1'" 
          :padding="singeBtnShowMarking?'5px 52px':'15px 52px'" width="100%" :roundPX="0">
          <div class="flex flex_acenter flex_center">
            <div v-if="singeBtnShowMarking"
              class="icon_status_inline" :class="{'isright':answerState == 1,'iserror':answerState == 0}">
              <i :class="{'icon-error_answer': answerState == 0,'icon-right_answer icon_color_fff': answerState == 1}" class="fontsize_18"></i>
            </div>
            <span class="fontsize_14" :class="{'margin_left16': singeBtnShowMarking }">{{leftBtnName}}</span>
          </div>
        </customButton>
      </el-col>
      <el-col  v-if="canShowFeedback" class="flex1">
        <customButton @click="$emit('tapBtn',2)" :disabled="answerState == -1" 
          :color="answerState==-1?'#A1A1A1':'#70ADC6'" padding="15px 52px" width="100%" :roundPX="0">
          <span class="fontsize_14">{{feedbackBtnName}}</span>
        </customButton>
      </el-col>
      <div v-if="canShowMarking && canShowFeedback && answerState != -1" class="icon_status" :class="{'isright':answerState == 1,'iserror':answerState == 0}" >
        <i :class="{'icon-error_answer': answerState == 0,'icon-right_answer icon_color_fff': answerState == 1}" class="fontsize_18"></i>
      </div>
    </el-row>
    <el-row v-if="shouldDisplayAttempts && answerState == -1" class="margin_top16">
      <p class="fontsize_12 color_A1A1A1">{{remainingAttemptsText}} {{attempts}}</p>
    </el-row>
  </div>
</template>

<script>
import customButton from "@/renderer/components/common/buttonTheme.vue";
export default {
  components: {customButton},
  props: {
    answerState:[Number, String],
    singeBtnShowMarking: Boolean,
    leftBtnName: String,
    feedbackBtnName: String,
    canShowMarking: Boolean,
    canShowFeedback: Boolean,
    shouldDisplayAttempts: Boolean,
    remainingAttemptsText: String,
    attempts: [Number, String],
  },
}
</script>

<style>

</style>