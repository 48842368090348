<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-blinds"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div class="blinds-inner component-inner">
          <div class="blinds-widget component-widget">
              <div class="blinds-container marginCenter" ref="blinds-container">
                  <div class="blinds-items clearfix" ref="blinds-items" @mouseleave="onMouseLeave($event)" :style="{'height':itemHeight+'px'}">
                      <div v-for="(item, bind) in optionsItem" :key="'blinds_'+bind" 
                        @click="onClick($event, bind)" @mouseenter="onMouseEnter($event,bind)"
                        class="blinds-item" ref="blinds-item" >
                          <div class="blinds-item-inner prel" :style="{
                            'background-image':'url('+item.src+')'
                          }">
                            <div class="blinds-captions">
                              <div  v-for="(citem, cind) in item._captions" :key="'captions_'+cind" 
                                ref="blinds-caption"
                                class="blinds-caption breakword"
                                :class="{'hide':hideCaption}"
                                :style="{
                                  'left': citem.left + 'px',
                                  'top': citem.top + 'px',
                                  'width': citem.width != 'auto' ? citem.width + 'px' : 'auto' 
                                }"
                                >{{citem.text || '请输入字幕'}}</div>
                            </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            :listItemSet="{'canDrag':true}"
            @closeOptionsModal="closeOptionsModal"
            @sortOptionList="handleSort"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_blinds_sub'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
                @addCaption="captionDialog"
                @editCaption="editCaption"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_blinds'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
      <fd-dialog
        custom-class="blinds-dialog"
        :visible.sync="blindsDialogSet.visible"
        destroy-on-close	
        width="100%"
        :modal-append-to-body="true"
        :append-to-body="true"
        :before-close="closeDialogSet"
        :style="containerStyle">
        <div class="blinds-popup-outer" 
          :class="[detail['component-_classes']]"
          >
          <!-- itemExpandedWidth - 4*2 + 'px' -->
          <div class="blinds-popup-main prel"
            :style="{ 'width': '800px', height: itemHeight + 'px',
              'background-size': 'contain',
              'background-position': 'center',
              'background-repeat': 'no-repeat',
              'background-image': blindsDialogSet.item && blindsDialogSet.item.src && 'url('+blindsDialogSet.item.src+')' || 'none',
            }">
            <div class="blinds-img-mask"></div>
            <dragCaption 
              :current="blindsDialogSet.captionIndex"
              @del="tapDelete"
              @change="changeCaption"
              :img="blindsDialogSet.item && blindsDialogSet.item.src || ''"
              :parentWidth="itemExpandedWidth - 4*2"
              :parentHeight="itemHeight"
              :list="blindsDialogSet.item._captions"></dragCaption>
          </div>
          <!-- itemExpandedWidth + 'px' -->
          <div class="blinds-popup-btns"
            :style="{ 'width': '800px' }">
            <button @click.stop="addCaption" class="caption_btn add">添加字幕</button>
            <button @click.stop="clearCaption(blindsDialogSet.current)" class="caption_btn remove">清除字幕</button>
            <button @click="closeDialogSet" class="caption_btn close">关闭</button>
          </div>
        </div>
      </fd-dialog>
      <fd-dialog
        append-to-body
        :title="delDialogDetail.title"
        :visible.sync="delDialogDetail.visible"
        width="600px"
        :before-close="handleCloseDel"
        custom-class="course-dialog not-full">
        <p class="fontsize_16 color_222 textcenter margin_bot16" style="padding-top:9px;">
          请确认是否{{ delDialogDetail.type==='deleteOne'? '删除该字幕：': '清除所有字幕'}}
          <span v-if="delDialogDetail.type==='deleteOne' && delDialogDetail.item">{{delDialogDetail.item.text}}</span>
        </p>
        <p class="fontsize_14 color_E74362 textcenter" style="padding-bottom:9px;">您确认要删除吗？删除之后将无法找回</p>
        <span slot="footer" class="dialog-footer">
          <button type="primary" @click="delCaption" class="course-save pointer">确定删除</button>
          <button @click="closeDelDialog" class="course-close pointer">不删了</button>
        </span>
      </fd-dialog>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import { forEach, bind } from 'lodash-es';
import dragCaption from "@/renderer/components/common/dragCaption.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import listCommon from '@/renderer/mixins/list/index';

export default {
  mixins: [hideModal, componentCommon, listCommon],
  components:{ topTools, rightForm, scaffoldForm, objForm, contentList, dragCaption },
  props: {
    containerStyle: Object,
  },
  data() {
    return {
      focusOfCaption:false,
      currentForm: {},
      componentName:"adapt-lianda-blinds",
      ckeditorPrefix:"componentBlinds",
      captionQueue: [],
      ENTER_DELAY: 150,
      ANIMATION_TIME: 400,
      CAPTION_DELAY: 600,
      queuedIndex: null,
      enterTimeout: null,
      hideCaption:false,
      isAnimating:false,
      itemWidth:0,
      itemExpandedWidth:0,
      itemCollapsedWidth:0,
      blindsDialogSet: {
        visible: false,
        item: {
          src:"https://oss.fei-dao.com/upload/tools/file/ef151be500e062f55932d138806fbcb3/2022-07/picture/fac478d94f7d957cd027d2b28359af9616570091773.png",
          top:10,
          left:20,
          width:200,
        },
        listLen: 0,
        current: 0, //第几个项- 父index
        captionIndex: -1, //第几条字幕
      },
      delDialogDetail: {
        title:"删除",
        visible: false,
        index:-1,
        item:{}
      },
    };
  },
  watch: {
    itemHeight: {
      immediate: true,
      handler(newVal) {
        if(newVal) {
          // 高度改变，重新计算宽度
          this.calculateWidths();
        }
      },
    },
    optionsItem: {
      deep: true,
      handler() {
        // 重新计算宽度
       this.$nextTick( () => {
         this.calculateWidths();
       });
      },
    },
  },
  computed: {
    itemHeight() {
      // 每一项高度 来自于参数数值
      if( this.detail["component-height"] ) return this.detail["component-height"]
      else 477
    },
    _expandBy() {
      return this.detail["component-_expandBy"] || 2
    },
    optionsItem() {
      return this.detail["component-_items"];
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("loaded");
      this.calculateWidths();
      // 监听窗口大小变化
      this.$el.addEventListener("resize", this.resizeEvent, true);
    });
  },
  destroyed() {
    this.$el.removeEventListener("resize", this.resizeEvent, true);
    this.closePop();
    clearTimeout(this.enterTimeout);
    clearTimeout(this.delayedEnterTimeout);
  },
  methods: {
    resizeEvent() {
      this.$nextTick(() => {
        this.calculateWidths();
      });
    },
    tapDelete({item, index}) {
      // 点击删除,确认弹窗
      this.delDialogDetail.type = "deleteOne"; 
      this.delDialogDetail.index = index;
      this.delDialogDetail.item = item;
      this.delDialogDetail.visible = true;
    },
    delCaption() {
      // 确认删除字幕
      let { item, index, type } = this.delDialogDetail;
      let { propName } = this.blindsDialogSet;
      let { current:parentIndex, key_name: parentProp} = this.innerDrawer;
      let items = JSON.parse( JSON.stringify( this.detail[parentProp] ) );
      let captions = items[parentIndex][propName];

      // 删除某个字幕
      if(type === "deleteOne") {
        if(captions) {
          captions.splice(index, 1);
          items[parentIndex][propName] = captions;
          this.blindsDialogSet.item = items[parentIndex];
        }
      }else if(type === "deleteAll") {
        // 清除字幕
        if(captions) {
          captions = [];
          items[parentIndex][propName] = captions;
          this.blindsDialogSet.item = items[parentIndex];
        }
      }

      if(this.innerDrawer.visible && this.innerDrawer.current >= 0) {
        this.innerDrawer.form = items[this.innerDrawer.current];
      }

      this.$set(this.detail, parentProp, items);
      // 发送删除请求
      this.saveDetail({
        [parentProp]: items
      }, false).then(res => {
        if(res.errorcode == 0) {
          this.$message.success("删除成功！");
          // 关闭弹窗
          this.closeDelDialog();
        }
      });
    },
    closeDelDialog() {
      this.delDialogDetail.visible = false;
      this.delDialogDetail.item = {};
      this.delDialogDetail.index = -1;
    },
    handleCloseDel(done) {
      done();
      this.delDialogDetail.visible = false;
      this.delDialogDetail.item = {};
      this.delDialogDetail.index = -1;
    },
    changeCaption({list}) {
      // 字幕位置/内容改变
      let { current: parentIndex, key_name: parentProp } = this.innerDrawer;
      
      let items = JSON.parse( JSON.stringify( this.detail[parentProp] ) );
      let { propName } = this.blindsDialogSet;
      items[parentIndex][propName] = list;

      if(this.innerDrawer.visible && this.innerDrawer.current >= 0) {
        this.innerDrawer.form = items[this.innerDrawer.current];
      }

      this.$set(this.detail, parentProp, items);
      this.saveDetail({
        [parentProp]: items
      }, false);
    },
    focusCaption() {
      this.focusOfCaption = true;
    },
    blurCaption() {
      this.focusOfCaption = false;
    },
    addCaption() {
      // 添加字幕
      let { key_name:parentProp, current } = this.innerDrawer;
      let items = JSON.parse( JSON.stringify( this.detail[parentProp] ) );
      let { propName, item: captionDetail } = this.blindsDialogSet;
      let captions = items[current][propName];
      let len = captions.length;

      captions.push({
        text:"",
        top:60 * (len + 1),
        left:20,
        width:150,
      });
      items[current][propName] = captions;
      captionDetail[propName] = captions;
      
      if(this.innerDrawer.visible && current >= 0) {
        this.innerDrawer.form = items[current];
      }

      this.$set(this.blindsDialogSet, 'item', captionDetail);
      this.$set(this.detail, parentProp, items);
      this.saveDetail({
        [parentProp]: items
      }, false);
    },
    clearCaption(index) {
      // 清除字幕
      this.delDialogDetail.type = "deleteAll";
      this.delDialogDetail.index = index;
      this.delDialogDetail.item = {};
      this.delDialogDetail.visible = true;
    },
    closeDialogSet(done) {
      this.blindsDialogSet.visible = false;
      done && typeof done == 'function' && done();
    },
    captionDialog({value, propName, form}) {
      // 显示字幕弹窗
      let { src, _captions } = this.innerDrawer.form;
      this.blindsDialogSet.item = {
        src,
        _captions: value
      };
      this.blindsDialogSet.captionIndex = value && value.length-1 || 0;
      this.blindsDialogSet.propName = propName;
      this.blindsDialogSet.visible = true;
    },
    editCaption({value, index, propName, form}) {
      // 编辑某一项字幕
      let { src, _captions } = this.innerDrawer.form;
      this.blindsDialogSet.item = {
        src,
        _captions: value
      };
      this.blindsDialogSet.captionIndex = index || 0;
      this.blindsDialogSet.propName = propName;
      this.blindsDialogSet.visible = true;
    },
    onMouseEnter(e,index) {
      //移入blind-item
      this.queuedIndex = null;

      this.hideCaptions();
      clearTimeout(this.enterTimeout);

      if (this.isAnimating) {
        this.queuedIndex = index;
      } else {
        this.enterTimeout = setTimeout(() => {
          this.expandBlind(index);
        }, this.ENTER_DELAY)
      }
    },
    onMouseLeave() {
      //移出blind-items
      clearTimeout(this.enterTimeout);
      clearTimeout(this.delayedEnterTimeout);
      this.queuedIndex = null;
      this.isAnimating = false;
      this.hideCaptions();
      this.$refs["blinds-item"].forEach( bitem => {
        bitem.style.width = this.itemWidth + "px";
      } );
    },
    onClick(e, index) {},
    hideCaptions() {
      forEach(this.captionQueue, clearTimeout);
      this.$refs["blinds-caption"].forEach( bitem => {
        bitem.style.visibility = "hidden";
        bitem.style.opacity = 0;
      } );
      // this.hideCaption = true;
    },
    expandBlind(index) {
      // 展开
      this.isAnimating = true;
      var $blind = this.getElement(index);
      var $siblings = this.getSiblings(index,$blind);
      
      $blind.style.width = this.itemExpandedWidth + "px";
      $siblings.forEach( sitem => {
        sitem.style.width = this.itemCollapsedWidth + "px";
      });
      this.displayCaptions(index, this.ANIMATION_TIME + this.ENTER_DELAY);

      this.delayedEnterTimeout = setTimeout(() => {
        this.isAnimating = false;
        if (typeof this.queuedIndex === "number") {
            this.expandBlind(this.queuedIndex);
            this.queuedIndex = null;
        }
      }, this.ANIMATION_TIME + 200);
    },
    getElement: function(index) {
      return this.$refs["blinds-item"][index]
    },
    getSiblings: function(index) {
      let blinds = this.$refs["blinds-item"];
      return blinds.filter( (v,i) => i != index ) || [];
    },
    displayCaptions: function(index, delay) {
      var blind = this.getElement(index);
      var captions = blind.querySelectorAll(".blinds-caption");
      captions.forEach( ctiem => {
        ctiem.style.visibility = "visible";
        ctiem.style.opacity = 1;
      } );

      forEach(this.captionQueue, clearTimeout);
    },
    calculateWidths: function() {
      let blinds = this.$refs['blinds-item'];

      if (this.itemHeight) {
          if( blinds && Array.isArray( blinds ) ) {
            blinds.forEach( bitem => {
              bitem.style.height = this.itemHeight + "px";
            } );
          }
      }

      if(!this.$refs['blinds-container']) return;
      let wTotal = this.$refs['blinds-container'].offsetWidth;
      this.itemWidth = wTotal / blinds.length;
      this.itemExpandedWidth = this.itemWidth * this._expandBy;
      this.itemCollapsedWidth = (wTotal - this.itemExpandedWidth) / (blinds.length - 1);

      if( blinds && Array.isArray( blinds ) ) {
        blinds.forEach( bitem => {
          bitem.style.width = this.itemWidth + "px";
        } );
      }
    },
    closeInnerDrawer(isBtn = 2) {
      this.innerDrawer.visible = false;
      // this.innerForm = {};
    },
    saveDetail(updateObj, isUpdateList) { //isUpdateList：是否刷新列表
      //保存组件详情
      return new Promise((resolve, reject) => {
        this.SaveCourse({
          "page_key":this.componentName,"course_id":this.detail.course_id,
          "properties_data": updateObj,
          "block_id": this.detail['block_id'],
          "component_id": this.detail['component-id']
        }).then(res=>{
          resolve(res);
          if(res.errorcode == 0) {
            if(isUpdateList) this.$emit("update");
            if(updateObj.hasOwnProperty("course-customStyle")) {
              // 更新了自定义css
              this.SET_CUSTOM_CSS({ data: res.data["course-customStyle"], course_id: this.detail.course_id });
            }
          }
        }).catch(err => {
          reject(err);
        });
      });
    },
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/

  .blinds-inner {
    padding-top: 16px;
    * {
      box-sizing: border-box;
    }

    .blinds-widget {
      margin: 0 -4px;
    }

    .blinds-container {
      overflow: hidden;
    }

    .blinds-items {
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }

	.blinds-item {
    display: inline-block;
		position: relative;
		height: 500px;
		-webkit-transition: width 0.4s;
		-moz-transition: width 0.4s;
		-ms-transition: width 0.4s;
		-o-transition: width 0.4s;
		transition: width 0.4s;

      .blinds-item-inner {
          overflow: hidden;
          height: 100%;
          padding: 0 12px;
          margin: 0 4px;
          // -webkit-background-size: auto 100%;
          // background-size: auto 100%;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
      }

      .blinds-captions {
          // position: relative;
      }

      .blinds-caption {
          opacity: 0;
          position: absolute;
          margin: 0;
          margin-left: 12px; //校准左边三角形
          padding: 10px 8px;
          line-height: 20px; //跟字幕弹窗的保持一致
          font-size: 14px;
          color: #FFFFFF;
          background-color: rgba(0,0,0,0.60);
          border-radius: 4px;
          white-space: normal;
          -webkit-transition: opacity 0.4s;
          -moz-transition: opacity 0.4s;
          -ms-transition: opacity 0.4s;
          -o-transition: opacity 0.4s;
          transition: opacity 0.4s;
          &::before {
            display: block;
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translate(-100%, -50%);
            border-width: 6px;
            border-left-width: 12px;
            border-right-width: 12px;
            border-style: solid;
            border-color: transparent;
            border-right-color: rgba(0,0,0,0.60);
          }
          &.hide {
            visibility: hidden;
            // opacity: 0;
          }
      }

      html.size-large & {
          display: inline-block;
      }

      html.size-medium &, html.size-small & {
          width: auto !important;
          margin-bottom: 10px;
      }
	}
}
  /deep/.blinds-dialog {
    box-sizing: border-box;
    height: 100%;
    padding: 25vh 0 50px;
    margin-top: 0 !important;
    margin-bottom: 0;
    background-color: rgba(0,0,0,0.40);
    box-shadow: none;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
    }
  }
  // 字幕弹窗
  .blinds-popup-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .blinds-popup-main {
    display: flex;
    justify-content: center;
    // background-color: #FFFFFF;
    background-color: rgba(0,0,0,0.7);
  }
  .blinds-img-mask {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(0,0,0,0.40);
  }
  .blinds-popup-btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    background-color: #FFFFFF;
    .caption_btn {
      box-sizing: border-box;
      border-radius: 14.5px;
      border: 1px solid transparent;
      width: 76px;
      padding: 6px 0;
      line-height: 18px;
      font-size: 12px;
      color: #FFFFFF;
      background-color: transparent;
      text-align: center;
      cursor: pointer;
      &.add {
        border-color: #E74362;
        color: #FFFFFF;
        background-color: #E74362;
        &:hover {
          border-color: #B5102F;
          background-color: #B5102F;
        }
      }
      &.remove {
        margin-left: 47px;
        border-color: #FF4D4F;
        color: #E74362;
        background-color: #FFFFFF;
        &:hover {
          background-color: #FFE6E6;
        }
      }
      &.close {
        margin-left: 47px;
        border-color: #D9D9D9;
        color: #8C8C8C;
        background-color: #FFFFFF;
        &:hover {
          border-color: rgba(0,0,0,0.80);
          color: #FFFFFF;
          background-color: rgba(0,0,0,0.80);
        }
      }
    }
  }
</style>