<template>
  <topTools
    type="right"
    rightTips="组件"
    :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true, 'moveInline':moveInline}"
    :isHighlight="isHighlight"
    class="component-container component-hotgird"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit"
    @del="delComponent"
    @copy="handleCopying"
    @moveInline="handleMoveInline"
  >
    <contentList
      :list="list"
      :currentForm="currentForm"
      :getProgressStatus="getProgressStatus"
      :ckeditorPrefix="ckeditorPrefix"
      :canKeyEnter="canKeyEnter"
      :throttleDelay="throttleDelay"
      @input="handleInput"
      @blur="formItemBlur"
      @blurNotUpdate="formItemBlur"
    ></contentList>
    <div
      class="component__inner hotgrid__inner"
      :style="{
        '--column-nums': columns
      }"
    >
      <div class="component__widget hotgrid__widget">
        <div class="hotgrid__grid" role="list">
          <div
            v-for="(oitem, oind) in optionsItem"
            :key="'hotgrid_'+oind"
            class="hotgrid__item"
            role="listitem"
            :style="{
                'width': itemWidth,
              }"
          >
            <button
              @click.stop="onItemClicked($event, oind)"
              class="hotgrid__item-btn js-hotgrid-item-click image-state"
              :data-index="oind"
              :class="[
                    oitem._graphic.src  ? 'is-image' : 'is-css',
                    'item-'+oind,
                    {
                      'is-round': !!detail['component-_isRound'],
                      'is-visited': !!imgState[oind]._isVisited,
                    }
                  ]"
            >
              <span
                class="aria-label"
              >{{oitem._graphic.title ? oitem._graphic.title : ''+oind}}.{{oitem._graphic.alt ? oitem._graphic.alt + '.' : ''}}</span>

              <div class="hotgrid__item-btn-inner" aria-hidden="true">
                <div class="hotgrid__item-image-container">
                  <img class="hotgrid__item-image is-default" :src="oitem._graphic.src" :class="oitem._graphic.src?'':'is-emptyImg'"/>
                  <img
                    class="hotgrid__item-image is-hover"
                    :src="oitem._graphic.srcHover || oitem._graphic.src"
                  />
                  <img class="hotgrid__item-image is-visited"
                    :src="oitem._graphic.srcVisited || oitem._graphic.src"
                  />
                </div>
                <div v-if="oitem._graphic.title" class="hotgrid__item-title" aria-hidden="true">
                  <div class="hotgrid__item-title-inner">{{oitem._graphic.title}}</div>
                </div>
                <!-- <div v-else class="btn-icon hotgrid__item-icon">
                    <div class="icon el-icon-plus"></div>
                </div>-->
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>

    <fd-drawer
      destroy-on-close
      :title="drawerSet.title"
      :visible.sync="drawerSet.visible"
      :direction="drawerSet.direction"
      :before-close="handleClose"
      :size="479"
      custom-class="drawer-right not-header"
      :with-header="false"
      append-to-body
      @open="openDrawer"
      @closed="closeDrawer"
      :modal="false"
    >
      <div class="drawer-right-inner prel bgc_fff">
        <div
          class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header"
          style="z-index:1;"
        >
          <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
          <i
            class="icon-close-popup header-close-btn pointer fontsize_14 color_333"
            @click="closeOuterDrawer"
          ></i>
        </div>
        <rightForm
          ref="rightForm"
          class="drawer-right-inner-form"
          :ruleForm="detail"
          :tabList="tabList"
          :currentTab="drawerSet.activeName"
          :ckeditorNameId="'component-id'"
          :ckeditorPrefix="ckeditorPrefix"
          :listItemSet="{'canDrag':true}"
          @closeOptionsModal="closeOptionsModal"
          @sortOptionList="handleSort"
          @open="openInnerDrawer"
          @close="closeInnerDrawer"
          @copy="handleCopy"
          @del="handleDel"
          @selectChange="selectChange"
          @formItemInput="formItemInput"
          @formItemBlur="formItemBlur"
        ></rightForm>
        <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
          <div class="right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14">
            <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
            <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop"></i>
          </div>
          <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
            <objForm
              v-if="innerDrawer.visible && innerDrawer.submitAsObj"
              :titleFontSize="14"
              :scrollBoxPaddingLR="16"
              :ruleForm="innerDrawer.form"
              :propList="innerDrawer.propList"
              :ckeditorNameId="'component-_hotgird_sub'+innerDrawer.current"
              :current="innerDrawer.current"
              @selectChange="objFormChange"
              @formItemBlur="objFormChange"
            ></objForm>
            <scaffoldForm
              v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
              :titleFontSize="14"
              :scrollBoxPaddingLR="16"
              :ruleForm="detail"
              :propList="innerDrawer.propList"
              :ckeditorNameId="'component-_hotgird'"
              @selectChange="formChangeBlur"
              @formItemBlur="formChangeBlur"
            ></scaffoldForm>
          </div>
        </div>
      </div>
    </fd-drawer>
    <fd-dialog
      custom-class="hotgraphic-dialog"
      :visible.sync="hotgridDialogSet.visible"
      width="100%"
      :close-on-click-modal="false"
      :modal-append-to-body="true"
      :append-to-body="true"
      :before-close="closeDialogSet"
      :style="containerStyle"
    >
      <div class="hotgrid-popup-outer">
        <div
          class="hotgraphic-popup-inner prel"
          v-if="_itemsLen > 0"
          :class="[detail['component-_classes']]"
        >
          <button
            @click="closeDialogSet"
            class="hotgraphic-popup__close js-hotgraphic-popup-close pointer"
            aria-label="Close popup"
          >
            <!-- <div class="el-icon-close fontsize_24 color_fff"></div> -->
            关闭
          </button>
          <div
            class="hotgraphic-popup__item is-visited is-round is-active flex flex_acenter"
            data-index="0"
          >
            <div class="hotgraphic-popup__item-content">
              <div class="hotgraphic-popup__item-content-inner">
                <div class="hotgraphic-popup__item-body">
                  <div class="hotgraphic-popup__item-body-inner">
                    <p
                      class="fontsize_18 hotgraphic-popup__item-body-title"
                      v-html="optionsItem[hotgridDialogSet.current].title"
                    ></p>
                    <p
                      class="fontsize_14 hotgraphic-popup__item-body-content"
                      v-html="optionsItem[hotgridDialogSet.current].body"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="hotgraphic-popup__item-image-container prel">
              <img
                v-if="optionsItem[hotgridDialogSet.current]._itemGraphic && optionsItem[hotgridDialogSet.current]._itemGraphic.src 
                  || optionsItem[hotgridDialogSet.current]._graphic && optionsItem[hotgridDialogSet.current]._graphic.src"
                loading="lazy"
                class="hotgraphic-popup__item-image"
                :class="{'is-round': !!detail['component-_isRound']}"
                :src="( optionsItem[hotgridDialogSet.current]._itemGraphic.src || optionsItem[hotgridDialogSet.current]._graphic.src ) 
                    +'?x-oss-process=style/s'"
                aria-hidden="true"
              />
              <span
                v-if="optionsItem[hotgridDialogSet.current]._itemGraphic && optionsItem[hotgridDialogSet.current]._itemGraphic.attribution"
                class="component__attribution"
              >{{optionsItem[hotgridDialogSet.current]._itemGraphic.attribution}}</span>
            </div>
          </div>
          <div
            v-if="!hidePagination"
            @click="tapHotArrow('back')"
            class="arrow-last"
            :class="{'disabled': !canCycleThroughPagination && hotgridDialogSet.current == 0}"
          ></div>
          <div
            v-if="!hidePagination"
            @click="tapHotArrow('next')"
            class="arrow-next"
            :class="{'disabled': !canCycleThroughPagination && hotgridDialogSet.current >= (optionsItem.length - 1)}"
          ></div>
          <div v-if="!hidePagination" class="nav-pagination-dot">
            <div
              v-for="(_, nind) in optionsItem"
              :key="nind"
              @click="tapNav(nind)"
              class="nav-dot"
              :class="{'active': hotgridDialogSet.current == nind, 'small': _itemsLen>10}"
            ></div>
          </div>
        </div>
      </div>
    </fd-dialog>
  </topTools>
</template>

<script>
import topTools from "@/renderer/components/common/topTools.vue";
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import hideModal from "@/renderer/mixins/popup/hideModal";
import componentCommon from "@/renderer/mixins/component/index";
import listCommon from "@/renderer/mixins/list/index";

/**
 * 热点网格
 */
export default {
  mixins: [hideModal, componentCommon, listCommon],
  components: { topTools, rightForm, scaffoldForm, contentList, objForm },
  props: {
    containerStyle: Object,
  },
  data() {
    return {
      currentForm: {},
      componentName: "adapt-lianda-hotgrid",
      ckeditorPrefix: "componentHotgrid",
      popupView: null,
      _isPopupOpen: false,
      imgState: {},
      hotgridDialogSet: {
        visible: false,
        item: {},
        listLen: 0,
        current: 0,
      },
    };
  },
  watch: {},
  computed: {
    optionsItem() {
      let optionList = this.detail["component-_items"];
      optionList.forEach((v, ind) => {
        this.imgState[ind] = {
          _isVisited: false,
          _isActive: false,
        };
      });
      return optionList;
    },
    _itemsLen() {
      return this.optionsItem.length || 0;
    },
    canCycleThroughPagination() {
      return this.detail["component-_canCycleThroughPagination"];
    },
    hidePagination() {
      return this.detail["component-_hidePagination"];
    },
    columns() {
      return this.currentForm["component-_columns"];
    },
    itemWidth() {
      return this.columns ? 100 / this.columns + "%" : "auto";
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("loaded");
      this.setUpViewData();
      this.setUpModelData();
    });
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    closeDialogSet(done) {
      this.hotgridDialogSet.visible = false;
      done && typeof done == "function" && done();
    },
    setUpViewData: function () {
      this.popupView = null;
      this._isPopupOpen = false;
    },
    setUpModelData: function () {
      if (this.detail["component-_canCycleThroughPagination"] === undefined) {
        this.detail["component-_canCycleThroughPagination"] = false;
      }
    },
    onItemClicked: function (event, index) {
      if (event) event.preventDefault();

      this.$set(this.imgState[index], "_isVisited", true);
      this.$set(this.imgState[index], "_isActive", true);
      this.$forceUpdate();

      this.openPopup(index);
    },
    openPopup: function (index) {
      if (this.hotgridDialogSet.visible) return;
      this.hotgridDialogSet.visible = true;
      this.hotgridDialogSet.current = index;
    },
    onPopupClosed: function () {
      this._isPopupOpen = false;
    },
    tapHotArrow(direction) {
      let current = this.hotgridDialogSet.current;
      let listLen = this._itemsLen;
      if (direction === "back" && current > 0) this.hotgridDialogSet.current--;
      else if (direction === "back" && !!this.canCycleThroughPagination)
        this.hotgridDialogSet.current = listLen - 1;

      if (direction === "next" && current < listLen - 1)
        this.hotgridDialogSet.current++;
      else if (direction === "next" && !!this.canCycleThroughPagination)
        this.hotgridDialogSet.current = 0;
    },
    tapNav(index) {
      let current = this.hotgridDialogSet.current;
      if (index == current) return;

      this.hotgridDialogSet.current = index;
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/component.less"; /*引入公共组件样式*/

// 热点网格
.hotgrid__inner {
  margin-top: 16px;
}
.hotgrid__item-title-inner {
  text-align: center;
}
.hotgrid__item-title {
  text-align: left;
  &:not(:hover) .hotgrid__item-title-inner {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.hotgrid {
  &__grid {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__item {
    margin-bottom: 5px;
    width: 50%;
    line-height: 0;
    &:hover {
      .hotgrid__item-title {
        background-color: rgba(0, 0, 0, 1);
      }
    }
  }

  &__item-btn {
    border: none;
    width: 100%;
    background-color: transparent;
  }

  &__item-btn.is-round &__item-image {
    border-radius: 50%;
  }

  &__item-image {
    display: block;
    max-width: 100%;
  }

  &__item-icon .icon {
    // .el-icon-plus;
  }

  // Application of item image states
  // --------------------------------------------------
  &__item-btn{
    .image-state.is-default;

    .no-touch &:hover {
      .image-state.is-hover;
      // border: 1px solid red;
    }
  }

  &__item-btn.is-image.is-visited {
    .image-state.is-visited;
  }
}

// Hotgrid item image states
// --------------------------------------------------
.item-state-mixin(default, hover, visited);
.item-state-mixin(hover, default, visited);
.item-state-mixin(visited, default, hover);

.item-state-mixin(@active-state, @inactive-state-one, @inactive-state-two) {
  .image-state.is-@{active-state} {
    .hotgrid__item-image.is-@{active-state} {
      display: block;
    }

    .hotgrid__item-image.is-@{inactive-state-one},
    .hotgrid__item-image.is-@{inactive-state-two} {
      display: none;
    }
  }
}

.image-state:hover {
  .hotgrid__item-image.is-hover {
    display: block;
  }

  .hotgrid__item-image.is-visited,
  .hotgrid__item-image.is-default {
    display: none;
  }
}
// --------------------------------------------------
// THEME
// --------------------------------------------------
.hotgrid {
  &__item-btn {
    padding: 0;
    position: relative;
    cursor: pointer;
  }
  &__item-image.is-default.is-emptyImg{
    min-height: 278.67px;
  }
  &__item-btn.is-round &__item-title {
    margin-top: 10px;
  }

  &__item-btn-inner {
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    margin-inline: 2.5px;
  }

  &__item-title {
    padding: 13px 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &__item-icon {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 50%;
  }

  // Hotgrid item theme
  // --------------------------------------------------
  &__item-btn &__item-title,
  &__item-btn &__item-icon {
    // background-color: var(--item-color);
    font-size: 16px;
    color: var(--item-color-inverted);
    background-color: rgba(0, 0, 0, 0.4);
  }

  .no-touch &__item-btn:hover &__item-title,
  .no-touch &__item-btn:hover &__item-icon {
    background-color: var(--item-color-hover);
    color: var(--item-color-inverted-hover);
    transition: background-color 500ms ease-in, color 500ms ease-in;
  }

  &__item-btn.is-visited &__item-title,
  &__item-btn.is-visited &__item-icon {
    // background-color: var(--visited);
    color: var(--visited-inverted);
  }

  // Hotgrid CSS border states
  // --------------------------------------------------
  &__item-btn.is-css &__item-image {
    // border: 3px solid var(--item-color);
  }

  .no-touch &__item-btn.is-css:hover &__item-image {
    border-color: var(--item-color-hover);
    transition: border-color 500ms ease-in;
  }

  &__item-btn.is-visited.is-css &__item-image {
    border-color: var(--visited);
  }
}
// 弹窗
/deep/.hotgraphic-dialog {
  background-color: transparent !important;
  box-shadow: none;
}
.hotgrid-popup-outer {
  background-color: transparent;
}
.hotgraphic-popup-inner {
  border-radius: 4px;
  position: relative;
  margin: 0 auto;
  padding: 4rem 32px;
  max-width: 56.25rem;
  background-color: var(--notify);
  color: var(--notify-inverted);
  p{
    color: var(--notify-inverted);
  }
  .hotgraphic-popup__inner {
    padding-top: 4rem;
  }
  .hotgraphic-popup__count {
    padding: 1rem;
    color: var(--notify-inverted);
  }
  .btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    width: 40px;
    height: 40px;
    // background-color: transparent;
    background-color: var(--notify-btn);
    color: var(--notify-btn-inverted);
    cursor: pointer;
    &:hover {
      // background-color: rgba(0, 0, 0, 0.15);
      color: var(--notify-btn-inverted-hover);
      background-color: var(--notify-btn-hover);
    }
    &.disabled {
      opacity: 0;
    }
  }
  .hotgraphic-popup__controls,
  .hotgraphic-popup__close {
    position: absolute;
    right: 0;
    top: 0;
    box-sizing: border-box;
    border-radius: 0 4px 0 4px;
    padding: 6px 21px;
    height: 30px;
    font-size: 12px;
    color: #ffffff;
    background-color: #636363;
    border: none;
  }
  .hotgraphic-popup__close {
    color: var(--notify-icon-inverted);
    background-color: var(--notify-icon);
    transition: all 0.3s;
    &:hover {
      color: var(--notify-icon-inverted-hover);
      background-color: var(--notify-icon-hover);
    }
  }
  .hotgraphic-popup__item-content {
    width: 60%;
  }
  .hotgraphic-popup__item-image-container {
    position: relative;
    overflow: hidden;
    width: 40%;
    margin-left: 24px;
    border-radius: 4px;
    overflow: hidden;
    .hotgraphic-popup__item-image {
      width: 100%;
      display: block;
      &.is-round {
        border-radius: 50%;
      }
    }
    .component__attribution {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      // min-height: 48px;
      padding: 13px 16px;
      text-align: center;
      line-height: 22px;
      font-size: 16px;
      color: #ffffff;
      background: rgba(0, 0, 0, 0.4);
    }
  }
  .hotgraphic-popup__item-body {
    margin-bottom: 1rem;
    color: var(--notify-inverted);
    p{
      color: var(--notify-inverted);
    }
  }
  .hotgraphic-popup__item-body-title {
    margin-bottom: 12px;
    font-weight: 500;
    color: #262626;
  }
  .hotgraphic-popup__item-body-content {
    line-height: 25px;
  }
}
.arrow-last,
.arrow-next {
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  background-size: contain;
  cursor: pointer;
  &.disabled {
    cursor: auto;
    opacity: 0;
  }
}
.arrow-last {
  left: -46px;
  transform: translate(-100%, -50%);
  background-image: url(~@/assets/other/pagination_left.svg);
  mask: url(~@/assets/other/pagination_left.svg);
}
.arrow-next {
  right: -46px;
  transform: translate(100%, -50%);
  background-image: url(~@/assets/other/pagination_right.svg);
  mask: url(~@/assets/other/pagination_right.svg);
}
.nav-pagination-dot {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
  padding: 24px 0;
  .nav-dot {
    margin-inline: 4px;
    border-radius: 5px;
    width: 42px;
    height: 4px;
    background-color: #8c8c8c;
    transition: all 0.3s;
    cursor: pointer;
    &:hover,
    &.active {
      background-color: #fff;
    }
    &.small {
      width: 30px;
    }
  }
}
.hotgraphic_img {
  width: 33.3%;
  .img {
    display: block;
    width: 100%;
    object-fit: contain;
  }
}
</style>