<template>
    <div class="topTools-container prel" :class="{ 'highlight-block': isHighlight, 'aloneBlock': type != 'left', 'darkBlock': type == 'left' }" v-bind="$attrs">
        <div class="topTools-editor position-center flex flex_acenter fontsize_12" v-if="type == 'center' || type == 'centerOne'">
            <div class="topTools-editor-item textcenter pointer prel" :class="{'only': type === 'centerOne'}" @click.stop="clickTools('edit')">
                <img src="@/assets/top_tool/edit.png" alt="" class="editor-img">
                <div class="hover-tips">{{$t('lang.edit')}}</div>
            </div>
            <div class="topTools-editor-item pointer prel" v-if="type === 'center'" @click.stop="clickTools('add')" >
                <img src="@/assets/top_tool/add_up.png" alt="" class="editor-img">
                <div class="hover-tips">添加章节于上方</div>
            </div>
            <div class="topTools-editor-item textcenter pointer prel"  v-if="type === 'center'" @click.stop="clickTools('copy')">
                <img src="@/assets/top_tool/copy.png" alt="" class="editor-img">
                <div class="hover-tips">{{$t('lang.copying')}}</div>
            </div>
            <div v-if="iconShowDetail.display" 
              class="topTools-editor-item textcenter pointer prel" 
              :class="{'only': type === 'centerOne','eye-hide':!eyeShow}" 
              @click.stop="clickTools('display')">
                <img v-if="eyeShow" src="@/assets/top_tool/content_show.png" alt="" class="editor-img">
                <img v-else src="@/assets/top_tool/content_hide.png" alt="" class="editor-img">
                <div class="hover-tips">{{eyeShow ? $t('lang.display') : $t('lang.hide')}}</div>
            </div>
            <div class="topTools-editor-item pointer prel" v-if="type === 'center' && iconShowDetail.moveUp" @click.stop="clickTools('moveUp')">
                <img src="@/assets/top_tool/order-up.png" alt="" class="editor-img">
                <div class="hover-tips">{{$t('lang.moveup')}}</div>
            </div>
            <div class="topTools-editor-item pointer prel" v-if="type === 'center' && iconShowDetail.moveDown" @click.stop="clickTools('moveDown')">
                <img src="@/assets/top_tool/order-down.png" alt="" class="editor-img">
                <div class="hover-tips">{{$t('lang.movedown')}}</div>
            </div>
            <div class="topTools-editor-item pointer prel" v-if="type === 'center'" @click.stop="clickTools('del')">
                <img src="@/assets/top_tool/delete.png" alt="" class="editor-img">
                <div class="hover-tips">{{$t('lang.delete')}}</div>
            </div>
        </div>
        <div class="topTools-editor flex fontsize_12 flex_acenter"  :class="[type == 'left' ? 'position-left': 'position-right']" v-else-if="type == 'left' || type == 'right'">
            <div v-if="iconShowDetail.edit" class="position-left-item pointer prel"  @click.stop="clickTools('edit')" >
                <img src="@/assets/top_tool/edit.png" alt="" class="editor-img">
                <div class="hover-tips">{{$t('lang.edit')}}</div>
            </div>
            <div  v-if="iconShowDetail.add" class="position-left-item pointer prel" @click.stop="clickTools('add')" >
                <img src="@/assets/top_tool/add_up.png" alt="" class="editor-img">
                <div class="hover-tips">添加{{rightTips}}于上方</div>
            </div>
            <div v-if="type == 'right' && iconShowDetail.reset" class="position-left-item pointer prel flex flex_acenter flex_center" @click.stop="clickTools('reset')">
                <img src="@/assets/top_tool/content_reset.png" alt="" class="editor-img">
                <div class="hover-tips">{{$t('lang.reset')}}</div>
            </div>
            <div class="position-left-item pointer prel" @click.stop="clickTools('copy')">
                <img src="@/assets/top_tool/copy.png" alt="" class="editor-img">
                <div class="hover-tips">{{$t('lang.copying')}}</div>
            </div>
            <div v-if="iconShowDetail.display" class="position-left-item pointer prel" :class="{'eye-hide':!eyeShow}"  @click.stop="clickTools('display')" >
                <img v-if="eyeShow" src="@/assets/top_tool/content_show.png" alt="" class="editor-img">
                <img v-else src="@/assets/top_tool/content_hide.png" alt="" class="editor-img">
                <div class="hover-tips">{{eyeShow ? $t('lang.display') : $t('lang.hide')}}</div>
            </div>
            <div v-if="type == 'left' && iconShowDetail.moveUp" class="position-left-item pointer prel" @click.stop="clickTools('moveUp')">
                <img src="@/assets/top_tool/order-up.png" alt="" class="editor-img">
                <div class="hover-tips">{{$t('lang.moveup')}}</div>
            </div>
            <div v-if="type == 'left' && iconShowDetail.moveDown" class="position-left-item pointer prel" @click.stop="clickTools('moveDown')">
                <img src="@/assets/top_tool/order-down.png" alt="" class="editor-img">
                <div class="hover-tips">{{$t('lang.movedown')}}</div>
            </div>
            <div v-if="type == 'right' && iconShowDetail.moveInline" class="position-left-item pointer prel" @click.stop="clickTools('moveInline')">
                <img src="@/assets/top_tool/order-inline.png" alt="" class="editor-img">
                <div class="hover-tips">{{$t('lang.move')}}</div>
            </div>
            <div v-if="iconShowDetail.delete" class="position-left-item pointer prel"  @click.stop="clickTools('del')" >
                <img src="@/assets/top_tool/delete.png" alt="" class="editor-img">
                <div class="hover-tips">{{$t('lang.delete')}}</div>
            </div>
        </div>
        <div v-if="iconShowDetail.hideStatus && !eyeShow && rightTips != '组件'" class="topTools-show-status">
          <i class="icon-always_hide_gray fontsize_16"></i>
        </div>
        <div v-if="iconShowDetail.hideStatus && !eyeShow && rightTips == '组件'" class="topTools-component-status">
          <i class="icon-always_hide_gray fontsize_16"></i>
        </div>
        <div v-if="rightTips" class="topTools-type-tips" :class="{'left': rightTips == '组件', 'right': rightTips != '组件'  }">{{componentTitle || rightTips}}</div>
        <!-- 四个边框 -->
        <div class="borderOnlySide left"></div>
        <div class="borderOnlySide top"></div>
        <div class="borderOnlySide right"></div>
        <div class="borderOnlySide bottom"></div>
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        type: {
          type: String,
          default: 'center',
          validator: type => ['center','right','centerOne','left'].includes( type )
        },
        detail: {
            type: Object,
            default: function() {
                return {
                    id: null,
                };
            }
        },
        isHighlight: Boolean,
        rightTips: String, //右上角提示文字
        componentTitle: String,
        iconShowDetail: {
           type: Object,
            default: function() {
                return {
                    add: false,
                    edit: false,
                    delete: false,
                    display: false,
                    hideStatus: false,
                    reset: false,
                    moveUp:false,
                    moveDown:false,
                };
            }
        },
        eyeShow: false, //iconShowDetail.display 显示的情况下， 显示还是隐藏
    },
    data() {
        return {};
    },
    methods: {
      clickTools(type) {
          console.log("clickTools", type)
          switch(type) {
              case "edit":
                this.$emit('edit')
                break;
              case "add":
                this.$emit('add')
                break;
              case "del":
                this.$emit('del')
                break;
              case "display":
                // this.eyeShow = !this.eyeShow;
                this.$emit("switchDisplay", !this.eyeShow);
                break;
              case "reset":
                this.$emit("reset")
                break;
              case "moveUp":
                this.$emit("moveUp")
                break;
              case "moveDown":
                this.$emit("moveDown")
                break;
              case "moveInline":
                this.$emit("moveInline")
                break;
              case "copy":
                this.$emit("copy")
                break;
          }
      },
    },
}
</script>

<style lang="less" scoped>
.topTools-container {
  &:not(.highlight-block):not(.not-highlight):hover, &.highlight-block {
    > .topTools-editor {
      display: flex;
      opacity: 1;
    }
    > .topTools-type-tips {
      display: flex;
      opacity: 1;
    }
  }
}
.topTools-editor {
  opacity: 0;
  display: none;
  position: absolute;
  // top: -1px;
  top: 0;
  height: 24px;
  z-index: 1;

  &.position-center {
       left: 50%;
       transform: translateX(-50%);
  }
  &.position-left {
    left: 0;
    .position-left-item {
      background: #348671;
      &:hover{
        background: #1A4137;
      }
      .editor-img {
        width: 12px;
        height: 12px;
      }
    }
  }
  .position-left-item {
        width: 32px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        &:not(:last-child){
          &::after {
              position: absolute;
              right: 0;
              top: 50%;
              transform: translate(50%, -50%);
              content: "";
              display: block;
              width: 1px;
              height: 10px;
              background-color: rgba(255, 255, 255, .4);
          }
        }
        &.eye-hide {
          background: #A1A1A1;
        }
    }
  &.position-right {
      right: 0;
      .position-left-item {
        background: #5ED8B8;
        &:hover{
          background: #4FB398;
        }
        &.eye-hide {
          background: #A1A1A1;
        }
      }
  }

  // 唯一且居中
  .topTools-editor-item.only {
    width: 60px;
    background-image: url(~@/assets/toolbg_cDefault_only.png);
    &:hover {
      background-image: url(~@/assets/toolbg_cHover_only.png);
    }
  }

  .topTools-editor-item {
    position: relative;
    width: 44px;
    height: 24px;
    line-height: 24px;
    background-image: url(~@/assets/toolbg_cDefault.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    &.eye-hide {
      i {
        position: relative;
        top: 1px;
      }
    }
    &:hover {
      background-image: url(~@/assets/toolbg_cHover.png);
    }
    &:first-child:not(:last-child) {
      position: relative;
      // text-indent: 22px;
      .editor-img {
        margin-left: 4px;
      }
      background-image: url(~@/assets/toolbg_lDefault.png);
       &:hover {
        background-image: url(~@/assets/toolbg_lHover.png);
      }
    }
    &:last-child:not(:first-child) {
      position: relative;
      // text-indent: 10px;
      .editor-img {
        margin-left: -2px;
      }
      background-color: transparent;
      background-image: url(~@/assets/toolbg_rDefault.png);
      &:hover {
        background-image: url(~@/assets/toolbg_rHover.png);
      }
    }
    &.eye-hide {
      background: #A1A1A1;
    }
  }
}
//  提示
.hover-tips {
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    display: none;
    border-radius: 1px; 
    border: 1px solid #DADADA;
    padding: 1px 6px;
    font-size: 12px;
    line-height: 17px;
    color: #222;
    text-indent: 0;
    background: #F2F2F1;
    white-space:nowrap;
}
.position-left {
  .hover-tips {
     //左边，保证提示文字不会超过左边边界
    left: 0;
    transform: translateX(0) translateY(100%);
  }
}
.topTools-editor-item:hover,
.position-left-item:hover {
    .hover-tips {
        display: block;
    }
}
// 深色边框
.darkBlock {
    box-sizing: border-box;
    border: 1px dashed transparent;
    &.highlight-block {
        border-color: #2A6958;
    }
}
// 无编辑状态下的hover
.darkBlock {
  &:not(.highlight-block):not(.not-highlight)&:hover {
    border-color: #2A6958;
  }
}

// 当前内容提示文字
.darkBlock {
  .topTools-type-tips.right {
    right: 0px;
    top: 0px;
  }
}
.topTools-type-tips {
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 1;
    box-sizing: border-box;
    border-radius: 1px;
    border: 1px solid #D4D4D4;
    padding: 0 13px;
    min-width: 50px;
    height: 20px;
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    color: #A1A1A1;
    background-color: #EDEDED;
    &.left {
      border-top: none;
      border-left: none;
      left: 1px;
      top: 1px;
    }
    &.right {
      border-top: none;
      border-right: none;
      right: 1px;
      top: 1px;
    }
}

.topTools-show-status {
  position: absolute;
  top: 2px;
  z-index: 1;
  right: 59px;
  height: 16px;
}
.topTools-component-status {
  position: absolute;
  bottom: 7px;
  z-index: 1;
  right: 8px;
  height: 16px;
}

.editor-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 14px;
  height: 14px;
  object-fit: contain;
}
</style>