<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true,'reset':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-flipper"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @reset="resetData"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div class="flipper-inner component-inner marginCenter breakword">
        <div class="flipper-widget component-widget marginCenter">
          <div class="flipper-container" @click.stop="onClick" :style="{ 'width': (itemWidth || 300) + 'px', 'height': itemHeight}">
            <div ref="flipper" class="flipper state-0" 
              :class="[isAnimating?'animating':'']">
              <div v-for="(fitem, find) in optionsItem" :key="'filpper_'+find"  
                ref="flipper-item" 
                :class="['flipper-item', 'nth-child-'+find, 'state-0']" 
                :style="{'height': itemHeight }">
                <div v-if="fitem.title" class="flipper-item-title" v-html="fitem.title"></div>
                <div v-if="fitem.body" class="flipper-item-body" v-html="fitem.body"></div>
                <img v-if="showImg && fitem._graphic && fitem._graphic.src" @load="onImageReady" @error="onImageError" 
                  class="flipper-item-img" :src="fitem._graphic.src" :alt="fitem._graphic.alt"
                  :style="{'width': 'calc(100% - 20)' + 'px'}">
                <div v-if="fitem.instruction" class="flipper-item-instruction">{{fitem.instruction}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            :listItemSet="{'canDrag':true}"
            @closeOptionsModal="closeOptionsModal"
            @sortOptionList="handleSort"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="rightItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_filpper_sub'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_filpper'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import { reduce, each } from 'lodash-es';
import objForm from "@/renderer/components/common/objForm.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import listCommon from '@/renderer/mixins/list/index';

/**
 * 翻页
*/
export default {
  mixins: [hideModal, componentCommon, listCommon],
  components:{ topTools, rightForm, scaffoldForm, contentList, objForm },
  data() {
    return {
      currentForm: {},
      componentName:"adapt-lianda-flipper",
      ckeditorPrefix:"componentFlipper",
      locked:false,
      itemHeight:"auto",
      isAnimating:false,
      loadedImgCount:0,
      showImg:true,
    };
  },
  watch: {
    itemWidth: {
      handler(n,o) {
        if(n) {
          // 宽度改变
          this.showImg = false;
          setTimeout( () => {
            this.itemHeight = "auto";
            this.loadedImgCount = 0;
            this.showImg = true;
          }, 50);
        }
      },
    },
    optionsItem: {
      deep: true,
      handler(n,o) {
        if(n) {
          this.locked = false;
          this.detail._stage = 0;
          this.itemHeight = "auto";
          this.$nextTick(() => {
            this.setItemVisibility();
            this.setItemHeights();
          });
        }
      },
    },
  },
  computed: {
    imgLen() {
      let len = this.optionsItem.filter( v => v._graphic && v._graphic.src ).length;
      return len;
    },
    optionsItem() {
      return this.detail["component-_items"];
    },
    itemWidth() {
      return this.detail["component-width"];
    },
  },
  mounted() {
    this.locked = false;
    this.detail._stage = 0;

    this.$nextTick(() => {
      this.$emit("loaded");

      // 判断是否有图片，判断全部图片加载完成
      if(this.imgLen === 0) {
        this.onImageReady();
      }
    });
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    resetData() {
      this.locked = false;
      this.isAnimating = false;
      this.detail._stage = 0;
      this.setStage(0);
    },
    onImageError: function() {
      this.loadedImgCount++;
      
      if(this.loadedImgCount === this.imgLen) {
        // 全部图片加载完成
        this.$nextTick(() => {
          this.setItemVisibility();
          this.setItemHeights();
        });
      }
    },
    onImageReady: function() {
      this.loadedImgCount++;

      if(this.loadedImgCount === this.imgLen) {
        // 全部图片加载完成
        this.$nextTick(() => {
          this.setItemVisibility();
          this.setItemHeights();
        });
      }
    },
    setItemHeights: function() {
      if (this.detail["_equalHeights"] === false) return;
      this.itemHeight = "auto";

      let items = this.$refs["flipper-item"];
      let hMax = reduce(items, function(hMax, el) {
          let h = el.offsetHeight;
          return h > hMax ? h : hMax;
      }, 0);
      this.itemHeight = hMax + "px";
    },
    setItemVisibility: function() {
        let stage = this.detail["_stage"];
        let rx = /state-\d+/;
        each(this.$refs["flipper-item"], (el, i) => {
            if (stage === i) {
                el.className = el.className.replace(rx, 'state-1');
            } else if (stage - 1 === i || (stage === 0 && i === this.optionsItem.length - 1)) {
                el.className = el.className.replace(rx, 'state-2');
            } else {
                el.className = el.className.replace(rx, 'state-0');
            }
        });
    },
    onClick: function() {
        if (this.locked) return;
        this.setLock();

        let stage = this.detail["_stage"] + 1;
        let numItems = this.optionsItem.length;
        if (stage + 1 === numItems) {
        } else if (stage === numItems) {
            stage = 0;
        }
        this.setStage(stage);
    },
    setLock: function() {
        this.isAnimating = true;
        this.locked = true;
        setTimeout(function() {
            this.isAnimating = false;
            this.locked = false;
        }.bind(this), 600);
    },
    setStage: function(stage) {
        this.detail["_stage"] = stage;
        let flipper = this.$refs["flipper"];
        flipper.className = flipper.className.replace(/stage-\d+/, "stage-" + stage);
        this.setItemVisibility();
    },
    // rightItemBlur(value,propName, {el, callback}) {
    rightItemBlur(value,propName,callback) {
      // 额外处理
      if(propName === "component-width" && value) {
        let newVal = parseInt(value);
        let currentEl = this.$el;
        // padding-inline:16
        let maxWidth = currentEl.offsetWidth - 32;
        
        if(newVal > maxWidth) {
          this.$message.warning("不可超过最大宽度");
          value = maxWidth;
          typeof callback === 'function' && callback();
        }
      }

      //更新
      let obj = {};
      obj[propName] = value;
      // this.detail[propName] = value;
      // this.currentForm[propName] = value;
      this.$set(this.detail, propName, value);
      this.$set(this.currentForm, propName, value);
      this.$forceUpdate();

      this.saveDetail(obj, true);
    },
    formItemBlur(value,propName, isUpdate = true) {
      //更新
      let obj = {};
      obj[propName] = value;
      // this.detail[propName] = value;
      // this.currentForm[propName] = value;
      this.$set(this.detail, propName, value);
      this.$set(this.currentForm, propName, value);
      this.$forceUpdate();

      !!isUpdate && this.saveDetail(obj, true);
    },
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/

  // 翻页组件
  @flip-speed: 0.6s;

  .flipper-inner {

    .flipper-container {
      margin: 0 auto 20px;
      perspective: 1000px;
      -webkit-perspective: 1000px;
      -moz-perspective: 1000px;
      -o-perspective: 1000px;
      -ms-perspective: 1000px;
      width: 100%;
      max-width: 100%;
    }

    .flipper {
      position: relative;
      height: 100%;
      margin: 10px;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      -o-transform-style: preserve-3d;
      transform-style: preserve-3d;
      cursor: pointer;

      &.animating {
        .flipper-item {
          &:not(.state-0) {
            -webkit-transition: @flip-speed;
            -moz-transition: @flip-speed;
            -ms-transition: @flip-speed;
            -o-transition: @flip-speed;
            transition: @flip-speed;
          }
        }
      }
    }

    /* hide back of pane during swap */
    .flipper-item {
      overflow: hidden;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 2;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: visible;
      -o-backface-visibility: hidden;
      backface-visibility: hidden;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background:  var(--item-color-inverted);
      -webkit-box-shadow: 0 1px 8px rgba(0,0,0,0.12);
      -moz-box-shadow: 0 1px 8px rgba(0,0,0,0.12);
      box-shadow: 0 1px 8px rgba(0,0,0,0.12);
      .transform(0deg);

      .flipper-item-title {
        padding: 15px 16px;
        font-size: 16px;
        color: var(--item-color-inverted);
        background:var(--item-color);
      }

      .flipper-item-body {
        flex: 1;
        padding: 10px 16px;
        font-size: 14px;
        line-height: 25px;
        color: var(--font-color);
        background: var(--font-color-inverted);
        /deep/p:not(:last-child) {
          margin-bottom: 8px;
        }
      }

      .flipper-item-img {
        display: block;
        flex: 1;
        width: 100%;
        object-fit: contain;
        vertical-align: bottom;
      }

      .flipper-item-instruction {
        // position: absolute;
        // margin: 20px;
        padding: 12px 16px;
        // bottom: 0;
        // right: 0;
        font-size: 14px;
        text-align: right;
        color: var(--item-color);
        background-color: var(--item-color-inverted);
      }

      &.state-0 {
        .transform(-180deg);
      }
      &.state-1 {
        .transform(0deg);
      }
      &.state-2 {
        .transform(180deg);
      }
    }


  }

  .transform(@angle) {
    -webkit-transform: rotateY(@angle);
    -moz-transform: rotateY(@angle);
    -ms-transform: rotateY(@angle);
    -o-transform: rotateY(@angle);
    transform: rotateY(@angle);
  }

</style>