<template>
  <div class="compListItem-container pointer" :class="{'active':current }" @click.stop="handleTap({event: $event})" @mouseenter="handleTap({event: $event,isClick:false})">
    <div class="compListItem-inner flex">
      <el-image :src="detail.icon" fit="contain" lazy class="component-item-img"></el-image>
      <div class="margin_left16 flex1">
        <p class="fontsize_14 color_222">{{detail.title}}</p>
        <p class="margin_top3 fontsize_12 color_A1A1A1 pover2 lineheight_1d5" style="width:100%">{{detail.description}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
      detail: {
          type: Object,
          default: function() {
              return {
                  component: 'text',
                  title: '文本',
                  description: '一种简单的文本组件，可以录入文本内容供学习者学习一种简单的文本组件，可以录入文本内容供学习者学习'
              }
          }
      },
      current: Boolean
    },
    data() {
      return {
        showMode: false,
      };
    },
    watch: {
      current(n) {
        if(n === false) {
          this.showMode = false;
        }
      }
    },
    methods: {
        getIconClass(type) {
             let classList = {
                'text': 'icon-component-text',
                'graphic': 'icon-component-image',
                'media': 'icon-component-media',
                'mcq': 'icon-component-choice',
                'matching': 'icon-component-matching-ques',
                'textinput': 'icon-component-cloze',
                'assessmentResults': 'icon-component-evaluate',
                'blank': 'icon-component-blank',
            };
            return classList[type]
        },
        handleTap({event, isClick=true}) { //isClick 直接添加 无模式的，比如空白组件
          let targetTop = this.getElementDistance(event.target);
          if(!isClick) {
            // this.showMode = !this.showMode;
            this.showMode = true;
          }
          if(this.detail.show_mode == 0) {
            // 不用显示模式列表，直接添加组件
            !!isClick && this.$emit("add",this.detail);
            !isClick && this.$emit('showMode', {mode:this.detail.component_mode, visible:false, component: this.detail.component });
            return;
          }
          
          !!isClick && this.$emit("add",this.detail);
          this.$emit('showMode', {mode:this.detail.component_mode, visible:this.showMode, component: this.detail.component, top: targetTop });
        },
        getElementDistance (elem,type = 'top'){ //元素到顶部左侧的距离  elem： DOM对象 type：top，left
            let scrollEle = document.getElementById( "filter-list" );
            let { scrollTop, scrollHeight } = scrollEle;
            var offset;
            switch(type){
                case 'top': 
                    offset='offsetTop';
                break;
                case 'left': 
                    offset='offsetLeft';
                break;
            }
            var elemDis=elem[offset];//获得elem元素距相对定位的父元素的top left
            var elem=elem.offsetParent;//获取相对定位的父元素
            //elem!=null 只要还有相对定位的父元素
            while(!elem.classList.contains('components-select-inner')){
                elemDis+=elem[offset];
                elem=elem.offsetParent;
        　　}
            if(scrollTop > 0) elemDis =  elemDis - scrollTop;
            if(elemDis > elem.offsetParent.clientHeight - 250) elemDis = -1;
        　　return elemDis;
        }
    },
}
</script>

<style lang="less" scoped>
  .compListItem-container {
      box-sizing: border-box;
      padding: 16px 14px;
      &:hover,
      &.active {
        background-color: #f5f5f5;
      }
      &:first-child {
        margin-top: 16px;
      }
      .compListItem-inner {
          // padding: 14px 0;
          // border-bottom: 1px solid #D4D4D4;
      }
  }
  .component-item-img {
    width: 24px;
    height: 24px;
  }
</style>