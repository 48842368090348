<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-narrative"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div v-if="currentForm['component-_items'] && Array.isArray(currentForm['component-_items']) && !hasNavigationInTextArea" 
        class="flex margin_top16 narrative-wrapper prel" 
        :class="{'flexcolumn text-below-image':currentForm['component-_isTextBelowImage']}"
        :style="{'--height':( imgList[currentSwiper] && imgList[currentSwiper].height || swiperHeight) +'px'}" >
        <el-carousel @change="changeSwiper" indicator-position="outside" class="carousel-wrapper" :autoplay="false" :loop="true">
          <el-carousel-item v-for="(item,dind) in currentForm['component-_items']" :key="dind">
            <div class="carousel-img-wrapper prel">
              <img v-if="item._graphic && item._graphic.src" 
                :src="getImgSrc(item._graphic.src)"
                ref="carouselImg" alt=""
                @load="getImgHeight"
                @error="errorImg"
                :loading="lazyImage?'lazy':'eager'"
                class="width_100p img">
              <div v-if="item._graphic && item._graphic.attribution" class="carousel-footer poverOfHtml fontsize_14 color_fff"><span v-if="item._graphic">{{item._graphic.attribution}}</span></div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <div v-if="currentForm['component-_items'].length" class="text-wrapper">
          <ckeditor
            v-if="currentForm['component-_items'][currentSwiper].title"
            type="simpleInline"
            class="fontsize_16 color_222 margin_bot16"
            :id="ckeditorPrefix+'-'+ currentForm.id +'-inline' +'-title-'+currentSwiper"
            :value="currentForm['component-_items'][currentSwiper].title" 
            :throttle="throttleDelay"
            @input="itemsInput($event,currentSwiper,{parentProp:'component-_items',prop:'title'})"
            @blur="itemsBlur($event,currentSwiper,{parentProp:'component-_items',prop:'title'})"
            @blurNotUpdate="itemsBlur($event,currentSwiper,{parentProp:'component-_items',prop:'title'}, false)"
          >
          </ckeditor>
          <ckeditor
            v-if="currentForm['component-_items'][currentSwiper].body"
            type="simpleInline"
            class="fontsize_14 color_222 margin_bot16"
            :id="ckeditorPrefix+'-'+ currentForm.id +'-inline' +'-body-'+currentSwiper"
            :value="currentForm['component-_items'][currentSwiper].body" 
            :throttle="throttleDelay"
            @input="itemsInput($event,currentSwiper,{parentProp:'component-_items',prop:'body'})"
            @blur="itemsBlur($event,currentSwiper,{parentProp:'component-_items',prop:'body'})"
            @blurNotUpdate="itemsBlur($event,currentSwiper,{parentProp:'component-_items',prop:'body'}, false)"
          >
          </ckeditor>
        </div>
      </div>
      <div v-else-if="currentForm['component-_items'] && Array.isArray(currentForm['component-_items']) && hasNavigationInTextArea" 
        class="flex margin_top16 narrative-wrapper hasNavigationInTextArea prel" 
        :class="{'flexcolumn text-below-image':currentForm['component-_isTextBelowImage']}" 
        :style="{'--height':( imgList[currentSwiper] && imgList[currentSwiper].height || swiperHeight) +'px'}">
        <div v-if="currentForm['component-_items'].length" class="prel img-wrapper">
          <img v-if="currentForm['component-_items'][currentSwiper]._graphic && currentForm['component-_items'][currentSwiper]._graphic.src" 
            ref="carouselImg_textbelow"
            :src="getImgSrc(currentForm['component-_items'][currentSwiper]._graphic.src)" alt="" class="width_100p img"
            :loading="lazyImage?'lazy':'eager'"
            @load="getImgHeight"
            @error="errorImg">
          <div v-if="currentForm['component-_items'][currentSwiper]._graphic && currentForm['component-_items'][currentSwiper]._graphic.attribution" 
            class="carousel-footer poverOfHtml fontsize_14 color_fff">
              <span v-if="currentForm['component-_items'][currentSwiper]._graphic">{{currentForm['component-_items'][currentSwiper]._graphic.attribution}}</span>
          </div>
        </div>
        <div class="hasNavigationInTextArea-right">
          <el-carousel @change="changeSwiper" indicator-position="outside" class="carousel-wrapper" :autoplay="false" :loop="true">
            <el-carousel-item v-for="(item,dind) in currentForm['component-_items']" :key="dind">
            </el-carousel-item>
          </el-carousel>
          <div v-if="currentForm['component-_items'].length" class="text-wrapper padding_left16">
            <ckeditor 
              v-if="currentForm['component-_items'][currentSwiper].title"
              type="simpleInline"
              class="fontsize_16 color_222 margin_bot16"
              :id="ckeditorPrefix+'-'+ currentForm.id +'-inline' +'-title-'+currentSwiper"
              :value="currentForm['component-_items'][currentSwiper].title" 
              :throttle="throttleDelay"
              @input="itemsInput($event,currentSwiper,{parentProp:'component-_items',prop:'title'})"
              @blur="itemsBlur($event,currentSwiper,{parentProp:'component-_items',prop:'title'})"
              @blurNotUpdate="itemsBlur($event,currentSwiper,{parentProp:'component-_items',prop:'title'},false)"
            >
            </ckeditor>
            <ckeditor 
              v-if="currentForm['component-_items'][currentSwiper].body"
              type="simpleInline"
              class="fontsize_14 color_222 margin_bot16"
              :id="ckeditorPrefix+'-'+ currentForm.id +'-inline' +'-body-'+currentSwiper"
              :value="currentForm['component-_items'][currentSwiper].body" 
              :throttle="throttleDelay"
              @input="itemsInput($event,currentSwiper,{parentProp:'component-_items',prop:'body'})"
              @blur="itemsBlur($event,currentSwiper,{parentProp:'component-_items',prop:'body'})"
              @blurNotUpdate="itemsBlur($event,currentSwiper,{parentProp:'component-_items',prop:'body'},false)"
            >
            </ckeditor>
          </div>
        </div>
      </div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            @open="openInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @close="closeInnerDrawer"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_narrative_pic'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_narrative'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import { mapState } from 'vuex';
import rightForm from "@/renderer/components/common/rightForm.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';

/**
 * 图文叙事
*/
export default {
  mixins: [hideModal, componentCommon],
  components:{ topTools, rightForm, objForm, scaffoldForm, contentList },
  data() {
    return {
      componentName:"adapt-contrib-narrative",
      currentForm: {
        "component-_items":[],
      },
      ckeditorPrefix:"componentNarrative",
      currentSwiper:0, //轮播图片位置
      swiperHeight: 230,
      imgList:[],
      imgMaxNatureHeight: 230, //最大原始高度
      imgMax: {
        naturalHeight: 230,
        naturalWidth: 230
      },
      loadImageDetail: {
        count:0,
      }
    };
  },
  computed: {
    ...mapState({
      lazyImage: state => state.course.lazyImage,
    }),
    hasNavigationInTextArea() {
      return this.detail['component-_hasNavigationInTextArea'];
    },
    imgTotal() {
      // 图片总数
      let length = 0;
      let items = this.currentForm['component-_items'];
      if( !this.hasNavigationInTextArea && items && Array.isArray(items) ) {
        items.forEach( v => {
          if(v._graphic && v._graphic.src) {
            length++;
          }
        } );
        return length;
      }
      if( this.hasNavigationInTextArea && items && Array.isArray(items) ) {
        if(items[this.currentSwiper]._graphic && items[this.currentSwiper]._graphic.src) length++;
      }
      return length;
    }
  },
  watch: {
    hasNavigationInTextArea: {
      handler(n ,o) {
        this.getImgHeight();
      },
      immediate: true
    }
  },
  created() {
    this.$watch(
      function () { // 第一个函数就是处理你要监听的属性，只要将其return出去就行
        return this.detail['component-_isTextBelowImage'];
      },
      (val, old) => {
        // 文本下方改变
        this.getImgHeight();
      }
    );
  },
  mounted() {
    this.loadImageDetail.count = 0;

    this.$nextTick(() => {
      //有图片时，需要等待图片加载完成
      if(this.imgTotal === 0) this.$emit("loaded");
    });
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    getImgSrc(src) {
      if(src && src.indexOf("x-oss-process=style/") == -1) {
        if(src.indexOf("?") >= 0) return src + `&x-oss-process=style/${'m'}`;
        else return src + `?x-oss-process=style/${'m'}`;
      }
      return src;
    },
    errorImg() {
      // 图片加载失败
      this.loadImageDetail.count++;

      if(this.loadImageDetail.count == this.imgTotal) {
        this.$emit("loaded");
      }
    },
    getImgHeight() {
      // 获取图片最高
      // 如果图片过大加载不出来，获取不到高度
      this.$nextTick(() => {
        let imgList = !this.hasNavigationInTextArea ? this.$refs.carouselImg : this.$refs.carouselImg_textbelow;

        let currentH = 230;
        if( imgList && Array.isArray(imgList) ) {
          this.imgList = [];

          imgList && imgList.forEach(v => {
            let imgTarget = v;
            
            let height = imgTarget.naturalHeight * imgTarget.width / imgTarget.naturalWidth;

            this.imgList.push({
              naturalWidth:imgTarget.naturalWidth,
              naturalHeight:imgTarget.naturalHeight,
              height: height
            });

            if(height > currentH) {
              currentH = height;
            }
            // 获取最大高度
            if( imgTarget.naturalHeight > this.imgMax.naturalHeight ) {
              this.imgMax.naturalHeight = imgTarget.naturalHeight;
              this.imgMax.naturalWidth = imgTarget.naturalWidth;
            }
          });
        }else if(imgList) {
          // console.log(this.imgList);
          let max = {
            naturalHeight: this.imgMax.naturalHeight > this.imgList.naturalHeight ? this.imgMax.naturalHeight : this.imgList.naturalHeight,
            naturalWidth: this.imgMax.naturalHeight > this.imgList.naturalHeight ? this.imgMax.naturalWidth : this.imgList.naturalWidth
          };
          this.imgMax = max;
          let height = max.naturalHeight * imgList.width / max.naturalWidth;
          currentH = height;
        }
        // console.log('currentH', currentH, this.imgMax);
        
        this.swiperHeight = currentH;

        // 图片加载完成计数
        this.loadImageDetail.count++;

        if(this.loadImageDetail.count == this.imgTotal) {
          this.$emit("loaded");
        }
      });
    },
    handleCopy({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value, component_target}) {
      if(component_target !== 'component-_items') return;
      
      let items = this.detail[key_name];
      let itemsLen = items.length;
      let copyItem = JSON.parse(JSON.stringify(items[index]));
      if(copyItem) {
        // 复制一项
        items.push(copyItem);
        this.currentForm[key_name].push(copyItem);
        this.saveDetail({
          [key_name]: items
        }, false);
      }
      
    },
    handleDel({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value, component_target}) {
      if(component_target !== 'component-_items') return;

      let items = this.detail[key_name];
      let itemsLen = items.length;
      if(index < itemsLen && index >= 0) {
        // 是否当前打开
        if(visible == 2) {
          this.closeInnerDrawer();
        }
        // 删除
        items.splice(index,1);
        this.currentForm[key_name].splice(index,1);
        this.saveDetail({
          [key_name]: items
        }, false);
      }
    },
    objFormChange(args) {
      
      let items = JSON.parse( JSON.stringify( this.detail[this.innerDrawer.key_name] ) );
      let current = this.innerDrawer.current;
      
      if(!items || !Array.isArray(items)) items= [];
      if(current >= 0 && current < items.length)  {
        items.splice(current,1,args.form);

        // 右侧刷新到 左侧时，不能整体刷新，不然导致输入框删除为空时，输入框不显示
        if(!this.currentForm[this.innerDrawer.key_name][current]) {
          this.currentForm[this.innerDrawer.key_name][current] = {};
        }
        Object.keys(args.form).forEach(v => {
          this.currentForm[this.innerDrawer.key_name][current][v] = args.form[v];
        });
        this.$forceUpdate();

      }else {
        items.push(args.form);
        this.currentForm[this.innerDrawer.key_name] = items;
      }
      this.$set(this.detail, this.innerDrawer.key_name, items);
      
      this.saveDetail({
        [this.innerDrawer.key_name]: items
      }, false);
    },
    changeSwiper(ind) {
      // 图片轮播
      this.currentSwiper = ind;
    },
    closeInnerDrawer(isBtn = 2) {
      this.innerDrawer.visible = false;
      this.innerDrawer.form = {};
    },
    formChangeBlur(value,propName,leftForm) {
      let obj = {};
      obj[propName] = value;
      this.$set(this.detail, propName, value);
      this.$set(this.currentForm, propName, value);
      this.saveDetail(obj, false);
    },
    closeInnerDrawer({list, title, visible} = {}) {
      // 显示\隐藏二层弹出窗口
      if(!visible) {
        this.innerDrawer.visible = false;
        this.innerDrawer.form = {};
        return;
      }
    },
    itemsInput(value, index, {parentProp, prop}) {
      let obj = {};
      if(this.detail[parentProp] && this.detail[parentProp][index]) {
        this.detail[parentProp][index][prop] = value;
      }
      obj[parentProp] = this.detail[parentProp];
      
      this.saveDetail(obj, false);
    },
    itemsBlur(value, index, {parentProp, prop}, isUpdate = true) {
      let obj = {};
      if(this.detail[parentProp] && this.detail[parentProp][index]) {
        this.detail[parentProp][index][prop] = value;
        this.currentForm[parentProp][index][prop] = value;
      }
      obj[parentProp] = this.detail[parentProp];
      
      !!isUpdate && this.saveDetail(obj, false);
    },
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/

  /deep/.el-carousel__item{
    color: #475669;
    font-size: 18px;
    // height: 230px;
    height: 100%;
    margin: 0;
  }
  /deep/.el-carousel__arrow {
    opacity: 0.5;
    background: #111111;
    width: 24px;
    height: 24px;
  }
  /deep/.el-carousel__indicators--outside button {
    opacity: 1;
    border-radius: 2px;
    //  width: 32px;
    height: 4px;
    background-color: #70ADC6;
  }
  /deep/.el-carousel__indicators--outside .el-carousel__indicator:hover button {
    opacity: 1;
    background-color: #4D98B7;
  }
  /deep/.el-carousel__indicator.is-active button {
    background-color: #4D98B7;
  }
  .carousel-footer {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    padding: 0 16px;
    width: 100%;
    height: 40px;
    line-height: 40px;
    opacity: 0.3;
    background: #000000;
  }
  /deep/.el-carousel__container {
    height: calc(100% - 28px);
  }
  // 常规-左右分，宽度50%
  // 文字在图片下方，宽度100%
  // 通用
  .img-wrapper {
    width: 100%;
    // height: 100%;
  }
  // 组件
  .narrative-wrapper {
    min-height: calc( var(--height, 230px)  + 28px);
    .img {
      // height: 100%;
      width: 100%;
      object-fit: contain;
      display: block;
    }
    // 常规-左右分，宽度50%
    &>div {
      width: 50%;
    }
    &.hasNavigationInTextArea {
      padding-bottom: 20px;
    }
  }
  // 文字在图片下方，宽度100%
  .narrative-wrapper.text-below-image {
    &>div {
      width: 100%;
    }
    &.hasNavigationInTextArea {
      padding-bottom: 0px;
    }
  }

  .carousel-wrapper {
    height: calc(var(--height, 230px) + 28px);
    .img {
      width: 100%;
      object-fit: contain;
      display: block;
    }
  }
  .text-wrapper {
    margin-left: 16px;
    box-sizing: border-box;
  }
  // 文本在图片下方
  .text-below-image {
    .carousel-wrapper {
      // height: 330px;
      .img {
        // height: 330px;
      }
    }
    .text-wrapper {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  // 导航在文本
  .hasNavigationInTextArea {
    .carousel-wrapper {
      margin-left: 2%;
      height: 56px;
    }
    .text-wrapper {
      margin-left: 0;
    }
  }

  .carousel-img-wrapper {
    box-sizing: border-box;
  }
</style>