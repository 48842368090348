<template>
  <div class="toc-menu" :style="{ width: showMenu ? '230px' : '0px'}">
    <div>
      <div class="toc-menu-head">
        <!-- <el-image :src="subMenuImg" fit="cover" lazy class="theme-img"></el-image> -->
        <img
          :src="subMenuImg"
          alt=""
          width="230px" height="179px" style="object-fit:cover"
        />
        <div class="menuInfo">
          <h3>{{ renderMenuTitle }}</h3>
          <!-- <progressExample
            v-if="menuChild"
            :isMenuProgress="true"
            :progress="30"
            class="margin_top16 flexshrink menuProgress"
          ></progressExample>
          <span v-if="menuChild">进度: 30%</span> -->
        </div>
      </div>
      <div
        class="body-theme-inner flex flex_atop flexwrap aloneCenter prel"
        :style="coloursVariable"
      >
        <h4 class="toc_directory">目录</h4>
        <div
          ref="themeCard"
          class="theme-card pointer"
          :class="{ 'always-show-hide': item['contentobject-_isHidden'],'isSelected':ContentId(item.id)}"
          v-for="(item, index) in courseTrees"
          :key="item.contentobject_id"
          @click="tapStudyBtn(item, index, $event)"
        >
          <div
            class="theme-content flex flexcolumn flex_jcbetween" :class="{opacity: showMenu?1:0 }">
            <!-- :class="{ 'is-hide': item['contentobject-_isHidden'] }"
          > -->
            <div class="listIcon">
              <div class="flex">
                <p
                  class="
                    theme-title
                    flex1
                    pover2OfHtmlTile
                    fontsize_14
                    color_222
                  "
                  v-html="item.title"
                ></p>
              </div>
            </div>
            <div class="flex flex_acenter flex_jcbetween flexwrap" v-if="item.properties_list['contentobject-_pageLevelProgress-properties-_isEnabled']">
              <el-progress
                type="circle"
                :percentage="30"
                :width="16"
                :show-text="false"
                :stroke-width="2"
              ></el-progress>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import progressExample from "@/renderer/components/common/progressExample.vue";
import { boolean } from "yargs";
var time = null; //  在这里定义time 为null
export default {
  props: {
    ruleForm: Object,
    showMenu: boolean,
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    list: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 主题颜色值
    themeVariables: {
      type: Object,
      default: function () {
        return {};
      },
    },
    enabledProgress: Boolean,
  },
  components: {
    progressExample,
  },
  data() {
    return {
      ckeditorPrefix: "themecard",
      drawerSet: {
        direction: "rtl", //从右往左
        visible: false, //是否显示
        title: "",
        activeName: 0,
      },
      innerDrawer: {
        direction: "rtl", //从右往左
        visible: false, //是否显示
        title: "",
        activeName: 0,
      },
      innerForm: {},
      maxHeight: "auto",
      delDialogDetail: {
        title: "删除",
        visible: false,
        item: {},
        index: -1,
      },
      menuChild: false, //渲染侧栏进度条
      renderMenuTitle: "", // 渲染菜单标题
      subMenuImg:'', // 菜单图片
      drag: false,
    };
  },
  computed: {
    ...mapState({
      popShowId: (state) => state.popShowId,
      // contentObjectList: state => state.content.currentContentList,
      schemaJson: (state) => state.course.schemaJson,
    }),
    ...mapGetters(["getLayerById", "getTreesById"]),
    contentObjectList: {
      get() {
        return this.$store.state.content.currentContentList;
      },
      set(value) {
        this.$store.commit("SET_CURRENT_CONTENT", value);
      },
    },
    
    ContentId(){
     return function(id){
       return this.$route.params.contentId == id;
     }
    },
    courseTrees() {
      let courseId = this.$route.params.id;
      let courseList = this.getTreesById(courseId);
      let allChildNode = courseList[0].children; // 所有的子页面
      let contentId = this.$route.params.contentId; //内容页的id
      let AllContentObj = this.$store.state.course.AllContentObj;
      let contentParentId = "";
      //查找当前上一级是否是主菜单
      let isMenu = AllContentObj.some((item) => {
        if (item._id == contentId && item._parentId.indexOf("-") < 0) {
          contentParentId = item._parentId;
          return AllContentObj.some(
            (i) => i._id == contentParentId && i._type == "menu"
          );
        }
      });
     
      if (isMenu && contentParentId) {
        let menuList = allChildNode.filter(
          (item) => item._id == contentParentId
        );
        this.menuChild = true;
        this.renderMenuTitle = menuList[0].title;
        this.subMenuImg = menuList[0].properties_list['contentobject-_vanilla-properties-_backgroundImage-properties-_large'];
        return menuList[0].children;
      } else {
        this.menuChild = false;
        this.renderMenuTitle = courseList[0].title;
        this.subMenuImg = courseList[0].properties_list['course-_boxMenu-properties-_backgroundImage-properties-_large'];
        return allChildNode;
      }
    },

    componentName() {
      return this.pageType == "index"
        ? "course-style-right"
        : "contentobject-style-right";
    },
    isHighlight() {
      return (
        (this.popShowId &&
          this.popShowId.includes(
            this.ruleForm[this.getCkId()] + "_" + this.componentName
          )) ||
        false
      );
    },
    tabList() {
      let schemaName = this.componentName;
      return (
        (this.schemaJson[schemaName] &&
          this.schemaJson[schemaName].tabgroup_list) ||
        []
      );
    },
    pageType() {
      //区分首页 index、主题页 menu、内容页 content
      return this.$route.query.pageType;
    },
    innerPropList() {
      // 页面属性列表
      let schemaName = "course-style-right-2";
      return (
        (this.schemaJson[schemaName] &&
          this.schemaJson[schemaName].tabgroup_list &&
          this.schemaJson[schemaName].tabgroup_list[0]) ||
        {}
      );
    },
    coloursVariable() {
      // 主题颜色变量
      let themeVariables = this.themeVariables;
      let obj = {
        "--menu-item-border-color":
          themeVariables["--menu-item-border-color"] ||
          themeVariables["--item-color"],
      };
      return obj;
    },
    contentListLen() {
      return this.contentObjectList.length;
    },
    courseLayer() {
      // 当前课程层级数据
      return this.getLayerById(this.$route.params.id);
    },
    contentLayer() {
      // 当前页面层级
      return this.$route.params.contentId
        ? this.courseLayer[this.$route.params.contentId]
        : 0;
    },
  },
  watch: {
    contentObjectList: {
      immediate: true,
      deep: true,
      handler(newVal) {
        // 数据改变，获取theme-card最大 高度
        this.$nextTick(() => {
          this.getCardHeight();
        });
      },
    },
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    ...mapMutations(["openPop", "closePop"]),
    ...mapActions(["GetContentObjectSonList", "getSonContentList"]),
    getCardHeight() {
      this.maxHeight = "auto";
      this.$nextTick(() => {
        // console.log('nextTick');
        // console.log(this.$refs.themeCard);
        let targets = this.$refs.themeCard;
        let height = 0;
        targets &&
          targets.forEach((v) => {
            if (v.offsetHeight > height) {
              height = v.offsetHeight;
            }
          });
        if (height > 0) {
          this.maxHeight = height + "px";
          // console.log('最终高度',height);
        }
      });
    },
    // 单击事件函数
    clickMenuItem(item, index, event) {
      clearTimeout(time); //首先清除计时器
      time = setTimeout(() => {
        // 单击
        this.tapShowEdit(item, index);
      }, 210); //大概时间200ms
    }, // 双击事件函数，清除计时器，直接处理逻辑
    dblclickMenuItem(item, event) {
      // clearTimeout(time);  //清除
      // // 双击
      // this.tapStudyBtn(item);
    },
    tapShowEdit(item, index) {
      // 显示对应页面编辑二级弹窗
      // this.showEidt();
      this.openInnerDrawer(item);
      // this.$nextTick( () => {
      //   this.$refs.rightForm && this.$refs.rightForm.toOpenWithStatus(item, index);
      // } );
    },
    tapStudyBtn(item) {
      // 跳转对应页面
      this.$router.push({
        name: "EditContent",
        params: {
          id: this.$route.params.id,
          contentId: item.contentobject_id,
        },
        query: {
          pageType: item._type === "page" ? "content" : "menu",
        },
      });
    },
    changeVisible(item) {
      this.saveDetail(
        { "contentobject-_isHidden": !item["contentobject-_isHidden"] },
        item
      );
    },
    formChangeBlur(value, propName, leftForm) {
      let obj = {};
      obj[propName] = value;

      this.saveDetail(obj, leftForm);
    },
    saveDetail(updateObj, leftForm) {
      let params = {
        page_key: this.innerPropList.page_key,
        course_id: leftForm.course_id,
        properties_data: updateObj,
        contentobject_id: leftForm["contentobject-id"],
        isLeftUpdate: true,
        updateContentObject: true,
        refreshOther: false,
      };
      //保存详情
      this.SaveCourse(params).then((res) => {
        if (res.errorcode == 0) {
          // 更新当前 currentObjectList
          let nextParams = {
            course_id: leftForm.course_id,
          };
          if (
            this.$route.query.pageType !== "index" ||
            this.$route.params.contentId != undefined
          ) {
            nextParams["contentObjectId"] = this.$route.params.contentId;
          }
          this.GetContentObjectSonList(nextParams).then((res) => {
            if (res.errorcode == 0) {
            }
          });
        }
      });
    },
    openInnerDrawer(item) {
      let properties_list = item.properties_list;
      delete item.properties_list;
      this.innerForm = Object.assign(item, properties_list);

      if (!this.innerDrawer.visible) {
        this.innerDrawer.visible = true;
      } else {
        this.innerDrawer.visible = false;
        setTimeout(() => {
          this.innerDrawer.visible = true;
        }, 105);
      }
    },
    closeInnerDrawer(isBtn = 2) {
      this.innerDrawer.visible = false;
      this.innerForm = {};
      // if(isBtn == 1) { //点击按钮 - 告诉子组件，恢复关闭
      //   this.$refs.rightForm && this.$refs.rightForm.closeTellChild();
      // }
    },
    showEidt() {
      this.openPop({
        type: this.componentName,
        id: this.ruleForm[this.getCkId()],
        ids: [this.ruleForm[this.getCkId()] + "_" + this.componentName],
      });
      this.drawerSet.title = this.schemaJson[this.componentName].title;
      this.drawerSet.visible = true;
    },
    closeOuterDrawer() {
      this.drawerSet.visible = false;
      if (this.innerDrawer.visible) this.innerDrawer.visible = false; //关闭内层
      this.closePop();
    },
    getCkId() {
      let pageType = this.pageType;
      let ids = {
        index: "course_id",
        menu: "contentobject_id",
      };
      return ids[pageType];
    },
    uploadImg(val, propName) {
      // console.log('uploadImg',val, propName)
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    changeVal(value, prop) {
      // console.log('changeVal',value,prop)
    },
    formItemBlur(value, propName) {
      let obj = {};
      obj[propName] = value;
      this.saveThemeDetail(obj);
    },
    changeFormProp(prop, data) {
      //更新courseForm 某个属性
      let ruleForm = JSON.parse(JSON.stringify(this.detail));
      ruleForm[prop] = data;
      return ruleForm;
    },
    selectChange(e, prop) {
      this.formItemBlur(e, prop);
    },
    handleClose(done) {
      //关闭右侧弹窗
      this.drawerSet.visible = false;
      // 关闭内部弹窗
      if (this.innerDrawer.visible) {
        this.closeInnerDrawer(1);
      }
      done();
      this.closePop();
    },
    saveThemeDetail(updateObj) {
      //保存主题详情
      let pageType = this.pageType;
      let params = {
        page_key: "",
        course_id: this.$route.params.id,
        properties_data: updateObj,
      };
      // 首页
      if (pageType == "index") {
        params["page_key"] = "course-style-right";
      }
      // 主题页
      if (pageType == "menu") {
        params["page_key"] = "contentobject-style-right";
        params["contentobject_id"] = this.$route.params.contentId;
      }
      this.SaveCourse(params).then((res) => {
        if (res.errorcode == 0) {
          // this.$emit("update")
        }
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
/*被拖拽对象的样式*/
.item {
  cursor: move;
}
/*选中样式*/
.chosen {
}
/*停靠位置样式*/
.ghostClass {
  opacity: 0.5 !important;
  // border-style: dashed !important;
}

.body-theme {
  box-sizing: border-box;
}
//抽屉菜单
.toc-menu {
  position: absolute;
  width: 230px;
  height: 100%;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  background-color: #FAFAFA;
  & > div {
    width: 230px;
  }
  .toc-menu-head {
    position: relative;
  }
  .menuInfo {
    position: absolute;
    top: 0;
    margin: 0 16px;
    width: calc(100% - 32px);
    height: 165px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > h3 {
      font-size: 16px;
      color: #ffffff;
      font-weight: 500;
      text-align: center;
    }
    /deep/.menuProgress {
      width: 100%;
      height: 6px;
      position: absolute;
      bottom: 30px;
    }
    & span {
      display: block;
      position: absolute;
      bottom: 8px;
      font-size: 12px;
      color: #ffffff;
      font-weight: 400;
    }
  }
  // .theme-content{
  //   transition: opacity 1s ease-out;
  // }

}
.body-theme-inner {
  box-sizing: border-box;
  padding-top: 19px;
  padding-bottom: 24px;
  width: 100%;
}
@themeCardWidth: 260px;

.toc_directory {
  margin: 6px 16px;
  padding-bottom: 3px;
  border-bottom: 1px solid #f0f0f0;
  width: calc(100% - 32px);
  font-size: 12px;
color: #262626;
font-weight: 400;
}
.theme-card {
  transition: height 0.6s;
  position: relative;
  display: flex;
  flex-direction: column;
  // margin-right: 35px;
  // margin-bottom: 30px;
  box-sizing: border-box;
  // border: 1px solid #D4D4D4;
  // border-radius: 4px;
  width: 100%;
  background-color: var(--menu-item-btn-color);
  color: var(--menu-item-btn-color-inverted);
  // border-color: var(--menu-item-border-color, #D4D4D4);
  // &:nth-child(3n) {
  //   margin-right: 0;
  // }
  // 最后一行
  &:nth-child(3n + 1):nth-last-child(-n + 3),
  &:nth-child(3n + 1):nth-last-child(-n + 3) ~ .theme-card {
    margin-bottom: 0;
  }
  &:hover {
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.12);
  background-color: var(--menu-item-btn-color-hover);
  color: var(--menu-item-btn-color-inverted-hover);
    .theme-show-icon,
    .theme-edit-icon,
    .theme-copy-icon,
    .theme-del-icon {
      opacity: 1;
    }
    .theme-show-icon {
    }
  }
  &.isSelected::before{
    content: "";
    width: 4px;
    height: 100%;
    position: absolute;
    left: 0;
    background-color: var(--menu-item-progress);
    border-radius: 0 4px 4px 0;
  }
  // 隐藏状态时需要一直显示隐藏
  &.always-show-hide {
    .theme-show-icon {
      opacity: 1;
      background-color: #a1a1a1;
    }
  }
  .listIcon>div::before{
    content: "";
    background-image: url(~@/assets/svg/toc-menu-listItem.svg);
    background-size: 16px;
    background-position: center;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    display: inline-block;
    margin-right: 8px;
  }
}
.theme-content {
  flex: 1;
  box-sizing: border-box;
  padding: 12px 16px;
  flex-direction: row;
  // min-height: 166px;
  p {
    color: var(--menu-item-inverted, --font-color);
  }
  &.is-hide {
    // 标题
    .theme-title /deep/p,
    .theme-title /deep/span {
      color: #aaaaaa !important;
    }
    // 介绍
    .theme-body /deep/p,
    .theme-body /deep/span {
      color: #aaaaaa;
    }
    // 用时
    .theme-duration,
    .theme-duration span {
      color: #aaaaaa !important;
    }
    // 按钮
    .theme-stydy-btn {
      border-color: #d8d8d8;
      background-color: #d8d8d8;
    }
    // 进度条
    /deep/.progress-inner {
      background-color: #aaaaaa !important;
    }
    /deep/.progress-example {
      background-color: #ededed;
      box-shadow: inset 0 0 0 1px #ededed;
    }
  }
  & /deep/.el-progress-circle {
    svg > path:first-child {
      stroke: var(--menu-item-progress-inverted) !important;
    }
    svg > path:last-child {
      stroke: var(--menu-item-progress) !important;
    }
  }
}
.theme-title {
  display: inline-block;
  padding: 0;
  line-height: 1.2;
}
.theme-body {
  // /deep/span {
  //   font-size: 12px!important;
  //   color: #6E6E6E!important;
  // }
}
.theme-duration {
  // /deep/span {
  //   font-size: 12px!important;
  //   color: #A1A1A1!important;
  // }
}
.theme-progress {
  width: 135px;
}
.theme-stydy-btn {
  box-sizing: border-box;
  border: 1px solid #222222;
  border-radius: 16px;
  padding: 4px 20px;
  overflow: visible;
  max-width: 100%;
  min-width: 64px;
  min-height: 28px;
  // line-height: 26px;
  line-height: 1.5;
  text-align: center;
  //主题色
  border-color: var(--menu-item-btn-color);
  color: var(--menu-item-btn-color-inverted);
  background-color: var(--menu-item-btn-color);
  opacity: 0.9;
  white-space: pre-wrap;
  &:hover {
    // color: #ffffff;
    opacity: 1;
    border-color: var(--menu-item-btn-color-hover);
    color: var(--menu-item-btn-color-inverted-hover);
    background-color: var(--menu-item-btn-color-hover);
  }
}

//由于内部弹出窗口
.drawer-right {
  /deep/ &.el-drawer {
    overflow: visible;
    .el-drawer__body {
      overflow: visible;
    }
  }
}
/deep/.el-drawer {
  // overflow: visible;
}
/deep/.el-drawer__body {
  // overflow: visible!important;
}
// 右侧弹窗
// 右侧弹窗头部高度
@drawHeaderHeight: 54px;
.drawer-right-inner {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .header {
    box-sizing: border-box;
    height: @drawHeaderHeight;
  }
  .header-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 19px 16px;
  }
  .drawer-right-inner-form {
    box-sizing: border-box;
    padding-top: 16px;
    height: calc(100% - @drawHeaderHeight);
  }
}
// 自定义 左边侧边栏弹窗
.right-sub-modal {
  position: absolute;
  z-index: 0;
  left: 0;
  transform: translateX(0);
  transition: all 0.05s;
  bottom: 0;
  width: 28vw;
  height: 100%;
  background: #fafafa;
  opacity: 0;

  /deep/.el-tabs__content {
    height: calc(100% - 40px);
  }

  &.visible {
    opacity: 1;
    box-shadow: -10px 2px 20px 0 rgba(0, 0, 0, 0.15);
    border-right: 1px solid #eee;
    transform: translateX(-100%);
    transition-duration: 0.2s; //展开
    & + .right-sub-modal-mask {
      display: block;
    }
  }

  .right-sub-modal-header {
    line-height: 22px;
    border-bottom: 1px solid #ededed;
  }
}
// 自定义 左侧弹窗 遮罩层
.right-sub-modal-mask {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.15);
}

// 主题盒子，页面标题进度条
.theme-title-progress {
  flex-shrink: 0;
  margin-top: 6px;
  margin-right: 5px;
}

.add-page-quickly {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 260px;
  // height: 302px;
  height: 166px;
  border-radius: 4px;
  border: 1px dashed #51cfae;
  &:hover {
    border-color: #1e9c7b;
    border-style: solid;

    .icon-quick-add-content::before {
      color: #1e9c7b;
    }
  }
  .content-page,
  .menu-page {
    border-radius: 4px;
    border: 1px solid #51cfae;
    width: 106px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #51cfae;
    transition: background 0.2s;
    cursor: pointer;
    &:hover {
      color: #ffffff;
      background-color: #51cfae;
      .icon-add::before {
        color: #ffffff;
      }
    }
    .icon-add::before {
      color: #51cfae;
    }
  }
}

//  提示
.hover-tips {
  position: absolute;
  bottom: -3px;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  display: none;
  border-radius: 1px;
  border: 1px solid #dadada;
  padding: 1px 6px;
  font-size: 12px;
  line-height: 17px;
  color: #222;
  text-indent: 0;
  background: #f2f2f1;
  white-space: nowrap;
}
</style>
  