<template>
  <topTools
    type="right"
    rightTips="组件"
    :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true, 'moveInline':moveInline}"
    :isHighlight="isHighlight"
    class="component-container component-hotgraphic"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit"
    @del="delComponent"
    @copy="handleCopying"
    @moveInline="handleMoveInline"
  >
    <contentList
      :list="list"
      :currentForm="currentForm"
      :getProgressStatus="getProgressStatus"
      :ckeditorPrefix="ckeditorPrefix"
      :canKeyEnter="canKeyEnter"
      :throttleDelay="throttleDelay"
      @input="handleInput"
      @blur="formItemBlur"
      @blurNotUpdate="formItemBlur"
    ></contentList>
    <div
      v-if="detail[imagePropName] && !detail['component-_useGraphicsAsPins']"
      class="component-image-wrapper prel"
      :class="{'isFullWidth':isFullWidth}"
    >
      <el-image
        v-if="detail[imagePropName]"
        @load="loadedImg"
        @error="errorImg"
        :src="getImgSrc(detail[imagePropName])"
        :lazy="lazyImage"
        fit="contain"
        class="img"
      ></el-image>
      <div
        v-if="detail[getPropName('attribution')]"
        class="component-image-tips poverOfHtml color_fff flex flex_acenter padding_left15"
        v-html="detail[getPropName('attribution')]"
      ></div>
      <div
        v-for="(litem, lind) in _items"
        :key="lind+'_items'"
        @click.stop="showHotgraphic({current_item:litem,current_index:lind,list:_items})"
        class="hotgraphic_pin pointer"
        :style="{'left':litem._left+'%', 'top':litem._top+'%'}"
      >
        <img
          v-if="litem._pin && litem._pin.src"
          @load="loadedImg"
          @error="errorImg"
          :src="litem._pin.src"
          alt
          class="img"
          :loading="lazyImage?'lazy':'eager'"
        />
      </div>
    </div>
    <div v-else-if="detail['component-_useGraphicsAsPins']" class="flex flexwrap prel">
      <div
        v-for="(litem, lind) in _items"
        :key="lind+'_items'"
        @click.stop="showHotgraphic({current_item:litem,current_index:lind,list:_items})"
        class="hotgraphic_img pointer"
      >
        <img
          v-if="litem._graphic && litem._graphic.src"
          @load="loadedImg"
          @error="errorImg"
          :src="litem._graphic.src +'?x-oss-process=style/s'"
          alt
          class="img"
        />
      </div>
    </div>
    <fd-drawer
      destroy-on-close
      :title="drawerSet.title"
      :visible.sync="drawerSet.visible"
      :direction="drawerSet.direction"
      :before-close="handleClose"
      :size="479"
      custom-class="drawer-right not-header"
      :with-header="false"
      append-to-body
      @open="openDrawer"
      @closed="closeDrawer"
      :modal="false"
    >
      <div class="drawer-right-inner prel bgc_fff">
        <div
          class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header"
          style="z-index:1;"
        >
          <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
          <i
            class="icon-close-popup header-close-btn pointer fontsize_14 color_333"
            @click="closeOuterDrawer"
          ></i>
        </div>
        <rightForm
          ref="rightForm"
          class="drawer-right-inner-form"
          :ruleForm="detail"
          :tabList="tabList"
          :currentTab="drawerSet.activeName"
          ckeditorNameId="component-_id"
          :ckeditorPrefix="ckeditorPrefix"
          :listItemSet="{'showArrow':true}"
          @open="openInnerDrawer"
          @copy="handleCopy"
          @del="handleDel"
          @close="closeInnerDrawer"
          @selectChange="selectChange"
          @formItemInput="formItemInput"
          @formItemBlur="formItemBlur"
        ></rightForm>
        <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
          <div class="right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14">
            <span
              v-html="innerDrawer.title"
              class="padding_16 fontsize_16 color_222 poverOfHtmlTile richHtml_c222"
            ></span>
            <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop"></i>
          </div>
          <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
            <objForm
              v-if="innerDrawer.visible && innerDrawer.submitAsObj"
              :titleFontSize="14"
              :scrollBoxPaddingLR="16"
              :componentName="componentName"
              :ruleForm="innerDrawer.form"
              :parentForm="JSON.parse(JSON.stringify(detail))"
              :propList="innerDrawer.propList"
              :ckeditorNameId="'component-_hotgraphic_pic'+innerDrawer.current"
              :current="innerDrawer.current"
              @selectChange="objFormChange"
              @formItemBlur="objFormChange"
            ></objForm>
            <scaffoldForm
              v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
              :titleFontSize="14"
              :scrollBoxPaddingLR="16"
              :ruleForm="detail"
              :propList="innerDrawer.propList"
              :ckeditorNameId="'component-_hotgraphic'"
              @selectChange="formChangeBlur"
              @formItemBlur="formChangeBlur"
            ></scaffoldForm>
          </div>
        </div>
      </div>
    </fd-drawer>
    <fd-dialog
      custom-class="hotgraphic-dialog"
      :visible.sync="hotgraphicDialogSet.visible"
      width="100%"
      :modal-append-to-body="true"
      :append-to-body="true"
      :before-close="closeDialogSet"
      :style="containerStyle"
    >
      <!-- <div class="hotgraphic-popup-outer" :class="[detail['component-_classes']]"> -->
      <div class="hotgraphic-popup-outer" >
        <div class="hotgraphic-popup-inner" v-if="_itemsLen > 0">
          <div class="hotgraphic-popup__toolbar flex flexwrap flex_jcbetween flex_acenter">
            <div class="hotgraphic-popup__nav flex flex_acenter">
              <button
                @click="tapHotArrow('back')"
                data-direction="back"
                :class="{'disabled': hotgraphicDialogSet.current == 0}"
                class="btn-icon hotgraphic-popup__controls back js-hotgraphic-controls-click is-disabled"
                aria-label="Back"
                tabindex="-1"
              >
                <div class="el-icon-arrow-left fontsize_18 color_fff"></div>
              </button>
              <div
                class="hotgraphic-popup__count color_fff fontsize_12"
              >{{Number(hotgraphicDialogSet.current)+1}} / {{_items.length}}</div>
              <button
                @click="tapHotArrow('next')"
                data-direction="next"
                :class="{'disabled': hotgraphicDialogSet.current >= (_items.length - 1)}"
                class="btn-icon hotgraphic-popup__controls next js-hotgraphic-controls-click"
                aria-label="Next"
              >
                <div class="el-icon-arrow-right fontsize_18 color_fff"></div>
              </button>
            </div>
            <button
              @click="closeDialogSet"
              class="btn-icon hotgraphic-popup__close js-hotgraphic-popup-close"
              aria-label="Close popup"
            >
              <div class="el-icon-close fontsize_24 color_fff"></div>
            </button>
          </div>
          <div
            class="hotgraphic-popup__item is-visited is-round is-active"
            :class="[_items[hotgraphicDialogSet.current]._classes]"
            data-index="0"
          >
            <div class="hotgraphic-popup__item-content">
              <div class="hotgraphic-popup__item-content-inner">
                <div class="hotgraphic-popup__item-body">
                  <div class="hotgraphic-popup__item-body-inner">
                    <p
                      class="fontsize_40 "
                      v-html="_items[hotgraphicDialogSet.current].title"
                    ></p>
                    <p
                      class="fontsize_16  padding_top16"
                      v-html="_items[hotgraphicDialogSet.current].body"
                    ></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="hotgraphic-popup__item-image-container">
              <img
                v-if="_items[hotgraphicDialogSet.current]._graphic && _items[hotgraphicDialogSet.current]._graphic.src"
                loading="lazy"
                class="hotgraphic-popup__item-image"
                :class="{'is_round':detail['component-_isRound']}"
                :src="_items[hotgraphicDialogSet.current]._graphic.src +'?x-oss-process=style/s'"
                aria-hidden="true"
              />
              <span
                v-if="_items[hotgraphicDialogSet.current]._graphic && _items[hotgraphicDialogSet.current]._graphic.attribution"
                class="component__attribution"
              >{{_items[hotgraphicDialogSet.current]._graphic.attribution}}</span>
            </div>
          </div>
        </div>
      </div>
    </fd-dialog>
  </topTools>
</template>

<script>
import topTools from "@/renderer/components/common/topTools.vue";
import { mapState } from "vuex";
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import hideModal from "@/renderer/mixins/popup/hideModal";
import componentCommon from "@/renderer/mixins/component/index";
import imageCommon from "@/renderer/mixins/image/index";

/**
 * 热点图片组件
 */
export default {
  mixins: [hideModal, componentCommon, imageCommon],
  components: { topTools, rightForm, scaffoldForm, objForm, contentList },
  props: {
    isFullWidth: Boolean,
    containerStyle: Object,
  },
  data() {
    return {
      currentForm: {},
      componentName: "adapt-contrib-hotgraphic",
      ckeditorPrefix: "componentHotgraphic",
      inlineInputObj: {
        //页面输入框
        displayTitle: "1",
        title: "1",
        body: "1",
        instruction: "1",
      },
      hotgraphicDialogSet: {
        visible: false,
        item: {},
        listLen: 0,
        current: 0,
      },
    };
  },
  computed: {
    ...mapState({
      lazyImage: (state) => state.course.lazyImage,
    }),
    atLeastOne() {
      //至少一个文本显示
      let target = -1;
      target = this.list.findIndex(
        (item) =>
          item.display == 1 &&
          (item.input_type === "richtext_mini" ||
            item.input_type === "richtext") &&
          this.inlineInputObj[item.properties_name] &&
          this.detail[item.key_name]
      );
      return target > -1;
    },
    imagePropName() {
      return "component-_graphic-properties-src";
    },
    _items() {
      // 热点列表
      let arr = this.detail["component-_items"];
      return Array.isArray(arr) ? arr : JSON.parse(arr) || [];
    },
    _itemsLen() {
      return this._items.length || 0;
    },
    imgTotal() {
      // 图片总数
      let length = 0;
      if (
        this.detail[this.imagePropName] &&
        !this.detail["component-_useGraphicsAsPins"]
      ) {
        length = 1;
        if (this._items && Array.isArray(this._items)) {
          this._items.forEach((v) => {
            if (
              v._pin &&
              v._pin.src &&
              v._pin.src != undefined &&
              v._pin.src != null
            ) {
              length++;
            }
          });
        }
        return length;
      }
      if (this.detail["component-_useGraphicsAsPins"]) {
        if (this._items && Array.isArray(this._items)) {
          this._items.forEach((v) => {
            if (
              v._graphic &&
              v._graphic.src &&
              v._graphic.src != undefined &&
              v._graphic.src != null
            ) {
              length++;
            }
          });
        }
        return length;
      }
      return length;
    },
  },
  watch: {},
  mounted() {},
  destroyed() {
    this.closePop();
  },
  methods: {
    getImgSrc(src) {
      if (src && src.indexOf("x-oss-process=style/") == -1) {
        if (src.indexOf("?") >= 0) return src + `&x-oss-process=style/${"xl"}`;
        else return src + `?x-oss-process=style/${"xl"}`;
      }
      return src;
    },
    tapHotArrow(direction) {
      let current = this.hotgraphicDialogSet.current;
      let listLen = this.hotgraphicDialogSet.listLen;
      if (direction === "back" && current > 0)
        this.hotgraphicDialogSet.current--;
      if (direction === "next" && current < listLen - 1)
        this.hotgraphicDialogSet.current++;
    },
    showHotgraphic({ current_item, current_index, list }) {
      let listLen = list.length;
      let item = JSON.parse(JSON.stringify(current_item));
      this.hotgraphicDialogSet.item = item;
      this.hotgraphicDialogSet.current = Number(current_index);
      this.hotgraphicDialogSet.listLen = listLen;
      this.hotgraphicDialogSet.visible = true;
    },
    closeDialogSet(done) {
      this.hotgraphicDialogSet.visible = false;
      done && typeof done == "function" && done();
    },
    handleCopy({
      list,
      title,
      visible,
      submitAsObj,
      parentDetail,
      properties_name,
      key_name,
      index,
      value,
      component_target,
    }) {
      if (component_target !== "component-_items") return;

      let items = this.detail[key_name];
      let copyItem = JSON.parse(JSON.stringify(items[index]));
      if (copyItem) {
        // 复制一项
        items.push(copyItem);
        this.saveDetail(
          {
            [key_name]: items,
          },
          false
        );
      }
    },
    handleDel({
      list,
      title,
      visible,
      submitAsObj,
      parentDetail,
      properties_name,
      key_name,
      index,
      value,
      component_target,
    }) {
      if (component_target !== "component-_items") return;

      let items = this.detail[key_name];
      let itemsLen = items.length;
      if (index < itemsLen && index >= 0) {
        // 是否当前打开
        if (visible == 2) {
          this.closeInnerDrawer();
        }
        // 删除
        items.splice(index, 1);
        this.saveDetail(
          {
            [key_name]: items,
          },
          false
        );
      }
    },
    getPropName(prop_name) {
      let target = this.list.find(
        (item) => item.display == 1 && item["properties_name"] === prop_name
      );
      return target ? target["key_name"] : "";
    },
    closeInnerDrawer(isBtn = 2) {
      this.innerDrawer.visible = false;
      this.innerDrawer.current = -1;
      // this.innerForm = {};
    },
    openInnerDrawer({
      list,
      title,
      visible,
      submitAsObj,
      parentDetail,
      properties_name,
      key_name,
      index,
      value,
    }) {
      // 显示\隐藏二层弹出窗口
      // 添加热点默认标题为热点1、热点2...
      this.innerDrawer.form = {
        title: "热点" + (this.detail["component-_items"].length + 1),
        _graphic: {},
      };
      this.innerDrawer.submitAsObj = submitAsObj || false; //整体提交
      this.innerDrawer.key_name = key_name;
      this.innerDrawer.parentDetail = parentDetail;
      this.innerDrawer.title = title;
      this.innerDrawer.propList = list;
      this.innerDrawer.current = index;
      if (value) {
        if (value._graphic && Array.isArray(value._graphic)) {
          value._graphic = {};
        }
        this.innerDrawer.form = value;
      }

      if (!visible || visible == 2) {
        this.innerDrawer.visible = false;
        return;
      } else {
        if (this.innerDrawer.visible == true) {
          this.innerDrawer.visible = false;
          setTimeout(() => {
            this.innerDrawer.visible = true;
          }, 220);
        } else {
          this.innerDrawer.visible = true;
        }
      }
    },
    selectChange(e, prop) {
      this.$set(this.detail, prop, e);
      this.formItemBlur(e, prop);
    },
    handleClose(done) {
      //关闭右侧弹窗
      this.drawerSet.visible = false;
      //关闭内层
      if (this.innerDrawer.visible) {
        this.closeInnerDrawer();
      }
      done();
      this.closePop();
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/css/component.less"; /*引入公共组件样式*/

.hasValue {
  margin-bottom: 16px;
}
.isFirstAndValue {
  margin-top: 16px;
}

// 图片
@singlePicHei: 477px;
@secondPicHei: 232px;
.component-image-wrapper {
  position: relative;
  width: 100%;
  margin-top: 16px;
  // height: @secondPicHei;
  &.isFullWidth {
    // height: @singlePicHei;
    .img {
      // height: @singlePicHei;
    }
  }
  .img {
    display: block;
    width: 100%;
    // height: @secondPicHei;
    height: auto;
  }
  .component-image-tips {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 40px;
    opacity: 0.3;
    background-color: #000000;
  }
}

// 标记
.hotgraphic_pin {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  width: 48px;
  height: 48px;
  // background-color: #fff;  
  background-color: var(--btn-icon-color);
  border-radius: 50%;
  &:hover {
    // background-color: #757c88;
  background-color: var(--btn-icon-color-hover);
  }
  &.is_round {
    border-radius: 50%;
  }
  .img {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
}

// 标记弹窗
.hotgraphic-popup__item {
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
}
.hotgraphic-popup-inner {
  margin: 0 auto;
  padding: 4rem 0.5rem;
  max-width: 56.25rem;
  p{
    color: var(--notify-inverted);
  }
  .hotgraphic-popup__inner {
    padding-top: 4rem;
  }
  .hotgraphic-popup__count {
    padding: 1rem;
    color: var(--notify-inverted);
  }
  .btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    width: 40px;
    height: 40px;
    // background-color: transparent;
    background-color: var(--notify-btn);
    color: var(--notify-btn-inverted);
    cursor: pointer;
    &:hover {
      background-color: var(--notify-btn-hover);
      color: var(--notify-btn-inverted-hover);
      // background-color: rgba(0, 0, 0, 0.15);
    }
    &.disabled {
      opacity: 0;
    }
  }
  .hotgraphic-popup__controls,
  .hotgraphic-popup__close {
    margin: 0.5rem;
    padding: 0.5rem;
    background-color: var(--notify-icon);
    // background-color: #36CDE8;
    color: var(--notify-icon-inverted);
    border-radius: 50%;
    &:hover{
      background-color: var(--notify-icon-hover);
      color: var(--notify-icon-inverted-hover);
    }
  }
  .hotgraphic-popup__item-content {
    width: 60%;
  }
  .hotgraphic-popup__item-image-container {
    position: relative;
    overflow: hidden;
    width: 40%;
    margin-left: 1rem;
    .hotgraphic-popup__item-image {
      width: 100%;
      display: block;
      &.is_round {
        border-radius: 50%;
      }
    }
  }
  /deep/.hotgraphic-popup__item-body {
    margin-bottom: 1rem;
    color: var(--notify-inverted);
    p{
      color: var(--notify-inverted);
    }
    a{
      &:link, &:-webkit-any-link{
        color: var(--notify-link);
      }
      &:hover{
        color: var(--notify-link-hover);
      }
    }
  }
}
.hotgraphic_img {
  width: 33.3%;
  .img {
    display: block;
    width: 100%;
    object-fit: contain;
  }
}

.component__attribution {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  transform: translate3d(0, 0, 0);
}
</style>