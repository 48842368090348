<template>
  <div>
    <div class="radio-group flex flex_acenter">
      <label v-for="item in options" :key="item.input_name"  @click.stop="changeWay(item.input_type)" class="flex flex_acenter margin_right32 pointer" >
        <span class="radio_box" :class="{'active': currentType === item.input_type}">
          <i class="icon-check-symbol fontsize_12 icon_color_fff"></i>
        </span>
        <span class="fontsize_14 color_222">{{item.title}}</span>
      </label>
    </div>
    <div v-if="currentType === 'number' && options[0]" class="flex flex_acenter">
      <NumberInput
        class="fontsize_14 color_222"
        :value="currentValue"
        :placeholder="options[0].placeholder"
        @input="inputChange($event,options[0].input_name)"
        @blur="inputBlur($event.value,options[0].input_name)"
      ></NumberInput>
    </div>
    <div v-if="currentType === 'range_number' && options[1]" class="flex flex_acenter">
      <NumberInput
        class="range_input fontsize_14 color_222"
        :value="currentObj[options[1].bottom]"
        :placeholder="options[1]['bottom_placeholder']"
        @input="inputChange($event,options[1].bottom)"
        @blur="inputBlur($event.value,options[1].bottom)"
      ></NumberInput>
      <span class="padding_0_16">~</span>
      <NumberInput
        class="range_input fontsize_14 color_222"
        :value="currentObj[options[1].top]"
        :placeholder="options[1]['top_placeholder']"
        @input="inputChange($event,options[1].top)"
        @blur="inputBlur($event.value,options[1].top)"
      ></NumberInput>
    </div>
  </div>
</template>

<script>
import NumberInput from "@/renderer/components/common/numberInput.vue";
export default {
  components: {
    NumberInput
  },
  props: {
    ruleForm:Object,
    options:Array,
    keyName: String,
    checkType: {
      type: String,
      default: "number", // range_number
    },
  },
  data() {
    return {
      currentValue:"",
      currentObj:{},
      currentType: this.checkType || "number",
    };
  },
  computed: {

  },
  created() {
    // 初始化
    this.currentValue = this.options[0] && this.ruleForm[this.options[0].input_name] || "";
    if(this.options[1]) {
      this.currentObj[this.options[1].top] = this.ruleForm[this.options[1].top];
      this.currentObj[this.options[1].bottom] = this.ruleForm[this.options[1].bottom];
    }
  },
  methods: {
    changeWay(type) {
      if(type === this.currentType) return;
      this.currentType = type;
      this.$emit("change",{
        propName:this.keyName,
        value:type
      });
    },
    inputChange(value, propName) {
      // 输入改变
      let { currentType } = this;
      if(currentType === "number") {
        this.currentValue = value;
        this.$emit("change",{
          propName:propName,
          value:value
        });
        return;
      }
      // 数值范围
      this.currentObj[propName] = value;
      this.$forceUpdate();
      this.$emit("change",{
        propName:propName,
        value:value
      });
    },
    inputBlur(value, propName) {
      // 失焦
      let { currentType } = this;

      if(currentType === "number" && value != this.currentValue) {
        this.inputChange(value,propName)
        return;
      }
      if(currentType === "range_number" && value != this.currentObj[propName]) {
        this.inputChange(value,propName)
        if(value != undefined) {
          this.$emit("change",{
            propName: this.options[0].input_name,
            value:""
          });
        }
      }

      this.additionalHandling(value, propName);
    },
    additionalHandling(value, propName) {
      // 只有在输入值时，才清空另一个值
      let { currentType } = this;
      let topValue = this.currentObj[ this.options[1].top ];
      let bottomValue = this.currentObj[ this.options[1].bottom ];
      if(currentType === "number" && value != undefined) {

        if(bottomValue != undefined) {
          this.currentObj[ this.options[1].bottom ] = "";
          // this.$emit("change",{
          //   propName: this.options[1].bottom,
          //   value:""
          // });
        }

        if(topValue != undefined) {
          this.currentObj[ this.options[1].top ] = "";
          // this.$emit("change",{
          //   propName: this.options[1].top,
          //   value:""
          // });
        }
      }
      if(currentType === "range_number" && value != undefined) {
        if(this.currentValue!= undefined) {
          this.currentValue = "";
          // this.$emit("change",{
          //   propName: this.options[0].input_name,
          //   value:""
          // });
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
  .radio-group {
    margin-bottom: 13px;
  }
  .radio_box {
    display: inline-block;
    box-sizing: border-box;
    margin-right: 5px;
    border-radius: 50%;
    border: 1px solid #D4D4D4;
    width: 20px;
    height: 20px;
    line-height: 18px;
    text-align: center;
    &.active {
      border-color: #E74362;
      background-color: #E74362;
    }
  }
  .range_input {
    overflow: hidden;
    width: calc( 100% );
    box-sizing: border-box;
  }
</style>