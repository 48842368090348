<template>
  <topTools  type="centerOne" rightTips="导航栏" :isHighlight="isHighlight" @edit="showEdit" 
    class="navigation-bar"
    :style="{'--menu-item':colorSet.barBgc,'--menu-item-color':colorSet.barColor,
    '--btn-bgc':colorSet.btnBgc,'--btn-bgc-hover':colorSet.btnBgcHover,
    '--btn-color':colorSet.btnColor,'--btn-color-hover':colorSet.btnColorHover}">
    <el-row class="theme-preview-inner width_110p theme-preview-nav flex flex_jcbetween flex_acenter">
      <el-col :span="12">
        <i v-if="pageType !== 'index'" 
          @click.stop="navBackParent" 
          class="icon-nav__back-btn fontsize_21 theme-preview-nav-item flex flex_center flex_acenter pointer"></i>
      </el-col>
      <el-col :span="12" class="flex flex_end">
        <div v-if="pageType === 'content' && ruleForm && ruleForm['contentobject-_pageLevelProgress-properties-_isEnabled']" 
          class="theme-preview-nav-item theme-preview-nav-progress flex flex_center flex_acenter pointer" >
          <el-progress :stroke-width="6" :show-text="false" :percentage="40" :color="'#666'"></el-progress>
        </div>
        <i v-if="ruleForm && ruleForm._languagePicker && ruleForm._languagePicker._isEnabled" 
          class="icon-nav__languagepicker-btn fontsize_24 theme-preview-nav-item flex flex_center flex_acenter pointer"></i>
        <i v-if="courseForm && courseForm['course-_resources-properties-_isEnabled']" 
          class="icon-nav__drawer-btn fontsize_21 theme-preview-nav-item flex flex_center flex_acenter pointer"></i>
      </el-col>
    </el-row>
    <fd-drawer
      destroy-on-close
      :title="drawerSet.title"
      :visible.sync="drawerSet.visible"
      :direction="drawerSet.direction"
      :before-close="handleClose"
      :size="479"
      custom-class="drawer-right"
      :modal="false"
    >
      <rightForm v-if="drawerSet.visible"
        @enterColorPick="enterColorPick"
        :ruleForm="ruleForm" :tabList="tabList"
        :ckeditorNameId="getCkId()"
        :ckeditorPrefix="ckeditorPrefix"
        @handleClick="handleClick" @changeVal="changeVal" @selectChange="selectChange"
        @uploadImg="uploadImg"  @formItemInput="formItemInput" @formItemBlur="formItemBlur"></rightForm>
    </fd-drawer>
  </topTools>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
export default {
  props: {
    ruleForm: Object,
    courseForm: Object
  },
  components: {
    topTools,
    rightForm
  },
  data() {
    return {
      ckeditorPrefix:"navigationbar",
      drawerSet: {
        direction: "rtl", //从右往左
        visible: false, //是否显示
        title: "",
        activeName: 0,
      },
      popDetail:{},
      dynamicForm:{},
    };
  },
  watch: {
    "$route" : {
      handler(n){
        if(n) {
          this.dynamicForm = this.ruleForm;
        }
      },
      immediate:true,
    },
  },
  computed: {
    ...mapState({
      popShowId: (state) => state.popShowId,
      schemaJson: state => state.course.schemaJson
    }),
    componentName() {
      return this.pageType !== "content" ? "course-navigation-bar" : "contentobject-navigation-bar"
    },
    isHighlight() {
      return this.popShowId && this.popShowId.includes(this.ruleForm[this.getCkId()] + '_' + this.componentName) || false;
    },
    pageType() { //区分首页 index、主题页 menu、内容页 content
      return this.$route.query.pageType;
    },
    tabList() {
      return this.schemaJson[this.componentName] && this.schemaJson[this.componentName].tabgroup_list || []
    },
    colorSet() {
      // 导航栏背景色course__themeVariables___nav__nav
      // 导航栏背景色反转course__themeVariables___nav__nav-inverted
      // 按钮背景色course__themeVariables___nav__nav-icon
      // 按钮背景色反转course__themeVariables___nav__nav-icon-inverted
      // 按钮背景色悬浮course__themeVariables___nav__nav-icon-hover
      // 按钮背景色悬浮反正course__themeVariables___nav__nav-icon-inverted-hover
      return {
        barBgc: this.dynamicForm['course__themeVariables___nav__nav'] || "#fff",
        barColor: this.dynamicForm['course__themeVariables___nav__nav-inverted'] || "#222",
        btnBgc: this.dynamicForm['course__themeVariables___nav__nav-icon'] || "transparent",
        btnBgcHover: this.dynamicForm['course__themeVariables___nav__nav-icon-hover'] || "transparent",
        btnColor: this.dynamicForm['course__themeVariables___nav__nav-icon-inverted'] || "#666",
        btnColorHover: this.dynamicForm['course__themeVariables___nav__nav-icon-inverted-hover'] || "#666",
      };
    },
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    ...mapMutations(['openPop','closePop']),
    ...mapActions(["SaveCourse","GetCourseDetail"]),
    navBackParent() {
      // 返回所属的主题页
      let parentId  = this.ruleForm['contentobject-_parentId'];
      let courseId  = this.ruleForm['contentobject-_courseId'];
      if(parentId === courseId) {
        // 首页
        this.$router.push("/course/edit/" + courseId + "?pageType=index");
      }else {
        this.$router.push({
          name: "EditContent",
          params: {
            type:'edit',
            id: courseId,
            contentId: parentId
          },
          query: {
            pageType:'menu'
          },
        });
      }
    },
    showEdit() {
      // Object.assign(this.detail, this.currentForm); //获取最新值
      this.openPop({type: this.componentName, id: this.ruleForm[this.getCkId()], ids:[ this.ruleForm[this.getCkId()]+'_'+this.componentName ] });
      this.drawerSet.activeName = 0;
      this.drawerSet.visible = true;
      this.drawerSet.title = this.schemaJson[this.componentName].title;
    },
    handleClose(done) {
      //关闭右侧弹窗
      done();
      this.closePop();
    },
    enterColorPick() {
      // 进入取色器
    },
    getCkId() {
      let pageType = this.pageType;
      let ids = {
        'index': 'course_id',
        'content': 'contentobject_id',
        'menu': 'contentobject_id',
      };
      return ids[pageType];
    },
    uploadImg(val,propName) {
      // console.log('uploadImg',val, propName)
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    changeVal(value, prop) {
    },
    selectChange(e,prop) {
      this.formItemBlur(e,prop);
    },
    handleInput(value,propName) { //当前页面输入
      let obj = {};
      obj[propName] = value;
      this.dynamicForm[propName] = value;
      this.ruleForm[propName] = value;
      this.saveDetail(obj);
    },
    formItemBlur(value,propName) {
      let obj = {};
      obj[propName] = value;
      this.dynamicForm[propName] = value;
      this.ruleForm[propName] = value;
      this.saveDetail(obj);
    },
    formItemInput(value,propName) { //右侧弹窗 输入
      let obj = {};
      obj[propName] = value;
      this.dynamicForm[propName] = value;
      this.saveDetail(obj);
    },
    saveDetail(updateObj) {
      //保存章节详情 contentobject_id
      let pageType = this.$route.query.pageType;
      let course_id = this.$route.params.id;
      let contentId = this.$route.params.contentId;
      //首页（特殊的主题页）index-index，主题页 contentobject-title ，内容页 content
      let params = {
        "page_key": "",
        "course_id": course_id,
        "properties_data": updateObj
      };
      // 是否改变了主题色
      let isChangeTheme = Object.keys(updateObj).findIndex(v => v.indexOf("course__themeVariables") > -1) > -1;
      
      params["page_key"] = this.componentName;
      // 是首页
      if(pageType=="index" &&  !contentId) {
        
      }
      // 主题页
      if(pageType=="menu" &&  contentId) {
        params["contentobject_id"] = contentId;
      }
      // 内容页 
      if(pageType === "content" &&  contentId) {
        params["contentobject_id"] = contentId;
      }
      this.SaveCourse(params).then(res=>{
        if(res.errorcode == 0) {
          // if(!!isChangeTheme) this.GetCourseDetail({course_id:course_id, refreshOther:false});
        }
      })
    },
  },
}
</script>

<style lang="less" scoped>
// 导航栏
@navProgress: var(--nav-progress, #EBEEF5);
@navProgressInner: var(--nav-progress-inverted, #666);
@navProgressBorder: var(--nav-progress-border, @navProgress);
// '--menu-item':colorSet.barBgc,
//     '--btn-bgc':colorSet.btnBgc,'--btn-bgc-hover':colorSet.btnBgcHover,
//     '--btn-color':colorSet.btnColor,'--btn-color-hover':colorSet.btnColorHover
.navigation-bar {
  // background-color: var(--menu-item);
}
.theme-preview-nav {
  box-sizing: border-box;
  width: 100%;
  height: 56px;
  background-color: var(--nav, #FFF);
  
  .theme-preview-nav-item {
    text-align: center;
    line-height: 56px;
    width: 56px;
    height: 56px;
    color: var(--nav-icon-inverted);
    background-color: var(--nav-icon);
    &::before {
      color: var(--nav-icon-inverted);
    }
    &:hover {
      background-color: var(--nav-icon-hover);
        &::before {
        color: var(--nav-icon-inverted-hover);
      }
    }
  }
  .theme-preview-nav-item.theme-preview-nav-progress {
    box-sizing: border-box;
    width: 144px;
    padding: 23px;
    height: auto;
    /deep/.el-progress {
      width: 100%;
      .el-progress-bar__outer {
        border-width: 2px !important;
      }
    }
  }
  // 进度条
  /deep/.el-progress-bar__outer {
      border: 1px solid var(--nav-progress-border)!important;
      background-color: var(--nav-progress-inverted)!important;
  }
  /deep/.el-progress-bar__inner {
    background-color: var(--nav-progress)!important;
  }
  .theme-preview-nav-item:hover {
    /deep/.el-progress-bar__outer {
      border-color: var(--nav-progress-border-hover)!important;
      background-color: var(--nav-progress-inverted-hover)!important;
    }
    /deep/.el-progress-bar__inner {
      background-color: var(--nav-progress-hover)!important;
    }
  }
}

  // 进度条
  /deep/.el-progress-bar__outer {
    border: 1px solid @navProgressBorder;
    background-color: @navProgress;
  }
  /deep/.el-progress-bar__inner {
    background-color: @navProgressInner !important;
  }
</style>