<template>
  <div class="theme-preview-container flex flexcolumn flex_center flex_acenter" :style="containerStyle">
    <el-row class="theme-preview-inner theme-preview-text" v-if="type === 'textSettings'">
      <p class="heading-title fontsize_20 margin_bot16" >首页</p>
      <p class="heading-body fontsize_14 margin_bot16 lineheight_20" >如果他正在受苦，他将被视为帮助他。埃尼亚会为醉酒而哭泣。在痛苦中，湖水将是舒适的，生活将是舒适的。该是苏打粉的时候了。当一个人出生，一个伟人出生，我们将被嘲笑。对于铁来说，背部没有肩膀受伤，猫就像一个柔软的果园，但兔子现在躺在智者的背上。</p>
      <span class="heading-link fontsize_12 pointer link" >https://builder.fei-dao.com/</span>
    </el-row>
    <el-row class="theme-preview-inner theme-preview-checkbox" v-else-if="type === 'optionSettings'">
      <el-checkbox :value="true" class="margin_bot24" >
        <span class="fontsize_14 color_222">选项内容1</span>
      </el-checkbox>
      <el-checkbox :value="false" class="">
        <span class="fontsize_14 color_222">选项内容1</span>
      </el-checkbox>
    </el-row>
    <el-row class="theme-preview-inner theme-preview-button" v-else-if="type === 'buttonSettings'">
      <button class="pointer">按钮</button>
    </el-row>
    <el-row class="theme-preview-inner " v-else-if="type === 'verificationStatus'">

    </el-row>
    <el-row class="theme-preview-inner theme-preview-progressbar" v-else-if="type === 'progressBar'">
      <div class="progress-bar-wrapper">
        <div class="progress-bar-inner"></div>
      </div>
    </el-row>
    <el-row class="theme-preview-inner " v-else-if="type === 'menu'">
      <div class="theme-card pointer" >
        <img src="@/assets/theme/menu_bg.jpg" alt="" class="theme-img" loading="lazy">
        <div class="theme-content flex flexcolumn flex_jcbetween">
          <div class="theme-font">
            <p class="theme-title pover2 fontsize_14 color_222">专业演讲技巧</p>
            <p class="fontsize_12 color_6E6E6E margin_top8">介绍文</p>
            <p class="fontsize_12 color_A1A1A1 margin_top8">用时：5分钟</p>
          </div>
          <div class="flex flex_acenter flex_jcbetween">
            <el-button class="theme-stydy-btn fontsize_14 color_222" >学习</el-button>
            <el-progress
              style="width:54px;"
              :stroke-width="10"
              :show-text="false"
              :percentage="40"
              :color="'#666'"
              class="theme-progress"
            ></el-progress>
          </div>
        </div>
      </div>
    </el-row>
    <el-row class="theme-preview-inner width_110p theme-preview-nav flex flex_jcbetween flex_acenter" v-else-if="type === 'navigationSettings'">
      <el-col :span="12" >
        <i class="icon-nav__back-btn fontsize_21 theme-preview-nav-item flex flex_center flex_acenter"></i>
      </el-col>
      <el-col :span="12" class="flex flex_end">
        <div class="theme-preview-nav-item flex flex_center flex_acenter">
          <el-progress  style="width:40px;" :stroke-width="4" :show-text="false" :percentage="40" :color="'#666'"></el-progress>
        </div>
        <i class="icon-nav__languagepicker-btn fontsize_24 theme-preview-nav-item flex flex_center flex_acenter"></i>
        <i class="icon-nav__drawer-btn fontsize_21 theme-preview-nav-item flex flex_center flex_acenter"></i>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// 按钮颜色：反转-代表文字颜色

import { defaultColors, defaultColorList, LightenDarkenColor } from "@/libs/utils/data";
export default {
  props:{
    type:{ //预览类型
      type: String,
      default: "textSettings"
    },
    form: { //预览绑定值
      type: Object,
      default: function() {
        return {};
      }
    },
    propList: { //绑定属性列表
      type: Array,
      default: function() {
        return [];
      }
    },
  },
  data() {
    return {

    };
  },
  watch: {
    propList: {
      handler(n) {
        if(n && Array.isArray(n)) {
          
        }
      },
      immediate: true,
    }
  },
  computed: {
    containerStyle() {
      // 最外层样式 - 当前获取了主题的颜色表
      let styleSet = {};
      let propSet = Object.keys(this.form).filter(v => v.indexOf("course__themeVariables___")>-1);
      propSet.forEach(v => {
        let prop = this.getShortPropOfTheme(v);
        styleSet[prop] = this.form[v];
      });
      styleSet = this.getColorDefault(styleSet);
      return styleSet;
    },
  },
  created() {
  },
  methods: {
    getColorDefault(obj) {
      // 颜色默认值
      defaultColorList.forEach(v => {
        let currentVal = obj['--'+v.propName];
        if(!currentVal) {
          if(v.from) {
            currentVal = !!v.withMethod ? LightenDarkenColor(obj['--'+v.from], v.params.value)  : obj['--'+v.from];
          }else {
            currentVal = defaultColors[v.default];
          }
          obj['--'+v.propName] = currentVal;
        }
      });
      return obj;
    },
    getShortPropOfTheme(propName) {
      // 获取简短属性
      // course__themeVariables___global__
      if(!propName || !propName.trim()) return;
      let shortName = propName.replace(/course__themeVariables___\w+__/, "");
      return '--'+shortName;
    },
  },
}
</script>

<style lang="less" scoped>
  .theme-preview-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 443px;
    height: 636px;
    width: 100%;
  }
  .theme-preview-inner {
    margin-top: -15px;
    width: calc(100% - 40px);
    max-width: 324px;
  }
  .link {
    &:hover {
      color: var(--link-hover)!important;
    }
  }

  // 标题
  .heading-title {
    color:var(--heading-color);
  }
  // 内容
  .heading-body {
    color:var(--font-color);
  }
  // 链接文本
  .heading-link {
    color: var(--link);
    &:hover {
      color: var(--link-hover);
    }
  }

  // 复选框
  .theme-preview-checkbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    /deep/.el-checkbox {
      margin-right: 0;
      &:hover {
        .el-checkbox__input .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            background-color: var(--item-color-hover)!important;
            border-color: var(--item-color-hover)!important;
          }
        }
        // 选中并且悬浮
        .el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: var(--item-color-selected)!important;
          border-color: var(--item-color-selected)!important;
        }
    }
    /deep/.el-checkbox__inner {
      border-radius: 4px;
      border-color: #979797;
      width: 20px;
      height: 20px;
      background-color: #fafafa;
      &:hover {
        border-color: #979797;
      }
      &::after {
        left: 6px;
        top: 3px;
        width: 4px;
        height: 8px;
        border-width: 2px;
      }
    }
    // 常规
    /deep/.el-checkbox__input .el-checkbox__inner{
      background-color: var(--item-color)!important;
      border-color: var(--item-color)!important;
    }
    // 选中
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
    /deep/.el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: var(--item-color-selected)!important;
      border-color: var(--item-color-selected)!important;
    }
   
  }

  // 按钮
  .theme-preview-button {
    display: flex;
    justify-content: center;
    width: calc(100% - 40px);
    max-width: 324px;
    button {
      outline: none;
      border: none;
      padding: 0;
      margin: 0;
      border-radius: 4px;
      width: 100%;
      height: 57px;
      text-align: center;
      line-height: 57px;
      color: #fff;
      color: var(--btn-color-inverted)!important;
      background-color: var(--btn-color)!important;
      &:hover {
        color: var(--btn-color-inverted-hover)!important;
        background-color: var(--btn-color-hover)!important;
      }
    }
  }

  // 进度条
  .progress-bar-wrapper {
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 28.5px;
    // width: 324px;
    width: 100%;
    height: 16px;
    background-color: #A1A1A1;
    background-color: var(--progress-inverted)!important;
    border: 1px solid var(--progress-border)!important;
    .progress-bar-inner {
      width: 40%;
      height: 16px;
      background-color: #4A90E2;
      background-color: var(--progress)!important;
    }
  }

  // 菜单
  @themeCardWidth: 260px;
  @themeCardHeight: 302px;
  @themeCardImgHeight: 136px;
  .theme-card {
    box-sizing: border-box;
    border: 1px solid #cccccc;
    border-radius: 4px;
    width: @themeCardWidth;
    height: 302px;
    background-color: var(--menu-item, #ffffff);
    border-color: var(--menu-item-border-color, #D4D4D4);
    &:nth-child(3n) {
      margin-right: 0;
    }
    // 最后一行
    &:nth-child(3n+1):nth-last-child(-n+3),
    &:nth-child(3n+1):nth-last-child(-n+3)~.theme-card {
      margin-bottom: 0;
    }
    &:hover {
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.12);
    }
  }
  .theme-img {
    border-radius: 4px 4px 0 0;
    width: @themeCardWidth - 2px;
    height: @themeCardImgHeight;
    object-fit: cover;
  }
  .theme-content {
    box-sizing: border-box;
    padding: 16px;
    height: calc(@themeCardHeight - @themeCardImgHeight);
  }
  .theme-font {
    >p {
      color: var(--menu-item-inverted);
    }
  }
  .theme-title {
    margin-bottom: 10px;
    padding: 0;
    line-height: 20px;
  }
  .theme-progress {
    width: 135px;
    /deep/.el-progress-bar__outer {
       border: 1px solid var(--menu-item-progress-border)!important;
       background-color: var(--menu-item-progress-inverted)!important;
    }
    /deep/.el-progress-bar__inner {
      background-color: var(--menu-item-progress)!important;
    }
  }
  .theme-stydy-btn {
    border: 1px solid #222222;
    border-radius: 16px;
    padding: 0;
    width: 66px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    //第一个颜色是当前动态修改（可能未保存-预览中），第二个颜色是全局已保存的
    border-color: var(--menu-item-btn-color);
    color: var(--menu-item-btn-color-inverted);
    background-color: var(--menu-item-btn-color);
    opacity: 0.8;
    &:hover {
      color: #ffffff;
      opacity: 1;
      //第一个颜色是当前动态修改（可能未保存-预览中），第二个颜色是全局已保存的
      border-color: var(--menu-item-btn-color-hover);
      color: var(--menu-item-btn-color-inverted-hover);
      background-color: var(--menu-item-btn-color-hover);
    }
  }

  // 导航栏
  .theme-preview-nav {
    box-sizing: border-box;
    max-width: 443px;
    height: 56px;
    background-color: var(--nav, #FFF);
    .theme-preview-nav-item {
      text-align: center;
      line-height: 56px;
      width: 56px;
      height: 56px;
      color: var(--nav-icon-inverted);
      background-color: var(--nav-icon);
      &::before {
        color: var(--nav-icon-inverted);
      }
      &:hover {
        background-color: var(--nav-icon-hover);
          &::before {
          color: var(--nav-icon-inverted-hover);
        }
      }
    }
    // 进度条
    /deep/.el-progress-bar__outer {
       border: 1px solid var(--nav-progress-border)!important;
       background-color: var(--nav-progress-inverted)!important;
    }
    /deep/.el-progress-bar__inner {
      background-color: var(--nav-progress)!important;
    }
    .theme-preview-nav-item:hover {
      /deep/.el-progress-bar__outer {
        border-color: var(--nav-progress-border-hover)!important;
        background-color: var(--nav-progress-inverted-hover)!important;
      }
      /deep/.el-progress-bar__inner {
        background-color: var(--nav-progress-hover)!important;
      }
    }
  }
</style>