<template>
  <el-container class="theme-container">
    <el-header class="theme-header flex flex_acenter flex_jcbetween bgc_fff">
      <div @click.stop="showRenameModal" class="padding_10" :class="{'pointer':pageType === 'template'}">
        <span class="fontsize_16 color_222">{{detail.info && detail.info.name || ''}}</span>
        <i v-if="pageType === 'template'" class="course-rename-top icon-course-rename-top fontsize_12 padding_left8">
          <span class="path1"></span>
          <span class="path2"></span>
        </i>
      </div>
      <div class="grid-content">
        <!-- <fdButton  v-if="detail.info && detail.info.is_current && detail.info.is_current == true && detail.type != 'theme_template'" @click="saveTheme" :width="64" :height="32" class="">保存</fdButton> -->
        <fdButton  v-if="detail.info && detail.info.is_current && detail.info.is_current == true && $route.params.type ==='course_theme'" @click="saveTheme" :width="64" :height="32" class="">保存</fdButton>
        <fdButton  v-else-if="detail.type == 'theme_template'" @click="saveTemplate" :width="64" :height="32" class="">保存</fdButton>
        <fdButton v-if="$route.params.type !=='theme'" @click="saveTemplateTips" :width="92" :height="32" plain class="margin_left16">保存到模板</fdButton>
        <fdButton v-if="detail.info && detail.info.is_current && detail.type == 'theme' && $route.params.type !=='theme'" @click="resetToInit" :width="64" :height="32" plain class="margin_left16">重置</fdButton>
      </div>
    </el-header>
    <el-container class="flex1 bgc_fff theme-center-area">
      <div class="theme-left">
        <el-aside width="104px" class="theme-aside" :style="{'--active-transform':(68 * Number(currentTab) +1)+'px'}">
          <div class="active-right-line" :style="{'transform':`translateY(${ 68 * Number(currentTab) +1}px)`}"></div>
          <div v-for="(titem,tind) in tab_sort" :key="titem" 
            @click="tapTab(tind,titem)"
            class="tabs_item pointer" :class="{'active': currentTab == tind, 'isFirst': currentTab == 0}">
            {{tab_list[titem].title}}
          </div>
        </el-aside>
        <div class="white-line top"></div>
        <div class="white-line bottom"></div>
        <div class="white-line left"></div>
      </div>
      <el-main class="theme-main flex scroll-box width0">
        <div class="theme-main-setting" v-if="tab_list[tab_sort[currentTab]]">
          <div v-for="(sitem, sind) in tab_list[tab_sort[currentTab]].detail" :key="sind" class="main-setting-item">
            <p class="main-setting-title fontsize_14 color_222">{{sitem.title}}</p>
            <div class="flex flexwrap">
              <div v-for="(pitem, pind) in sitem.input_list" :key="sind+'_'+pind" 
                class="prel set-bgcolor-picker-wrapper margin_right16 margin_bot16">
                <fd-color-picker
                  class="set-bgcolor-picker"
                  v-model="ruleForm[pitem.name]"
                  :show-alpha="true"
                  color-format="hex"
                  @change="colorChange($event,pitem.name)"
                  @active-change="colorActiveChange($event,pitem.name)"
                  :predefine="predefineColors">
                </fd-color-picker>
                <span class="picker-colorvalue">{{pitem.title}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="theme-main-preview flex flexcolumn flex_acenter" v-if="tab_list[tab_sort[currentTab]]">
          <themePreview :form="ruleForm" :type="tab_sort[currentTab]" :propList="tab_list[tab_sort[currentTab]].detail"></themePreview>
          <button class="previewRefresh_btn margin_top24 flex flex_acenter flex_center" @click="tapRefresh">
            <i class="icon-refresh-preview fontsize_16"></i><span class="padding_left6">刷新预览</span>
          </button>
        </div>
      </el-main>
    </el-container>
    <fd-dialog
      append-to-body
      :title="templateDialog.title"
      :visible.sync="templateDialog.visible"
      width="600px"
      :before-close="(arg)=>beforeCloseDialog(arg,'templateSave')"
      custom-class="course-dialog">
       <p class="margin_bot9 fontsize_14 color_222"><span>{{templateData.label}}</span></p>
       <el-input v-model="templateData.name" autocomplete="off" :placeholder="templateData.placeholder" class="theme-edit-input noborder" ></el-input>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="saveToTemplate" class="course-save pointer">保存</button>
        <button @click="templateDialog.visible = false" class="course-close pointer">关闭</button>
      </span>
    </fd-dialog>
    <fd-dialog
      append-to-body
      :title="resetDialogDetail.title"
      :visible.sync="resetDialogDetail.visible"
      width="600px"
      :before-close="(arg)=>beforeCloseDialog(arg, resetDialogDetail.type)"
      custom-class="course-dialog">
      <div class="flex flexcolumn flex_acenter">
        <p class="fontsize_14 color_222 textcenter margin_bot16">请确认是否重置当前主题</p>
        <p class="fontsize_12 color_E74362 lineheight_17" style="width:406px">重置主题将丢失您当前所设置的颜色、字体、图片等样式，且无法恢复。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="resetTheme" class="course-save pointer">确定</button>
        <button @click="resetDialogDetail.visible = false" class="course-close pointer">取消</button>
      </span>
    </fd-dialog>
    <fd-dialog
      :title="renameDialogDetail.title"
      :visible.sync="renameDialogDetail.visible"
      width="600px"
      :before-close="closeRenameDialog"
      :custom-class="{'course-dialog not-full':true}">
      <p  class="fontsize_14 color_222 margin_bot7">{{renameDialogDetail.label}}</p>
      <el-form @submit.native.prevent>
        <el-form-item >
          <el-input :value="renameDialogDetail.inputTemp" autocomplete="off" :placeholder="renameDialogDetail.placeholder" 
            @input="inputTemplateName" class="h48 border_D1 noborder" >
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <button type="primary" @click="renameOfTemplate" class="course-save pointer">保存</button>
        <button @click="renameDialogDetail.visible = false" class="course-close pointer">关闭</button>
      </span>
    </fd-dialog>
  </el-container>
</template>

<script>
import { mapActions } from 'vuex';
import { getThemeSetting, getCourseThemeSetting, saveThemeSetting, saveCourseThemeSetting, saveThemeTotemplate, 
  getTemplateSetting, saveTemplateSetting, resetTheme, renameTemplate } from '@/libs/api/theme';
import fdButton from '@/renderer/components/common/button.vue';
import themePreview from '@/renderer/components/common/themePreview.vue';
export default {
  components: {
    fdButton,
    themePreview
  },
  props:{
    
  },
  data() {
    return {
      templateData:{
        label:"模板名称",
        placeholder:"请输入模板名称",
        name:"", //模板名称
      },
      templateDialog: {
        title:"保存到模板",
        visible:false,
      },
      ruleForm:{},
      detail:{},
      tab_sort:[], //tab排序
      tab_list:{}, //tabs 集合
      list: [],
      currentTab:0,
      resetDialogDetail: {
        title:"重置",
        visible:false
      },
      renameDialogDetail: {
        inputTemp:"",
        title: "命名模板",
        visible: false,
        label: "模板名称",
        placeholder: "请输入模板名称"
      },
      predefineColors:[
        '#222222',
        '#ff4500',
        '#ff8c00',
        '#ffd700',
        "#36cde8",
        'rgba(255, 255, 255, 0)',
      ],
    };
  },
  watch: {
    $route: {
      handler(n) {
        console.log( 'n.params.type', n.params.type )
        if(!n.params.contentId || !n.params.id) return;
        
        if(n.params.type == 'theme') {
          this.getThemeSets(); //获取主题设置
        }else if(n.params.type == 'template') {
          this.getTemplateSets(); //获取模板设置
        }else if(n.params.type == 'course_theme') {
          this.getCourseThemeSets(); //当前主题
        }
        
      },
      immediate: true
    }
  },
  computed: {
    pageType() { //theme/template
      return this.$route.params.type
    }
  },
  created() {
    // this.getThemeSets();
  },
  methods: {
    ...mapActions(['GetCourseDetail']),
    getThemeSets(isCourse = false) {
      //获取主题设置
      let params = {
        theme_key: this.$route.params.contentId,
        course_id: this.$route.params.id
      };
      getThemeSetting(params).then(res => {
        if(res.errorcode == 0) {
          this.detail = res.data;
          this.ruleForm = res.data.theme_variables;
          this.tab_sort = res.data.setting.tab_sort;
          this.tab_list = res.data.setting.tab_list;
        }
      }).catch();
    },
    getCourseThemeSets() {
      // 获取课程主题
      getCourseThemeSetting({
        course_id: this.$route.params.id
      }).then(res => {
        if(res.errorcode == 0) {
          this.detail = res.data;
          this.ruleForm = res.data.theme_variables;
          this.tab_sort = res.data.setting.tab_sort;
          this.tab_list = res.data.setting.tab_list;
        }
      }).catch();
    },
    getTemplateSets() {
      // 获取模板设置
      getTemplateSetting({
        course_id: this.$route.params.id,
        id: this.$route.params.contentId
      }).then(res => {
        if(res.errorcode == 0) {
          this.detail = res.data;
          this.ruleForm = res.data.theme_variables;
          this.tab_sort = res.data.setting.tab_sort;
          this.tab_list = res.data.setting.tab_list;
        }
      }).catch();
    },
    saveTheme() {
      // 主题修改，保存
      let course_id = this.$route.params.id;
      saveCourseThemeSetting({
        course_id: course_id,
        theme_key: this.$route.params.contentId,
        theme_variables: this.ruleForm
      }).then(res => {
        if(res.errorcode == 0) {
          this.$message.success(res.msg || "保存成功！");
          // 刷新课程-主题
          this.GetCourseDetail({course_id,refreshOther:false});
        }
      }).catch();
    },
    saveTemplate() {
      // 模板修改，保存
      let course_id = this.$route.params.id;
      saveTemplateSetting({
        course_id: course_id,
        id: this.$route.params.contentId,
        theme_variables: this.ruleForm
      }).then(res => {
        if(res.errorcode == 0) {
          // 刷新课程-主题
          this.GetCourseDetail({course_id,refreshOther:false});
          this.$message.success(res.msg || "保存成功！");
        }
      }).catch();
    },
    saveToTemplate() {
      // 主题设置/模板-保存到模板
      if(!this.templateData.name) {
        this.$message.warning("请输入模板名称！");
        return;
      }
      saveThemeTotemplate({
        name: this.templateData.name,
        course_id: this.$route.params.id,
        theme_key: this.$route.params.contentId,
        theme_variables: this.ruleForm
      }).then(res => {
        this.templateDialog.visible = false;
        if(res.errorcode == 0) {
          this.$emit("updateList",{type:"template"});
          this.$message.success(res.msg || "保存成功！");
        }
      }).catch(()=>{
        this.templateDialog.visible = false;
      });
    },
    saveTemplateTips() {
      //保存到模板，弹窗，获取模板名
      this.templateData.name = "";
      this.templateDialog.visible = true;
    },
    resetToInit() {
      //重置主题-弹窗
      this.resetDialogDetail.visible = true;
    },
    resetTheme() {
      //重置主题
      resetTheme({
        course_id: this.$route.params.id,
        theme_key: this.$route.params.contentId
      }).then(res => {
        this.resetDialogDetail.visible  = false;
        if(res.errorcode == 0) {
          this.$message.success("重置成功")
          this.getThemeSets();
        }
      }).catch(() => {
        this.resetDialogDetail.visible  = false;
      });
    },
    showRenameModal() {
      // 重命名模板-弹窗
      if(this.pageType !== 'template') return;

      this.renameDialogDetail.visible = true;
      this.renameDialogDetail.inputTemp = this.detail.info && this.detail.info.name || "";
    },
    renameOfTemplate() {
      // 重命名模板
      let value = this.renameDialogDetail.inputTemp;
      if(!value) {
        this.$message.warning("请输入模板名称！");
        return;
      }
      renameTemplate({
        id: this.$route.params.contentId,
        name: value
      }).then(res => {
        this.renameDialogDetail.visible = false;
        if(res.errorcode == 0) {
          this.detail.info.name = value;
          this.$emit("updateList",{type:"template"});
          this.$message.success(res.msg);
        }else {

        }
      }).catch(_ => {
        this.renameDialogDetail.visible = false;
      });
    },
    inputTemplateName(val) {
      // 模板名称输入值
      this.renameDialogDetail.inputTemp = val;
    },
    closeRenameDialog(done) {
      done();
    },
    beforeCloseDialog(done, actionType) {
      done();
      if(actionType === 'templateSave') {
        
      }
    },
    tapTab(ind, item) {
      if(this.currentTab == ind) return;
      this.currentTab = ind;
    },
    colorChange(val,prop) {
      // 颜色选择改变
      this.ruleForm[prop] = val;
      this.$forceUpdate();
    },
    colorActiveChange(val,prop) {
      // console.log('colorActiveChange',prop, val);
    },
    tapRefresh() {
      this.$forceUpdate();
      this.$nextTick(() => {
        this.$message.success("刷新成功!");
      });
    },
  },
}
</script>

<style lang="less" scoped>
  @headerHeight: 60px;
  .theme-container {
    padding: 16px;
    background-color: #EDEDED;
    box-sizing: border-box;
    // height: 100%;
    height: calc(100vh - 50px);
  }
  .theme-header {
    border-bottom: 1px solid #D4D4D4;
    padding: 16px;
    box-sizing: border-box;
    height: @headerHeight;
  }
  .theme-center-area {
    height: calc(100% - @headerHeight);
  }
  .theme-left {
    position: relative;
    .white-line {
      // 用于盖住 box-shadow
      position: absolute;
      background-color: #fafafa;
      z-index: 0;
      &.top {
        top: 0;
        left: 1px;
        right: 1px;
        height: 1px;
      }
      &.bottom {
        bottom: 0;
        left: 1px;
        right: 1px;
        height: 1px;
      }
      &.left {
        top: 0;
        bottom: 0;
        left: 0;
        width: 1px;
        height: 100%;
      }
    }
  }
  .theme-aside {
    position: relative;
    z-index: 0; //如果index大于0，会高于左侧弹出
    // border-right: 1px solid #D4D4D4;
    box-shadow: 0 0 0 1px inset #D4D4D4;
    overflow: visible scroll;
    height: 100%;
    background-color: #fafafa;
     &::-webkit-scrollbar-thumb {
    }
    &::-webkit-scrollbar {
      width: 0;
    }
    .active-right-line {
      position: absolute;
      top: 0;
      right: 0;
      height: 66px;
      width: 1px;
      background-color: #FFFFFF;
      z-index: 1;
    }
    
  }
  .tabs_item {
    position: relative;
    margin-right: -1px;
    box-sizing: border-box;
    width: 100%;
    height: 68px;
    line-height: 68px;
    text-align: center;
    font-size: 14px;
    color: #A1A1A1;
    border: 1px solid transparent;
    border-right-width: 0;
    // background-color: #fafafa;
    &.active {
      color: #222;
      border-top-color: #D4D4D4;
      border-left-color: #D4D4D4;
      border-bottom-color:  #D4D4D4;
      background-color: #FFFFFF;
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        display: block;
        content: "";
        width: 1px;
        background-color:#D4D4D4;
      }
    }
    &.active.isFirst {
      border-top-color: transparent;
    }
  }
  .theme-main {
    padding: 24px 16px 16px;
    .main-setting-title {
      position: relative;
      margin: 0 0 16px 8px;
      &::before {
        position: absolute;
        left: -9px;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        display: inline-block;
        border-radius: 2px;
        width: 2px;
        height: 14px;
        background-color: #D4D4D4;
      }
    }
  }
  .theme-main-setting {
    flex-shrink: 0;
    // width: calc(100% - 507px);
    flex: 5;
    min-width: 478px;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .main-setting-item {
    padding-bottom: 16px;
    width: 100%;
    flex-shrink: 0;
  }
  .theme-main-preview {
    box-sizing: border-box;
    flex-shrink: 0;
    flex: 4;
    max-width: 507px;
    padding: 10px 16px;
    background-color: #FAFAFA;
    .previewRefresh_btn {
      margin-bottom: 24px;
    }
  }
  .set-bgcolor-picker-wrapper {
    width: calc(50% - 16px); //16是margin-right
    .picker-colorvalue {
      width: calc(100% - 90px);
    }
  }
</style>