<template>
  <div class="icon-list-select prel">
    <div @click="showList" class="selected-img-wrapper pointer">
      <img :src="src" alt="" class="selected-img" loading="lazy">
    </div>
    <div v-if="showIconList" v-clickoutside="hide" class="icon-list-wrapper flex flexwrap " >
      <div v-for="(litem) in list" :key="litem.id" class="icon-list-item flexshrink">
        <div class="icon-list-imgwrap">
          <img  v-if="litem.icon" @click.stop="tapIcon(litem)" :src="litem.icon" alt="" class="icon-list-img" loading="lazy">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Clickoutside from 'element-ui/src/utils/clickoutside';
import { getIconList } from '@/libs/api/component'
export default {
  directives: { Clickoutside },
  props: {
    selected: String,
    default: String,
    componentName: String, //组件名称用来获取 哪个组件下的icon
  },
  data() {
    return {
      src: "",
      list:[],
      showIconList: false,
    }
  },
  created() {
    this.$nextTick(() => {
      if(!this.selected) {
        this.$emit("change",this.default);
        this.src = this.default; //默认
      }
      else this.src = this.selected;
    })
    this.getList();
  },
  methods: {
    hide() {
      // 点击了列表之外的地方
      this.showIconList = false;
    },
    getList() {
      getIconList(this.componentName).then((result) => {
        if(result.errorcode == 0) {
          this.list = result.data;
        }
      }).catch((err) => {
        
      });
    },
    tapIcon(item ) {
      item = JSON.parse(JSON.stringify(item));
      this.src = item.icon;
      this.showIconList = false;
      this.$emit("change",item.icon);
    },
    showList() {
      this.showIconList = true;
    },
  },
}
</script>

<style lang="less" scoped>
  .icon-list-select {
    .selected-img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      border: 1px solid #D4D4D4;
      width: 36px;
      height: 36px;
    }
    .selected-img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }
  .icon-list-wrapper {
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 1;
    background-color: #FFF;
    box-shadow: 0 2px 8px 0 rgba(0,0,0,0.12);
    border-radius: 2px;
  }
  .icon-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    cursor: pointer;
  }
  .icon-list-imgwrap {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; //将原本的颜色，偏离80px，并且隐藏掉
    border-radius: 50%;
    border: 1px solid #D4D4D4;
    width: 24px;
    height: 24px;
    transform: background 0.6s;
    background: transparent;
    &:hover {
      background: #EDEDED;
    }
  }

  .icon-list-img {
    // overflow: hidden; //将原本的颜色，偏离80px，并且隐藏掉
    width: 16px;
    height: 16px;
    // position: relative;
    // left: -80px;
    // filter: drop-shadow(#5098b6 80px 0);
  }
</style>