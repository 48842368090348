<template>
  <div class="answer-item-wrapper">
    <div v-for="(citem,key) in copyList" :key="'ai_'+key" class="flex flex_acenter answer-option" :gutter="18">
        <span v-if="setProps.prefix_text" class="fontsize_14 color_222 flexshrink padding_right8">{{setProps.prefix_text+ (key+1)}}:</span>
        <el-input :value="citem" :placeholder="setProps.placeholder + (key+1)" class="modal-sets-inlineinput noborder bgc_fff" @input="handleInput($event,key)"></el-input>
        <div  @click="handleDel(citem,key)" class="answer-option-del pointer">
          <i class="icon-menu-del"></i>
        </div>
    </div>
    <customButton color="#E74362" :width="64" :height="32" @click="addItem">
        <span class="fontsize_12">添加</span>
    </customButton>
  </div>
</template>

<script>
// placeholder: "请输入答案{{key}}"
// prefix_text: "答案{{key}}"
import customButton from "@/renderer/components/common/button.vue";
export default {
  props: {
    list: Array,
    setProps: Object, //属性设置
  },
  components: { customButton },
  data() {
    return {
      copyList: [],
    };
  },
  computed: {
  },
  watch: {
    list: {
      immediate:true,
      handler(n) {
        this.copyList = n || [];
      }
    }
  },
  created() {
    this.copyList = this.list || [];
  },
  methods: {
    handleInput(val,index) {
      this.$set(this.copyList, index, val);
      this.$emit("change",JSON.parse( JSON.stringify(this.copyList) ));
    },
    addItem() {
      this.copyList.push("");
      this.$emit("change",JSON.parse( JSON.stringify(this.copyList) ));
    },
    handleDel(item, index) {
      // 答案删除
      this.copyList.splice(index,1);
      this.$emit("change",JSON.parse( JSON.stringify(this.copyList) ));
    },
  },
}
</script>

<style lang="less" scoped>
    .answer-option {
        margin-bottom: 16px;
    }
    .answer-option-del {
      margin-left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 33px;
      height: 30px;
      &:hover {
        i:before {color: #E74362;}
      }
    }
</style>