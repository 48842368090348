<template>
  <draggable v-model="drapList" :disabled="!canDrag" 
    :delay="1"
    :fallbackTolerance="3"
    chosenClass="chosen" forceFallback="true" ghostClass="ghostClass" group="optionItem" animation="1000" 
    filter=".list-item-tools" @start="onStart" @end="onEnd" @sort="onSort"
    :style="{'--cursor':!canDrag ? 'default' :'move'}"
    >
    <transition-group name="flip-list" tag="div">
      <div class="item resource-wrapper pointer" :class="{'active':current==lind}" v-for="(litem,lind) in drapList" :key="lind + 'i'" @click.stop="tapAction(litem,lind,'edit')">
        <div class="list-item flex flex_jcbetween flex_acenter">
          <div class="list-item-content flex flex_acenter">
            <div class="list-item-title fontsize_14 color_222 flex flex_acenter">
              <div v-if="setProps.show_title_icon && setProps.title_icon && !showArrow" 
                :style="{
                  'background-image': getTitleImg(litem, setProps) ? `url(${getTitleImg(litem, setProps)}?x-oss-process=style/s)` : 'none'
                }" 
                class="title_icon">
              </div>
              <div v-else class="flex flex_acenter">
                <i class="icon-tree-folder4 round-arrow fontsize_14"  :class="{'active':current==lind}" ></i>
                <div v-if="setProps.show_title_icon && setProps.title_icon" 
                  :style="{
                    'background-image': getTitleImg(litem, setProps) ? `url(${getTitleImg(litem, setProps)})` : 'none'
                  }" 
                  class="round_icon">
                </div>
              </div>
              <div class="pover list-title-richhtml" 
                :class="{'padding_left10':setProps.show_title_icon && setProps.title_icon}" 
                v-html="getTitleHtml(litem, setProps.title)"></div>
            </div>
          </div>
          <div class="flex flex_end">
            <div class="list-item-tools flex flex_acenter" >
              <div v-for="(bitem,bind) in setProps.action" :key="'rbtn'+bind" class="list-item-one">
                <div v-if="bitem.display_type === 'icon'" 
                  class="list-item-action pointer prel "
                  @click.stop="tapAction(litem,lind,bitem.type)" >
                  <i class="icon-edit-black fontsize_14 color_222 icon_color_222" v-if="bitem.title == '编辑'"></i>
                  <i class="icon-menu-del fontsize_14 color_222 all_del_icon icon_color_222 icon_hover_E74362" v-else-if="bitem.title == '删除'"></i>
                  <i class="icon-page-copy fontsize_14 color_222 icon_color_222" v-else-if="bitem.type == 'copy'"></i>
                  <span class="icon-tips fontsize_12 color_222">{{bitem.title}}</span>
                </div>
                <div v-else-if="bitem.display_type === 'button'" @click.stop="tapAction(litem,lind,bitem.type)" class="margin_left5 pointer bgc_F7F7F7">
                  {{bitem.title}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition-group>
  </draggable>
</template>

<script>
//导入draggable组件
import draggable from 'vuedraggable';
import checkBox from "@/renderer/components/common/checkBox.vue";
export default {
  props: {
    setProps: Object,
    list: {
      type: Array,
      default: function() {
        return []
      }
    },
    current: Number,
    type: String, //['hotgraphic']
    showArrow: Boolean, //是否显示箭头
    canDrag: { //是否可以拖拽
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drapList:this.list,
      drag:false,
      editTimer:null
    };
  },
  watch: {
    list: {
      immediate:true,
      deep: true,
      handler(newVal) {
        this.drapList = newVal || [];
      }
    }
  },
  components: {
    checkBox,
    draggable,
  },
  methods: {
    //开始拖拽事件
    onStart(){
      this.drag=true;
      this.$emit('closeOptionsModal'); //关闭弹窗
    },
    //拖拽结束事件
    onEnd() {
      this.drag=false;
      
      this.$emit("updateSort", JSON.parse( JSON.stringify( this.drapList ) ) );
    },
    onSort(evt) {
      let { newDraggableIndex, oldDraggableIndex } = evt;
    },
    changeCheck(value,{item, prop, index}) {
      this.$emit("changeCheck", {item, prop, index, value});
    },
    tapAction(item,ind,type) {
      item = JSON.parse(JSON.stringify(item));
      switch(type) {
        case "edit":
          // 编辑
          if(!this.editTimer) {
            this.$emit("edit",{item:item,index:ind});
            this.editTimer = setTimeout( () => {
              this.editTimer = null;
            }, 500 );
          }
          break;
        case "copy":
          // 复制
          this.$emit("copy",{item:item,index:ind});
          break;
        case "delete":
          // 删除
          this.$emit("del",{item:item,index:ind});
          break;
      }
    },
    getTitleHtml(item, prop) {
      if(prop === '_captions[0].text') {
        return item._captions && item._captions[0] && item._captions[0].text || ''
      }
      if(prop.indexOf('.') >= 0) {
        let propsArr = prop.split('.');
        let res = item;
        propsArr.forEach( v => {
          res = res[v]
        } );
        return res;
      }
      return item[prop]
    },
    getTitleImg(item,setprop) {
      //获取背景图
      let propArr = setprop.title_icon.indexOf('.') > -1 ? setprop.title_icon.split(".") : setprop.title_icon;
      if(!Array.isArray(propArr)) return item[propArr]
      if(propArr.length == 1) return item[propArr[0]]
      else if(propArr.length == 2) return item[propArr[0]][propArr[1]]
      return ""
    }
  },
}
</script>

<style lang="less" scoped>
  /deep/.el-checkbox {
    margin-right: 5px;
  }
  .flip-list-move {
    transition: transform 1s;
  }

   /*被拖拽对象的样式*/
  .item {
    cursor: var(--cursor);
  } 
  /*选中样式*/
  .chosen {
    
  }
  /*停靠位置样式*/
  .ghostClass {
    opacity: 0.5 !important;
    border-width: 1px;
    border-color: #000;
    border-style: dashed !important;
  }

  .resource-wrapper {
    box-sizing: border-box;
    padding: 8px 16px;
    transition: background 0.6s;
    &.active,&:hover {
      background: #EDEDED;
    }
  }
  // 头部
  .list-item {
    box-sizing: border-box;
    border: 1px solid #CCCCCC;
    padding: 4px 8px;
    height: 46px;
    width: 100%;
    border-radius: 4px;
  }
  .list-item-content {
    // padding-left: 8px;
    width: calc(100% - 82px);
    .list-item-title {
      // width: 144px;
      /deep/p{
        font-size: 14px!important;
        color: #222!important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      /deep/span {
        font-size: 14px!important;
        color: #222!important;
      }
    }
    .list-title-richhtml {
      // max-width: 206px;
      width: 13vw;
      height: 18px;
      line-height: 18px;
    }
  }
  .list-item-tools {
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    
    .list-item-action {
      margin-left: 8px;
      &:hover {
        & > .icon-tips {
          display: block;
        }
      }

      >i {
        display: block;
        position: relative;
        border-radius: 4px;
        width: 33px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        color: #505050;
        background-color: #FAFAFA;
        &:hover {
          background-color: #e4e4e4;
          .icon-tips {
            display: block;
          }
        }
      }
    }

    .icon-tips {
      display: none;
      position: absolute;
      left: 50%;
      bottom: -2px;
      transform: translateY(100%) translateX(-50%);
      background: #F2F2F1;
      border: 1px solid #DADADA;
      border-radius: 1px;
      padding: 0 6px;
      height: 17px;
      line-height: 17px;
      white-space:nowrap;
    }
  }

  .title_icon {
    box-sizing: border-box;
    flex-shrink: 0;
    border: 1px solid #D4D4D4;
    border-radius: 1px;
    width: 38px;
    height: 38px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .round_icon {
    box-sizing: border-box;
    border: 1px solid #D4D4D4;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    // background-size: 24px 24px;
    background-size: 12px 12px;
    background-repeat: no-repeat;
    background-position: center;
  }
  .round-arrow {
    margin-right: 8px;
    width: 14px;
    transition: all 0.5s;
    &.active {
      transform: rotate(180deg);
    }
  }
</style>