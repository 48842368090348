<template>
  <div class="flex flexwrap flex_center" :style="{'--column-width':(100 / column)+'%'}">
    <fdCheckbox  v-for="(litem,lind) in list" :key="'checkbox_'+lind"
      class="margin_bot16 checkbox_one"
      :value="litem.value" 
      :label="litem.label" 
      :checked="getCheckStatus(litem.value)"
      :name="name"
      :graphic="litem._graphic"
      :disabled="disabled"
      :showRes="showRes"
      :isRightAnswer="litem._shouldBeSelected"
      :imgHeight="imgHeight"
      :imgSize="imgSize"
      @loadedImg="loadedImg"
      @errorImg="errorImg"
      @change="handleChange">
      </fdCheckbox>
  </div>
</template>

<script>
import fdCheckbox from "@/renderer/components/FormItem/fd-checkbox-graphic.vue";
export default {
  props: {
    name:String, //组别
    selected: Array, //选中值
    disabled: Boolean, //是否禁用
    showRes: Boolean, //是否显示结果
    //几列显示
    column:{
      type:[Number, String],
      default: 1,
    },
    list: {
      type: Array,
      default: function() {
        return [
          {
            value:"",
            label:"",
            id: 0,
          }
        ];
      }
    },
    imgHeight:String
  },
  components: {
    fdCheckbox
  },
  data() {
    return {
      selectedArr: this.selected, //选中值 []
      loadImageDetail: {
        count:0,
      }
    };
  },
  computed: {
    imgTotal() {
      // 图片总数
      let length = 0;
      if(Array.isArray(this.list)) {
        this.list.forEach(v => {
          if(v._graphic && v._graphic.large) {
            length++;
          }
        });
      }
      return length;
    },
    imgSize() {
      let column = this.column;
      if(column == 1) return "xl";
      if(column == 2) return "m";
      return "s";
    },
  },
  watch: {
    selected: {
      handler(n) {
        if(n != undefined) {
          //保证值是 String
          let newArr = JSON.parse(JSON.stringify(n));
          newArr.map((element,index) => {
            newArr[index] = String(element);
          });
          this.selectedArr = newArr;
        }
        // console.log(n, this.selectedArr);
      },
      immediate: true
    },
  },
  mounted() {
    this.loadImageDetail.count = 0;

    this.$nextTick(() => {
      //有图片时，需要等待图片加载完成
      if(this.imgTotal === 0) this.$emit("loaded");
    });
  },
  methods: {
    loadedImg(evt) {
      // 图片加载完成
      this.loadImageDetail.count++;

      if(this.loadImageDetail.count == this.imgTotal) {
        this.$emit("loaded");
      }
      this.$emit("loadimg", evt);
    },
    errorImg() {
      // 图片加载失败
      this.loadImageDetail.count++;

      if(this.loadImageDetail.count == this.imgTotal) {
        this.$emit("loaded");
      }
    },
    getCheckStatus(value) { 
      //是否勾选状态
      return this.selectedArr.indexOf(String(value)) > -1;
    },
    handleChange({value, checked}) { //value 接收到的是字符串格式
      // 选中状态改变
      let targetIndex = this.selectedArr.indexOf(value);
      
      if(checked && targetIndex == -1) {
        // 存储选中
        this.selectedArr.push(value);
      }else if(!checked && targetIndex != -1){
        // 取消选中
        this.selectedArr.splice(targetIndex,1);
      }
      this.$emit("change",this.selectedArr);
    },
  },
}
</script>

<style lang="less" scoped>
  .checkbox_one {
    box-sizing: border-box;
    padding: 0.5rem;
    width: calc(var(--column-width));
  }
</style>