<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true,'reset':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-tabs"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @reset="resetData"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div class="tabs__inner component__inner marginCenter" :class="[`is-${layout}-layout`]" :style="{'max-height':  layout === 'vertical' ? '477px' : '' }">
        <div class="tabs__widget component__widget clearfix breakword">
          <el-scrollbar :wrapStyle="navStyle"  class="tabs__nav prel">
            <div class="tabs__nav-inner" role="tablist">
              <button
                v-for="(titem, tind) in optionsItem"
                :key="tind"
                role="tab"
                :aria-selected="titem._isActive"
                :aria-controls="`${tind}-tabpanel`"
                :aria-label="`${titem.tabTitle}.${titem._isVisited ? `` : ''}`"
                :class="[
                  'tabs__nav-item-btn',
                  'js-tabs-nav-item-btn-click',
                  titem._isVisited && 'is-visited',
                  titem._isActive && 'is-selected'
                ]"
                @click="onTabItemClicked($event, tind)"
                @keyup="onTabItemKeyUp"
                :style="{width:itemWidth}"
                :data-index="tind">
                <div :id="`${tind}-tabtitle`" class="tabs__nav-item-btn-inner fontsize_16">{{titem.tabTitle}}</div>
              </button>
            </div>
          </el-scrollbar >
          <!-- :style="{'min-height':minHeight}" -->
          <el-scrollbar :wrapStyle="[{'overflow-x':'hidden', 'max-height': '477px'}]" class="tabs__content" >
            <div class="tabs__content-inner">
              <div
                  v-for="(titem, tind) in optionsItem"
                  :key="tind"
                  :id="`${tind}-tabpanel`"
                  role="tabpanel"
                  :aria-hidden="!titem._isActive || null"
                  :aria-labelledby="`${tind}-tabtitle`"
                  :data-index="tind"
                  :class="[
                    'tabs__content-item',
                    `item-${tind}`,
                    titem._classes,
                    titem._isActive && 'is-active'
                  ]"
                >
                  <div class="tabs__content-item-inner">
                    <div v-if="titem.title" class="tabs__content-item-title fontsize_16">
                      <div class="tabs__content-item-title-inner" role="heading" v-html="titem.title"></div>
                    </div>
                    <div v-if="titem.body" class="tabs__content-item-body fontsize_14">
                      <div class="tabs__content-item-body-inner" v-html="titem.body"></div>
                    </div>
                    <img v-if="titem._graphic" :src="titem._graphic.src" :alt="titem._graphic.alt" class="tabs__content-item-img">
                  </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            :listItemSet="{'canDrag':true}"
            @closeOptionsModal="closeOptionsModal"
            @sortOptionList="handleSort"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_tabs_sub'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_tabs'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import listCommon from '@/renderer/mixins/list/index';

/**
 * 标签
*/
export default {
  mixins: [hideModal, componentCommon, listCommon],
  components:{ topTools, rightForm, scaffoldForm, contentList, objForm },
  data() {
    return {
      currentForm: {},
      componentName:"adapt-lianda-tabs",
      ckeditorPrefix:"componentTabs",
      itemWidth:"auto",
      minHeight:"auto",
    };
  },
  watch: {
    layout: {
      handler(n) {
        if(n) {
          this.$nextTick(() => {
            this.setItemWidth();
          });
        }
      }
    },
    optionsItem: {
      handler() {
        this.$nextTick(() => {
          this.reset();
        });
      }
    }
  },
  computed: {
    optionsItem() {
      return this.detail["component-_items"];
    },
    layout() {
      let layout = this.detail["component-_tabLayout"];
      return layout;
    },
    navStyle() {
      if(this.layout === 'horizontal') return [{'overflow-y':'visible', 'height':'64px'}];
      return [{'overflow-x':'hidden', 'max-height': '477px'}];
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("loaded");
      this.reset();

      // 监听窗口大小变化
      // this.$el.addEventListener("resize", this.resizeEvent, true);
    });
  },
  destroyed() {
    // this.$el.removeEventListener("resize", this.resizeEvent, true);
    this.closePop();
  },
  methods: {
    resetData() {
      let items = this.optionsItem;
      items.forEach( (item, index) => {
        item._isActive = index === 0;
        item._isVisited = false;
      } );
    },
    resizeEvent() {
      this.$nextTick(() => {
        this.setItemWidth();
      });
    },
    openInnerDrawer({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value}) {
      // 显示\隐藏二层弹出窗口
      this.innerDrawer.form = {};
      this.innerDrawer.submitAsObj = submitAsObj || false; //整体提交
      this.innerDrawer.key_name = key_name;
      this.innerDrawer.parentDetail = parentDetail;
      this.innerDrawer.title = title;
      this.innerDrawer.propList = list;
      this.innerDrawer.current = index;
      if(value) {
        if(value._graphic && Array.isArray(value._graphic)) {
          value._graphic = {};
        }
        this.innerDrawer.form = value;
      }

      if(!visible || visible == 2) {
        this.innerDrawer.visible = false;
        return;
      }else{
        this.innerDrawer.visible = true;
      }
    },
    handleDel({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value, component_target}) {
      if(component_target !== 'component-_items') return;
      
      let items = this.detail[key_name];
      let itemsLen = items.length;
      if(index < itemsLen && index >= 0) {
        // 删除
        items.splice(index,1);
        // 是否当前打开
        if(visible == 2) {
          this.closeInnerDrawer();
        }
        this.saveDetail({
          [key_name]: items
        }, false);
      }
    },
    onItemsActiveChange(item, isActive) {
      if (!isActive) return;
      this.$set( item, '_isVisited',  true);
    },
    onTabItemClicked(e, index) {
      if (e && e.preventDefault) e.preventDefault();
      
      this.onItemsActiveChange(this.optionsItem[index], true);
      this.optionsItem.forEach( (_, curIndex) => {
        this.$set( this.optionsItem[curIndex], '_isActive',  curIndex === index);
      } );
    },
    onTabItemKeyUp(event) {
      // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/Tab_Role
      // const $tabs = $(event.currentTarget).parents('[role=tablist]').find('[role=tab]');
      // if (!$tabs.length) return;
      // let currentIndex = $tabs.toArray().findIndex(tab => tab === event.currentTarget);
      // switch (event.which) {
      //   case 37:
      //     if (currentIndex === 0) currentIndex = $tabs.length;
      //     currentIndex--;
      //     break;
      //   case 39:
      //     if (currentIndex === $tabs.length - 1) currentIndex = -1;
      //     currentIndex++;
      // }
      // a11y.focus($tabs[currentIndex]);
    },
    reset() {
      const firstItem = this.optionsItem[0];
      if (!firstItem) return;
      
      this.optionsItem.forEach( (item, index) => {
        this.$set( this.optionsItem[index], '_isActive',  index === 0);
        this.$set( this.optionsItem[index], '_isVisited',  index === 0);
      } );
    },
    setItemWidth () {
      const itemWidth = (this.layout === 'horizontal')
        ? 100 / this.optionsItem.length + '%'
        : '';
      // this.itemWidth = itemWidth;
    }
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/
  
  // 标签组件
  @duration: 0.2s;
  @animation-duration: 2000ms;
  @animation-easing: cubic-bezier(.23,1,.32,1);
  @item-padding: 4px 7px;
  @item-title-margin: 1rem;
  //----------------------
  // Horizontal layout (default mobile view)
  //----------------------
  .is-horizontal-layout .tabs__widget {
    flex-direction: column;

    /deep/.el-scrollbar__bar.is-vertical {
      display: none;
    }
    .tabs__nav {
      margin-bottom: -6px;
      border-radius: 4px 4px 0 0;
    }
    .tabs__nav-inner {
      padding-bottom: 10px;
      .tabs__nav-item-btn:last-child {
        border-radius: 0 4px 0 0;
      }
    }
    .tabs__nav-item-btn.is-visited::after {
      background-color: var(--visited, #CCCCCC);
    }
    .tabs__nav-item-btn.is-selected {
      position: relative;
      z-index: 1;
      &::after {
        position: absolute;
        bottom: 0;
        z-index: 2;
        left: 50%;
        transform: translateX(-50%) translateY(9px);
        display: block;
        content: "";
        width: 20px;
        height: 10px;
        background-color: var(--item-color-selected);
        clip-path: polygon(0 0, 100% 0, 50% 50%);
      }
    } 
  }

  //----------------------
  // Vertical layout (default mobile view)
  //----------------------
  .is-vertical-layout {
    .tabs__widget {
      // flex-direction: row-reverse;
    }

    .tabs__nav {
      margin-right: -10px;
      width: 30%;
    }

    .tabs__nav-inner {
      overflow: hidden;
      border-radius: 4px 0 0 4px;
      flex-direction: column;
      padding-right: 10px;
    }

    .tabs__content {
      overflow: hidden;
      border-radius: 4px;
      width: 70%;
    }

    .tabs__nav-item-btn {
      &:not(:last-child) {
        margin-bottom: 1px;
      }
      &.is-visited::after {
        background-color: var(--visited, #CCCCCC);
      }
      &.is-selected {
        &::after {
          position: absolute;
          right: 0;
          z-index: 2;
          top: 50%;
          transform: translateX(100%) translateY(-50%);
          display: block;
          content: "";
          width: 10px;
          height: 100%;
          background-color: var(--item-color-selected);
          clip-path: polygon(0 0, 0% 100%, 100% 50%);
        }
      }
    }
    /deep/.el-scrollbar__bar.is-horizontal {
      display: none;
    }
  }

  // ----------------------
  // Global
  // ----------------------
  .tabs__inner {
    margin-top: 16px;
    .tabs__widget {
      height: 100%;
    }
  }
  .tabs__nav {
    z-index: 1;
  }
  .tabs {
    &__nav-inner {
      // overflow: hidden;
      display: flex;
      border-radius: 4px 4px 0 0;
      // @media (max-width: @device-width-medium) {
      //   flex-direction: column;
      // }
    }

    &__widget {
      display: flex;
    }

    &__content-item {
      height: 0;
      overflow: hidden;
      opacity: 0;
      transform: translateY(20px);
    }

    &__content-item.is-active {
      height: auto;
      opacity: 1;
      transform: translateY(0);
      transition: all @animation-duration @animation-easing;
    }
  }

  //----------------------
  // Theme
  //----------------------
  .tabs {
    &__content {
      box-shadow: 0 1px 8px rgba(0,0,0,0.12);
      background-color: var(--item-color-inverted-selected);
    }

    &__content-inner {
      padding: 16px;
    }

    &__content-item-title {
      margin-bottom: 25px;
      line-height: 22px;
    }

    &__content-item-body a {
      // .link-text;
    }

    &__nav-item-btn {
      flex-shrink: 0;
      border: none;
      padding: 15px 16px;
      line-height: 22px;
      color: var(--item-color-inverted);
      background-color: var(--item-color);
      cursor: pointer;

      .no-touch &:not(.is-selected):hover {
        background-color: var(--item-color-hover);
        color: var(--item-color-inverted-hover);
        transition: background-color @duration ease-in, color @duration ease-in;
      }
    }

    &__nav-item-btn.is-visited {
      background-color: var(--visited, #CCCCCC);
      color: var(--visited-inverted, #FFFFFF);
    }

    &__nav-item-btn.is-selected {
      position: relative;
      z-index: 1;
      background-color: var(--item-color-selected);
      color: var(--item-color-inverted);
    }

    &__nav-item-btn-inner {
      text-align: left;
      .dir-rtl & {
        text-align: right;
      }
    }
  }
  .tabs__content-item-img {
    display: block;
    border-radius: 4px;
    margin-top: 10px;
    max-width: 100%;
  }
  .tabs__content-item-body {
    line-height: 25px;
    /deep/p:not(:last-child) {
      margin-bottom: 8px;
    }
  }
</style>