<template>
  <div 
    class="drag-caption-container" 
    :style="{
      'background-image': img && 'url('+img+')' || 'none',
      'width': width,
      'height': height,
      'background-repeat': 'no-repeat',
      'background-position': 'center',
      'background-size': 'cover',
    }"
    @mousedown.stop="hang"
    @mouseup.stop="drop"
    @mouseleave.stop="mouseleave"
  >
    <div class="blinds-popup-captions">
      <div v-for="(citem, cind) in list" :key="'caption_'+cind" 
        data-drag="1"
        :data-index="cind"
        class="blinds-popup-caption prel canDrag" 
        :class="{'focus':focusIndex === cind}"
        :style="{
          left: getUnitResult(citem.left),
          top: getUnitResult(citem.top),
          width: changeIndex == cind ? 'auto' : getUnitResult(citem.width),
        }">
        <div class="remove_btn flex flex_center flex_acenter pointer"
          @click.stop="tapRemove(citem, cind)"
          @mousedown.stop
          @mouseup.stop>
          <i class="el-icon-close fontsize_14 color_222"></i>
        </div>
        <el-input
          class="resize_input"
          ref="caption_input"
          @mouseup.native.stop="inputMouseUp($event, citem, cind)"
          @mousedown.native.stop="inputMouseDown($event, citem, cind)"
          @focus="focusCaption($event, citem, cind)"
          @input="inputCaption($event, citem, cind)"
          @blur="blurCaption($event, citem, cind)"
          type="textarea"
          resize="horizontal"
          autosize
          :placeholder="placeholder"
          v-model="citem.text">
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es';
export default {
  props: {
    //drag-caption-container
    parentWidth:{
      type: [Number,String],
      default: "auto"
    },
    parentHeight: {
      type: [Number,String],
      default: 480
    },
    img:String,
    list: Array,
    placeholder:{
      type: String,
      default: "请输入字幕"
    },
    current: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    shiftY: null,
    shiftX: null,
    left: 0,
    top: 0,
    moveIndex:-1,
    elem: null,
    ballElem:null,
    isIos: false,
    parent: {
      offsetLeft:0,
      offsetTop:0,
    },
    focusIndex: -1, //聚焦下标
    changeIndex:-1,
    
  }),
  computed: {
    width() {
      let width = this.parentWidth;
      if( !width ) width = 850;
      if( typeof width == "number" ) return width + "px";
      return width;
    },
    height() {
      let height = this.parentHeight;
      if( !height ) height = "auto";
      if( typeof height == "number" || /^[0-9]*[.]?[0-9]*$/.test(height)) return height + "px";
      return height;
    }
  },
  watch: {
    current: {
      immediate:true,
      handler(newVal) {
        if(newVal != undefined) {
          this.$nextTick( () => {
            this.initFocus();
          } );
        }
      },
    },
  },
  mounted() {
    this.isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    this.elem = this.$el;
    let { left, top } = this.elem.getBoundingClientRect();
    this.parent.offsetLeft = left;
    this.parent.offsetTop = top;
    // 需要初始化 每个字幕宽度
    this.$nextTick( () => {
      this.listenResizeWidth();
    } );
  },
  methods: {
    listenResizeWidth() {
      let inputElements = this.$el.querySelectorAll('.resize_input');
      inputElements.forEach((ele, ind) => {
        const emitDebouncedResizeWidth = debounce(entries => {
          for (let entry of entries) {
            if( this.changeIndex >= 0 ) {
              let ele = this.$el.getElementsByClassName("blinds-popup-caption")[this.changeIndex];
              let width = ele.offsetWidth; // 12是三角形部分,不用加

              this.list[this.changeIndex].width = width;
              this.$emit("change", {list: JSON.parse( JSON.stringify(this.list) ) });
            }
          }
        }, 600, { leading:true });
        const resizeObserver = new ResizeObserver(emitDebouncedResizeWidth);
        resizeObserver.observe(ele);
      });
    },
    initFocus() {
      // 默认最新的聚焦状态
      let inputEls = this.$refs.caption_input;
      if(!inputEls) return;

      if( this.current != -1 && inputEls[this.current] ) {

        inputEls[this.current].focus();

      }else if( inputEls[inputEls.length - 1] ){

        inputEls[inputEls.length - 1].focus();

      }
    },
    inputMouseUp(e, item, index) {
      // 鼠标在输入框弹起
      let ele = this.$el.getElementsByClassName("blinds-popup-caption")[this.changeIndex];
      let width = ele.offsetWidth; // 12是三角形部分,不用加

      this.list[this.changeIndex].width = width;

      this.changeIndex = -1;
      this.$emit("change", {list: JSON.parse( JSON.stringify(this.list) ) });
    },
    inputMouseDown(e, item, index) {
      this.changeIndex = index;
    },
    tapRemove(item, index) {
      // 点击删除字幕
      this.$emit("del", { item, index })
    },
    inputCaption(value, item, index) {
      this.$emit("change", {list: JSON.parse( JSON.stringify(this.list) ) });
    },
    getUnitResult(value) {
      if(!value) return 0;
      // number
      if(/^\d+[.]?(\d+)?$/.test(value)) return value + "px";
      // // %
      // if(typeof value === "string" && value.indexOf("%") >= 0) return value;
      // // px 
      // if(/^\d+[.]?(\d+)?px$/.test(value)) return value;
      return value;
    },
    focusCaption(e, item, index) {
      this.focusIndex = index;
    },
    blurCaption(e, item, index) {
      this.focusIndex = -1;
    },
    mouseleave(e) {
      // 鼠标移出容器
    },
    hang(e) {
      let { index } = e.target.dataset;
      
      // 鼠标按下
      if(e.pageX - this.parent.offsetLeft > (this.parentWidth - 12) || e.pageY - this.parent.offsetTop > this.parentHeight) {
        // 点击-范围不在容器内
        // this.drop(e);
        // return;
      }
      //鼠标按下在字幕上
      if( e.target.dataset.drag == 1) {
        this.moveIndex = index;
        this.ballElem = e.target;
        this.hangBall(e,index);
        this.elem.addEventListener("mousemove", this.elementMove);
      }else {
        // 点击-范围不在字幕
        this.drop(e);
      }
    },
    hangBall(e, index) {
      //shiftX：鼠标在小球上的位置 + 父元素的位置
      this.shiftX = e.pageX  ? e.pageX - this.ballElem.offsetLeft : e.changedTouches[0].pageX - this.ballElem.offsetLeft;
      this.shiftY = e.pageY ? e.pageY - this.ballElem.offsetTop : e.changedTouches[0].pageY - this.ballElem.offsetTop;
      if (e.pageX) {
        if (this.isIos) {
          this.elem.addEventListener("touchmove", this.elementMove);
        } else {
          this.elem.addEventListener("mousemove", this.elementMove);
        }
      } else {
        this.elem.addEventListener("touchmove", this.elementMove);
      }
    },
    drop(e) {
      if(this.moveIndex === -1) return;

      //鼠标按下
      document.body.style.overflow = null;
      this.elem.removeEventListener("mousemove", this.elementMove, false);
      this.elem.onmouseup = null;

      this.list[this.moveIndex].left = this.left;
      this.list[this.moveIndex].top = this.top;

      this.ballElem = null;
      this.moveIndex = -1;
      
      this.$emit("change", {list: JSON.parse( JSON.stringify(this.list) ) });
    },
    elementMove(e) {
      this.$emit("dragging");
      e.preventDefault();
      if (!e.pageX) {
        document.body.style.overflow = "hidden";
      }
      const x = e.pageX || e.changedTouches[0].pageX;
      const y = e.pageY || e.changedTouches[0].pageY;
      let newLeft = x - this.shiftX;
      let newTop = y - this.shiftY;
      const newRight = x - this.shiftX + this.ballElem.offsetWidth;
      const newBottom = y - this.shiftY + this.ballElem.offsetHeight;
      
      if (newLeft < 0) {
        newLeft = 0;
      } else if (newRight > (this.parentWidth - 12)) {
        // 小球即将-X轴上超出容器范围
        newLeft = (this.parentWidth - 12) - this.ballElem.offsetWidth;
      } else {
        newLeft = x - this.shiftX;
      }
      if (newTop < 0) {
        newTop = 0;
      } else if (newBottom > this.parentHeight) {
        // 小球即将-Y轴上超出容器范围
        newTop = this.parentHeight - this.ballElem.offsetHeight;
      } else {
        newTop = y - this.shiftY;
      }
      
      this.ballElem.style.left = `${ newLeft }px`;
      this.left = newLeft;
      this.ballElem.style.top = `${ newTop }px`;
      this.top = newTop;
    },
  },

}
</script>

<style lang="less" scoped>
  .canDrag {
    cursor: move;
  }
  // 拖拽容器
  .drag-caption-container {
    z-index: 2;
    position: relative;
    top: 0;
    left: 0;
    box-sizing: border-box;
    margin-inline: auto;
    width: max-content;
    height: "auto";
    // background-size: auto 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    // border: 1px solid red;
  }
  // 拖拽字幕
  .blinds-popup-captions {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    right: 0;
    left: 12px; //三角形部分宽度一半
  }
  .blinds-popup-caption {
    position: absolute;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 10px 0 0 8px;
    height: auto;
    font-size: 14px;
    color: #FFFFFF;
    background-color: rgba(0,0,0,0.60);
    white-space: normal;
    &::before {
      display: block;
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-100%, -50%);
      border-width: 6px;
      border-left-width: 12px;
      border-right-width: 12px;
      border-style: solid;
      border-color: transparent;
      border-right-color: rgba(0,0,0,0.60);
    }
    &.focus {
      background-color: rgba(0,0,0,0.80);
      &::before {
        border-right-color: rgba(0,0,0,0.80);
      }
    }
    .remove_btn {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
      transform: translate(50%, -50%);
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background-color:#FFFFFF;
      &:hover {
        background-color: #FF4D4F;
      }
    }
  }
  // 文本域
  /deep/.el-textarea__inner {
    border: none;
    padding: 0 8px 10px 0;
    color: #FFFFFF;
    background-color: transparent;
    height: auto;
    line-height: 20px;
    max-height: 300px; //受限于当前容器多高宽-top
    max-width: 300px; //受限于当前容器多宽-left
  }
</style>