<template>
  <el-form @submit.native.prevent action="false" :model="ruleForm" class="right-form-container height_100p prel bgc_fff" style="z-index:1">
    <el-tabs
      v-model="activeTab"
      @tab-click="handleClick"
      :stretch="true"
      :style="{'--tabs-num': tabsLength}"
    >
      <el-tab-pane
        v-for="(pitem, pind) in tabList"
        :key="pitem.id"
        :name="pind + ''"
      >
        <span slot="label" class="fontsize_14" :class="{'color_222':activeTab==pind, 'color_A1A1A1':activeTab!=pind}">{{ pitem.tab_group_name}}</span>
        <div class="scroll-parent hidden">
          <div class="scroll-box width0">
            <div v-if="pitem.special_key != 'course-style-right' && pitem.special_key != 'contentobject-style-right'">
              <div v-for="sitem in pitem.setting_list" :key="sitem.id" class="modal-sets-item padding_0_16 prel" 
                :class="{'border_topED padding_top25': sitem.display == 1 && sitem.pivot && sitem.pivot.dividing_line == 1 }" >
                <p class="title" v-if="sitem.display == 1">{{ sitem.title }}</p>
                <p v-if="sitem.display == 1 && sitem.help" class="fontsize_12 color_A1A1A1 margin_bot10">{{sitem.help}}</p>
                <div v-if="sitem.properties && sitem.input_type !== 'pop-up_button'" 
                  :style="{'--color-picker-column': sitem['itemNum']}"
                  :class="{'flex flex_jcbetween': sitem.properties_name == '_backgroundStyles' || sitem.properties[0]['input_type'] === 'color_picker_mini',
                  'flex flexwrap flex_jcbetween':sitem['itemNum'],}"
                  class="flexwrap"
                  >
                  <div v-for="(ritem, prind) in sitem.properties" :key="ritem.id" 
                    :class="{'margin_bot20': prind !== sitem.properties.length - 1 && ritem['input_type'] != 'checkbox', 
                    'set-bgimg-select-container right':  ritem.display == 1 && ritem['input_type'] == 'single_select',
                    'multiColumns':sitem['itemNum']
                    }">
                    <p class="margin_bot8" v-if="ritem.display == 1 && (ritem['input_type'] == 'number' || ritem['input_type'] == 'truefalse')">{{ritem.title}}</p>
                    <ckeditor v-if=" ritem.display == 1 && ritem['input_type'] == 'richtext_mini'"
                      type="simple" 
                      :id=" ckeditorPrefix + '-' + ruleForm[ckeditorNameId] + '-' + ritem.id" 
                      :value="currentForm[ritem.key_name]" 
                      :throttle="throttleDelay"
                      :placeholder="ritem.placeholder"
                      @input="richTextInput($event,ritem.key_name)" 
                      @blur="formItemBlur($event,sitem.key_name)">
                    </ckeditor>
                    <ckeditor v-else-if=" ritem.display == 1 && ritem['input_type'] == 'richtext' "
                      :type="'rich'" 
                      :id="ckeditorPrefix + '-' + ruleForm[ckeditorNameId] + '-' + ritem.id" 
                      :value="currentForm[ritem.key_name]" 
                      :throttle="throttleDelay"
                      :placeholder="ritem.placeholder"
                      @input="richTextInput($event,ritem.key_name)" 
                      @blur="formItemBlur($event,sitem.key_name)" >
                    </ckeditor>
                    <div v-else-if="ritem.display == 1 && (ritem['input_type'] == 'number' || ritem['input_type'] == 'untitled_number')" class="flex flex_acenter" >
                      <NumberInput
                        :id="ckeditorPrefix+'_'+ ruleForm[ckeditorNameId] +'_'+ ritem.id"
                        :keyName="ritem.key_name"
                        :min="ritem.min"
                        :value="ruleForm[ritem.key_name]/( ritem.unit_value_multiple ? ritem.unit_value_multiple : 1 )"
                        :rightTips="ritem.unit"
                        :valueMultiple="ritem.unit_value_multiple"
                        :placeholder="ritem.placeholder"
                        @input="handleNumberInput($event,ritem.key_name)"
                        @blur="formItemBlur($event.value,ritem.key_name, {el:$event.el, callback:$event.callback})" >
                      </NumberInput>
                    </div>
                    <el-select v-else-if=" ritem.display == 1 && ritem['input_type'] == 'single_select' "
                      :popper-append-to-body="false"
                      :value="ruleForm[ritem.key_name]"
                      @change="selectChange($event,ritem.key_name)"
                      @mouseenter.stop=""
                      @mouseleave.stop=""
                      placeholder="请选择"
                      class="set-bgimg-select noborder"
                    >
                      <el-option
                        @mouseenter.stop=""
                        @mouseleave.stop=""
                        v-for="item in ritem.enum"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                    <assetupload v-else-if=" ritem.display == 1 && ritem['input_type'] == 'image' "
                      :src="ruleForm[ritem.key_name]"
                      :uploadText="ritem.upload_text"
                      @change="uploadImg($event,ritem.key_name)"
                    ></assetupload>
                    <checkBox v-else-if=" ritem.display == 1 && ritem['input_type'] == 'checkbox'" 
                      :value="ruleForm[ritem.key_name]" :label="ritem.title" 
                      @change="checkboxBlur($event,ritem.key_name, ritem)" 
                      class="margin_bot24" >
                    </checkBox>
                    <el-switch
                      v-else-if="ritem.display == 1 && ritem['input_type'] == 'truefalse'" 
                      @change="switchChange($event,ritem.key_name)"
                      v-model="ruleForm[ritem.key_name]"
                      :width="52"
                      active-color="#E74362"
                      inactive-color="#D4D4D4"
                      class="">
                    </el-switch>
                    <div class="set-bgcolor flex1" v-else-if="ritem.display == 1 && (ritem['input_type'] == 'color_picker' || ritem['input_type'] == 'color_picker_mini')">
                      <div class="prel set-bgcolor-picker-wrapper" :class="{'width144': ritem['input_type'] == 'color_picker_mini'}">
                        <fd-color-picker
                          :class="{'set-bgcolor-picker':true,'notValue': !ruleForm[ritem.key_name] }"
                          popper-class=""
                          :value="courseForm[ritem.key_name]"
                          :show-alpha="true"
                          color-format="hex"
                          @enterColorPick="enterColorPick"
                          @change="colorChange($event,ritem.key_name)"
                          @active-change="colorActiveChange"
                          :predefine="predefineColors">
                        </fd-color-picker>
                        <span class="picker-colorvalue">{{ritem.title}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="sitem.properties && sitem.input_type === 'pop-up_button' && sitem.display == 1" class="">
                  <el-switch
                    v-if="sitem.display == 1 && sitem['special_key'] == 'trickle'" 
                    @change="switchChange($event,sitem.key_name,()=>{showSecondDialog(sitem.properties,sitem.title)})"
                    v-model="ruleForm[sitem.key_name]"
                    :width="52"
                    active-color="#E74362"
                    inactive-color="#D4D4D4"
                    class="margin_right16">
                  </el-switch>
                  <customButton v-if="sitem.input_type === 'pop-up_button' && sitem['special_key'] != 'trickle'" 
                    @click="showSecondDialog(sitem.properties,sitem.title)" plain color="#E74362" :width="52" :height="30">{{sitem.buttontext}}</customButton>
                  <div v-if="sitem['special_key'] == 'trickle'" @click="showSecondDialog(sitem.properties,sitem.title)" class="trickle_setting">
                    <div class="trickle_bg"></div>
                    <span>{{sitem.buttontext}}</span>
                  </div>
                </div>
                <el-input v-if="sitem.display == 1 && sitem['input_type'] == 'text' " 
                  :value="currentForm[sitem.key_name]" 
                  :placeholder="sitem.placeholder" class="modal-sets-inlineinput noborder bgc_fff"
                  @input="richTextInput($event,sitem.key_name)"
                  @blur="formItemBlur($event.target.value,sitem.key_name)"></el-input>
                <ckeditor v-else-if=" sitem.display == 1 && sitem['input_type'] == 'richtext_mini' "
                  :type="'simple'" 
                  :id="ckeditorPrefix + '-' + ruleForm[ckeditorNameId] + '-' + sitem.id" 
                  :value="currentForm[sitem.key_name]" 
                  :throttle="throttleDelay"
                  :canKeyEnter="canKeyEnter || sitem.properties_name !== 'displayTitle' && sitem.properties_name !== 'title'"
                  :placeholder="sitem.placeholder"
                  @input="richTextInput($event,sitem.key_name)" 
                  @blur="formItemBlur($event,sitem.key_name)" >
                </ckeditor>
                <ckeditor v-else-if=" sitem.display == 1 && sitem['input_type'] == 'richtext' "
                  :type="'rich'" 
                  :id="ckeditorPrefix + '-' + ruleForm[ckeditorNameId] + '-' + sitem.id" 
                  :value="currentForm[sitem.key_name]" 
                  :throttle="throttleDelay"
                  :canKeyEnter="canKeyEnter || sitem.properties_name !== 'displayTitle' && sitem.properties_name !== 'title'"
                  :placeholder="sitem.placeholder"
                  @input="richTextInput($event,sitem.key_name)" 
                  @blur="formItemBlur($event,sitem.key_name)" >
                </ckeditor>
                <div v-else-if="sitem.display == 1 && (sitem['input_type'] == 'number' || sitem['input_type'] == 'untitled_number')" class="flex flex_acenter">
                  <NumberInput
                    :id="ckeditorPrefix+'_'+ ruleForm[ckeditorNameId] +'_'+ sitem.id"
                    :keyName="sitem.key_name"
                    :min="sitem.min"
                    :value="ruleForm[sitem.key_name]/( sitem.unit_value_multiple ? sitem.unit_value_multiple : 1 )"
                    :rightTips="sitem.unit"
                    :valueMultiple="sitem.unit_value_multiple"
                    :form="ruleForm"
                    :placeholder="sitem.placeholder"
                    @input="handleNumberInput($event,sitem.key_name)"
                    @blur="formItemBlur($event.value,sitem.key_name, {el: $event.el, callback:$event.callback})"
                  ></NumberInput>
                </div>
                <el-select
                  v-else-if="sitem.display == 1 && sitem['input_type'] == 'single_select'"
                  :value="ruleForm[sitem.key_name]"
                  @change="selectChange($event,sitem.key_name)"
                  placeholder="请选择"
                  class="set-bgimg-select noborder"
                >
                  <el-option
                    v-for="item in sitem.enum"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <checkBox v-else-if=" sitem.display == 1 && sitem['input_type'] == 'checkbox'" 
                  :value="ruleForm[sitem.key_name]" :label="sitem.title" @change="checkboxBlur($event,sitem.key_name, sitem)" class="margin_bot24"></checkBox>
                <el-switch
                  v-else-if="sitem.display == 1 && sitem['input_type'] == 'truefalse'" 
                  @change="switchChange($event,sitem.key_name,function(){})"
                  v-model="ruleForm[sitem.key_name]"
                  :width="52"
                  active-color="#E74362"
                  inactive-color="#D4D4D4"
                  class="">
                </el-switch>
                <assetupload v-else-if="sitem.display == 1 && sitem['input_type'] == 'image' "
                  :required="sitem.is_required"
                  :uploadText="sitem.upload_text"
                  :src="ruleForm[sitem.key_name]"
                  @change="uploadImg($event,sitem.key_name)"
                ></assetupload>
                <AltInput v-else-if=" sitem.display == 1 && sitem['input_type'] == 'alt' "
                  placeholder="可选文本，可不填"
                  :value="ruleForm[sitem.key_name]"
                  @input="changeVal($event,sitem.key_name)"
                  @blur="formItemBlur($event,sitem.key_name)">
                </AltInput>
                <div v-else-if="sitem.display == 1 && sitem.input_type === 'list'">
                  <listItems 
                    :list="ruleForm[sitem.key_name]" 
                    :setProps="sitem.item"
                    style="margin-left:-16px;margin-right:-16px;"
                    class="margin_bot8"
                    :canDrag="listItemSet && listItemSet.canDrag"
                    :showArrow="listItemSet && listItemSet.showArrow"
                    :current="listItemData.current"
                    @closeOptionsModal="closeOptionsModal"
                    @updateSort="sortList({list: $event, propName: sitem.key_name})"
                    @edit="tapItems({value:$event.item,index:$event.index,action:'edit',propName:sitem.key_name,parentProps:sitem})"
                    @copy="tapItems({value:$event.item,index:$event.index,action:'copy',propName:sitem.key_name,parentProps:sitem})"
                    @del="tapItems({value:$event.item,index:$event.index,action:'del',propName:sitem.key_name,parentProps:JSON.parse(JSON.stringify(sitem))})"
                    ></listItems>
                  <customButton  v-if="sitem.item && sitem.item.button_title" plain color="#E74362" hoverBorderColor="#B5102F" :width="52" :height="30" 
                    @click="addItems(JSON.parse(JSON.stringify(sitem)))"
                     class="sureBtn pointer button_hover_B5102F">
                    <span class="fontsize_12">{{sitem.item.button_title}}</span>
                  </customButton>
                </div>
                <fileOption v-else-if="sitem.display == 1 && sitem['input_type'] == 'resource'" 
                  :src="ruleForm[sitem.key_name]"  :exts="sitem.exts"
                  :name="ruleForm[sitem.key_name + '__resouce_name']"
                  :cover="ruleForm[sitem.key_name + '__resouce_cover']"
                  :checkType="ruleForm[sitem.key_name + '__resouce_type']"
                  @change="fileChange($event,sitem.key_name)"></fileOption>
                <rangeOption v-else-if="sitem.display == 1 && sitem['input_type'] == 'select_range'" 
                  :options="sitem.optional"
                  :keyName="sitem.key_name"
                  :ruleForm="ruleForm"
                  :checkType="ruleForm[sitem.key_name]"
                  @change="formItemBlur($event.value,$event.propName)"></rangeOption>
                <div v-else-if="sitem.display == 1 && sitem['input_type'] == 'textinput_answer'">
                  <inputList  
                    :list="ruleForm[sitem.key_name]" 
                    :setProps="sitem.items"
                    :placeholder="sitem.placeholder"
                    style="margin-left:-16px;margin-right:-16px;"
                    class="margin_bot8"
                    :canDrag="listItemSet && listItemSet.canDrag"
                    :showArrow="listItemSet && listItemSet.showArrow"
                    @closeOptionsModal="closeOptionsModal"
                    @updateSort="sortList({list: $event, propName: sitem.key_name})"
                    @change="tapInputList({value:$event.item,index:$event.index,action:'change',propName:sitem.key_name,parentProps:sitem})"
                    @copy="tapInputList({value:$event.item,index:$event.index,action:'copy',propName:sitem.key_name,parentProps:sitem})"
                    @del="tapInputList({value:$event.item,index:$event.index,action:'del',propName:sitem.key_name,parentProps:JSON.parse(JSON.stringify(sitem))})"
                  ></inputList>
                  <customButton  v-if="sitem.items && sitem.items.button_title" plain color="#E74362" hoverBorderColor="#B5102F" :width="52" :height="30" 
                    @click="addInputItems(JSON.parse(JSON.stringify(sitem)))"
                     class="sureBtn pointer button_hover_B5102F">
                    <span class="fontsize_12">{{sitem.items.button_title}}</span>
                  </customButton>
                </div>
              </div>
            </div>
            <div v-else class="margin_top24">
              <div class="pages-add padding_0_16">
                <div v-if="addPageControl.page" class="pages-add-item" @click.stop="addPage('page')">
                  <i class="icon-add-content-page fontsize_14">
                    <span class="path1"></span>
                    <span class="path2"></span>
                    <span class="path3"></span>
                    <span class="path4"></span>
                  </i>
                  <span class="margin_left8 fontsize_12 color_222" style="vertical-align: bottom;">{{$t('lang.contentpage')}}</span>
                </div>
                <div v-if="addPageControl.menu" class="pages-add-item margin_left24" @click.stop="addPage('menu')">
                  <i class="icon-add-theme-page fontsize_14">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </i>
                  <span class="margin_left8 fontsize_12 color_222" style="vertical-align: bottom;">{{$t('lang.themepage')}}</span>
                </div>
              </div>
              <collapsePanel accordion :list="contentObjectList" @openLeft="openInner" @closeLeft="closeInner" @del="handleDelPage" ref="collapsePanel">
              </collapsePanel>
            </div>
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-form>
</template>

<script>
import Config from '@/libs/config';
import { mapMutations, mapState, mapActions, mapGetters } from "vuex";
import NumberInput from "@/renderer/components/common/numberInput.vue";
import AltInput from "@/renderer/components/common/altInput.vue";
import checkBox from "@/renderer/components/common/checkBox.vue";
import fileOption from "@/renderer/components/common/fileOption.vue";
import rangeOption from "@/renderer/components/common/rangeOption.vue";
import collapsePanel from "@/renderer/components/common/collapsePanel.vue";
import customButton from "@/renderer/components/common/button.vue";
import listItems from "@/renderer/components/common/listItems.vue";
import inputList from "@/renderer/components/common/inputList.vue";
export default {
  props: {
    ruleForm: {
      type: Object,
      default: function() {
        return {};
      }
    },
    tabList:{
      type: Array,
      default: function() {
        return [];
      }
    },
    currentTab: [String, Number],
    ckeditorNameId: {
      type: [String, Number],
      required: true,
      default:""
    },
    ckeditorPrefix: { //用于输入框id前缀
      type: [String, Number],
      required: true,
      default:""
    },
    listItemSet: {
      type: Object,
      default: function() {
        return {
          showArrow: false, //是否显示items的箭头
          canDrag: false, //能否拖拽
        };
      }
    },
    addPageControl: {
      type: Object,
      default: function() {
        return {
          menu: false, //是否显示添加主题页
          page: false, //是否显示添加内容页
        };
      }
    },
  },
  components: {
    NumberInput,
    checkBox,
    AltInput,
    collapsePanel,
    customButton,
    listItems,
    fileOption,
    rangeOption,
    inputList
  },
  data() {
    return {
      canKeyEnter:Config.titleCkeditorCanKeyEnter, //标题能否回车换行
      activeTab: 0,
      //预定义颜色
      predefineColors: [
        '#FFFFFF', //为原始值，做历史记录用
        '#ff8c00',
        '#ffd700',
        '#90ee90',
        '#00ced1',
        '#1e90ff',
        '#c71585',
        'rgb(255, 120, 0)',
        'hsv(51, 100, 98)',
        'hsva(120, 40, 94, 0.5)',
        'hsl(181, 100%, 37%)',
        'hsla(209, 100%, 56%, 0.73)',
        '#c7158577'
      ],
      currentForm: this.ruleForm,
      throttleDelay:Config.realTimeInput,
      listItemData: {
        current:-1
      }
    };
  },
  watch:{
    currentTab(n) {
      this.activeTab = this.currentTab;
    }
  },
  computed: {
    ...mapState({
      contentObjectList: state => state.content.currentContentList
    }),
    ...mapGetters([
      'getThemeById',
      'getCourseFormById'
    ]),
    tabsLength() {
      let tabList = this.tabList;
      return tabList && Array.isArray( tabList ) ? tabList.length : 0
    },
    courseForm() {
      return this.getCourseFormById(this.$route.params.id) || {};
    },
  },
  methods: {
    ...mapActions(["CreateContentObject","GetTree","GetContentObjectSonList"]),
    handleDelPage({ item, index }) {
      this.$emit("handleDelPage", { item, index });
    },
    closeOptionsModal() {
      this.listItemData.current = -1;
      this.$emit("closeOptionsModal");
    },
    sortList({list, propName}) {
      //选项排序
      this.$emit("sortOptionList",{list, propName});
    },
    enterColorPick(event) {
      this.$emit('enterColorPick',event);
    },
    tapItems({action,propName,value, parentProps, index}) {
      // 改变是否当前项
      let changeCurrent = this.listItemData.current == index;
      
      switch(action) {
        case "edit":
          let { item, properties_name, key_name } = JSON.parse(JSON.stringify(parentProps));
          item = JSON.parse(JSON.stringify(item));
          if(!changeCurrent) {
            this.listItemData.current = index;
          }else {
            this.listItemData.current = -1;
          }
          this.$emit("open",{title:parentProps.title || "项目",list:item.properties,value,index, visible:!changeCurrent?1:2,submitAsObj:true, parentDetail:item,properties_name, key_name })
          break;
        case "copy":
          this.$emit("copy",{
            title:parentProps.title || "项目",
            list:parentProps.item.properties,
            value,
            index, 
            visible:2,
            submitAsObj:true, 
            parentDetail:parentProps.item,
            properties_name, 
            key_name:propName,
            'component_target':'component-_items' });
          break;
        case "del":
          this.$emit("del",{
            title:parentProps.title || "项目",
            list:parentProps.item.properties,
            value,
            index, 
            visible: changeCurrent ? 2 : 1,
            submitAsObj:true, 
            parentDetail:parentProps.item,
            properties_name: parentProps.item,properties_name, 
            key_name: propName,
            'component_target':'component-_items' 
          });
          if(changeCurrent) {
            // 删除当前项，关闭弹出
            this.listItemData.current = -1;
          }
          break;
      }
    },
    addItems({item, properties_name, key_name, title}) {
      let index = this.ruleForm[key_name] && this.ruleForm[key_name].length || 0; //index是数组下标

      //重置掉之前打开的
      this.listItemData.current = index;

      let requiredProps = item.properties.filter(v => v.required == 1);
      //有必填项时
      if(requiredProps.length > 0) {
        let newItem = {};
        let list = this.ruleForm[key_name];
        requiredProps.forEach(v => {
          if(v.default) newItem[v.input_name] = v.default;
        });
        list.push(newItem);
        this.$emit("formItemBlur", list, key_name,this.ruleForm);
        this.$emit("open",{index, list:item.properties, title:title || "项目", visible:1,submitAsObj:true, parentDetail:item,properties_name, key_name,
          value:newItem});
        return;
      }

      // 无必填项时
      this.$emit("open",{index, list:item.properties, title:title || "项目", visible:1,submitAsObj:true, parentDetail:item,properties_name, key_name});
    },
    addInputItems({item, properties_name, key_name, title}) {
      let list = this.ruleForm[key_name];
      list.push("");
      this.$emit("formItemBlur", list, key_name,this.ruleForm);
    },
    tapInputList({action,propName,value, parentProps, index}) {
      let list = JSON.parse( JSON.stringify(this.ruleForm[propName]) );
      switch(action) {
        case "change":
          list[index] = value;
          this.$emit("formItemBlur", list, propName,this.ruleForm);
          break;
        case "copy":
          let newOne = this.ruleForm[propName][index];
          list.splice(index+1,0,newOne);
          this.$emit("formItemBlur", list, propName,this.ruleForm);
          break;
        case "del":
          list.splice(index,1);
          this.$emit("formItemBlur", list, propName,this.ruleForm);
          this.$forceUpdate();
          break;
      }
    },
    showSecondDialog(propertiesList, title) {
      // 显示二层弹出窗口
      // 如果之前有显示，则
      if(this.listItemData.current != -1) {
        this.listItemData.current = -1;
      }
      this.$emit("open",{list:propertiesList, title, visible:1});
    },
    colorActiveChange(activeVal) {
      // 面板中当前显示的颜色发生改变时触发
      // console.log("colorActiveChange",activeVal);
    },
    colorChange(colorVal, prop) {
      // 当绑定值变化时触发
      // console.log("colorChange",colorVal);
      this.$emit("formItemBlur", colorVal, prop,this.ruleForm)
    },
    switchChange(val,prop, callback) {
      // 开关切换
      this.ruleForm[prop] = val;
      this.$forceUpdate();
      this.$emit("formItemBlur", val,prop)
      if(callback && typeof callback === 'function') {
        // 有回调
        !!val && callback();
      }
    },
    addPage(pageType) {
      if(!['page','menu'].includes(pageType)) return;
      let course_id = this.$route.params.id;
      let contentObject_id = this.$route.params.contentId;
      let params = {
        course_id: course_id,
        _parentId: contentObject_id,
        _type: pageType,
      };
      if(this.$route.query.pageType == 'index') { //首页
        params._parentId = course_id;
      }
      this.CreateContentObject(params).then(res => {
        if(res.errorcode == 0) {
          this.$message("创建成功！");

          // 刷新中间主题列表
          let contParams = {
            course_id: course_id,
          };
          if(this.$route.query.pageType != 'index') {
            contParams.contentObjectId = contentObject_id;
          }
          this.GetContentObjectSonList(contParams);
        }
      }).catch();
    },
    closeTellChild() {
      // 告诉collapsePanel，关闭
      if(this.$refs.collapsePanel && Array.isArray(this.$refs.collapsePanel)) {
        this.$refs.collapsePanel[0].clearAllStatus();
      }
    },
    closeListItems() {
      // 关闭listItem的展开
      this.listItemData.current = -1;
    },
    openInner(item,index) {
      this.$emit('open',item,index)
    },
    closeInner(item,index) {
      this.$emit('close',item,index)
    },
    handleClick(tab, event) {
      if(this.activeTab !== 1) {
        if(this.$refs.collapsePanel && Array.isArray(this.$refs.collapsePanel)) {
          this.$refs.collapsePanel[0].clearAllStatus();
        }
        this.$emit("close",{list:[], title:"", visible:false});
      }
    },
    toOpenInner(item,i) {
      // 外部调用内部
      if(item === undefined || i === undefined) return;
      if(this.$refs.collapsePanel && Array.isArray(this.$refs.collapsePanel)) {
        this.$refs.collapsePanel[0].open(item,i);
      }
    },
    toOpenWithStatus(item,i) {
      // 外部调用内部
      if(item === undefined || i === undefined) return;
      if(this.$refs.collapsePanel && Array.isArray(this.$refs.collapsePanel)) {
        this.$refs.collapsePanel[0].openWithStatus(item,i);
      }
    },
    uploadImg(val,propName) {
      this.$emit("formItemBlur", val, propName)
    },
    changeVal(value, prop) {
      this.$emit("changeVal",value,prop);
    },
    richTextInput(value, prop) {
      this.currentForm[prop] = value;
      this.$emit("formItemInput", value, prop)
    },
    fileChange(value, prop) { //文件相关属性修改
      let obj = {
        [prop]: value.url,
        [prop+'__resouce_name']: value.name,
        [prop+'__resouce_cover']: value.cover,
        [prop+'__resouce_type']: value.type,
      };
      this.$emit("changeListItem", JSON.parse(JSON.stringify(obj)), null);
    },
    formItemBlur(value, prop, args = {}) {
      this.$emit("formItemBlur", value, prop, args)
    },
    checkboxBlur(value, prop, item) {
      this.$emit("formItemBlur", value, prop)
    },
    handleNumberInput(value, prop) {
      this.$emit("formItemInput", value, prop)
    },
    changeFormProp(prop,data) { //更新courseForm 某个属性
      let ruleForm  = JSON.parse(JSON.stringify(this.ruleForm));
      ruleForm[prop] = data;
      return ruleForm;
    },
    selectChange(e,prop) {
      this.$emit('selectChange',e,prop)
    },
  }
}
</script>

<style lang="less" scoped>
  .right-form-container {
    box-sizing: border-box;
    height: 100%;
    padding-top: 16px;
  }
  // 两列
  .multiColumns {
    width: calc(100%/var(--color-picker-column) - 8px);
    flex-shrink: 0;
    // &:nth-child(3n) {
    //   margin-left: 16px;
    // }
  }
  /deep/.el-tabs__active-bar {
    width: calc(100% / var(--tabs-num)) !important;
  }

  // 继续按钮设置
  .trickle_setting {
    position: absolute;
    top: 25px;
    right: 16px;
    width: 60px;
    height: 20px;
    font-size: 14px;
    color: #E74362;
    line-height: 20px;
    background: #F8EFF1;
    border-radius: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .trickle_bg {
      width: 16px;
      height: 16px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-image: url(~@/assets/svg/trickle_setting.svg);
    }
    &:hover {
      color: #B5102F;
      background: #F8EFF1;
      .trickle_bg {
        background-image: url(~@/assets/svg/trickle_setting_hover.svg);
      }
    }
    span {
      padding-left: 4px;
    }
  }
</style>