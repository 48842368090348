<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true,'reset':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-matching"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @reset="resetComponent"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div class="margin_top16 prel">
        <fdMatchGroup 
          ref="matchGroup"
          :list="optionsItem" 
          :selected="selected" 
          :name="detail['component-id']"
          :placeholder="detail['component-placeholder']"
          :disabled="answerState != -1"
          :showRes="detail[singleData.canShowMarking]"
          @change="matchChange">
        </fdMatchGroup>
        <questionsBtnGroup 
          :answerState="answerState" 
          :singeBtnShowMarking="singeBtnShowMarking"
          :leftBtnName="getBtnName('left') && detail[getBtnName('left')]"
          :feedbackBtnName="getBtnName('left') && detail[getBtnName('showFeedback')]"
          :canShowMarking="detail[singleData.canShowMarking]"
          :canShowFeedback="detail['component-_canShowFeedback']"
          :shouldDisplayAttempts="detail['component-_shouldDisplayAttempts']"
          :remainingAttemptsText="detail['component-_buttons-properties-remainingAttemptsText']"
          :attempts="detail['component-_attempts']"
          @tapBtn="tapBtn"></questionsBtnGroup>
      </div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            :listItemSet="{'canDrag':true}"
            @closeOptionsModal="closeOptionsModal"
            @sortOptionList="handleSort"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_matching_sub'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_matching'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
      <fd-dialog
        custom-class="hotgraphic-dialog"
        :visible.sync="feedbackDialogSet.visible"
        width="100%"
        :modal-append-to-body="true"
        :append-to-body="true"
        :before-close="beforeCloseDialogSet"
        :style="containerStyle">
        <div class="feedback-popup-inner" >
          <div class="notify__popup-inner">
            <div class="notify__content">
              <div class="notify__content-inner">
                <div class="notify__title" id="notify-heading">
                  <div class="notify__title-inner" role="heading" aria-level="1">
                    <p class="fontsize_16"><strong v-html="detail['component-_feedback-properties-title']"></strong></p>
                  </div>
                </div>
                <div class="notify__body">
                  <div class="notify__body-inner">
                    <p v-if="answerState == 1" v-html="detail['component-_feedback-properties-correct']"></p>
                    <p v-else-if="answerState == 0" v-html="detail['component-_feedback-properties-_incorrect-properties-final']"></p>
                  </div>
                </div>
              </div>
            </div>
            <button @click="feedbackDialogSet.visible = false" class="btn-icon notify__btn-icon notify__close-btn js-notify-close-btn" aria-label="关闭弹出窗口">
              <div class="el-icon-close fontsize_24 color_fff"></div>
            </button>
          </div>
          <a class="a11y-focusguard a11y-ignore a11y-ignore-focus" role="presentation">&nbsp;</a>
        </div>
      </fd-dialog>
  </topTools>
</template>

<script>
import { shuffle } from 'lodash-es';
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import fdMatchGroup from "@/renderer/components/FormItem/fd-match-group.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import questionsBtnGroup from "@/renderer/components/FormItem/questions-btn-group.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import listCommon from '@/renderer/mixins/list/index';
import questionsCommon from '@/renderer/mixins/questions/index';

/**
 * 匹配题 组件
 * 1、
 * 2、切换-是否显示正确答案按钮 (canShowModelAnswer)
 * 3、切换-是否显示标记- 用户选中项的对错标记 (canShowMarking)
 * 4、如果选项修改、新增、删除 - 初始化相关数据 (optionsItem)
*/
export default {
  mixins: [hideModal, componentCommon, listCommon, questionsCommon],
  components:{ topTools, rightForm, scaffoldForm, objForm, questionsBtnGroup, fdMatchGroup,contentList },
  props: {
    containerStyle: Object,
  },
  data() {
    return {
      currentForm: {},
      componentName:"adapt-contrib-matching",
      ckeditorPrefix:"componentMatching",
      feedbackDialogSet: {
        visible: false, //是否显示
        title: "",
      },
      selected: {}, //选中
      userAnswer: {}, //用户选择
      rightAnswer: {},
      firstFeedback: true, //是否显示首次提交显示反馈
      answerState: -1, //答题状态： -1 未答题（未提交）、0 提交并且错误、1 提交并且正确、
      showRightAnswer: false, //是否显示了正确答案
      singleData: {
        canShowMarking:"component-_canShowMarking", //显示标记 - 正确错误标记
        canShowModelAnswer:"component-_canShowModelAnswer", //显示答案 - 按钮
      },
    };
  },
  computed: {
    optionsItem() {
      // 选项
      let optionList = this.detail["component-_items"];
      // 选项新增或删除，初始化选项相关数据
      this.initOptionRelative();

      optionList.forEach((element,index) => {
        element.label = element.text;
        element.value = index;
        // 获取各个匹配题 的可选正确答案
        element._options && element._options.forEach((v,sind) => {
          if(v._isCorrect) {
            if(this.rightAnswer[index]) {
              this.rightAnswer[index].push(sind)
            }else {
              this.rightAnswer[index] = [];
              this.rightAnswer[index].push(sind);
            }
          }
        });
      });
      
      return optionList;
    },
    singeBtnShowMarking() {
      // 单个按钮-显示标记
      return this.detail[this.singleData.canShowMarking] && !this.detail['component-_canShowFeedback'] && this.answerState != -1;
    },
    isRandom() {
      return this.detail['component-_isRandom'];
    },
  },
  watch: {
    isRandom: {
      immediate: true,
      handler(n) {
        if(!!n) {
          // 随机答案
          this.optionsItem.forEach((item, index) => {
            let _options = shuffle(item._options);
            this.$set(this.optionsItem[index], '_options', _options);
          });
        }
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$emit("loaded");
    });
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    resetComponent() {
      // 重置组件-状态
      this.resetAll();
    },
    resetAll() {
      this.selected = {};
      this.userAnswer = {};
      this.firstFeedback = true;
      this.answerState = -1;
      this.rightAnswer = {};
      this.showRightAnswer = false;
      this.$refs.matchGroup && this.$refs.matchGroup.initStatus();
      this.$forceUpdate();
    },
    closeOptionsModal() {
      // 拖拽排序时，关闭左侧弹窗
      this.selected = {};
      this.$refs.matchGroup && this.$refs.matchGroup.initStatus();
      this.innerDrawer.current = -1;
      this.closeInnerDrawer();
    },
    beforeCloseDialogSet(done) {
      this.feedbackDialogSet.visible = false;
      done();
    },
    initOptionRelative() {
      // 初始化选项相关数据
      this.resetAll();
    },
    tapBtn(btnWhere = 1) {
      // btnWhere: 1 左侧按钮 提交或者查看正确答案; 2 反馈
      let { firstFeedback, selected, answerState, showRightAnswer } = this;
      let list = this.optionsItem;
      let listLen = this.optionsItem.length;
      //答案集合
      let answerSet = this.rightAnswer;
      let userAnswerLen = Object.keys(selected).filter( key => selected[key] >= 0 ).length;
      // 是否开启答案显示
      let canShowModelAnswer = this.detail[this.singleData.canShowModelAnswer];
      // 全部选择
      let isSelectAll = userAnswerLen == listLen;
      //是否开启了显示反馈
      let canShowFeedback = this.detail["component-_canShowFeedback"];

      // 点击提交，显示反馈
      if( btnWhere == 1 && !!firstFeedback && !!canShowFeedback && isSelectAll ) {
        this.feedbackDialogSet.visible = true;
        this.firstFeedback = false;
      }

      if( btnWhere == 1  && isSelectAll && answerState == -1 ) {
        // 提交
        let inconformityIndex = list.findIndex((val,ind)=> {
          //!answerSet[ind] 无答案情况
          return !answerSet[ind] || !answerSet[ind].includes( selected[ind] )
        })
        this.answerState = inconformityIndex == -1 ? 1 : 0;
        return;
      }
      if(canShowModelAnswer && btnWhere == 1 && isSelectAll && answerState == 0) {
        // 查看答案 - 隐藏答案
        //隐藏答案 - 查看用户答案
        if(showRightAnswer) {
          // this.selected = {};
          this.selected = this.userAnswer;
          this.showRightAnswer = false;
          return;
        }
        // 显示答案
        this.selected = answerSet;
        this.showRightAnswer = true;
        return;
      }

      if( btnWhere == 2 && answerState != -1 && !!canShowFeedback) {
        // 已答题，开启反馈，显示反馈弹窗
        this.feedbackDialogSet.visible = true;
        return;
      }
    },
    matchChange(val) {
      //选中改变
      this.selected = val;
      // 记录用户选择
      this.userAnswer = val;
    },
    openInnerDrawer({list, title, visible, submitAsObj,parentDetail, properties_name, key_name,index, value}) {
      // 显示\隐藏二层弹出窗口
      this.innerDrawer.form = {};
      
      list && list.forEach(v => {
        if(v.input_type === "truefalse" && v.display == 1) {
          
        }else if(v.input_type === "options"){
          this.$set(this.innerDrawer.form, v.input_name, []);
        }else if(v.display == 1){
          this.$set(this.innerDrawer.form, v.input_name, "");
        }
      });
      
      this.innerDrawer.submitAsObj = submitAsObj || false; //整体提交
      this.innerDrawer.key_name = key_name;
      this.innerDrawer.parentDetail = parentDetail;
      this.innerDrawer.title = title;
      this.innerDrawer.propList = list;
      this.innerDrawer.current = index;
      if(value) this.innerDrawer.form = value;
      this.$forceUpdate();

      if(!visible || visible == 2) {
        this.innerDrawer.visible = false;
        return;
      }else{
        if(this.innerDrawer.visible == true) {
          this.innerDrawer.visible = false;
          setTimeout(() => {
            this.innerDrawer.visible = true;
          }, 220);
        }else {
          this.innerDrawer.visible = true;
        }
      }
    },
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/
</style>