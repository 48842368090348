<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true,'reset':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-flipcard"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @reset="resetData"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div class="flipcard__widget component__widget clearfix" :style="{
        '--flipTime': detail['component-_flipTime']/1000 +'s'
      }">
        <div v-for="(fitem, find) in optionsItem" :key="'filp_'+find" 
          ref="flipcard__item"
          @click="onClickFlipItem($event, find)" 
          class="flipcard__item component__item" 
          :class="[ 'item-'+find, fitem.show ? 'flipcard-flip' : '' ]"
          :style="itemStyles">
          <div class="flipcard__item-face flipcard__item-front">
            <img v-if="fitem.frontImage" 
              ref="flipcard__item-frontImage"
              :src="fitem.frontImage.src" 
              @load="onImageReady" @error="onImageError" 
              class="flipcard__item-frontImage" :aria-label="fitem.frontImage.alt" tabindex="0">
          </div>
          <div class="flipcard__item-face flipcard__item-back">
            <div v-if="fitem.backTitle" class="flipcard__item-back-title" :class="{'flex1': !fitem.backBody }" v-html="fitem.backTitle"> </div>
            <div v-if="fitem.backBody" class="flipcard__item-back-body breakword" v-html="fitem.backBody"></div>
          </div>
        </div>
      </div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            :listItemSet="{'canDrag':true}"
            @closeOptionsModal="closeOptionsModal"
            @sortOptionList="handleSort"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_flipcard_sub'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_flipcard'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import listCommon from '@/renderer/mixins/list/index';

/**
 * 动画卡
*/
export default {
  mixins: [hideModal, componentCommon, listCommon],
  components:{ topTools, rightForm, scaffoldForm, contentList, objForm },
  data() {
    return {
      currentForm: {},
      componentName:"adapt-lianda-flipcard",
      ckeditorPrefix:"componentFlipcard",
      loadedImgCount:0,
      itemHeight: "auto"
    };
  },
  watch: {
    optionsItem: {
      deep: true,
      handler(n, o) {
        if(n) {
          this.itemHeight = "auto";
          this.$nextTick(() => {
            this.reRender();
          });
          
        }
      },
    },
  },
  computed: {
    optionsItem() {
      return this.detail["component-_items"];
    },
    imgLen() {
      let len = this.optionsItem.filter( v => v.frontImage && v.frontImage.src ).length;
      return len;
    },
    getSupport3d() {
      // 检测 transform-style:preserve-3d 是否支持
      let supports3d = CSS.supports("(transform-style: preserve-3d) or (-moz-transform-style: preserve-3d) or (-webkit-transform-style: preserve-3d)");
      return supports3d;
    },
    itemStyles() {
      if(this.itemHeight == "auto") return {};
      return {
        height: this.itemHeight
      }
    },
  },
  mounted() {
    this.loadedImgCount = 0;

    this.$nextTick(() => {
      this.$emit("loaded");

      // 判断是否有图片，判断全部图片加载完成
      if(this.imgLen === 0) {
        this.onImageReady();
      }
      
      this.$el.addEventListener("resize", this.resizeEvent, true);
    });
  },
  destroyed() {
    this.$el.removeEventListener("resize", this.resizeEvent, true);
    this.closePop();
  },
  methods: {
    resetData() {
      let items = this.optionsItem;
      items.forEach( item => {
        item.show = false;
      } );
    },
    resizeEvent() {
      this.$nextTick(() => {
        this.reRender();
      });
    },
    onClickFlipItem: function(event, index) {
      if (event && event.target.tagName.toLowerCase() === 'a') {
          return;
      } else {
          event && event.preventDefault();
      }

      let selectedElement = event.target; //flipcard__item
      let flipType = this.detail['component-_flipType'];
      if (flipType === 'allFlip') {
        // 所有都可同时翻转
        this.performAllFlip(selectedElement, index);
      } else if (flipType === 'singleFlip') {
        // 只有一个可以翻转
        this.performSingleFlip(selectedElement, index);
      }
    },
    performAllFlip: function(selectedElement, index) {
      if (!this.getSupport3d) {
          // let frontflipcard = selectedElement.find('.flipcard__item-front');
          // let backflipcard = selectedElement.find('.flipcard__item-back');
          // let flipTime = this.detail['component-_flipTime'] || 'fast';
          // if (frontflipcard.is(':visible')) {
          //     frontflipcard.fadeOut(flipTime, function() {
          //         backflipcard.fadeIn(flipTime);
          //     });
          // } else if (backflipcard.is(':visible')) {
          //     backflipcard.fadeOut(flipTime, function() {
          //         frontflipcard.fadeIn(flipTime);
          //     });
          // }
      } else {
          this.$set(this.optionsItem[index], 'show', !this.optionsItem[index].show);
      }
    },
    performSingleFlip: function(selectedElement, index) {
      // let flipcardContainer = selectedElement.closest('.flipcard__widget');
      if (!this.getSupport3d) {
        // let frontflipcard = selectedElement.find('.flipcard__item-front');
        // let backflipcard = selectedElement.find('.flipcard__item-back');
        // let flipTime = this.detail['component-_flipTime'] || 'fast';

        // if (backflipcard.is(':visible')) {
        //     backflipcard.fadeOut(flipTime, function() {
        //         frontflipcard.fadeIn(flipTime);
        //     });
        // } else {
        //     let visibleflipcardBack = flipcardContainer.find('.flipcard__item-back:visible');
        //     if (visibleflipcardBack.length > 0) {
        //         visibleflipcardBack.fadeOut(flipTime, function() {
        //             flipcardContainer.find('.flipcard__item-front:hidden').fadeIn(flipTime);
        //         });
        //     }
        //     frontflipcard.fadeOut(flipTime, function() {
        //         backflipcard.fadeIn(flipTime);
        //     });
        // }
      } else {
        let currentShow = this.optionsItem[index].show;
        if (currentShow) {
          this.$set(this.optionsItem[index], 'show', false);
        } else {
          this.optionsItem.forEach((element,eind) => {
            if(!!element.show) {
              this.$set(this.optionsItem[eind], 'show', false);
            }
          });
          this.$set(this.optionsItem[index], 'show', true);
        }
      }
    },
    onImageError: function() {
      this.loadedImgCount++;
      
      if(this.loadedImgCount === this.imgLen) {
        // 全部图片加载完成
        this.reRender();
      }
    },
    onImageReady: function() {
      this.loadedImgCount++;

      if(this.loadedImgCount === this.imgLen) {
        // 全部图片加载完成
        this.reRender();
      }
    },
    reRender: function() {
      if(!this.$refs["flipcard__item-frontImage"] || !this.$refs["flipcard__item-frontImage"][0]) return;
      var imageHeight = this.$refs["flipcard__item-frontImage"][0].offsetHeight;
      if (imageHeight) {
        this.itemHeight = imageHeight + "px";
      }
    },
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/

  // 动画卡
  @flipcard-item-transition: all var(--flipTime, .4s) ease-in-out;
  @flipcard-item-rotate-Y-minus180deg: rotateY(-180deg);
  @flipcard-item-rotate-Y-plus180deg: rotateY(180deg);
  @flipcard-item-rotate-Y-0deg: rotateY(0deg);

  .flipcard__widget {
    margin-top: 16px;
  }
  .flipcard__item {
    position: relative;
    float: left;
    width: 50%;
    height: 320px;
    color: var(--font-color-inverted);

    -webkit-perspective: 600px;
    -moz-perspective: 600px;
    -ms-perspective: 600px;
    -o-perspective: 600px;
    perspective: 600px;

    html.size-medium &,
    html.size-small & {
      width: 100%;
    }

    .flipcard__item-back-title {
      padding: 16px;
      font-size: 16px;
      line-height: 22px;
      color: var(--item-color-inverted);
      background-color: var(--item-color);
    }
    .flipcard__item-back-body {
      box-sizing: border-box;
      padding: 10px 16px;
      flex: 1;
      line-height: 25px;
      font-size: 14px;
      color: #222222;
      background-color: #fff;
      /deep/p:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &-face {
      position: absolute;
      z-index: 110;
      top: 0;
      left: 0;
      float: none;
      overflow: hidden;
      border-radius: 4px;
      width: calc(100% - 12px);
      height: calc(100% - 12px);
      cursor: pointer;
      box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
      // background: var(--item-color);

      -webkit-transition: @flipcard-item-transition;
      -moz-transition: @flipcard-item-transition;
      -ms-transition: @flipcard-item-transition;
      -o-transition: @flipcard-item-transition;
      transition: @flipcard-item-transition;

      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -ms-transform-style: preserve-3d;
      -o-transform-style: preserve-3d;
      transform-style: preserve-3d;

      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -ms-backface-visibility: hidden;
      -o-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    &-front {
      -webkit-transform: @flipcard-item-rotate-Y-0deg;
      -moz-transform: @flipcard-item-rotate-Y-0deg;
      -ms-transform: @flipcard-item-rotate-Y-0deg;
      -o-transform: @flipcard-item-rotate-Y-0deg;
      transform: @flipcard-item-rotate-Y-0deg;

      .flipcard__item-frontImage {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    &-back {
      // padding: 10px;

      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      -ms-box-sizing: border-box;
      -o-box-sizing: border-box;
      box-sizing: border-box;

      -webkit-transform: @flipcard-item-rotate-Y-minus180deg;
      -moz-transform: @flipcard-item-rotate-Y-minus180deg;
      -ms-transform: @flipcard-item-rotate-Y-minus180deg;
      -o-transform: @flipcard-item-rotate-Y-minus180deg;
      transform: @flipcard-item-rotate-Y-minus180deg;
    }

    &.flipcard-flip {
      .flipcard__item-front {
        -webkit-transform: @flipcard-item-rotate-Y-plus180deg;
        -moz-transform: @flipcard-item-rotate-Y-plus180deg;
        -ms-transform: @flipcard-item-rotate-Y-plus180deg;
        -o-transform: @flipcard-item-rotate-Y-plus180deg;
        transform: @flipcard-item-rotate-Y-plus180deg;
      }

      .flipcard__item-back {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
        overflow-y: auto;

        -webkit-transform: @flipcard-item-rotate-Y-0deg;
        -moz-transform: @flipcard-item-rotate-Y-0deg;
        -ms-transform: @flipcard-item-rotate-Y-0deg;
        -o-transform: @flipcard-item-rotate-Y-0deg;
        transform: @flipcard-item-rotate-Y-0deg;
      }
    }
  }

</style>