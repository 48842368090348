<template>
  <topTools type="right" rightTips="组件" :componentTitle="componentTitle"
    :id="detail['component-id']"
    :eyeShow="!detail['component-_isHidden']"
    :iconShowDetail="{'edit':true,'display':true,'delete':true,'hideStatus':true, 'reset':true, 'moveInline':moveInline}" 
    :isHighlight="isHighlight"
    class="component-container component-stacklist"
    :class="[detail['component-_classes']]"
    @switchDisplay="switchDisplay"
    @edit="showEdit" 
    @del="delComponent"
    @reset="resetData"
    @copy="handleCopying"
    @moveInline="handleMoveInline">
      <contentList
        :list="list"
        :currentForm="currentForm"
        :getProgressStatus="getProgressStatus"
        :ckeditorPrefix="ckeditorPrefix"
        :canKeyEnter="canKeyEnter"
        :throttleDelay="throttleDelay"
        @input="handleInput"
        @blur="formItemBlur"
        @blurNotUpdate="formItemBlur"
      ></contentList>
      <div>
        <div class="stacklist-widget component-widget breakword">
          <div class="stacklist-items">
            <div class="stacklist-items-inner">
              <div v-for="(sitem, sind) in optionsItem" :key="'stackList_'+sind" 
                class="stacklist-item" 
                :class="{'visibility-hidden': hideList }" ref="stacklist-item">
                  <span v-if="detail.length" class="fontsize_14">{{sitem}}</span>
                  <span v-else v-html="sitem.body" class="fontsize_14"></span>
              </div>
              <div v-if="showBtn" class="stacklist-button" :style="{'top':buttonOffset + 'px'}" ref="stacklist-button">
                <button 
                  ref="stacklist-next" 
                  class="stacklist-next" 
                  @click.stop="nextItem" 
                  v-html="btnStartText"
                  :aria-label="btnStartText"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <fd-drawer
          destroy-on-close
          :title="drawerSet.title"
          :visible.sync="drawerSet.visible"
          :direction="drawerSet.direction"
          :before-close="handleClose"
          :size="479"
          custom-class="drawer-right not-header"
          :with-header="false"
          append-to-body
          @open="openDrawer"
          @closed="closeDrawer"
          :modal="false"
      >
         <div class="drawer-right-inner prel bgc_fff">
          <div class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header" style="z-index:1;">
            <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
            <i class="icon-close-popup header-close-btn pointer fontsize_14 color_333" @click="closeOuterDrawer"></i>
          </div>
          <rightForm
            ref="rightForm"
            class="drawer-right-inner-form"
            :ruleForm="detail" 
            :tabList="tabList" 
            :currentTab="drawerSet.activeName"
            :ckeditorNameId="'component-id'"
            :ckeditorPrefix="ckeditorPrefix"
            :listItemSet="{'canDrag':true}"
            @closeOptionsModal="closeOptionsModal"
            @sortOptionList="handleSort"
            @open="openInnerDrawer"
            @close="closeInnerDrawer"
            @copy="handleCopy"
            @del="handleDel"
            @selectChange="selectChange"
            @formItemInput="formItemInput"
            @formItemBlur="formItemBlur">
          </rightForm>
          <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
            <div class=" right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14  " >
              <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
              <i class="icon-close-popup color_222 pointer padding_16" @click.stop="closeInnerPop" ></i>
            </div>
            <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
              <objForm 
                v-if="innerDrawer.visible && innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="innerDrawer.form"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_stacklist_sub'+innerDrawer.current"
                :current="innerDrawer.current"
                @selectChange="objFormChange" 
                @formItemBlur="objFormChange"
              ></objForm>
              <scaffoldForm 
                v-else-if="innerDrawer.visible && !innerDrawer.submitAsObj"
                :titleFontSize="14"
                :scrollBoxPaddingLR="16"
                :ruleForm="detail"
                :propList="innerDrawer.propList"
                :ckeditorNameId="'component-_stacklist'"
                @selectChange="formChangeBlur" 
                @formItemBlur="formChangeBlur"
              ></scaffoldForm>
            </div>
          </div>
         </div>
      </fd-drawer>
  </topTools>
</template>

<script>
import topTools from '@/renderer/components/common/topTools.vue';
import rightForm from "@/renderer/components/common/rightForm.vue";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import contentList from "@/renderer/components/FormItem/content-list.vue";
import objForm from "@/renderer/components/common/objForm.vue";
import hideModal from '@/renderer/mixins/popup/hideModal';
import componentCommon from '@/renderer/mixins/component/index';
import listCommon from '@/renderer/mixins/list/index';

/**
 * 闪进列表
*/
export default {
  mixins: [hideModal, componentCommon, listCommon],
  components:{ topTools, rightForm, scaffoldForm, contentList, objForm },
  data() {
    return {
      currentForm: {},
      componentName:"adapt-lianda-stacklist",
      ckeditorPrefix:"componentStacklist",
      _stage:-1,
      hideList: false,
      showBtn: false,
      buttonOffset:0,
    };
  },
  watch: {
    optionsItem: {
      deep: true,
      handler(n, o) {
        if(n) {
          this._stage = -1;
          this.buttonOffset = 0;
          // this.showBtn = true;
          this.setupListItems();
          
          if(this.$refs["stacklist-button"]) this.$refs["stacklist-button"].style.opacity = 1;
          if(this.$refs["stacklist-next"]) this.$refs["stacklist-next"].innerHTML = this.btnStartText;

          this.$nextTick(() => {
            this.setupItemOffsets();
          });
        }
      },
    },
  },
  computed: {
    optionsItem() {
      return this.detail["component-_items"];
    },
    btnStartText() {
      return this.detail["component-_button-properties-startText"] || "开始";
    },
    btnContinueText() {
      return this.detail["component-_button-properties-continueText"] || "下一步";
    }
  },
  mounted() {
    // 初始化
    this._stage = -1;

    this.$nextTick(() => {
      this.$emit("loaded");

      if (!this.detail["_isComplete"] || this.detail["_isResetOnRevisit"]) {
        this.setupListItems();
        this.setupItemOffsets();
        
        this.$el.addEventListener("resize", this.resizeEvent, true);
      }

    });
  },
  destroyed() {
    this.$el.removeEventListener("resize", this.resizeEvent, true);
    this.closePop();
  },
  methods: {
    resetData() {
      this._stage = -1;
      this.hideList = false;
      this.showBtn = false;
      this.buttonOffset = 0;
      this.setupListItems();
      this.setupItemOffsets();
      if(this.$refs["stacklist-button"]) this.$refs["stacklist-button"].style.opacity = 1;
      if(this.$refs["stacklist-next"]) this.$refs["stacklist-next"].innerHTML = this.btnStartText;
    },
    resizeEvent() {
      this.$nextTick(() => {
        this.setupItemOffsets();
      });
    },
    setupListItems: function() {
      this.hideList = true;
      this.showBtn = true;
    },
    setupItemOffsets: function() {
      // Set item positions alternating R and L
      let wWin = document.body.getBoundingClientRect().width;
      let offsetLeft = -this.$el.offsetWidth;
      let offsetRight = wWin + 10;
      let stage = this._stage;
      let buttonOffset = 0;
      let items = this.$refs["stacklist-item"];

      items.forEach(function(el, i) {
        if (i <= stage) {
          buttonOffset += el.offsetHeight;
          return;
        }
        let isLeft = i % 2 === 0;
        el.style.left = (isLeft ? offsetLeft : offsetRight) + "px";
      });
      this.buttonOffset = buttonOffset;
    },
    nextItem: function() {
      let stage = this._stage + 1;
      this.setStage(stage);
    },
    setStage: function(stage) {
      this._stage = stage;

      let items = this.optionsItem;
      let isComplete = items.length - 1 === stage;
      let item = this.$refs["stacklist-item"][stage];

      this.hideList = false;

      let h = item.offsetHeight;
      item.velocity({ left: 0 }, {
        delay: this.TRANSITION_TIME,
        duration: this.TRANSITION_TIME,
        complete: function() {
          item.style.display = "block";
        }
      });

      if (isComplete) {
        this.onComplete();
        this.updateButton('', h);
      } else {
        let continueText = items[stage].next || this.btnContinueText;
        // var btnAriaLabel = this.detail._globals._components._stacklist.ariaButtonLabel || this.detail._globals._accessibility._ariaLabels.next;
        // var ariaLabel = continueText + ', ' + btnAriaLabel;
        let ariaLabel = continueText + ', ';

        this.updateButton(continueText, h, ariaLabel);
      }
    },
    updateButton: function(text, offset, ariaLabel) {
      this.buttonOffset += (offset + 16);

      if (text === '') { // On last item we do not want to update text (it's most important when stack-list has only one item)
        return;
      }
      let button = this.$refs["stacklist-next"];
      button.blur();
      setTimeout(function() {
        button.innerHTML = text;
        button['aria-label'] = ariaLabel;
      }, this.TRANSITION_TIME * 2);
    },
    onComplete: function() {
      let buttonDiv = this.$refs["stacklist-button"];
      buttonDiv.style.opacity = 0;
      
      setTimeout(function() {
        //移除.stacklist-next
        this.showBtn = false;
      }, this.TRANSITION_TIME);
    },
  },
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/component.less'; /*引入公共组件样式*/

  // 闪进列表
  @item-margin: 0.5rem;
  .stacklist-widget {
    overflow: hidden;
  }
  .stacklist-items {
    position: relative;
    // margin-bottom: -@item-margin;
  }
  .visibility-hidden {
    visibility: hidden;
  }
  .stacklist-item {
    position: relative;
    padding: 18px 16px;
    margin-top: 16px;
    line-height: 20px;
    background-color: var(--item-color);
    color: var(--font-color-inverted);

      &.show {
          position: static !important;
      }
  }

  .stacklist-button {
    // display: none;
    position: absolute;
    top: 0;
    height: 56px;
    width: 100%;
    text-align: center;
    -webkit-transition: 0.25s;
    -moz-transition: 0.25s;
    -ms-transition: 0.25s;
    -o-transition: 0.25s;
    transition: 0.25s;
    border-radius: 4px;

    button {
      border-radius: 4px;
      border: none;
      min-height: 56px;
      min-width: 250px;
      color:var(--btn-color-inverted);
      background-color: var(--btn-color);
      font-size: 16px;
      cursor: pointer;
      &:hover {
        color: var(--btn-color-inverted-hover);
        background-color: var(--btn-color-hover);
      }
    }
  }
</style>