<template>
  <div>
    <el-form @submit.native.prevent action="false" :model="ruleForm" >
      <div v-for="sitem in propList" :key="sitem.id"
        class="modal-sets-item padding_0_16"
        :style="{'padding-left':(topLayer ? scrollBoxPaddingLR : 0)+'px','padding-right':(topLayer ? scrollBoxPaddingLR : 0)+'px',}"
        :class="{'border_topED padding_top25': sitem.display == 1 && sitem.pivot && sitem.pivot.dividing_line == 1 }">
        <p v-if="sitem.display == 1 && sitem.hidden_title !== true" class="title"  :style="{'font-size': titleFontSize +'px'}">{{ sitem.title }}</p>
        <p v-if="sitem.display == 1 && sitem.help" class="fontsize_12 color_999 margin_bot10" :style="{'font-size': (titleFontSize-2) +'px'}">{{sitem.help}}</p>
        <div v-if="sitem.properties">
          <coreForm
            :ruleForm="ruleForm[sitem.input_name]" 
            :propList="sitem.properties" 
            :ckeditorNameId="ckeditorNameId" 
            :componentName="componentName" 
            :titleFontSize="titleFontSize" 
            :scrollBoxPaddingLR="scrollBoxPaddingLR"
            @formItemBlur="handleItemBlur($event, sitem)"
            @changeVal="handleVal($event, sitem)"
            @selectChange="handleSelect($event, sitem)"
            @showLocationDialog="showLocationDialog($event, sitem)"
            @addCaption="handleAddCaption($event, sitem)"
            @editCaption="handleEditCaption($event, sitem)"
          ></coreForm>
        </div>
        <ckeditor v-if=" sitem.display == 1 && sitem['input_type'] == 'richtext_mini' "
          :type="'simple'" :id="'rightForm-' + ckeditorNameId + '-'+sitem.input_name" 
          :value="ruleForm[sitem.input_name]" 
          :throttle="throttleDelay"
          :placeholder="sitem.placeholder"
          @input="richTextInput($event,sitem.input_name)" 
          @blur="formItemBlur($event,sitem.input_name)" >
        </ckeditor>
        <ckeditor v-else-if="sitem.display == 1 && sitem['input_type'] == 'richtext' "
          :type="'rich'" 
          :id="'rightForm-' + ckeditorNameId + '-'+sitem.input_name" 
          :value="ruleForm[sitem.input_name]" 
          :throttle="throttleDelay"
          :placeholder="sitem.placeholder"
          @input="richTextInput($event,sitem.input_name)" 
          @blur="formItemBlur($event,sitem.input_name)" >
        </ckeditor>
        <div v-else-if="sitem.display == 1 && (sitem['input_type'] == 'number' || sitem['input_type'] == 'untitled_number')" class="flex flex_acenter" >
          <NumberInput
            :value="ruleForm[sitem.input_name]/( sitem.unit_value_multiple ? sitem.unit_value_multiple : 1 )"
            :rightTips="sitem.unit"
            :valueMultiple="sitem.unit_value_multiple"
            :placeholder="sitem.placeholder"
            @input="changeVal($event,sitem.input_name)"
            @blur="formItemBlur($event.value,sitem.input_name)"
          ></NumberInput>
        </div>
        <el-select
          v-else-if="sitem.display == 1 && sitem['input_type'] == 'single_select'"
          :value="ruleForm[sitem.input_name]"
          @change="selectChange($event,sitem.input_name)"
          placeholder="请选择"
          class="set-bgimg-select noborder"
        >
          <el-option
            v-for="item in sitem.enum"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <checkBox v-else-if="sitem.display == 1 && sitem['input_type'] == 'checkbox'" 
          :value="!!ruleForm[sitem.input_name]" :label="sitem.title" @change="formItemBlur($event,sitem.input_name)" class="margin_bot24">
        </checkBox>
        <el-switch
          v-else-if="sitem.display == 1 && sitem['input_type'] == 'truefalse'" 
          @change="switchChange($event,sitem.input_name)"
          v-model="ruleForm[sitem.input_name]"
          :width="52"
          active-color="#E74362"
          inactive-color="#D4D4D4"
          class="">
        </el-switch>
        <div v-else-if="sitem.display == 1 && sitem['input_type'] == 'text'" class="" >
          <el-input v-model="ruleForm[sitem.input_name]" :placeholder="sitem.placeholder" class="modal-sets-inlineinput noborder bgc_fff" @blur="formItemBlur($event.target.value,sitem.input_name)"></el-input>
        </div>
        <div v-else-if="sitem.display == 1 && sitem['input_type'] == 'textarea'" class="modal-sets-item" >
          <ace :value="customStyle" @input="changeLessCode" @blur="formItemBlur($event,sitem.input_name)" ></ace>
        </div>
        <assetupload
          v-else-if="sitem.display == 1 && sitem['input_type'] == 'image'"
          :src="ruleForm[sitem.input_name]"
          :height="129"
          :uploadText="sitem.upload_text"
          :required="sitem.required"
          @change="uploadImg($event,sitem.input_name)"
        ></assetupload>
        <AltInput v-else-if="sitem.display == 1 && sitem['input_type'] == 'alt'"
          bgColor="#fafafa"
          :placeholder="sitem.title"
          :value="ruleForm[sitem.input_name]"
          @input="changeVal($event,sitem.input_name)"
          @blur="formItemBlur($event,sitem.input_name)">
        </AltInput>
        <div v-else-if="sitem.display == 1 && sitem['input_type'] === 'location'">
          <input type="hidden" :value="ruleForm[sitem.left]">
          <input type="hidden" :value="ruleForm[sitem.top]">
          <customButton  v-if="sitem.button_text" plain color="#E74362" :width="52" :height="30" 
            @click="showLocationDialog({input_name:sitem.input_name, left:sitem.left, top:sitem.top,imgPropName: sitem['image_input_name'] })"
            class="sureBtn pointer">
            <span class="fontsize_12">{{sitem.button_text}}</span>
          </customButton>
        </div>
        <div v-else-if="sitem.display == 1 && (sitem['input_type'] == 'resource')">
          <fileOption 
            :src="ruleForm[sitem.input_name]" 
            :exts="sitem.exts" 
            :name="ruleForm[sitem.input_name + '__resouce_name']"
            :cover="ruleForm[sitem.input_name + '__resouce_cover']"
            :checkType="ruleForm[sitem.input_name + '__resouce_type']"
            @change="fileChange($event,sitem.input_name)"
          ></fileOption>
        </div>
        <div v-else-if="sitem.display == 1 && sitem['input_type'] === 'options'">
          <matchingItem 
            @change="matchChange($event,sitem.input_name)"
            :list="ruleForm[sitem.input_name]"
            :setting="sitem.options"
            :placeholder="sitem.placeholder"
          ></matchingItem>
        </div>
        <div v-else-if="sitem.display == 1 && sitem['input_type'] === 'hotgraphic_icon'">
          <iconSelect  @change="changeIcon($event,sitem.input_name, sitem)" 
            :selected="ruleForm[sitem.input_name]"
            :default="sitem.default" :componentName="componentName"></iconSelect>
        </div>
        <answerItem v-else-if="sitem.display == 1 && sitem['input_type'] === 'textinput_answer'" 
          @change="answerChange($event,sitem.input_name)"
          :list="ruleForm[sitem.input_name]" :setProps="sitem" ></answerItem>
        <captionItem v-else-if="sitem.display == 1 && sitem['input_type'] === 'captions_list'"
          @change="answerChange($event,sitem.input_name)"
          @add="addCaption($event,sitem.input_name)"
          @edit="editCaption($event,sitem.input_name)"
          :list="ruleForm[sitem.input_name]" :setProps="sitem">
        </captionItem>
      </div>
    </el-form>
  </div>
</template>

<script>
import Config from '@/libs/config';
import { mapGetters, mapMutations, mapState } from "vuex";
import NumberInput from "@/renderer/components/common/numberInput.vue";
import checkBox from "@/renderer/components/common/checkBox.vue";
import AltInput from "@/renderer/components/common/altInput.vue";
import iconSelect from "@/renderer/components/common/iconListSelect.vue";
import customButton from "@/renderer/components/common/button.vue";
import dragPosition from "@/renderer/components/common/dragPosition.vue";
import fileOption from "@/renderer/components/common/fileOption.vue";
import matchingItem from "@/renderer/components/FormItem/matching-item.vue";
import answerItem from "@/renderer/components/FormItem/answer-item.vue";
import captionItem from "@/renderer/components/FormItem/caption-item.vue";
import dragBall from "@/renderer/components/common/dragBall.vue";
export default {
  name:"coreForm",
  props: {
    topLayer: { //是否作为顶层
      type: Boolean,
      default: false
    },
    ruleForm: {
      type: Object,
      default: function() {
        return {};
      }
    },
    propList:{
      type: Array,
      default: function() {
        return [];
      }
    },
    componentName: String,
    ckeditorNameId: {
      type: [String, Number],
      required: true,
      default:""
    },
    titleFontSize: { //每一项标题 字体大小
      type: [Number, String],
      validator(val) { 
        return ! /\D/.test(val)
      },
      default: 16
    },
    scrollBoxPaddingLR: { //scrollBox左右padding值
      type: [Number, String],
      validator(val) { 
        return ! /\D/.test(val)
      },
      default: 13
    }

  },
  components: {
    NumberInput,
    checkBox,
    AltInput,
    customButton,
    dragPosition,
    iconSelect,
    fileOption,
    matchingItem,
    answerItem,
    dragBall,
    captionItem
  },
  data() {
    return {
      throttleDelay:Config.realTimeInput,
    };
  },
  watch: {
    propList: {
      handler(n) {
        if(n) {
          Array.isArray(n) && n.forEach(v => {
            if(v.properties && Array.isArray(v.properties)) {
              if(this.ruleForm[v.input_name] === undefined) {
                this.$set(this.ruleForm, v.input_name, {})
              }
            }
          })
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters(['getCustomStyleById']),
    customStyle() {
      return this.getCustomStyleById(this.$route.params.id) || "";
    },
  },
  methods: {
    richTextInput(value, prop) {
      this.$set(this.ruleForm, prop, value);
      this.$emit("formItemBlur", {
        value: value, 
        propName: prop,
        form: this.ruleForm
      })
    },
    formItemBlur(value, prop, parentItem) {
      if( parentItem && parentItem.properties) {
        this.subForm[prop] = value;
        this.ruleForm[parentItem.input_name][prop] = value;
        
        this.$emit("formItemBlur", {
          value: value,
          propName: prop,
          form: this.ruleForm,
          subForm: this.ruleForm[parentItem.input_name]
        })
        return;
      }
      this.$set(this.ruleForm, prop, value);
      this.$emit("formItemBlur", {
        value: value,
        propName: prop,
        form: this.ruleForm
      })
    },
    changeVal(value, prop) {
      this.$set(this.ruleForm, prop, value);
      this.$emit("changeVal",{
        value: value,
        propName: prop,
        form: this.ruleForm
      });
    },
    selectChange(e,prop) {
      this.$set(this.ruleForm, prop, e);
      this.$emit('selectChange',{
        value: e,
        propName: prop,
        form: this.ruleForm
      })
    },
    switchChange(val,prop) {
      // 开关切换
      this.$set(this.ruleForm, prop, val);
      this.$emit('formItemBlur',{
        value: val,
        propName: prop,
        form: this.ruleForm
      });
    },
    changeLessCode(val) {
      // this.lessCss = val;
    },
    uploadImg(val,propName, parentItem) {
      if( parentItem && parentItem.properties) {
        this.subForm[propName] = val;
        this.ruleForm[parentItem.input_name][propName] = val;
        
        this.$emit("formItemBlur", {
          value: val, 
          propName: propName,
          form: this.ruleForm,
          subForm: this.ruleForm[parentItem.input_name]
        })
        return;
      }
      let copyForm = JSON.parse( JSON.stringify( this.ruleForm ) );
      if(Array.isArray(copyForm)) {
        copyForm = {};
      }
      copyForm[propName] = val;
      this.$set(this.ruleForm, propName, val);
      this.$emit("formItemBlur", {
        value: val, 
        propName: propName,
        form: copyForm
      })
      
    },
    showLocationDialog(item) {
      this.$emit("showLocationDialog", item);
    },
    fileChange(value, prop, parentItem) {
      let obj = {
        [prop]: value.url,
        [prop+'__resouce_name']: value.name,
        [prop+'__resouce_cover']: value.cover,
        [prop+'__resouce_type']: value.type,
      };
      if( parentItem && parentItem.properties) {
        Object.assign(this.subForm, obj);
        Object.assign(this.ruleForm[parentItem.input_name], obj);
        this.$set(this, 'ruleForm', this.ruleForm); //强制刷新
        this.$forceUpdate();
        
        this.$emit("formItemBlur", {
          value: JSON.parse(JSON.stringify( obj )),
          propName: null,
          form: this.ruleForm,
          subForm: this.ruleForm[parentItem.input_name]
        })
        return;
      }
      Object.assign(this.ruleForm, obj);
      this.$set(this, 'ruleForm', this.ruleForm); //强制刷新
      this.$forceUpdate();

      this.$emit("formItemBlur", {
        value: JSON.parse(JSON.stringify( obj )),
        propName: null,
        form: this.ruleForm
      });
    },
    matchChange(args, propName) {
      let val = args.list;
      this.$set(this.ruleForm, propName, args.list);
      this.$emit('formItemBlur',{
        value: val,
        propName: propName,
        form: this.ruleForm
      });
    },
    answerChange(list, propName) {
      this.$set(this.ruleForm, propName, list);
      this.$emit('formItemBlur',{
        value: list,
        propName: propName,
        form: this.ruleForm
      });
    },
    addCaption(list, propName) {
      // 添加字幕弹窗
      this.$set(this.ruleForm, propName, list);
      this.$emit('formItemBlur',{
        value: list,
        propName: propName,
        form: this.ruleForm
      });
      this.$emit("addCaption",{
        value: list,
        propName: propName,
        form: this.ruleForm
      });
    },
    editCaption({list, index}, propName) {
      this.$emit("editCaption",{
        value: list,
        propName: propName,
        form: this.ruleForm,
        index
      });
    },
    changeIcon(src, key_name, stiem) {
      // 改变icon
      this.ruleForm[key_name] = src;
      
      this.$emit("formItemBlur", {
        value: src, 
        propName: key_name,
        form: this.ruleForm
      });
    },
    handleItemBlur(args, parentItem) {
      // 递归
      let { value,propName:prop, form } = args;
      
      if( parentItem && parentItem.properties) {
        // this.ruleForm[parentItem.input_name][prop] = value; //会有bug，不需要
        this.$emit("formItemBlur", {
          value: value,
          propName: prop,
          form: this.ruleForm,
          // subForm: this.ruleForm[parentItem.input_name]
          subForm: form || this.ruleForm[parentItem.input_name]
        })
        return;
      }
    },
    handleVal(args, parentItem) {
      let { value, prop } = args;
      this.$set(this.ruleForm, prop, value);
      this.$emit("changeVal",args);
    },
    handleSelect(args, parentItem) {
      this.$emit("selectChange",args);
    },
    showLocationDialog(args, parentItem) {
      this.$emit("showLocationDialog",args);
    },
    handleAddCaption(args, parentItem) {
      this.$emit("addCaption",args);
    },
    handleEditCaption(args, parentItem) {
      this.$emit("editCaption",args);
    },
  },
}
</script>

<style>

</style>