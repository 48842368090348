<template>
  <div 
    class="drag-ball-container" 
    :style="{
      'width': width,
      'height': height
    }"
    @mousedown.stop="hang"
    @mouseup.stop="drop"
    @mouseleave.stop="mouseleave"
  >
    <img v-if="img" :src="img" loading="lazy"  fit="contain" @load="imgLoad" lazy class="darg-bgc-img" ref="darg-img"/>
    <div class="darg-bgc-img-mask"></div>
    <div class="drag-ball-self" id="drag-ball-self"></div>
  </div>
</template>

<script>
export default {
  props: {
    //drag-ball-container
    parentWidth:{
      type: [Number,String],
      default: "auto"
    },
    parentHeight: {
      type: [Number,String],
      default: 480
    },
    initLeft:[Number,String], //drag-ball-self
    initTop:[Number,String],
    img:String
  },
  data: () => ({
    shiftY: null,
    shiftX: null,
    left: 0,
    top: 0,
    elem: null,
    ballElem:null,
    isIos: false,
    parent: {
      width: 0,
      height: 0
    },
  }),
  computed: {
    width() {
      let width = this.parent.width;
      if( !width ) width = 850;
      if( typeof width == "number" ) return width + "px";
      return width;
    },
    height() {
      let height = this.parent.height;
      if( !height ) height = "auto";
      if( typeof height == "number" ) return height + "px";
      return height;
    }
  },
  watch: {
    initLeft: {
      handler(n) {
        if(n) {
          this.$nextTick(() => {
            this.ballElem.style.left = n + '%';
          });
        }
      },
      immediate: true
    },
    initTop: {
      handler(n) {
        if(n) {
          this.$nextTick(() => {
            this.ballElem.style.top = n + '%';
          });
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    this.elem = this.$el;
    this.ballElem = document.getElementById("drag-ball-self");
  },
  methods: {
    imgLoad() {
      // 图片加载完成
      let { naturalWidth, naturalHeight, offsetWidth, offsetHeight } = this.$refs['darg-img'];
      this.parent.width = offsetWidth;
      this.parent.height = offsetHeight;
    },
    mouseleave(e) {
      // 鼠标移出容器
    },
    hang(e) {
      // 鼠标按下
      if(e.pageX - this.elem.offsetLeft > this.parent.width || e.pageY - this.elem.offsetTop > this.parent.height) {
        // 点击-范围不在容器内
        this.drop(e);
        return;
      }
      //鼠标按下在小球上
      if( e.target.id === "drag-ball-self" ) {
        this.hangBall(e);
        this.elem.addEventListener("mousemove", this.elementMove);
      }
    },
    hangBall(e) {
      //shiftX：鼠标在小球上的位置 + 父元素的位置
      this.shiftX = e.pageX  ? e.pageX - this.ballElem.offsetLeft : e.changedTouches[0].pageX - this.ballElem.offsetLeft;
      this.shiftY = e.pageY ? e.pageY - this.ballElem.offsetTop : e.changedTouches[0].pageY - this.ballElem.offsetTop;
      if (e.pageX) {
        if (this.isIos) {
          this.elem.addEventListener("touchmove", this.elementMove);
        } else {
          this.elem.addEventListener("mousemove", this.elementMove);
        }
      } else {
        this.elem.addEventListener("touchmove", this.elementMove);
      }
    },
    drop() {
      //鼠标按下
      document.body.style.overflow = null;
      this.elem.removeEventListener("mousemove", this.elementMove, false);
      this.elem.onmouseup = null;
      this.$emit("dropped",{
        left:this.left,
        top: this.top,
        parentWidth: this.parent.width,
        parentHeight: this.parent.height,
      });
    },
    elementMove(e) {
      this.$emit("dragging");
      e.preventDefault();
      if (!e.pageX) {
        document.body.style.overflow = "hidden";
      }
      const x = e.pageX || e.changedTouches[0].pageX;
      const y = e.pageY || e.changedTouches[0].pageY;
      let newLeft = x - this.shiftX;
      let newTop = y - this.shiftY;
      const newRight = x - this.shiftX + this.ballElem.offsetWidth;
      const newBottom = y - this.shiftY + this.ballElem.offsetHeight;
      
      if (newLeft < 0) {
        newLeft = 0;
      } else if (newRight > this.parent.width) {
        // 小球即将-X轴上超出容器范围
        newLeft = this.parent.width - this.ballElem.offsetWidth;
      } else {
        newLeft = x - this.shiftX;
      }
      if (newTop < 0) {
        newTop = 0;
      } else if (newBottom > this.parent.height) {
        // 小球即将-Y轴上超出容器范围
        newTop = this.parent.height - this.ballElem.offsetHeight;
      } else {
        newTop = y - this.shiftY;
      }
      
      this.ballElem.style.left = `${ newLeft }px`;
      this.left = newLeft;
      this.ballElem.style.top = `${ newTop }px`;
      this.top = newTop;
    },
  },

}
</script>

<style lang="less">
  // 拖拽容器
  .drag-ball-container {
    position: relative;
    margin-inline: auto;
    width: max-content;
    height: "auto";
    background-color: rgba(0,0,0,0.02);
    background-size: contain;
    background-repeat: no-repeat;
  }
  // 拖拽小球
  .drag-ball-self {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
    background: #FFFFFF;
    cursor: pointer;
  }
  .darg-bgc-img {
    position: relative;
    width: 100%;
    height: "auto";
    object-fit: contain;
    user-select: none;
    z-index: 0;
    display: block;
  }
  .darg-bgc-img-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>