var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative"}},[(_vm.courseTheme != 'greentree-theme')?_c('NavigationBar',{attrs:{"ruleForm":_vm.headForm,"courseForm":_vm.courseForm,"enabledProgress":_vm.enabledProgress}}):_vm._e(),(_vm.courseTheme === 'greentree-theme' && _vm.$route.query.pageType == 'content')?_c('NavigationBar',{attrs:{"ruleForm":_vm.headForm,"courseForm":_vm.courseForm,"enabledProgress":_vm.enabledProgress}}):_vm._e(),(
  _vm.courseTheme === 'greentree-theme' &&
    _vm.$route.query &&
    _vm.$route.query.pageType == 'content'
  )?_c('div',[_c('div',{staticClass:"toc-menu-icon",class:_vm.tocIcon ? '' : 'hideIcon',on:{"click":_vm.handleMenu}})]):_vm._e(),(
    _vm.courseTheme === 'greentree-theme' &&
    _vm.$route.query &&
    _vm.$route.query.pageType == 'content'
  )?_c('SidebarMenu',{attrs:{"showMenu":_vm.tocIcon}}):_vm._e(),_c('div',{class:_vm.courseTheme === 'greentree-theme'
      ? _vm.tocIcon && _vm.$route.query && _vm.$route.query.pageType == 'content'
        ? 'greenTree showMenu'
        : 'greenTree'
      : ''},[_c('TopHead',{attrs:{"detail":_vm.detail,"ruleForm":_vm.headForm,"enabledProgress":_vm.enabledProgress,"theme":_vm.courseTheme}}),(
      _vm.$route.query &&
      (_vm.$route.query.pageType == 'index' ||
        _vm.$route.query.pageType == 'menu') &&
      _vm.courseTheme != 'greentree-theme'
    )?_c('ToTheme',{attrs:{"ruleForm":_vm.headForm,"enabledProgress":_vm.enabledProgress}}):_vm._e(),(
      _vm.$route.query &&
      _vm.courseTheme === 'greentree-theme' &&
      (_vm.$route.query.pageType == 'index' ||
        _vm.$route.query.pageType == 'menu')
    )?_c('GreenTreeTheme',{attrs:{"ruleForm":_vm.headForm,"enabledProgress":_vm.enabledProgress,"detail":_vm.detail}}):_vm._e(),(_vm.$route.query && _vm.$route.query.pageType == 'content')?_c('TopBody',{attrs:{"enabledProgress":_vm.enabledProgress,"containerStyle":_vm.containerStyle},on:{"loaded":function () { return _vm.$emit('loaded'); },"scrollToModule":function (args) { return _vm.$emit('scrollToModule', args); },"scrollToNew":function (args) { return _vm.$emit('scrollToNew', args); }}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }