<template>
  <div class="answer-item-wrapper">
    <div v-for="(citem,key) in copyList" :key="'ai_'+key" @click.stop="tapItem(key)" class="answer-option flex flex_acenter flex_jcbetween" :gutter="18">
        <span v-if="citem.text" class="answer-text fontsize_14 color_333 pover">{{citem.text}}</span>
        <span v-else class="fontsize_14 color_A1A1A1">{{setProps.propertie && setProps.propertie.placeholder || ''}}</span>
        <!-- <el-input 
          :value="citem.text" 
          :placeholder="setProps.propertie && setProps.propertie.placeholder || ''" 
          @input="handleInput($event,key)"
          class="modal-sets-inlineinput noborder bgc_fff"></el-input> -->
        <div  @click.stop="handleDel(citem,key)" class="answer-option-del pointer">
          <i class="icon-menu-del fontsize_14"></i>
        </div>
    </div>
    <customButton color="#E74362" plain :width="64" :height="32" @click="addItem" class="button_hover">
      <span class="fontsize_12">{{setProps.button_title || '添加'}}</span>
    </customButton>
  </div>
</template>

<script>
import customButton from "@/renderer/components/common/button.vue";
export default {
  props: {
    list: Array,
    setProps: Object, //属性设置
  },
  components: { customButton },
  data() {
    return {
      copyList: [],
    };
  },
  computed: {
    total() {
      return this.copyList.length || 0
    },
  },
  watch: {
    list: {
      immediate:true,
      handler(n) {
        this.copyList = n || [];
      }
    }
  },
  created() {
    this.copyList = this.list || [];
  },
  methods: {
    tapItem(index) {
      this.$emit("edit",{
        index,
        list:JSON.parse( JSON.stringify(this.copyList))
      } );
    },
    handleInput(val,index) {
      this.$set(this.copyList, index, val);
      this.$emit("change",JSON.parse( JSON.stringify(this.copyList) ));
    },
    addItem() {
      this.copyList.push({
        text: "",
        left: 20,
        top: (this.total + 1) * 60,
        width: "auto"
      });
      this.$emit("add",JSON.parse( JSON.stringify(this.copyList) ));
    },
    handleDel(item, index) {
      // 答案删除
      this.copyList.splice(index,1);
      this.$emit("change",JSON.parse( JSON.stringify(this.copyList) ));
    },
  },
}
</script>

<style lang="less" scoped>
    .answer-option {
      box-sizing: border-box;
      margin-bottom: 16px;
      border-radius: 4px;
      padding-inline: 16px;
      border: 1px solid #D4D4D4;
      height: 36px;
      .answer-text {
        width: calc(100% - 40px);
      }
    }
    .answer-option-del {
      margin-left: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      width: 25px;
      height: 24px;
      background-color: #FAFAFA;
      &:hover {
        background-color: #E4E4E4;
        i:before {color: #E74362;}
      }
    }
    .button_hover:hover {
      border-color: #B5102F !important;
      color: #B5102F !important;
    }
</style>