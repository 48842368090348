<template>
  <topTools
    type="centerOne"
    rightTips="介绍栏"
    :isHighlight="isHighlight"
    @edit="showEdit"
    :style="courseHeaderStyle"
    class="course-header flex flex_acenter flex_center prel lineheight_1d5"
  >
    <div v-if="isChangeHeight" class="height-line">
      <div class="course_arrow_up flex1">
        <img :src="arrowUpSrc" class="arrow-up" alt />
      </div>
      <div class="course_arrow_down flex1">
        <img :src="arrowDownSrc" class="arrow-down" alt />
      </div>
      <div class="course_dashed_line"></div>
      <div class="course_dashed_line bottom"></div>
      <div class="height-value">
        <span v-if="headerMinHeight <= 200">最小高度值</span>
        <span class>{{headerMinHeight > 200 ? headerMinHeight : 200}}px</span>
      </div>
    </div>
    <div class="course-header-inner aloneCenter prel">
      <div
        v-for="(item, ind) in list"
        :key="item.id"
        class="prel"
        :class="{'margin_bot16': (ruleForm[item.key_name] || ckFocusStatus[item.key_name]) && item.display == 1 }"
      >
        <div v-if="item.display == 1" class="flex prel">
          <div
            v-if="showProgress && (item.properties_name == 'title' || item.properties_name == 'displayTitle')"
            class="progress-position"
            :class="{'hasValue': dynamicForm[item.key_name]}"
          >
            <progressExample :detail="dynamicForm"></progressExample>
            <p
              v-html="dynamicForm[item.key_name]"
              class="progress-text"
              :class="{ 'fontsize_20': item.properties_name == 'title' || item.properties_name == 'displayTitle' }"
            ></p>
          </div>
          <ckeditor
            v-if="dynamicForm[item.key_name]"
            type="simpleInline"
            :id="ckeditorPrefix+'-'+ currentId +'-inline'+'-'+item.id"
            :value="dynamicForm[item.key_name]"
            :canKeyEnter="canKeyEnter || item.properties_name !== 'displayTitle' && item.properties_name !== 'title'"
            :throttle="throttleDelay"
            @focus="handleFocus($event,item.key_name)"
            @input="handleInput($event,item.key_name)"
            @blur="formItemBlur($event,item.key_name)"
            @blurNotUpdate="formItemBlur($event,item.key_name,false)"
            :class="{ 'is_title fontsize_20': item.properties_name == 'title' || item.properties_name == 'displayTitle',
              'ck-has-progress': showProgress && (item.properties_name == 'title' || item.properties_name == 'displayTitle'),}"
          ></ckeditor>
        </div>
      </div>
      <div class="menuStartBtn" v-if="(theme === 'greentree-theme' && showBtn) && MenuBtnText">
        <button
          type="button"
          v-html="MenuBtnText"
        ></button>
      </div>
    </div>
    <fd-drawer
      destroy-on-close
      :title="drawerSet.title"
      :visible.sync="drawerSet.visible"
      :direction="drawerSet.direction"
      :before-close="handleClose"
      :size="479"
      custom-class="drawer-right not-header"
      append-to-body
      @open="openDrawer"
      @closed="closeDrawer"
      :modal="false"
      :with-header="false"
    >
      <div class="drawer-right-inner prel bgc_fff">
        <div
          class="header flex flex_acenter padding_left16 width_100p prel bgc_fff lineheight_22 modal-header"
          style="z-index:1;"
        >
          <span class="fontsize_16 color_222">{{drawerSet.title}}</span>
          <i
            class="icon-close-popup header-close-btn pointer fontsize_14 color_333"
            @click="handleClose"
          ></i>
        </div>
        <rightForm
          :ruleForm="ruleForm"
          :tabList="tabList"
          :currentTab="drawerSet.activeName"
          :ckeditorNameId="getCkId()"
          :ckeditorPrefix="ckeditorPrefix"
          @handleClick="handleClick"
          @changeVal="changeVal"
          @selectChange="selectChange"
          @uploadImg="uploadImg"
          @formItemInput="formItemInput"
          @formItemBlur="formItemBlur"
          @open="openInnerDrawer"
          class="drawer-right-inner-form"
        ></rightForm>
        <div class="right-sub-modal" :class="{ visible: innerDrawer.visible }">
          <div class="right-sub-modal-header flex flex_acenter flex_jcbetween fontsize_14">
            <span v-html="innerDrawer.title" class="padding_16 fontsize_16 color_222"></span>
            <i
              class="icon-close-popup color_222 pointer padding_16"
              @click.stop="closeInnerDrawer(1)"
            ></i>
          </div>
          <div class="right-sub-modal-body" style="height: calc(100% - 55px)">
            <scaffoldForm
              v-if="innerDrawer.visible"
              :titleFontSize="14"
              :scrollBoxPaddingLR="16"
              :ruleForm="ruleForm"
              :propList="innerDrawer.propList"
              :ckeditorNameId="getCkId()"
              @selectChange="formChangeBlur"
              @formItemBlur="formChangeBlur"
            ></scaffoldForm>
          </div>
        </div>
      </div>
    </fd-drawer>
  </topTools>
</template>

<script>
import arrowDownSvg from "@/assets/svg/height_arrow_down.svg";
import arrowUpSvg from "@/assets/svg/height_arrow_up.svg";
import Config from "@/libs/config";
import { throttle, debounce } from "@/libs/utils/tools";
import { mapMutations, mapState, mapActions } from "vuex";
import topTools from "@/renderer/components/common/topTools.vue";
import rightForm from "@/renderer/components/common/rightForm.vue";
import progressExample from "@/renderer/components/common/progressExample.vue";
import hideModal from "@/renderer/mixins/popup/hideModal";
import scaffoldForm from "@/renderer/components/common/scaffoldForm.vue";
import { boolean } from "yargs";
export default {
  mixins: [hideModal],
  props: {
    ruleForm: Object,
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    theme: {
      type: String,
      default: "feidao-vanilla-theme",
    },
    bgcStyle: {
      type: Object,
      default: function () {
        return {
          "background-image": "none",
          "background-repeat": "repeat",
          "background-position": "0% 0%",
          "background-size": "auto",
        };
      },
    },
    enabledProgress: Boolean,
  },
  components: {
    topTools,
    rightForm,
    progressExample,
    scaffoldForm,
  },
  data() {
    return {
      arrowDownSrc: arrowDownSvg,
      arrowUpSrc: arrowUpSvg,
      canKeyEnter: Config.titleCkeditorCanKeyEnter, //标题能否回车换行
      ckeditorPrefix: "tophead",
      headerMinHeight: "auto",
      backgroundImage: "none",
      drawerSet: {
        direction: "rtl", //从右往左
        visible: false, //是否显示
        title: "",
        activeName: 0,
      },
      // popDetail:{},
      dynamicForm: this.ruleForm, //ruleForm是跟随请求响应而实时刷新，会导致跟输入不同步；所以不直接用ruleForm
      isFirst: true,
      throttleDelay: Config.realTimeInput,
      // 编辑框-聚集状态
      ckFocusStatus: {},
      innerDrawer: {
        visible: false, //是否显示
        title: "",
        form: {},
        propList: [],
        submitAsObj: false,
        key_name: "",
        current: -1,
      },
    };
  },
  computed: {
    ...mapState({
      popShowId: (state) => state.popShowId,
      schemaJson: (state) => state.course.schemaJson,
      miniHeightId: (state) => state.miniHeightId,
      miniHeightValue: (state) => state.miniHeightValue,
    }),
    showBtn() {
      // 是否显示页面开始按钮
      let pageType = this.pageType;
      let menuBtn = this.dynamicForm["course-_startbtn-properties-_isEnabled"];
      let contentMenuBtn = this.dynamicForm["contentobject-_startbtn-properties-_isEnabled"];
      // if (!this.enabledProgress) return false;
      return (
        (pageType === "index" && menuBtn) || (pageType === "menu" && contentMenuBtn)
      );
    },
    MenuBtnText(){
      let pageType = this.pageType;
      let IndexText = this.ruleForm['course-_startbtn-properties-_startButton-properties-buttonText'];
      let ContentText = this.ruleForm['contentobject-_startbtn-properties-_startButton-properties-buttonText'];
      // if (!this.enabledProgress) return false;
      return (
        (pageType === "index" && IndexText) ||
        (pageType !== "index" && ContentText)
      );
    },
    showProgress() {
      // 是否显示页面标题进度条
      let pageType = this.pageType;
      let pageProgress =
        this.dynamicForm[
          "course-_pageLevelProgress-properties-_isCompletionIndicatorEnabled"
        ];
      let contentProgress =
        this.dynamicForm[
          "contentobject-_pageLevelProgress-properties-_isCompletionIndicatorEnabled"
        ];
      if (!this.enabledProgress) return false;
      return (
        (pageType === "index" && pageProgress) ||
        (pageType !== "index" && contentProgress)
      );
    },
    isChangeHeight() {
      //当前最小高度输入中
      let idArr = (this.miniHeightId && this.miniHeightId.split("_")) || [];
      return (
        (idArr.length >= 3 &&
          idArr[0] === this.ckeditorPrefix &&
          idArr[1] === this.currentId) ||
        false
      );
    },
    isHighlight() {
      return (
        (this.popShowId &&
          this.popShowId.includes(
            this.ruleForm[this.getCkId()] + "_" + this.componentName
          )) ||
        false
      );
    },
    list() {
      return (
        (this.detail["tabgroup_list"] &&
          this.detail["tabgroup_list"][0].setting_list.filter(
            (v) =>
              v.input_type === "richtext_mini" || v.input_type === "richtext"
          )) ||
        []
      );
    },

    menuBtn() {
      //菜单开始按钮
      if (this.pageType == "index") {
        return (
          (this.detail["tabgroup_list"] &&
            this.detail["tabgroup_list"][0].setting_list.filter(
              (v) => v.key_name === "courFse-_startbtn-properties-_isEnabled"
            )) ||
          []
        );
      } else {
        return (
          (this.detail["tabgroup_list"] &&
            this.detail["tabgroup_list"][0].setting_list.filter(
              (v) => v.key_name === "content-_startbtn-properties-_isEnabled"
            )) ||
          []
        );
      }
    },
    pageType() {
      //区分首页 index、主题页 menu、内容页 content
      return this.$route.query.pageType;
    },
    courseHeaderStyle() {
      // ?x-oss-process=style/m
      return {
        "background-image": this.ruleForm[this.styleProps["_backgroundImage"]]
          ? `url(${this.ruleForm[this.styleProps["_backgroundImage"]]})`
          : "none",
        "background-repeat":
          this.ruleForm[this.styleProps["_backgroundRepeat"]] || "repeat",
        "background-position":
          this.ruleForm[this.styleProps["_backgroundPosition"]] || "0% 0%",
        "background-size":
          this.ruleForm[this.styleProps["_backgroundSize"]] || "auto",
        "min-height":
          this.headerMinHeight == "auto"
            ? this.headerMinHeight
            : this.headerMinHeight + "px",
      };
    },
    tabList() {
      return (this.popDetail && this.popDetail.tabgroup_list) || [];
    },
    styleProps() {
      //获取样式各项属性名
      let styleProps = {};
      this.tabList[1] &&
        this.tabList[1].setting_list &&
        this.tabList[1].setting_list.forEach((v) => {
          if (v.properties_name == "_backgroundStyles") {
            v.properties.forEach((sval) => {
              styleProps[sval.properties_name] = sval.key_name;
            });
          } else {
            styleProps[v.properties_name] =
              (v.properties && v.properties[0].key_name) || v.key_name;
          }
        });
      return styleProps;
    },
    currentId() {
      //当前id
      let pageType = this.pageType;
      let ids = {
        index: this.$route.params.id,
        content: this.$route.params.contentId,
        menu: this.$route.params.contentId,
      };
      return ids[pageType];
    },
    componentName() {
      let pageType = this.$route.query.pageType;
      let obj = {
        index: "index-index",
        menu: "contentobject-title",
        content: "contentobject-content",
      };
      return obj[pageType];
    },
    popDetail() {
      // let pageType = this.$route.query.pageType;
      // let obj = {
      //   index: "index-index",
      //   menu: "contentobject-title",
      //   content: "contentobject-content",
      // };
      return this.schemaJson[this.componentName] || {};
    },
  },
  watch: {
    $route: {
      handler(n) {
        if (n) {
          this.isFirst = true;
          // this.getPopDetail();
        }
      },
      immediate: true,
    },
    ruleForm: {
      handler(n) {
        if (n) {
          let ruleArr = Object.keys(this.ruleForm);
          if (this.isFirst && ruleArr.length > 0) {
            this.isFirst = false;
            this.dynamicForm = this.ruleForm; //首次赋值
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    // 背景高度监听
    this.$watch(
      function () {
        // 第一个函数就是处理你要监听的属性，只要将其return出去就行
        // return this.ruleForm['course-_boxMenu-properties-_menuHeader-properties-_minimumHeights-properties-_large'];
        this.headerMinHeight =
          this.ruleForm[this.styleProps["_minimumHeights"]];
        return this.ruleForm[this.styleProps["_minimumHeights"]];
      },
      (val, old) => {
        this.headerMinHeight = val || 0;
      }
    );
  },
  destroyed() {
    this.closePop();
  },
  methods: {
    ...mapMutations([
      "openPop",
      "closePop",
      "SET_COURSE_FORM",
      "inputHighLight",
    ]),
    ...mapActions(["SaveCourse"]),
    //左侧弹窗事件
    formChangeBlur(value, propName, leftForm) {
      let obj = {};
      obj[propName] = value;
      this.dynamicForm[propName] = value;
      this.ckFocusStatus[propName] = false;
      this.ruleForm[propName] = value;
      // this.$set(this.detail, propName, value);
      // this.$forceUpdate();
      this.savePageDetail(obj);
    },
    openInnerDrawer({ list, title, visible }) {
      // 显示\隐藏二层弹出窗口
      this.innerDrawer.title = title;
      this.innerDrawer.propList = list;

      if (!visible || visible == 2) {
        this.innerDrawer.visible = false;
        return;
      } else {
        this.innerDrawer.visible = true;
      }
    },
    closeInnerDrawer({ list, title, visible } = {}) {
      // 显示\隐藏二层弹出窗口
      if (!visible) {
        this.innerDrawer.visible = false;
        return;
      }
    },
    handleFocus(val, key_name) {
      // console.log('handleFocus', this.dynamicForm[key_name], key_name);
      // this.ckFocusStatus[key_name] = true;
    },
    inputKey(val) {
      // console.log('val',val);
    },
    getPopDetail() {
      let pageType = this.$route.query.pageType;

      //首页（特殊的主题页）index-index，主题页 contentobject-title ，内容页 contentobject-content
      let obj = {
        index: "index-index",
        menu: "contentobject-title",
        content: "contentobject-content",
      };

      this.popDetail = this.schemaJson[obj[pageType]];
    },
    handleInput(value, propName) {
      //当前页面输入 - 不要回写数据-不然会有输入重复bug

      //如果右侧弹窗输入，刷新当前触发的change事件，不更新
      if (!!this.drawerSet.visible) return;

      let obj = {};
      obj[propName] = value;
      this.savePageDetail(obj);
    },
    formItemBlur(value, propName, isUpdate = true) {
      // 关闭按钮后关闭二级侧栏
      if (!value && typeof value === "boolean") {
        this.closeInnerDrawer(1);
      }
      let obj = {};
      obj[propName] = value;
      this.dynamicForm[propName] = value;

      // 失去焦点
      this.ckFocusStatus[propName] = false;

      this.ruleForm[propName] = value;
      !!isUpdate && this.savePageDetail(obj);
    },
    formItemInput(value, propName) {
      //右侧弹窗 输入
      let obj = {};
      obj[propName] = value;
      this.dynamicForm[propName] = value;
      this.savePageDetail(obj);
    },
    savePageDetail(updateObj) {
      //保存章节详情
      let pageType = this.$route.query.pageType;
      let course_id = this.$route.params.id;
      let contentId = this.$route.params.contentId;
      //首页（特殊的主题页）index-index，主题页 contentobject-title ，内容页 content
      let params = {
        page_key: "",
        course_id: course_id,
        properties_data: updateObj,
        theme_type: this.theme,
      };

      // 是首页
      if (pageType == "index" && !contentId) {
        params["page_key"] = "index-index";
      }
      // 主题页
      if (pageType == "menu" && contentId) {
        params["page_key"] = "contentobject-title";
        params["contentobject_id"] = contentId;
      }
      // 内容页
      if (pageType === "content" && contentId) {
        params["page_key"] = "contentobject-content";
        params["contentobject_id"] = contentId;
      }

      let haschangeMinHeight =
        Object.keys(updateObj).findIndex((v) =>
          v.indexOf("_menuHeader-properties-_minimumHeights")
        ) != -1;
      if (haschangeMinHeight) {
        params.refreshOther = false;
      }
      this.SaveCourse(params).then((res) => {
        if (res.errorcode == 0) {
          if (haschangeMinHeight) {
            this.headerMinHeight = res.data[this.styleProps["_minimumHeights"]];
          }
        }
      });
    },
    getCkId() {
      let pageType = this.pageType;
      let ids = {
        index: "course_id",
        content: "contentobject_id",
        menu: "contentobject_id",
      };
      return ids[pageType];
    },
    openFrame() {
      let openType = this.popDetail.type;
      switch (openType) {
        case "right":
          this.openPop({
            type: this.componentName,
            id: this.ruleForm[this.getCkId()],
            ids: [this.ruleForm[this.getCkId()] + "_" + this.componentName],
          });
          this.drawerSet.title = this.popDetail.title;
          this.drawerSet.visible = true;
          break;
        case "center":
          //待处理 中间弹窗
          break;
      }
    },
    uploadImg(val, propName) {
      // console.log('uploadImg',val, propName)
    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    changeVal(value, prop) {},
    changeFormProp(prop, data) {
      //更新courseForm 某个属性
      let ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
      ruleForm[prop] = data;
      return ruleForm;
    },
    selectChange(e, prop) {
      this.formItemBlur(e, prop);
    },
    handleClose(done) {
      //关闭右侧弹窗
      this.drawerSet.visible = false;
      this.closePop();
      typeof done === "function" && done();
    },

    clickTools(type) {
      switch (type) {
        case "edit":
          this.showEdit();
          break;
        case "add":
          break;
        case "del":
          break;
      }
    },
    showEdit() {
      this.openFrame();
    },
  },
};
</script>

<style lang="less" scoped>
//由于内部弹出窗口
.drawer-right {
  /deep/ &.el-drawer {
    overflow: visible;
    .el-drawer__body {
      overflow: visible;
    }
  }
}
[contenteditable]:focus {
  outline: none;
}
.course-header {
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  padding-block: 100px;
  .height-line {
    position: absolute;
    z-index: 0;
    display: block;
    content: "";
    left: 0px;
    top: 0px;
    bottom: 0;
    height: 100%;
    width: 100%;
  }
  .course_arrow_up,
  .course_arrow_down {
    position: absolute;
    left: 38px;
    width: 100%;
    height: calc(50% - 20px);
    .arrow-up {
      position: absolute;
      font-size: 10px;
      top: 0px;
      left: 0px;
      transform: translate(-50%);
      transform-origin: center;
      z-index: 1;
    }
    .arrow-down {
      position: absolute;
      font-size: 10px;
      bottom: 0px;
      left: 0px;
      transform: translate(-50%);
      transform-origin: center;
      z-index: 1;
    }
  }
  .course_arrow_up {
    top: 0;
  }
  .course_arrow_down {
    bottom: 0;
  }
  .height-value {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translate(0, -50%);
    border-radius: 4px;
    padding: 1px 4px;
    line-height: 16px;
    height: 17px;
    font-size: 12px;
    color: #fff;
    background-color: #51cfae;
  }
  // 虚线
  .course_dashed_line {
    position: absolute;
    left: 38px;
    height: calc(50% - 20px);
    border-left: 1px dashed #56cfae;
    transform: translateX(-50%);
    &.bottom {
      bottom: 0;
    }
    &.top {
      top: 0;
    }
  }
}
.course-header-inner {
  box-sizing: border-box;
  padding: 0 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .menuStartBtn {
    button {
      background: var(--start-btn-color);
      color: var(--start-btn-color-inverted);
      border: 1px solid var(--start-btn-border-color);
      font-size: 14px;
      font-weight: 400;
      padding: 8px 16px;
      border-radius: 18px;
      &:hover {
        color: var(--start-btn-hover-color-inverted);
        background: var(--start-btn-hover-color);
      }
    }
  }
}
// 右侧弹窗头部高度
@drawHeaderHeight: 54px;
.drawer-right-inner {
  width: 100%;
  height: 100%;
  background-color: #fff;
  .header {
    box-sizing: border-box;
    height: @drawHeaderHeight;
  }
  .header-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 19px 16px;
  }
  .drawer-right-inner-form {
    box-sizing: border-box;
    padding-top: 16px;
    height: calc(100% - @drawHeaderHeight);
  }
}
.progress-position {
  left: 0;
  z-index: 0;
  padding-top: 2px;
  line-height: 1.3;
  display: inline-block;
  vertical-align: middle;
  &.hasValue {
    position: absolute;
  }
  .progress-text {
    width: 0;
    overflow: hidden;
    visibility: hidden;
    display: inline;
    vertical-align: middle;
    /deep/p {
      width: 0;
      overflow: hidden;
      display: inline;
      &:not(:first-child) {
        display: none;
      }
    }
  }
}
</style>
